import { gql } from '@apollo/client'
import {
  Alert,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
} from '@mui/material'
import _ from 'lodash'
import { useState } from 'react'
import { makeStylesFast } from 'siteline-common-web'
import { EditableCardRowText } from '../../../../common/components/EditableCardRow'
import * as fragments from '../../../../common/graphql/Fragments'
import { useUpdateIntegrationMappingsMutation } from '../../../../common/graphql/apollo-operations'
import { TexturaIntegration } from '../../../../common/util/Integration'
import { TexturaRefreshDialog } from './TexturaRefreshDialog'

gql`
  mutation updateIntegrationMappings($input: UpdateIntegrationMappingsInput!) {
    updateIntegrationMappings(input: $input) {
      ...IntegrationProperties
    }
  }
  ${fragments.integration}
`

const useStyles = makeStylesFast(() => ({
  card: {
    paddingBottom: 0,
  },
  cardContent: {
    padding: 0,
    '&:last-child': {
      padding: 0,
    },
    '& .MuiFormControl-root': {
      width: '100%',
    },
  },
}))

type TexturaIntegrationDetailsProps = {
  integration: TexturaIntegration
  contract: fragments.Contract
}

export function TexturaIntegrationDetails({
  integration,
  contract,
}: TexturaIntegrationDetailsProps) {
  const classes = useStyles()
  const [texturaRefreshDialogOpen, setTexturaRefreshDialogOpen] = useState<boolean>(false)
  const [updateMappings] = useUpdateIntegrationMappingsMutation()

  return (
    <>
      <Card className={classes.card}>
        <CardHeader
          title="Textura Metadata"
          action={
            <Button color="primary" onClick={() => setTexturaRefreshDialogOpen(true)}>
              Refresh Textura Data
            </Button>
          }
        />
        <Divider />
        <CardContent className={classes.cardContent}>
          <Table>
            <TableBody>
              {integration.mappings.project.texturaProjectId ? (
                <EditableCardRowText
                  readOnly={false}
                  value={integration.mappings.project.texturaProjectId}
                  label="Textura Project ID"
                  variables={(value) => {
                    const newMappings = _.cloneDeep(integration.mappings)
                    newMappings.project.texturaProjectId = value
                    return {
                      input: {
                        integrationId: integration.id,
                        mappings: newMappings as Record<string, unknown>,
                      },
                    }
                  }}
                  mutate={updateMappings}
                />
              ) : (
                <TableCell>
                  <Alert severity="error">Missing project info, refresh Textura data.</Alert>
                </TableCell>
              )}
              {integration.mappings.contract.texturaContractId ? (
                <EditableCardRowText
                  readOnly={false}
                  value={integration.mappings.contract.texturaContractId}
                  label="Textura Contract ID"
                  variables={(value) => {
                    const newMappings = _.cloneDeep(integration.mappings)
                    newMappings.contract.texturaContractId = value
                    return {
                      input: {
                        integrationId: integration.id,
                        mappings: newMappings as Record<string, unknown>,
                      },
                    }
                  }}
                  mutate={updateMappings}
                />
              ) : (
                <TableCell>
                  <Alert severity="error">Missing contract info, refresh Textura data.</Alert>
                </TableCell>
              )}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
      <Card className={classes.card}>
        <CardHeader title="Textura Vendors" />
        <Divider />
        <CardContent className={classes.cardContent}>
          <Table>
            <TableBody>
              {integration.mappings.vendorContracts.map((contract, index) => (
                <EditableCardRowText
                  readOnly={false}
                  value={contract.texturaVendorContractId}
                  label={contract.sitelineVendorId}
                  variables={(value) => {
                    const newMappings = _.cloneDeep(integration.mappings)
                    newMappings.vendorContracts[index].texturaVendorContractId = value
                    return {
                      input: {
                        integrationId: integration.id,
                        mappings: newMappings as Record<string, unknown>,
                      },
                    }
                  }}
                  mutate={updateMappings}
                  key={index}
                />
              ))}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
      <TexturaRefreshDialog
        open={texturaRefreshDialogOpen}
        setOpen={setTexturaRefreshDialogOpen}
        integration={integration}
        contract={contract}
      />
    </>
  )
}
