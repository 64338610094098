import { gql } from '@apollo/client'
import {
  Alert,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
} from '@mui/material'
import { useCallback, useState } from 'react'
import { useSitelineSnackbar } from 'siteline-common-web'
import { useMarkWriteSyncAsFailedMutation } from '../../common/graphql/apollo-operations'
import { WriteSyncOperation } from './WriteSyncOperationRow'

gql`
  mutation markWriteSyncAsFailed($input: MarkWriteSyncAsFailedInput!) {
    markWriteSyncAsFailed(input: $input) {
      id
      updatedAt
      status
      result
    }
  }
`

type MarkAsFailedDialogProps = {
  operation: WriteSyncOperation
  open: boolean
  onClose: () => void
}

/**
 * Dialog that allows to mark a write sync operation as failed and notify the user.
 */
export function MarkAsFailedDialog({ operation, open, onClose }: MarkAsFailedDialogProps) {
  const [notifyUser, setNotifyUser] = useState(true)
  const [markAsFailed] = useMarkWriteSyncAsFailedMutation()
  const snackbar = useSitelineSnackbar()

  const handleSubmit = useCallback(() => {
    snackbar.showLoading('Marking as failed...')
    markAsFailed({
      variables: {
        input: {
          writeSyncOperationId: operation.id,
          notifyUser,
        },
      },
    })
      .then(() => {
        snackbar.showSuccess('Operation marked as failed')
        onClose()
      })
      .catch((err) => {
        snackbar.showError(err)
      })
  }, [markAsFailed, notifyUser, onClose, operation.id, snackbar])

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Mark as failed</DialogTitle>
      <DialogContent>
        <Alert severity="warning">
          Only do this if the operation is stuck in the Not Started or Running state. This can
          happen if our task service fails to talk to the integration service. Alternatively, you
          can re-queue the operation to try to get it unstuck.
        </Alert>
        <FormControlLabel
          control={
            <Checkbox
              value={notifyUser}
              checked={notifyUser}
              onChange={(ev, checked) => setNotifyUser(checked)}
            />
          }
          label="Notify user who initiated the sync"
        />
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button onClick={handleSubmit}>Mark as failed</Button>
      </DialogActions>
    </Dialog>
  )
}
