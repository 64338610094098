import { EmailType, NotificationType } from '../graphql/apollo-operations'

export type NotificationSections = {
  [sectionTitle in 'RETENTION' | 'PAY_APP' | 'LIEN_WAIVER']: NotificationType[]
}

export function getSectionForNotificationType(type: NotificationType): string {
  const sections = getNotificationPreferencesSections()
  let category = ''
  Object.keys(sections).forEach((section) => {
    if (sections[section as keyof NotificationSections].includes(type)) {
      category = section
    }
  })
  return category
}

export function getNotificationPreferencesSections(): NotificationSections {
  return {
    RETENTION: [
      NotificationType.RETENTION_COMPLETE,
      NotificationType.RETENTION_HALFWAY,
      NotificationType.RETENTION_REMINDER,
    ],
    PAY_APP: [NotificationType.PAY_APP_PASTDUE, NotificationType.OPTED_OUT_OF_BILLING],
    LIEN_WAIVER: [
      NotificationType.LOWER_TIER_LIEN_WAIVER_COMPLETE,
      NotificationType.UNCONDITIONAL_LIEN_WAIVER_REMINDER,
    ],
  }
}

export type EmailSections = {
  [sectionTitle in 'WEEKLY' | 'DAILY' | 'GENERAL']: EmailType[]
}

export function getSectionForEmailType(type: EmailType): string {
  const sections = getEmailPreferencesSections()
  let category = ''
  Object.keys(sections).forEach((section) => {
    if (sections[section as keyof EmailSections].includes(type)) {
      category = section
    }
  })
  return category
}

export function getEmailPreferencesSections(): EmailSections {
  return {
    WEEKLY: [EmailType.WEEKLY_PAY_APP, EmailType.WEEKLY_LEGAL_REQUIREMENT],
    DAILY: [EmailType.DAILY_PAY_APP, EmailType.DAILY_LEGAL_REQUIREMENT],
    GENERAL: [
      EmailType.PAY_APP_PAID,
      EmailType.LOWER_TIER_LIEN_WAIVER_COMPLETE,
      EmailType.CREATE_PAY_APP,
      EmailType.TEXTURA_SYNC,
      EmailType.MONTHLY_REPORT,
      EmailType.ADDED_TO_PROJECT,
      EmailType.OPTED_OUT_OF_BILLING,
      EmailType.ONBOARDED_PROJECT_FORMS,
    ],
  }
}
