import { gql } from '@apollo/client'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material'
import { useCallback, useState } from 'react'
import { SitelineText, useSitelineSnackbar } from 'siteline-common-web'
import * as fragments from '../../common/graphql/Fragments'
import {
  FormTemplateProperties,
  useSkipFormTemplateValidationMutation,
} from '../../common/graphql/apollo-operations'

gql`
  mutation skipFormTemplateValidation($input: SkipFormTemplateValidationInput!) {
    skipFormTemplateValidation(input: $input) {
      ...FormTemplateProperties
    }
  }
  ${fragments.formTemplate}
`

interface SkipFormTemplateValidationDialogProps {
  open: boolean
  onClose: () => void
  formTemplate: FormTemplateProperties
}

export function SkipFormTemplateValidationDialog({
  open,
  onClose,
  formTemplate,
}: SkipFormTemplateValidationDialogProps) {
  const snackbar = useSitelineSnackbar()
  const [skipValidation] = useSkipFormTemplateValidationMutation()
  const [comment, setComment] = useState<string>('')

  const resetDialog = useCallback(() => {
    setComment('')
  }, [])

  const handleSkipValidation = useCallback(async () => {
    try {
      await skipValidation({
        variables: { input: { formTemplateId: formTemplate.id, message: comment } },
      })
      snackbar.showSuccess('Skipped validation and added comment')
      onClose()
    } catch (err) {
      snackbar.showError(err.message)
    }
  }, [comment, formTemplate.id, onClose, skipValidation, snackbar])

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      TransitionProps={{ onEnter: resetDialog, onExited: resetDialog }}
    >
      <DialogTitle>Skip validation?</DialogTitle>
      <DialogContent>
        <SitelineText variant="body1" color="grey50">
          Skipping validation is not recommended unless essential. If you do skip for now, the
          validator should still validate the form as soon as possible.
        </SitelineText>
        <TextField
          multiline
          rows={2}
          value={comment}
          autoFocus
          onChange={(ev) => ev.target.value.length <= 1000 && setComment(ev.target.value)}
          sx={{ marginTop: 2, width: '100%' }}
          placeholder="Leave a comment explaining why validation is being skipped"
        />
      </DialogContent>
      <DialogActions sx={{ padding: 2.5 }}>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button
          onClick={handleSkipValidation}
          variant="contained"
          color="error"
          disabled={!comment}
        >
          Skip validation
        </Button>
      </DialogActions>
    </Dialog>
  )
}
