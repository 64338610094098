import CloseIcon from '@mui/icons-material/Close'
import SearchIcon from '@mui/icons-material/Search'
import { IconButton, InputAdornment, InputBase, Theme } from '@mui/material'
import { clsx } from 'clsx'
import _ from 'lodash'
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react'
import { isDesktop } from 'react-device-detect'
import { colors } from '../themes/Styles'
import { makeStylesFast } from '../themes/fast-styles'

const useStyles = makeStylesFast((theme: Theme) => ({
  search: {
    backgroundColor: colors.grey20,
    color: colors.grey70,
    padding: theme.spacing(0, 1),
    border: '1px solid transparent',
    borderRadius: theme.spacing(0.5),
    fontSize: theme.typography.body2.fontSize,
    '&.whiteTheme': {
      backgroundColor: colors.white,
      borderColor: colors.grey30,
    },
    '& input::placeholder': {
      color: colors.grey50,
      opacity: 1,
    },
    margin: theme.spacing(0, 2),
    height: '40px',
    width: 'auto',
    flexGrow: 1,
    '&:hover:not(.disabled)': {
      borderColor: colors.grey40,
    },
    '& .MuiSvgIcon-root': {
      fontSize: 18,
      color: colors.grey50,
    },
    '&:focus-within': {
      backgroundColor: colors.white,
      borderColor: colors.grey70,
      '& .MuiSvgIcon-root': {
        color: colors.grey70,
      },
    },
    '&.disabled': {
      '& input::placeholder': {
        opacity: 0.5,
      },
    },
    '& .endAdornment': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      height: '100%',
      '&:has(.closeIcon), &:has(.endIcon)': {
        '& .endIcon': {
          marginLeft: theme.spacing(-1),
        },
      },
    },
  },
}))

export interface SitelineSearchProps {
  /**
   * Typically a React anti-pattern but we need a way to clear the local state variable inside
   * this search box from outside of this component. In order to do this, you should set this
   * variable as follows:
   *
   * const [clearSearch, setClearSearch] = useState<number>(0)
   *
   * The initial value of 0 is important so we don't clear in any initialSearch values on the
   * initial render. If you want to clear the search, you should increase the value of clearSearch
   * by 1 each time.
   *
   * See https://stackoverflow.com/a/60739001 for more info.
   */
  clearSearch?: number
  initialSearch?: string
  onSearchChanged: (value: string) => void
  placeholder: string
  showClearButton?: boolean
  autoFocus?: boolean
  theme?: 'white' | 'grey'
  className?: string
  disabled?: boolean
  endIcon?: ReactNode
}

export function SitelineSearch({
  clearSearch,
  initialSearch,
  onSearchChanged,
  placeholder,
  showClearButton,
  autoFocus,
  theme = 'grey',
  className,
  disabled = false,
  endIcon,
}: SitelineSearchProps) {
  const classes = useStyles()
  const [search, setSearch] = useState(initialSearch ?? '')

  // Special case to clear out the local state
  useEffect(() => {
    if (clearSearch && search !== '') {
      setSearch('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clearSearch])

  const handleUpdateSearchTerm = useMemo(
    () =>
      _.debounce((value) => {
        onSearchChanged(value ?? '')
      }),
    [onSearchChanged]
  )

  const handleUpdateLocally = useCallback(
    (value?: string) => {
      setSearch(value ?? '')
      handleUpdateSearchTerm(value ?? '')
    },
    [handleUpdateSearchTerm]
  )

  const handleClearSearch = () => {
    handleUpdateLocally('')
  }

  const hasSearch = search.length > 0
  const shouldShowClearButton = hasSearch && showClearButton
  const shouldShowEndAdornment = endIcon !== undefined || shouldShowClearButton

  return (
    <InputBase
      className={clsx(classes.search, className, {
        whiteTheme: theme === 'white',
        disabled,
        hasSearch,
      })}
      placeholder={placeholder}
      startAdornment={
        <div className="startAdornment">
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        </div>
      }
      endAdornment={
        shouldShowEndAdornment ? (
          <div className="endAdornment">
            <InputAdornment position="end">
              <IconButton color="secondary" onClick={handleClearSearch} size="small">
                <CloseIcon className="closeIcon" />
              </IconButton>
            </InputAdornment>
            {endIcon && <div className="endIcon">{endIcon}</div>}
          </div>
        ) : undefined
      }
      fullWidth={true}
      value={search}
      onChange={(event) => handleUpdateLocally(event.target.value)}
      // Don't autofocus on touch devices
      autoFocus={autoFocus && isDesktop}
      disabled={disabled}
    />
  )
}
