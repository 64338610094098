import { gql } from '@apollo/client'
import {
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  FormControlLabel,
  Table,
  Theme,
} from '@mui/material'
import { useState } from 'react'
import { FormTemplateType, makeStylesFast } from 'siteline-common-web'
import { EditableFormTemplateCardRow } from '../../common/components/EditableFormTemplateCardRow'
import * as fragments from '../../common/graphql/Fragments'
import { DetailedCompany } from '../../common/graphql/Fragments'
import {
  UpdateDefaultChangeOrderLogFormInput,
  useUpdateDefaultChangeOrderLogFormMutation,
} from '../../common/graphql/apollo-operations'

const useStyles = makeStylesFast((theme: Theme) => ({
  card: {
    marginTop: theme.spacing(2),
  },
}))

gql`
  mutation updateDefaultChangeOrderLogForm($input: UpdateDefaultChangeOrderLogFormInput!) {
    updateDefaultChangeOrderLogForm(input: $input) {
      ...CompanyForDetailsProperties
    }
  }
  ${fragments.companyForDetails}
`

/** Manage change order log template for the company. */
export function CompanyDetailsChangeOrderLog({ company }: { company: DetailedCompany }) {
  const classes = useStyles()
  const [updateContractTemplates, setUpdateContractTemplates] = useState<boolean>(true)
  const [showCheckbox, setShowCheckbox] = useState<boolean>(false)

  const [updateChangeOrderLogForm] = useUpdateDefaultChangeOrderLogFormMutation()

  return (
    <Card className={classes.card}>
      <CardHeader title="Change Order Log Template" />
      <CardContent>
        {showCheckbox && (
          <FormControlLabel
            control={
              <Checkbox
                checked={updateContractTemplates}
                onChange={(ev) => setUpdateContractTemplates(ev.target.checked)}
                size="small"
              />
            }
            label="Update existing projects to use new template (if using current default)"
            style={{ paddingLeft: 16 }}
          />
        )}
        <Table>
          <EditableFormTemplateCardRow
            defaultTemplateId={company.defaultChangeOrderLogTemplate?.id}
            variables={(value?: string | null) => {
              const input: UpdateDefaultChangeOrderLogFormInput = {
                companyId: company.id,
                changeOrderLogFormTemplateId: value,
                updateContractTemplates,
              }
              return {
                input,
              }
            }}
            label="Default Change Order Log Template"
            templateType={FormTemplateType.CHANGE_ORDER_LOG}
            mutate={updateChangeOrderLogForm}
            readOnly={false}
            onToggleEditing={setShowCheckbox}
          />
        </Table>
      </CardContent>
    </Card>
  )
}
