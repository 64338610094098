import { gql } from '@apollo/client'
import { Alert } from '@mui/material'
import { useParams } from 'react-router-dom'
import { isMonthlyBillingType } from 'siteline-common-all'
import { Loader } from '../../common/components/Loader'
import * as fragments from '../../common/graphql/Fragments'
import {
  BillingType,
  GetContractQuery,
  useGetContractQuery,
} from '../../common/graphql/apollo-operations'
import ContractDetailsLumpSum from './lump-sum/ContractDetailsLumpSum'
import ContractDetailsQuickBill from './quick-bill/ContractDetailsQuickBill'

gql`
  query getContract($id: ID!) {
    contract(id: $id) {
      ...ContractProperties
      project {
        ...ProjectProperties
      }
      company {
        ...CompanyProperties
        users {
          ...CompanyUserProperties
        }
        companyIntegrations {
          ...CompanyIntegrationProperties
        }
      }
      users {
        id
        user {
          ...UserProperties
        }
      }
      payApps {
        ...PayAppNoProgressProperties
        payAppNumber
      }
    }
  }
  ${fragments.project}
  ${fragments.contract}
  ${fragments.company}
  ${fragments.user}
  ${fragments.companyUser}
  ${fragments.payAppNoProgress}
  ${fragments.companyIntegration}
`

export type ProjectForSubcontractor = GetContractQuery['contract']['project']
export type ContractForDetails = GetContractQuery['contract']
export type ContractPayApp = ContractForDetails['payApps'][number]
export type UserForContractDetails = GetContractQuery['contract']['users'][number]['user']

type ContractDetailsParams = {
  contractId: string
}

export default function ContractDetails() {
  const { contractId } = useParams() as ContractDetailsParams

  const {
    data: contractData,
    error: contractError,
    loading: contractLoading,
  } = useGetContractQuery({
    variables: { id: contractId },
    fetchPolicy: 'cache-and-network',
  })

  const isLoadingFirstTime = contractLoading && !contractData

  if (isLoadingFirstTime) {
    return <Loader />
  }

  const contract = contractData?.contract
  const payApps = [...(contract?.payApps ?? [])]

  return (
    <>
      {contractLoading && <Loader />}
      {contractError && <Alert severity="error">{contractError.message}</Alert>}
      {contract && (
        <>
          {isMonthlyBillingType(contract.billingType) && (
            <ContractDetailsLumpSum
              project={contract.project}
              company={contract.company}
              payApps={payApps}
              contract={contract}
            />
          )}
          {contract.billingType === BillingType.QUICK && (
            <ContractDetailsQuickBill
              project={contract.project}
              company={contract.company}
              payApps={payApps}
              contract={contract}
            />
          )}
        </>
      )}
    </>
  )
}
