import { gql } from '@apollo/client'
import { DeleteSweep } from '@mui/icons-material'
import { Button, Card, CardActions, CardContent, CardHeader, Typography } from '@mui/material'
import { DateCalendar } from '@mui/x-date-pickers'
import moment, { Moment } from 'moment-timezone'
import { useCallback, useMemo, useState } from 'react'
import { useSitelineSnackbar } from 'siteline-common-web'
import { useDeleteLienWaiverRequestsForMonthMutation } from '../../common/graphql/apollo-operations'
import DemoCompanySelector from './DemoCompanySelector'

gql`
  mutation deleteLienWaiverRequestsForMonth($input: DeleteLienWaiverRequestsForMonthInput!) {
    deleteLienWaiverRequestsForMonth(input: $input)
  }
`

/**
 * Demo tool to delete vendor lien waivers for a specific month
 */
export default function DemoToolsDeleteLienWaivers() {
  const snackbar = useSitelineSnackbar()
  const [companyId, setCompanyId] = useState<string | null>(null)
  const [deleteLienWaiverRequests] = useDeleteLienWaiverRequestsForMonthMutation()

  const initialMonthAndYear = useMemo(() => moment.tz(moment.tz.guess()).startOf('month'), [])
  const [monthAndYear, setMonthAndYear] = useState<Moment>(initialMonthAndYear)

  const handleDeleteLienWaiverRequests = useCallback(() => {
    if (!companyId) {
      return
    }
    const confirmed = window.confirm(
      'Are you sure you want to delete all lien waivers for this month?'
    )
    if (!confirmed) {
      return
    }
    snackbar.showLoading('Deleting lien waivers')
    deleteLienWaiverRequests({
      variables: {
        input: {
          companyId,
          month: monthAndYear.month(),
          year: monthAndYear.year(),
        },
      },
    })
      .then(() => snackbar.showSuccess())
      .catch((err) => snackbar.showError(err.message))
  }, [companyId, deleteLienWaiverRequests, monthAndYear, snackbar])

  return (
    <Card>
      <CardHeader title="Delete lien waivers" />
      <CardContent>
        <Typography>Find all vendor lien waivers for a month and delete them.</Typography>
        <DemoCompanySelector companyId={companyId} onCompanyIdChange={setCompanyId} />
        <DateCalendar
          value={monthAndYear}
          onChange={(value) => setMonthAndYear(value)}
          views={['year', 'month']}
          openTo="month"
          sx={{ height: 250, marginTop: 2 }}
        />
      </CardContent>
      <CardActions>
        <Button
          startIcon={<DeleteSweep />}
          disabled={!companyId}
          onClick={handleDeleteLienWaiverRequests}
        >
          Delete lien waivers and their requests
        </Button>
      </CardActions>
    </Card>
  )
}
