import { gql, useApolloClient } from '@apollo/client'
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Grid2,
  Table,
  TableBody,
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { makeStylesFast, useSitelineSnackbar } from 'siteline-common-web'
import {
  EditableCardRowCompany,
  EditableCardRowLocation,
  EditableCardRowNumber,
  EditableCardRowSelect,
  EditableCardRowText,
} from '../../common/components/EditableCardRow'
import IdentifierRow from '../../common/components/IdentifierRow'
import * as fragments from '../../common/graphql/Fragments'
import { DetailedProject } from '../../common/graphql/Fragments'
import {
  GetCompanyDocument,
  GetCompanyQuery,
  GetCompanyQueryVariables,
  LocationProperties,
  useDeleteProjectMutation,
  useUpdateProjectMutation,
} from '../../common/graphql/apollo-operations'

const useStyles = makeStylesFast(() => ({
  card: {
    paddingBottom: 0,
  },
  cardContent: {
    padding: 0,
    '&:last-child': {
      padding: 0,
    },
  },
  actions: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
}))

gql`
  mutation updateProject($input: UpdateProjectInput!) {
    updateProject(input: $input) {
      ...ProjectForDetailsProperties
    }
  }
  ${fragments.projectForDetails}
`

gql`
  mutation deleteProject($id: ID!) {
    deleteProject(id: $id) {
      id
    }
  }
`

export default function ProjectDetailsOverview({ project }: { project: DetailedProject }) {
  const classes = useStyles()

  const client = useApolloClient()
  const [updateProjectMutation] = useUpdateProjectMutation()
  const [deleteProjectMutation] = useDeleteProjectMutation()
  const snackbar = useSitelineSnackbar()
  const navigate = useNavigate()

  const handleDelete = () => {
    if (!window.confirm('Are you sure you want to delete this project?')) {
      return
    }
    snackbar.showLoading()
    deleteProjectMutation({
      variables: {
        id: project.id,
      },
    })
      .then(() => {
        snackbar.showSuccess()
        navigate('/')
      })
      .catch((err) => snackbar.showError(err.message))
  }

  const addressDescription = (location: LocationProperties) => {
    return location.nickname ?? location.street1 ?? location.street2 ?? location.city
  }

  const ownerLocations = project.owner?.company.locations ?? []
  const generalContractorLocations = project.generalContractor?.company.locations ?? []
  const architectLocations = project.architect?.company.locations ?? []

  return (
    <Grid2 size={{ xs: 6 }}>
      <Card className={classes.card}>
        <CardHeader title="Overview" />
        <Divider />
        <CardContent className={classes.cardContent}>
          <Table>
            <TableBody>
              <IdentifierRow id={project.id} />
              <EditableCardRowText
                readOnly={false}
                value={project.name}
                label="Name"
                variables={(value: string) => ({
                  input: {
                    id: project.id,
                    name: value,
                  },
                })}
                mutate={updateProjectMutation}
              />
              <EditableCardRowLocation
                readOnly={false}
                value={project.location}
                label="Address"
                mutate={updateProjectMutation}
                variables={(value) => ({
                  input: {
                    id: project.id,
                    location: {
                      ...value,
                      timeZone: undefined,
                      id: undefined,
                      __typename: undefined,
                      createdAt: undefined,
                      nickname: value.nickname || null,
                      street1: value.street1 || null,
                      street2: value.street2 || null,
                      postalCode: value.postalCode || null,
                      county: value.county || null,
                    },
                  },
                })}
              />

              <EditableCardRowText
                readOnly={false}
                value={project.projectNumber}
                label="Project number"
                variables={(value: string) => ({
                  input: {
                    id: project.id,
                    projectNumber: value,
                  },
                })}
                mutate={updateProjectMutation}
              />

              <EditableCardRowNumber
                readOnly={false}
                label="Full pay-app deadline"
                value={project.metadata.payAppDueOnDayOfMonth}
                mutate={updateProjectMutation}
                variables={(value) => ({
                  input: {
                    id: project.id,
                    metadata: {
                      payAppDueOnDayOfMonth: value,

                      // Workaround for https://github.com/apollographql/apollo-client/issues/4691
                      __typename: undefined,
                    },
                  },
                })}
              />
              <EditableCardRowCompany
                readOnly={false}
                label="Owner"
                value={project.owner?.company.id ?? ''}
                nameOverride={project.owner?.companyName ?? ''}
                mutate={updateProjectMutation}
                variables={async (companyId) => {
                  if (!companyId) {
                    return {
                      input: {
                        id: project.id,
                        owner: {
                          companyId: project.owner?.company.id ?? '',
                          selectedAddressId: project.owner?.selectedAddress?.id ?? '',
                        },
                      },
                    }
                  }
                  const { data } = await client.query<GetCompanyQuery, GetCompanyQueryVariables>({
                    query: GetCompanyDocument,
                    variables: { companyId },
                  })
                  return {
                    input: {
                      id: project.id,
                      owner: {
                        companyId: data.company.id,
                        selectedAddressId: data.company.locations[0].id,
                      },
                    },
                  }
                }}
              />
              <EditableCardRowSelect
                readOnly={false}
                value={project.owner?.selectedAddress?.id ?? ''}
                label="Owner address"
                options={ownerLocations.map((location) => ({
                  key: location.id,
                  value: addressDescription(location),
                }))}
                formatValue={(key) => {
                  const location = ownerLocations.find((location) => location.id === key)
                  if (!location) {
                    return ''
                  }
                  return addressDescription(location)
                }}
                variables={(locationId: string) => ({
                  input: {
                    id: project.id,
                    owner: {
                      companyId: project.owner?.company.id ?? '',
                      selectedAddressId: locationId,
                    },
                  },
                })}
                mutate={updateProjectMutation}
              />
              <EditableCardRowCompany
                readOnly={false}
                label="GC"
                value={project.generalContractor?.company.id ?? ''}
                nameOverride={project.generalContractor?.companyName ?? ''}
                mutate={updateProjectMutation}
                variables={async (companyId) => {
                  if (!companyId) {
                    return {
                      input: {
                        id: project.id,
                        generalContractor: {
                          companyId: project.generalContractor?.company.id ?? '',
                          selectedAddressId: project.generalContractor?.selectedAddress?.id ?? '',
                        },
                      },
                    }
                  }
                  const { data } = await client.query<GetCompanyQuery, GetCompanyQueryVariables>({
                    query: GetCompanyDocument,
                    variables: { companyId },
                  })
                  return {
                    input: {
                      id: project.id,
                      generalContractor: {
                        companyId: data.company.id,
                        selectedAddressId: data.company.locations[0].id,
                      },
                    },
                  }
                }}
              />
              <EditableCardRowSelect
                readOnly={false}
                value={project.generalContractor?.selectedAddress?.id ?? ''}
                label="GC address"
                options={generalContractorLocations.map((location) => ({
                  key: location.id,
                  value: addressDescription(location),
                }))}
                formatValue={(key) => {
                  const location = generalContractorLocations.find(
                    (location) => location.id === key
                  )
                  if (!location) {
                    return ''
                  }
                  return addressDescription(location)
                }}
                variables={(locationId: string) => ({
                  input: {
                    id: project.id,
                    generalContractor: {
                      companyId: project.generalContractor?.company.id ?? '',
                      selectedAddressId: locationId,
                    },
                  },
                })}
                mutate={updateProjectMutation}
              />
              <EditableCardRowCompany
                readOnly={false}
                label="Architect"
                value={project.architect?.company.id ?? ''}
                nameOverride={project.architect?.companyName ?? ''}
                mutate={updateProjectMutation}
                variables={async (companyId) => {
                  if (!companyId) {
                    return {
                      input: {
                        id: project.id,
                        removeArchitect: true,
                      },
                    }
                  }
                  const { data } = await client.query<GetCompanyQuery, GetCompanyQueryVariables>({
                    query: GetCompanyDocument,
                    variables: { companyId },
                  })
                  return {
                    input: {
                      id: project.id,
                      architect: {
                        companyId: data.company.id,
                        selectedAddressId: data.company.locations[0].id,
                      },
                    },
                  }
                }}
              />
              <EditableCardRowSelect
                readOnly={false}
                value={project.architect?.selectedAddress?.id ?? ''}
                label="Architect address"
                options={architectLocations.map((location) => ({
                  key: location.id,
                  value: addressDescription(location),
                }))}
                formatValue={(key) => {
                  const location = architectLocations.find((location) => location.id === key)
                  if (!location) {
                    return ''
                  }
                  return addressDescription(location)
                }}
                variables={(locationId: string) => ({
                  input: {
                    id: project.id,
                    architect: {
                      companyId: project.architect?.company.id ?? '',
                      selectedAddressId: locationId,
                    },
                  },
                })}
                mutate={updateProjectMutation}
              />
            </TableBody>
          </Table>
        </CardContent>
        <CardActions className={classes.actions} disableSpacing>
          <Button color="primary" onClick={() => handleDelete()}>
            Delete
          </Button>
        </CardActions>
      </Card>
    </Grid2>
  )
}
