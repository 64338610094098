import { ApolloProvider } from '@apollo/client'
import { Alert, CssBaseline, StyledEngineProvider, ThemeProvider } from '@mui/material'
import * as Sentry from '@sentry/react'
import { getApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { StrictMode, Suspense, useState } from 'react'
import { createRoot } from 'react-dom/client'
import { HelmetProvider } from 'react-helmet-async'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import {
  SitelineSnackbarProvider,
  configurePdfService,
  identifyUser,
  initSentry,
  initializeLogRocket,
  removeUserIdentification,
} from 'siteline-common-web'
import { App } from './App'
import './Fonts.js'
import { devTheme, prodTheme } from './Theme'
import { apolloClient, getCurrentUserAuthorization, initializeFirebaseAuth } from './client'
import { NotFound } from './common/components/404'
import { Loader } from './common/components/Loader'
import { NavBarProvider } from './common/components/NavBar'
import {
  API_ENV,
  SENTRY_RELEASE,
  logRocketApiUrl,
  logRocketProject,
  pdfBaseUrl,
  sentryDsn,
  sentryTunnel,
} from './common/config/constants'
import Signin from './components/Signin'

configurePdfService({
  baseUrl: pdfBaseUrl,
  getAuth: getCurrentUserAuthorization,
})

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes)

initSentry({
  dsn: sentryDsn,
  tunnel: sentryTunnel,
  release: SENTRY_RELEASE,
  environment: API_ENV,
})

// Initialize LogRocket session logging for prod and staging environments. Following install
// instructions using the app ID provided by LogRocket.
if (API_ENV === 'production' || API_ENV === 'staging') {
  void initializeLogRocket({
    project: logRocketProject,
    serverURL: logRocketApiUrl,
    release: SENTRY_RELEASE,
  })
}

function LoadingApp() {
  const [initialized, setInitialized] = useState<boolean>(false)
  const [isUserLoggedIn, setIsUserLoggedIn] = useState<boolean>(false)

  // Show a loading spinner if Firebase Auth hasn't been initialized yet
  if (!initialized) {
    initializeFirebaseAuth(
      () => setInitialized(true),
      async () => {
        return undefined
      }
    )
    return <Loader />
  }

  const auth = getAuth(getApp())
  auth.onAuthStateChanged((user) => {
    if (user) {
      setIsUserLoggedIn(true)
      void identifyUser({
        id: user.uid,
        email: user.email ?? undefined,
      })
    } else {
      setIsUserLoggedIn(false)
      void removeUserIdentification()
    }
  })

  // Show the main App if Firebase has been authenticated, otherwise take to signin screen
  return (
    <BrowserRouter>
      <ApolloProvider client={apolloClient}>
        {isUserLoggedIn ? (
          <NavBarProvider>
            <App />
          </NavBarProvider>
        ) : (
          <SentryRoutes>
            <Route path="/" element={<Signin />} />
            <Route path="*" element={<NotFound />} />
          </SentryRoutes>
        )}
      </ApolloProvider>
    </BrowserRouter>
  )
}

// Wraps the app with the current Material theme and CssBaseline (applies a basic level of styles
// to all of our components)
function ThemedApp() {
  // Use a different color theme on staging and dev to distinguish from production
  const isProduction = API_ENV === 'production'
  const theme = isProduction ? prodTheme : devTheme

  return (
    <StrictMode>
      <HelmetProvider>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <Suspense fallback={<Loader />}>
              <SitelineSnackbarProvider AlertComponent={Alert}>
                <LoadingApp />
              </SitelineSnackbarProvider>
            </Suspense>
          </ThemeProvider>
        </StyledEngineProvider>
      </HelmetProvider>
    </StrictMode>
  )
}

const container = document.getElementById('root')
if (!container) {
  throw new Error('Could not get root element')
}
const root = createRoot(container)
root.render(<ThemedApp />)
