interface SitelineCSSProperties extends React.CSSProperties {
  // https://developer.mozilla.org/en-US/docs/Web/CSS/box-orient
  boxOrient?: 'horizontal' | 'vertical' | 'inline-axis' | 'block-axis' | 'inherit' | 'unset'
}

export function clampToLines(numLines: number): SitelineCSSProperties {
  return {
    display: '-webkit-box',
    lineClamp: numLines,
    WebkitLineClamp: numLines,
    boxOrient: 'vertical',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  }
}

export function unclampLines(): SitelineCSSProperties {
  return {
    lineClamp: 'none',
    WebkitLineClamp: 'none',
    boxOrient: 'unset',
    WebkitBoxOrient: 'unset',
  }
}
