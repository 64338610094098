import { gql } from '@apollo/client'
import { DeleteOutline } from '@mui/icons-material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material'
import _ from 'lodash'
import { bindMenu, bindToggle, usePopupState } from 'material-ui-popup-state/hooks'
import moment from 'moment-timezone'
import { useCallback } from 'react'
import { decimalToPercent, safeDivide } from 'siteline-common-all'
import { toReferences, useSitelineSnackbar } from 'siteline-common-web'
import type { WritableDeep } from 'type-fest'
import { IdentifierButton, IdentifierIconButton } from '../../../common/components/IdentifierRow'
import { DollarNumberFormat } from '../../../common/components/NumberFormat'
import * as fragments from '../../../common/graphql/Fragments'
import {
  CompanySovProperties,
  RetentionTrackingLevel,
  Sov,
  useDeleteSovLineItemMutation,
} from '../../../common/graphql/apollo-operations'
import { sortSovLineItemsByOrder } from '../../../common/util/PayApp'

gql`
  mutation deleteSovLineItem($id: ID!) {
    deleteSovLineItem(id: $id) {
      id
    }
  }
`

type LineItem = CompanySovProperties['lineItems'][0]

function usesStandardOrLineItemTracking(level: RetentionTrackingLevel): boolean {
  return level === RetentionTrackingLevel.STANDARD || level === RetentionTrackingLevel.LINE_ITEM
}

interface UneditableRowProps {
  contract: fragments.Contract
  lineItem: LineItem
  timeZone: string
}

function UneditableLineItemRow({ contract, lineItem, timeZone }: UneditableRowProps) {
  const snackbar = useSitelineSnackbar()
  const [deleteLineItem] = useDeleteSovLineItemMutation()
  const handleDelete = useCallback(() => {
    const confirmed = window.confirm('Are you sure you want to delete this line item?')
    if (!confirmed) {
      return
    }
    snackbar.showLoading('Deleting line item...')
    deleteLineItem({
      variables: { id: lineItem.id },
      update: (cache) => {
        if (!contract.sov) {
          return
        }
        cache.modify<WritableDeep<Sov>>({
          id: cache.identify(contract.sov),
          fields: {
            lineItems(existingRefs, { readField, toReference }) {
              const refs = toReferences(existingRefs, toReference)
              return refs.filter((ref) => readField('id', ref) !== lineItem.id)
            },
          },
        })
      },
    })
      .then(() => {
        snackbar.showSuccess('Line item deleted')
      })
      .catch((error) => {
        snackbar.showError(error.message)
      })
  }, [contract.sov, deleteLineItem, lineItem.id, snackbar])

  const canBeDeleted = lineItem.totalBilled === 0

  return (
    <TableRow hover>
      <TableCell>{lineItem.code}</TableCell>
      <TableCell>{lineItem.name}</TableCell>
      <TableCell>{lineItem.sovLineItemGroup ? lineItem.sovLineItemGroup.name : 'N/A'}</TableCell>
      <TableCell>{lineItem.isChangeOrder ? 'Yes' : 'No'}</TableCell>
      <TableCell>
        {lineItem.changeOrderApprovedDate
          ? moment.tz(lineItem.changeOrderApprovedDate, timeZone).format('MM/DD/YYYY')
          : 'N/A'}
      </TableCell>
      <TableCell>
        <DollarNumberFormat value={lineItem.latestTotalValue} />
      </TableCell>
      <TableCell>
        <DollarNumberFormat value={lineItem.totalBilled} />
      </TableCell>
      {usesStandardOrLineItemTracking(contract.retentionTrackingLevel) && (
        <TableCell>
          <DollarNumberFormat value={lineItem.totalRetention} />
        </TableCell>
      )}
      <TableCell>{lineItem.progressComplete}%</TableCell>
      <TableCell sx={{ whiteSpace: 'nowrap' }}>
        <IdentifierIconButton id={lineItem.id} />
        {canBeDeleted && (
          <IconButton onClick={handleDelete}>
            <DeleteOutline />
          </IconButton>
        )}
      </TableCell>
    </TableRow>
  )
}

interface TotalRowProps {
  contract: fragments.Contract
  lineItems: LineItem[]
}

function TotalLineItemRow({ contract, lineItems }: TotalRowProps) {
  const totalValue = _.sumBy(lineItems, (lineItem) => lineItem.latestTotalValue)
  const totalBilled = _.sumBy(lineItems, (lineItem) => lineItem.totalBilled)
  const totalRetention = _.sumBy(lineItems, (lineItem) => lineItem.totalRetention)
  const progressComplete = decimalToPercent(safeDivide(totalBilled, totalValue, 0), 2)
  return (
    <TableRow>
      <TableCell>Totals row</TableCell>
      <TableCell />
      <TableCell />
      <TableCell />
      <TableCell />
      <TableCell>
        <DollarNumberFormat value={totalValue} />
      </TableCell>
      <TableCell>
        <DollarNumberFormat value={totalBilled} />
      </TableCell>
      {usesStandardOrLineItemTracking(contract.retentionTrackingLevel) && (
        <TableCell>
          <DollarNumberFormat value={totalRetention} />
        </TableCell>
      )}
      <TableCell>{progressComplete}%</TableCell>
      <TableCell />
    </TableRow>
  )
}

interface ContractDetailsSovProps {
  sov: CompanySovProperties
  contract: fragments.Contract
}

export default function ContractDetailsSov({ sov, contract }: ContractDetailsSovProps) {
  const timeZone = sov.contract.timeZone
  const sortedLineItems = [...sov.lineItems].sort(sortSovLineItemsByOrder)
  const popupState = usePopupState({ variant: 'popover' })

  const handleDeleteEmptyLineItems = useCallback(() => {
    const confirmed = window.confirm('Are you sure you want to delete all empty line items?')
    if (!confirmed) {
      return
    }
  }, [])

  return (
    <Card>
      <CardHeader
        title="Project SOV"
        action={
          <IconButton {...bindToggle(popupState)}>
            <MoreVertIcon />
          </IconButton>
        }
      />
      <Divider />
      <CardContent>
        <IdentifierButton id={sov.id} fullWidth={false} />
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Code</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Group Name</TableCell>
              <TableCell>Change order</TableCell>
              <TableCell>Change order date</TableCell>
              <TableCell>Total value</TableCell>
              <TableCell>Total billed</TableCell>
              {usesStandardOrLineItemTracking(contract.retentionTrackingLevel) && (
                <TableCell>Total retention</TableCell>
              )}
              <TableCell>Progress complete</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {[...sortedLineItems].map((sovLineItem) => (
              <UneditableLineItemRow
                contract={contract}
                lineItem={sovLineItem}
                key={sovLineItem.id}
                timeZone={timeZone}
              />
            ))}
            <TotalLineItemRow lineItems={[...sortedLineItems]} contract={contract} />
          </TableBody>
        </Table>
      </CardContent>
      <Menu {...bindMenu(popupState)}>
        <MenuItem onClick={handleDeleteEmptyLineItems}>Delete empty line items</MenuItem>
      </Menu>
    </Card>
  )
}
