import {
  Card,
  CardContent,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material'
import { makeStylesFast } from 'siteline-common-web'
import { IdentifierIconButton } from '../../common/components/IdentifierRow'
import { DollarNumberFormat } from '../../common/components/NumberFormat'
import { DetailedPayApp } from '../../common/graphql/Fragments'
import { SovLineItemProgressProperties } from '../../common/graphql/apollo-operations'
import { sortSovLineItemProgress } from '../../common/util/PayApp'

const useStyles = makeStylesFast(() => ({
  cardContent: {
    padding: 0,
    '&:last-child': {
      padding: 0,
    },
  },
  lineItemName: {
    maxWidth: 300,
  },
}))

interface PayAppDetailsProgressProps {
  payApp: DetailedPayApp
}

export default function PayAppDetailsProgress({ payApp }: PayAppDetailsProgressProps) {
  const classes = useStyles()

  const sortedProgress = [...payApp.progress].sort(sortSovLineItemProgress)

  const retentionHeld = (progress: SovLineItemProgressProperties) => {
    return progress.currentRetention + progress.previousRetention - progress.previousRetentionBilled
  }
  return (
    <Card>
      <CardHeader title="Progress" />
      <CardContent className={classes.cardContent}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Code</TableCell>
              <TableCell className={classes.lineItemName}>Name</TableCell>
              <TableCell>Total value</TableCell>
              <TableCell>Previous billed</TableCell>
              <TableCell>Progress billed</TableCell>
              <TableCell>Stored material billed</TableCell>
              <TableCell>Current Retention</TableCell>
              <TableCell>Retention Held</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedProgress.map((sovLineItemProgress) => (
              <TableRow hover key={sovLineItemProgress.id}>
                <TableCell>{sovLineItemProgress.sovLineItem.code}</TableCell>
                <TableCell className={classes.lineItemName}>
                  {sovLineItemProgress.sovLineItem.name}
                </TableCell>
                <TableCell>
                  <DollarNumberFormat value={sovLineItemProgress.totalValue} />
                </TableCell>
                <TableCell>
                  <DollarNumberFormat value={sovLineItemProgress.previousBilled} />
                </TableCell>
                <TableCell>
                  <DollarNumberFormat value={sovLineItemProgress.progressBilled} />
                </TableCell>
                <TableCell>
                  <DollarNumberFormat value={sovLineItemProgress.storedMaterialBilled} />
                </TableCell>
                <TableCell>
                  <DollarNumberFormat value={sovLineItemProgress.currentRetention} />
                </TableCell>
                <TableCell>
                  <DollarNumberFormat value={retentionHeld(sovLineItemProgress)} />
                </TableCell>
                <TableCell>
                  <IdentifierIconButton id={sovLineItemProgress.id} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  )
}
