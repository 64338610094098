import { gql } from '@apollo/client'
import PublishIcon from '@mui/icons-material/Publish'
import {
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid2,
  Paper,
  Theme,
} from '@mui/material'
import { clsx } from 'clsx'
import { useCallback, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { pdfTypes } from 'siteline-common-all'
import { evictWithGc, makeStylesFast, useSitelineSnackbar } from 'siteline-common-web'
import type { SetOptional } from 'type-fest'
import { v4 as uuidv4 } from 'uuid'
import * as fragments from '../../../common/graphql/Fragments'
import {
  FormAnnotationInput,
  FormTemplateAnnotationProperties,
  FormTemplateVersionProperties,
  TextAlignment,
  useReplaceFormTemplateAnnotationsMutation,
} from '../../../common/graphql/apollo-operations'

const useStyles = makeStylesFast((theme: Theme) => ({
  uploadPaper: {
    height: 148,
    width: 400,
    padding: theme.spacing(2),
    border: '1px dashed rgba(0, 0, 0, 0.12)',
    cursor: 'pointer',
    marginBottom: theme.spacing(2),
  },
  fileDrag: {
    border: '1px solid rgba(0, 0, 0, 0.54)',
  },
  import: {
    height: 116,
    textAlign: 'center',
    '& span': {
      display: 'block',
    },
  },
}))

gql`
  mutation replaceFormTemplateAnnotations($input: ReplaceFormTemplateAnnotationsInput!) {
    replaceFormTemplateAnnotations(input: $input) {
      ...FormTemplateAnnotationProperties
    }
  }
  ${fragments.formTemplateAnnotation}
`

type FormTemplateAnnotationImportProps = {
  formTemplateVersion: FormTemplateVersionProperties
  open: boolean
  setOpen: (open: boolean) => void
}

// Type for annotations stored in JSON file. Since those go through migrations on the API, some fields
// might not be defined at import time.
type ImportedAnnotation = SetOptional<
  FormTemplateAnnotationProperties,
  | 'textAlignment'
  | 'wrapText'
  | 'copyDefaultValueFromPreviousAnnotationValue'
  | 'defaultValueKey'
  | 'doNotRetainOnReset'
  | 'dynamicFieldTag'
  | 'fieldType'
  | 'imageType'
  | 'isOptional'
  | 'prefix'
  | 'selectedKey'
  | 'suffix'
> & {
  metadata?: pdfTypes.FormTemplateAnnotationMetadata
}

export function FormTemplateVersionAnnotationImport({
  formTemplateVersion,
  open,
  setOpen,
}: FormTemplateAnnotationImportProps) {
  const snackbar = useSitelineSnackbar()
  const classes = useStyles()
  const [firstPageOnly, setFirstPageOnly] = useState<boolean>(true)

  const [replaceAnnotationsMutation] = useReplaceFormTemplateAnnotationsMutation({
    update(cache) {
      evictWithGc(cache, (evict) => {
        evict({ id: cache.identify(formTemplateVersion) })
      })
    },
  })

  const replaceAnnotations = useCallback(
    (newAnnotations: ImportedAnnotation[]) => {
      snackbar.showLoading('Replacing annotations...')
      replaceAnnotationsMutation({
        variables: {
          input: {
            formTemplateVersionId: formTemplateVersion.id,
            annotations: newAnnotations.map((annotation): FormAnnotationInput => {
              const copyDefaultValueFromPreviousAnnotationValue =
                annotation.copyDefaultValueFromPreviousAnnotationValue ??
                annotation.metadata?.copyDefaultValueFromPreviousAnnotationValue ??
                false
              const defaultValueKey =
                annotation.defaultValueKey ?? annotation.metadata?.defaultValueKey ?? null
              const doNotRetainOnReset =
                annotation.doNotRetainOnReset ?? annotation.metadata?.doNotRetainOnReset ?? false
              const dynamicFieldTag =
                annotation.dynamicFieldTag ?? annotation.metadata?.dynamicFieldTag ?? null
              const fieldType = annotation.fieldType ?? annotation.metadata?.fieldType ?? null
              const imageType = annotation.imageType ?? annotation.metadata?.imageType ?? null
              const isOptional = annotation.isOptional ?? annotation.metadata?.isOptional ?? false
              const prefix = annotation.prefix ?? annotation.metadata?.prefix ?? null
              const selectedKey = annotation.selectedKey ?? annotation.metadata?.selectedKey ?? null
              const suffix = annotation.suffix ?? annotation.metadata?.suffix ?? null

              return {
                id: uuidv4(),
                userVisibleName: annotation.userVisibleName,
                type: annotation.type,
                xStart: annotation.xStart,
                yStart: annotation.yStart,
                width: annotation.width,
                height: annotation.height,
                pageNumber: annotation.pageNumber,
                fontFamily: annotation.fontFamily,
                fontColor: annotation.fontColor,
                textAlignment: annotation.textAlignment ?? TextAlignment.LEFT,
                wrapText: annotation.wrapText ?? false,
                copyDefaultValueFromPreviousAnnotationValue,
                defaultValueKey,
                doNotRetainOnReset,
                dynamicFieldTag,
                fieldType,
                imageType,
                isOptional,
                prefix,
                selectedKey,
                suffix,
                signatureType: annotation.signatureType,
              }
            }),
          },
        },
      })
        .then(() => {
          snackbar.showSuccess('Annotations replaced')
          setOpen(false)
        })
        .catch((err) => snackbar.showError(err.message))
    },
    [formTemplateVersion.id, replaceAnnotationsMutation, setOpen, snackbar]
  )

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      const message = 'This will remove all previous annotations. Are you sure you want to do this?'
      if (!window.confirm(message)) {
        return
      }
      const reader = new FileReader()
      reader.onload = () => {
        const json: ImportedAnnotation[] = JSON.parse(reader.result as string)
        const filtered = json.filter((annotation) => {
          if (!firstPageOnly) {
            return true
          }
          return annotation.pageNumber === 1
        })
        replaceAnnotations(filtered)
      }
      reader.readAsText(acceptedFiles[0])
    },
    [firstPageOnly, replaceAnnotations]
  )

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'application/json': ['.json'],
    },
  })

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle>Import Annotations</DialogTitle>
      <DialogContent>
        <div {...getRootProps()}>
          <input {...getInputProps()} />
          <Paper
            elevation={0}
            className={clsx(classes.uploadPaper, {
              [classes.fileDrag]: isDragActive,
            })}
          >
            <Grid2
              className={classes.import}
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              spacing={0}
            >
              <Grid2>
                <PublishIcon />
              </Grid2>
              <Grid2>
                {isDragActive && <p>Drop here</p>}
                {!isDragActive && <p>Upload a JSON file here</p>}
              </Grid2>
            </Grid2>
          </Paper>
        </div>

        <FormControl>
          <FormControlLabel
            control={
              <Checkbox
                checked={firstPageOnly}
                onChange={(ev) => setFirstPageOnly(ev.currentTarget.checked)}
              />
            }
            label="Filter out annotations that are not on the 1st page"
          />
        </FormControl>
      </DialogContent>
    </Dialog>
  )
}
