import { gql } from '@apollo/client'
import CloudDoneOutlinedIcon from '@mui/icons-material/CloudDoneOutlined'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import GetAppIcon from '@mui/icons-material/GetApp'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import ListIcon from '@mui/icons-material/List'
import RotateLeftIcon from '@mui/icons-material/RotateLeft'
import SendIcon from '@mui/icons-material/Send'
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@mui/material'
import moment from 'moment-timezone'
import { useState } from 'react'
import { IntegrationTypeFamily, getIntegrationTypeFamily } from 'siteline-common-all'
import { generatePdf, makeStylesFast, savePdf, useSitelineSnackbar } from 'siteline-common-web'
import { EditableCardRowCheckbox } from '../../common/components/EditableCardRow'
import { GetDateDialog } from '../../common/components/GetDateDialog'
import IdentifierRow from '../../common/components/IdentifierRow'
import { DollarNumberFormat } from '../../common/components/NumberFormat'
import * as fragments from '../../common/graphql/Fragments'
import { DetailedPayApp } from '../../common/graphql/Fragments'
import {
  MutationUpdatePayAppArgs,
  PayAppStatus,
  useClearPayAppEventsMutation,
  useDeletePayAppMutation,
  useResetPayAppMutation,
  useRetractPayAppMutation,
  useSetPayAppProposedMutation,
  useSetPayAppSyncedMutation,
  useUpdatePayAppMutation,
} from '../../common/graphql/apollo-operations'

const useStyles = makeStylesFast(() => ({
  card: {
    paddingBottom: 0,
  },
  cardContent: {
    padding: 0,
    '&:last-child': {
      padding: 0,
    },
  },
  actions: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
}))

gql`
  mutation resetPayApp($payAppId: ID!) {
    resetPayApp(payAppId: $payAppId) {
      ...PayAppProperties
    }
  }
  ${fragments.payApp}
`

gql`
  mutation clearPayAppEvents($payAppId: ID!) {
    clearPayAppEvents(payAppId: $payAppId) {
      ...PayAppProperties
    }
  }
  ${fragments.payApp}
`

gql`
  mutation retractPayApp($payAppId: ID!) {
    retractPayApp(payAppId: $payAppId) {
      ...PayAppProperties
    }
  }
  ${fragments.payApp}
`

gql`
  mutation setPayAppSynced($input: SetPayAppSyncedInput!) {
    setPayAppSynced(input: $input) {
      ...PayAppProperties
    }
  }
  ${fragments.payApp}
`

gql`
  mutation deletePayApp($payAppId: ID!) {
    deletePayApp(payAppId: $payAppId) {
      id
    }
  }
`

gql`
  mutation setPayAppProposed($input: SetPayAppProposedInput!) {
    setPayAppProposed(input: $input) {
      ...PayAppProperties
    }
  }
  ${fragments.payApp}
`

gql`
  mutation updatePayApp($input: UpdatePayAppInput!) {
    updatePayApp(input: $input) {
      ...PayAppProperties
      contract {
        id
        project {
          id
          name
          generalContractor {
            company {
              ...CompanyProperties
            }
          }
        }
      }
    }
  }
  ${fragments.payApp}
  ${fragments.company}
`

interface PayAppDetailsInfoProps {
  payApp: DetailedPayApp
  contract: fragments.DetailedPayAppContract
}

export default function PayAppDetailsInfo({ payApp, contract }: PayAppDetailsInfoProps) {
  const classes = useStyles()
  const [dateDialogCallback, setDateDialogCallback] = useState<(date: moment.Moment) => void>()
  const [retractPayAppMutation] = useRetractPayAppMutation()
  const [resetPayAppMutation] = useResetPayAppMutation()
  const [deletePayAppMutation] = useDeletePayAppMutation()
  const [updatePayAppMutation] = useUpdatePayAppMutation()
  const [setPayAppSynced] = useSetPayAppSyncedMutation()
  const [setPayAppProposedMutation] = useSetPayAppProposedMutation()
  const [clearPayAppEventsMutation] = useClearPayAppEventsMutation()
  const snackbar = useSitelineSnackbar()

  const confirmAction = () => window.confirm('Are you sure you want to do this?')
  const withConfirmation = async (run: () => Promise<unknown>) => {
    if (!confirmAction()) {
      return
    }
    snackbar.showLoading()
    try {
      await run()
      snackbar.showSuccess()
    } catch (err) {
      snackbar.showError(err.message)
    }
  }

  const download = async () => {
    const blob = await generatePdf({ type: 'payApp', payAppId: payApp.id })
    savePdf(blob, 'payApp')
  }

  const handleDownload = () => {
    snackbar.showLoading('Generating PDF...')
    download()
      .then(() => snackbar.showSuccess())
      .catch((err) => snackbar.showError(err.message))
  }

  const handleReset = () => {
    withConfirmation(() =>
      resetPayAppMutation({
        variables: { payAppId: payApp.id },
      })
    )
  }

  const handleClearEvents = () => {
    withConfirmation(() =>
      clearPayAppEventsMutation({
        variables: { payAppId: payApp.id },
      })
    )
  }

  const handleRetract = () => {
    withConfirmation(() =>
      retractPayAppMutation({
        variables: { payAppId: payApp.id },
      })
    )
  }

  const handleMarkAsSynced = (date: moment.Moment) => {
    withConfirmation(() =>
      setPayAppSynced({
        variables: {
          input: {
            id: payApp.id,
            syncedAt: date.toISOString(),
          },
        },
      })
    )
  }

  const handleDelete = () => {
    withConfirmation(() =>
      deletePayAppMutation({
        variables: { payAppId: payApp.id },
      })
    )
  }

  const handleSetProposed = (date: moment.Moment) => {
    withConfirmation(() =>
      setPayAppProposedMutation({
        variables: {
          input: {
            id: payApp.id,
            proposedAt: date.toISOString(),
          },
        },
      })
    )
  }

  const formatDate = (date: string) => {
    return moment.tz(date, payApp.timeZone).format('MMM DD, YYYY')
  }

  // If a pay app isn't in draft mode, it cannot be edited.
  const readOnly =
    payApp.status !== PayAppStatus.DRAFT && payApp.status !== PayAppStatus.SYNC_FAILED
  const hasGcPortalIntegration = contract.integrations.some(
    (integration) => getIntegrationTypeFamily(integration.type) === IntegrationTypeFamily.GC_PORTAL
  )

  return (
    <Card className={classes.card}>
      <CardHeader title="Overview" />
      <Divider />
      <CardContent className={classes.cardContent}>
        <Table>
          <TableBody>
            <IdentifierRow id={payApp.id} />
            <TableRow>
              <TableCell>Created at</TableCell>
              <TableCell>{formatDate(payApp.createdAt)}</TableCell>
              {!readOnly && <TableCell />}
            </TableRow>
            <TableRow>
              <TableCell>Status</TableCell>
              <TableCell>{payApp.status}</TableCell>
              {!readOnly && <TableCell />}
            </TableRow>
            <EditableCardRowCheckbox
              readOnly={readOnly}
              label="Retention Only"
              value={payApp.retentionOnly}
              mutate={updatePayAppMutation}
              // The MutationUpdatePayAppArgs is necessary because the TS compiler isn't inferring
              // types correctly
              variables={(value): MutationUpdatePayAppArgs => ({
                input: {
                  id: payApp.id,
                  retentionOnly: value,
                },
              })}
            />
            <TableRow>
              <TableCell>Billing Start</TableCell>
              <TableCell>{payApp.billingStart}</TableCell>
              {!readOnly && <TableCell />}
            </TableRow>
            <TableRow>
              <TableCell>Billing End</TableCell>
              <TableCell>{payApp.billingEnd}</TableCell>
              {!readOnly && <TableCell />}
            </TableRow>
            <TableRow>
              <TableCell>Due Date</TableCell>
              <TableCell>{payApp.payAppDueDate}</TableCell>
              {!readOnly && <TableCell />}
            </TableRow>
            <TableRow>
              <TableCell>Current progress/materials billed</TableCell>
              <TableCell>
                <DollarNumberFormat value={payApp.currentBilled} />
              </TableCell>
              {!readOnly && <TableCell />}
            </TableRow>
            <TableRow>
              <TableCell>Previous retention billed</TableCell>
              <TableCell>
                <DollarNumberFormat value={payApp.previousRetentionBilled} />
              </TableCell>
              {!readOnly && <TableCell />}
            </TableRow>
            <TableRow>
              <TableCell>Current retention</TableCell>
              <TableCell>
                <DollarNumberFormat value={payApp.currentRetention} />
              </TableCell>
              {!readOnly && <TableCell />}
            </TableRow>
            <TableRow>
              <TableCell>Total retention held</TableCell>
              <TableCell>
                <DollarNumberFormat value={payApp.totalRetention} />
              </TableCell>
              {!readOnly && <TableCell />}
            </TableRow>
          </TableBody>
        </Table>
      </CardContent>
      <CardActions className={classes.actions} disableSpacing>
        <Button startIcon={<GetAppIcon />} onClick={handleDownload}>
          Download as PDF
        </Button>
        <Button startIcon={<RotateLeftIcon />} onClick={handleRetract}>
          Retract (set to DRAFT)
        </Button>
        <Button startIcon={<ListIcon />} onClick={handleClearEvents}>
          Clear activity feed
        </Button>
        {!hasGcPortalIntegration &&
          payApp.status !== PayAppStatus.PROPOSED &&
          payApp.status !== PayAppStatus.PAID && (
            <Button
              startIcon={<SendIcon />}
              onClick={() => setDateDialogCallback(() => handleSetProposed)}
            >
              Set to PROPOSED
            </Button>
          )}
        {hasGcPortalIntegration && !readOnly && (
          <Button
            startIcon={<CloudDoneOutlinedIcon />}
            onClick={() => setDateDialogCallback(() => handleMarkAsSynced)}
          >
            Set to SYNCED
          </Button>
        )}
        <Button startIcon={<HighlightOffIcon />} onClick={handleReset}>
          Reset entirely
        </Button>
        <Button
          startIcon={<DeleteOutlineIcon />}
          color="primary"
          onClick={handleDelete}
          disabled={readOnly}
        >
          Delete entirely
        </Button>
      </CardActions>
      <GetDateDialog
        timeZone={payApp.timeZone}
        open={dateDialogCallback !== undefined}
        onClose={(date?: moment.Moment) => {
          if (date && dateDialogCallback) {
            dateDialogCallback(date)
          }
          setDateDialogCallback(undefined)
        }}
      />
    </Card>
  )
}
