import { Dialog, DialogContent } from '@mui/material'
import TemplatePreview from '../form-template-versions/form-template-version-details/TemplatePreview'

interface ViewTemplateVersionDialogProps {
  open: boolean
  onClose: () => void
  templateId: string
}

/**
 * Dialog that previews the latest version of a template.
 * Used from the template list page to quickly preview a template.
 */
export function ViewTemplateVersionDialog({
  open,
  onClose,
  templateId,
}: ViewTemplateVersionDialogProps) {
  return (
    <Dialog fullWidth maxWidth="lg" open={open} onClose={() => onClose()}>
      <DialogContent>
        <TemplatePreview templateId={templateId} />
      </DialogContent>
    </Dialog>
  )
}
