import { gql } from '@apollo/client'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import DownloadIcon from '@mui/icons-material/Download'
import EditIcon from '@mui/icons-material/Edit'
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import {
  Alert,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  Theme,
  Tooltip,
} from '@mui/material'
import { clsx } from 'clsx'
import _ from 'lodash'
import { createRef, useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDebounce, useMeasure } from 'react-use'
import {
  FormTemplateStatus,
  generatePdf,
  makeStylesFast,
  saveUrlAs,
  useSitelineSnackbar,
} from 'siteline-common-web'
import { useFilePicker } from 'use-file-picker'
import { PayAppAutocomplete } from '../../../common/components/PayAppAutocomplete'
import * as fragments from '../../../common/graphql/Fragments'
import {
  GetFormTemplateDocument,
  GetFormTemplateQuery,
  GetFormTemplateQueryVariables,
  useCloneFormTemplateVersionMutation,
  useTemplateForPreviewQuery,
  useUpdateFormTemplateStatusMutation,
  useUpdateFormTemplateVersionMutation,
} from '../../../common/graphql/apollo-operations'
import { Document, Page as PdfPage } from '../../../pdf'
import { PdfPageSelector } from '../form-template-version-editor/PdfPageSelector'

gql`
  query templateForPreview($id: ID!) {
    formTemplate(id: $id) {
      id
      updatedAt
      status
      versions {
        id
        versionNumber
        annotations {
          ...FormTemplateAnnotationProperties
        }
        file {
          id
          url
          name
        }
      }
      variants {
        id
        internalName
        isDefaultVariant
        hidesZeroDollarAmounts
        roundPercentages
        annotationOverrides {
          ...AnnotationOverrideProperties
        }
      }
    }
  }
  ${fragments.formTemplateAnnotation}
  ${fragments.annotationOverride}
`

const useStyles = makeStylesFast((theme: Theme) => ({
  center: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  loader: {
    marginTop: theme.spacing(3),
  },
  error: {
    marginTop: theme.spacing(2),
  },
  filters: {
    display: 'flex',
    flexDirection: 'row',
  },
}))

type TemplatePreviewProps = {
  templateId: string
  versionId?: string
  onVersionIdChange?: (versionId: string) => void
  variantId?: string
  onVariantIdChange?: (variantId: string) => void
  showValidateButton?: boolean

  /** When true, Edit and Clone buttons are visible */
  showEditButtons?: boolean

  className?: string
}

/**
 * Previews a template by version and variant.
 * There is an optional pay app filter to allow previewing for a specific pay app.
 */
export default function TemplatePreview({
  templateId,
  versionId: controlledVersionId,
  variantId: controlledVariantId,
  showEditButtons = true,
  showValidateButton = false,
  className,
}: TemplatePreviewProps) {
  const classes = useStyles()
  const snackbar = useSitelineSnackbar()
  const navigate = useNavigate()

  const [file, setFile] = useState<Blob | null>(null)
  const [error, setError] = useState<Error | null>(null)
  const [payAppId, setPayAppId] = useState<string | null>(null)
  const [pageNumber, setPageNumber] = useState(1)
  const [pageCount, setPageCount] = useState(1)
  const canvasRef = createRef<HTMLDivElement>()
  const [updateStatus] = useUpdateFormTemplateStatusMutation()
  const [updateFormTemplateVersionMutation] = useUpdateFormTemplateVersionMutation()
  const [ref, { width }] = useMeasure<HTMLDivElement>()

  const { data: templateData, loading: loadingTemplate } = useTemplateForPreviewQuery({
    variables: { id: templateId },
  })

  const template = useMemo(() => templateData?.formTemplate ?? null, [templateData?.formTemplate])

  const versions = useMemo(() => {
    return _.orderBy(template?.versions ?? [], (version) => version.versionNumber, 'desc')
  }, [template?.versions])

  const latestVersion = useMemo(() => {
    return _.first(versions)
  }, [versions])

  const initialUncontrolledVersionId = useMemo(() => latestVersion?.id ?? null, [latestVersion?.id])
  const [uncontrolledVersionId, setUncontrolledVersionId] = useState<string | null>(
    initialUncontrolledVersionId
  )

  const selectedVersion = useMemo(() => {
    const id = controlledVersionId ?? uncontrolledVersionId
    const found = versions.find((version) => version.id === id)
    return found ?? latestVersion
  }, [controlledVersionId, uncontrolledVersionId, versions, latestVersion])

  const variants = useMemo(() => {
    return _.orderBy(
      template?.variants ?? [],
      [(variant) => (variant.isDefaultVariant ? -1 : 1), (variant) => variant.internalName],
      ['asc', 'desc']
    )
  }, [template?.variants])

  const defaultVariant = useMemo(() => {
    const found = variants.find((variant) => variant.isDefaultVariant)
    return found ?? null
  }, [variants])

  const initialUncontrolledVariantId = useMemo(() => defaultVariant?.id ?? null, [defaultVariant])
  const [uncontrolledVariantId, setUncontrolledVariantId] = useState<string | null>(
    initialUncontrolledVariantId
  )

  const selectedVariant = useMemo(() => {
    const id = controlledVariantId ?? uncontrolledVariantId
    const found = variants.find((variant) => variant.id === id)
    return found ?? defaultVariant
  }, [uncontrolledVariantId, defaultVariant, controlledVariantId, variants])

  const { openFilePicker: openTemplateReplacementFilePicker } = useFilePicker({
    accept: ['.pdf', '.docx', '.xlsx'],
    readFilesContent: false,
    onFilesSelected: ({ plainFiles }) => {
      if (!selectedVersion) {
        return
      }
      const file = plainFiles[0]
      const confirmed = window.confirm(
        'This will affect all pay-apps that use this template, retroactively. Are you sure you want to replace this template?'
      )
      if (!confirmed) {
        return
      }
      snackbar.showLoading('Replacing template...')
      updateFormTemplateVersionMutation({
        variables: {
          input: {
            id: selectedVersion.id,
            file,
          },
        },
      })
        .then(() => snackbar.showSuccess())
        .catch((err) => snackbar.showError(err.message))
    },
  })

  // Whenever the initial uncontrolled variant ID changes, update the uncontrolled state
  useEffect(
    () => setUncontrolledVariantId(initialUncontrolledVariantId),
    [initialUncontrolledVariantId]
  )

  // Whenever the initial uncontrolled version ID changes, update the uncontrolled state
  useEffect(
    () => setUncontrolledVersionId(initialUncontrolledVersionId),
    [initialUncontrolledVersionId]
  )

  const handleReadyForValidation = useCallback(() => {
    if (!template) {
      return
    }
    if (FormTemplateStatus.READY_FOR_VALIDATION === template.status) {
      return
    }
    updateStatus({
      variables: {
        input: {
          id: template.id,
          status: FormTemplateStatus.READY_FOR_VALIDATION,
        },
      },
    })
      .then(() => snackbar.showSuccess())
      .catch((err) => snackbar.showError(err.message))
  }, [template, snackbar, updateStatus])

  const [cloneFormTemplateVersion] = useCloneFormTemplateVersionMutation({
    update(cache, { data }) {
      const result = cache.readQuery<GetFormTemplateQuery, GetFormTemplateQueryVariables>({
        query: GetFormTemplateDocument,
        variables: { id: templateId },
      })

      if (!result || !data) {
        return
      }

      const updatedTemplate = {
        ...result.formTemplate,
        versions: [...result.formTemplate.versions, data.cloneFormTemplateVersion],
      }

      cache.writeQuery({
        query: GetFormTemplateDocument,
        data: { formTemplate: updatedTemplate },
      })
    },
  })

  const onClone = useCallback(() => {
    if (!selectedVersion) {
      return
    }
    if (!window.confirm('Are you sure you want to clone this template version?')) {
      return
    }

    snackbar.showLoading('Creating ...')
    cloneFormTemplateVersion({ variables: { formTemplateVersionId: selectedVersion.id } })
      .then(() => {
        navigate(`/templates/${templateId}/edit`)
        snackbar.showSuccess('Done!')
      })
      .catch((error) => snackbar.showError(error.message))
  }, [cloneFormTemplateVersion, templateId, selectedVersion, navigate, snackbar])

  const onEdit = useCallback(() => {
    if (!selectedVersion) {
      return
    }
    navigate(`/templates/${templateId}/edit`)
  }, [templateId, selectedVersion, navigate])

  const onDownload = useCallback(() => {
    if (!selectedVersion) {
      return
    }
    saveUrlAs(selectedVersion.file.url, selectedVersion.file.name)
  }, [selectedVersion])

  const onReplace = useCallback(async () => {
    openTemplateReplacementFilePicker()
  }, [openTemplateReplacementFilePicker])

  const onGoToVersion = useCallback(() => {
    if (!selectedVersion) {
      return
    }
    navigate(`/templates/${templateId}/edit`)
  }, [templateId, selectedVersion, navigate])

  const onPayAppSelected = useCallback((payAppId: string | null) => {
    setFile(null)
    setPayAppId(payAppId)
  }, [])

  // Debounce rendering of the PDF to avoid having too many re-renders in a row
  useDebounce(
    () => {
      if (!selectedVersion || !selectedVariant) {
        return
      }
      setError(null)
      generatePdf({
        type: 'templatesPreview',
        formTemplateVersionIds: [selectedVersion.id],
        formTemplateVariantIds: [selectedVariant.id],
        payAppId: payAppId ?? undefined,
      })
        .then((pdf) => setFile(pdf))
        .catch((err) => setError(err))
    },
    500,
    [selectedVersion, selectedVariant, payAppId]
  )

  let validateButton = null
  if (showValidateButton) {
    if (template?.status === FormTemplateStatus.BUILDING) {
      validateButton = (
        <Button onClick={handleReadyForValidation} color="primary">
          Ready to validate
        </Button>
      )
    } else if (template?.status === FormTemplateStatus.READY_FOR_VALIDATION) {
      validateButton = <i>Validation in progress</i>
    }
  }

  return (
    <Card className={className}>
      <CardHeader
        title="Preview"
        action={
          <div style={{ marginTop: 8 }}>
            {selectedVersion && (
              <>
                <PdfPageSelector
                  pageNumber={pageNumber}
                  setPageNumber={setPageNumber}
                  pageCount={pageCount}
                />
                {validateButton}
                {showEditButtons && (
                  <>
                    <Tooltip title="Clone" placement="top">
                      <IconButton color="secondary" onClick={onClone}>
                        <ContentCopyIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Download" placement="top">
                      <IconButton color="secondary" onClick={onDownload}>
                        <DownloadIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Replace" placement="top">
                      <IconButton color="secondary" onClick={onReplace}>
                        <FileUploadOutlinedIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Edit" placement="top">
                      <IconButton color="secondary" onClick={onEdit}>
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Version info" placement="top">
                      <IconButton color="secondary" onClick={onGoToVersion}>
                        <InfoOutlinedIcon />
                      </IconButton>
                    </Tooltip>
                  </>
                )}
              </>
            )}
          </div>
        }
      ></CardHeader>
      {!selectedVersion && !loadingTemplate && (
        <CardContent>
          <Alert severity="info">This template doesn&apos;t have a version yet</Alert>
        </CardContent>
      )}
      {selectedVersion && (
        <CardContent>
          <div className={classes.filters}>
            {!controlledVersionId && (
              <FormControl>
                <Select
                  disabled={Boolean(controlledVersionId)}
                  value={selectedVersion.id}
                  onChange={(ev) => setUncontrolledVersionId(ev.target.value)}
                  size="small"
                >
                  {versions.map((version) => (
                    <MenuItem key={version.id} value={version.id}>
                      v{version.versionNumber}
                      {latestVersion?.id === version.id ? ' (latest)' : ''}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            {!controlledVariantId && (
              <FormControl sx={{ marginLeft: 1 }}>
                <Select
                  disabled={Boolean(controlledVariantId)}
                  value={uncontrolledVariantId}
                  onChange={(ev) => setUncontrolledVariantId(ev.target.value)}
                  size="small"
                >
                  {variants.map((variant) => (
                    <MenuItem key={variant.id} value={variant.id}>
                      {variant.isDefaultVariant ? 'Default settings' : variant.internalName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            <FormControl sx={{ marginLeft: 1, flex: 1 }}>
              <PayAppAutocomplete payAppId={payAppId} onPayAppIdChange={onPayAppSelected} />
            </FormControl>
          </div>
          <div>
            {file && (
              <div ref={ref} className={classes.center}>
                <Document
                  file={file}
                  inputRef={canvasRef}
                  // It's possible that this success callback is triggered with a null value
                  onLoadSuccess={(document: { numPages: number } | null) => {
                    if (document !== null) {
                      setPageCount(document.numPages)
                    }
                  }}
                  loading={<CircularProgress />}
                >
                  <PdfPage pageNumber={pageNumber} width={width} />
                </Document>
              </div>
            )}
            {!file && !error && (
              <div className={clsx([classes.loader, classes.center])}>
                <CircularProgress />
              </div>
            )}
            {error && (
              <Alert severity="error" className={classes.error}>
                Could not preview template because of the following error:
                <pre>
                  <code>{error.message}</code>
                </pre>
              </Alert>
            )}
          </div>
        </CardContent>
      )}
    </Card>
  )
}
