import { ReactNode } from 'react'
import { Helmet } from 'react-helmet-async'

interface PageProps {
  className?: string
  title: string
  children: ReactNode
}

export default function Page({ className, title, children }: PageProps) {
  return (
    <div className={className}>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {children}
    </div>
  )
}
