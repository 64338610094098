import { gql } from '@apollo/client'
import { Send } from '@mui/icons-material'
import { Button, Card, CardActions, CardContent, CardHeader, Typography } from '@mui/material'
import { DateCalendar } from '@mui/x-date-pickers'
import moment, { Moment } from 'moment-timezone'
import { useCallback, useMemo, useState } from 'react'
import { useSitelineSnackbar } from 'siteline-common-web'
import { useMarkLienWaiverRequestsAsSubmittedForMonthMutation } from '../../common/graphql/apollo-operations'
import DemoCompanySelector from './DemoCompanySelector'

gql`
  mutation markLienWaiverRequestsAsSubmittedForMonth(
    $input: MarkLienWaiverRequestsAsSubmittedForMonthInput!
  ) {
    markLienWaiverRequestsAsSubmittedForMonth(input: $input)
  }
`

/**
 * Demo tool to mark vendor lien waivers as submitted for a specific month
 */
export default function DemoToolsSubmitLienWaivers() {
  const snackbar = useSitelineSnackbar()
  const [companyId, setCompanyId] = useState<string | null>(null)
  const [markLienWaiverRequestsAsSubmitted] = useMarkLienWaiverRequestsAsSubmittedForMonthMutation()

  const initialMonthAndYear = useMemo(() => moment.tz(moment.tz.guess()).startOf('month'), [])
  const [monthAndYear, setMonthAndYear] = useState<Moment>(initialMonthAndYear)

  const handleMarkLienWaiverRequestsAsSubmitted = useCallback(() => {
    if (!companyId) {
      return
    }
    const confirmed = window.confirm(
      'Are you sure you want to delete all lien waivers for this month?'
    )
    if (!confirmed) {
      return
    }
    snackbar.showLoading('Deleting lien waivers')
    markLienWaiverRequestsAsSubmitted({
      variables: {
        input: {
          companyId,
          month: monthAndYear.month(),
          year: monthAndYear.year(),
        },
      },
    })
      .then(() => snackbar.showSuccess())
      .catch((err) => snackbar.showError(err.message))
  }, [companyId, markLienWaiverRequestsAsSubmitted, monthAndYear, snackbar])

  return (
    <Card>
      <CardHeader title="Mark lien waiver requests as submitted" />
      <CardContent>
        <Typography>
          Find all vendor lien waiver requests for a month and mark them as submitted.
        </Typography>
        <DemoCompanySelector companyId={companyId} onCompanyIdChange={setCompanyId} />
        <DateCalendar
          value={monthAndYear}
          onChange={(value) => setMonthAndYear(value)}
          views={['year', 'month']}
          openTo="month"
          sx={{ height: 250, marginTop: 2 }}
        />
      </CardContent>
      <CardActions>
        <Button
          startIcon={<Send />}
          disabled={!companyId}
          onClick={handleMarkLienWaiverRequestsAsSubmitted}
        >
          Mark lien waiver requests as submitted
        </Button>
      </CardActions>
    </Card>
  )
}
