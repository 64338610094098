import { ApolloCache, FetchResult, MutationFunctionOptions } from '@apollo/client'
import { FormTemplateType } from 'siteline-common-web'
import { Exact, useTemplateForAutocompleteQuery } from '../graphql/apollo-operations'
import EditableCardRow from './EditableCardRow'
import { TemplateAutocomplete } from './TemplateAutocomplete'

interface FormTemplateAutocompleteProps<
  T,
  TData,
  TVariables extends { [key: string]: unknown },
  TContext,
  TCache extends ApolloCache<unknown>,
> {
  defaultTemplateId?: string | null
  mutate: (
    options?: MutationFunctionOptions<TData, TVariables, TContext, TCache>
  ) => Promise<FetchResult<TData>>
  variables: (value: T) => Exact<TVariables> | Promise<Exact<TVariables>>
  templateType: FormTemplateType
  label: string
  readOnly: boolean
  onToggleEditing?: (editing: boolean) => void
}

/** Editable row for a form template autocomplete. */
export function EditableFormTemplateCardRow<
  TData,
  TVariables extends { [key: string]: unknown },
  TContext,
  TCache extends ApolloCache<unknown>,
>({
  defaultTemplateId,
  mutate,
  variables,
  templateType,
  label,
  readOnly,
  ...rest
}: FormTemplateAutocompleteProps<string, TData, TVariables, TContext, TCache>) {
  const { data } = useTemplateForAutocompleteQuery({
    variables: {
      id: defaultTemplateId ?? '',
    },
    skip: !defaultTemplateId,
  })
  return (
    <EditableCardRow
      readOnly={readOnly}
      formatValue={(value) => {
        if (defaultTemplateId !== value) {
          return ''
        }
        return data?.formTemplate.userVisibleName ?? ''
      }}
      value={defaultTemplateId ?? ''}
      label={label}
      variables={variables}
      mutate={mutate}
      editComponent={(value, setValue) => (
        <TemplateAutocomplete
          disabled={readOnly}
          type={templateType}
          template={value}
          onTemplateChange={(newValue) => setValue(newValue?.id ?? '')}
          label={label}
        />
      )}
      {...rest}
    />
  )
}
