import { z } from 'zod'

export const zodUuidSchema = z.string().uuid()
export const zodDaySchema = z.string().regex(/^\d{4}-\d{2}-\d{2}$/)
export const zodIntegrationEntityIdSchema = z.union([
  zodUuidSchema,
  z
    .string()
    .min(1)
    .max(20)
    .regex(/^[a-zA-Z0-9]+$/),
])
