import { gql } from '@apollo/client'
import { Autocomplete, StandardTextFieldProps, TextField } from '@mui/material'
import _ from 'lodash'
import { useCallback, useMemo } from 'react'
import {
  CompanyListSortCriteria,
  PaginatedListSortOrder,
  useDebouncedSearch,
} from 'siteline-common-web'
import {
  useCompaniesForAutocompleteQuery,
  useCompanyForAutocompleteQuery,
} from '../graphql/apollo-operations'

gql`
  query companiesForAutocomplete($input: GetPaginatedCompaniesInput!) {
    paginatedCompanies(input: $input) {
      companies {
        id
        name
      }
    }
  }
`

gql`
  query companyForAutocomplete($id: ID!) {
    company(id: $id) {
      id
      name
    }
  }
`

type CompanyAutocompleteProps = StandardTextFieldProps & {
  companyId: string | null
  setCompanyId: (id: string | null) => void
}

interface Option {
  id: string
  name: string
}

export function CompanyAutocomplete({
  companyId,
  setCompanyId,
  ...rest
}: CompanyAutocompleteProps) {
  const { search, debouncedSearch, onSearch } = useDebouncedSearch()

  const { data: companiesData, loading } = useCompaniesForAutocompleteQuery({
    variables: {
      input: {
        search: debouncedSearch,
        sortCriteria: CompanyListSortCriteria.SEARCH_RELEVANCE,
        sortOrder: PaginatedListSortOrder.DESC,
      },
    },
    skip: debouncedSearch.length < 3,
  })

  const { data: companyData } = useCompanyForAutocompleteQuery({
    variables: {
      id: companyId ?? '',
    },
    skip: !companyId,
  })

  const options = useMemo(() => {
    const autocompleted = companiesData?.paginatedCompanies.companies ?? []
    if (!companyData) {
      return autocompleted
    }
    return _.uniqBy([...autocompleted, companyData.company], (company) => company.id)
  }, [companyData, companiesData?.paginatedCompanies.companies])

  const selectedOption = useMemo(() => {
    const found = options.find((option) => option.id === companyId)
    return found ?? null
  }, [options, companyId])

  const onOptionSelected = useCallback(
    (option: Option | null) => {
      setCompanyId(option?.id ?? null)
    },
    [setCompanyId]
  )

  return (
    <Autocomplete
      loading={loading}
      inputValue={search}
      onInputChange={(ev, value) => onSearch(value)}
      getOptionLabel={(option) => option.name}
      options={options}
      noOptionsText={search ? undefined : 'Type to search companies'}
      includeInputInList
      onChange={(ev, value) => onOptionSelected(value)}
      filterOptions={(options) => options}
      value={selectedOption}
      multiple={false}
      renderInput={(params) => <TextField {...params} {...rest} />}
      // Required to provide an explicit child key
      // See https://stackoverflow.com/a/69396153
      renderOption={(props, option) => (
        <li {...props} key={option.id}>
          {option.name}
        </li>
      )}
    />
  )
}
