import { CircularProgress, Container } from '@mui/material'
import { Theme } from '@mui/material/styles'
import { makeStylesFast } from 'siteline-common-web'

const useStyles = makeStylesFast((theme: Theme) => ({
  progressContainer: {
    textAlign: 'center',
  },
  loader: {
    height: '40px',
    position: 'absolute',
    left: '0',
    right: '0',
    top: '0',
    bottom: '0',
    margin: 'auto',
  },
  progress: {
    margin: theme.spacing(2),
  },
}))

export function Loader() {
  const classes = useStyles()

  return (
    <Container className={classes.progressContainer}>
      <div className={classes.loader}>
        <CircularProgress className={classes.progress} />
      </div>
    </Container>
  )
}
