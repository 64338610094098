import { gql } from '@apollo/client'
import _ from 'lodash'
import { useState } from 'react'
import { DocumentExpirationFrequency, useSitelineSnackbar } from 'siteline-common-web'
import * as fragments from '../../../common/graphql/Fragments'
import {
  LegalRequirementProperties,
  useUpdateLegalRequirementsMutation,
} from '../../../common/graphql/apollo-operations'
import { isStandardLegalRequirement } from '../../../common/util/LegalRequirement'
import { getTimeNoTimeZone } from '../../../common/util/Moment'
import LegalRequirements from '../../legal-requirement/LegalRequirement'

export type LegalRequirementData = {
  id?: string
  createdAt: moment.Moment
  name: string
  expirationFrequency: DocumentExpirationFrequency
  // If there are already documents for this requirement
  hasDocuments: boolean
  startDate?: string | null
  endDate?: string | null
  formTemplateId?: string | null
}

gql`
  mutation updateLegalRequirements($input: UpdateLegalRequirementsInput!) {
    updateLegalRequirements(input: $input) {
      ...ContractProperties
    }
  }
  ${fragments.contract}
`

interface ContractDetailsLegalRequirementsProps {
  contract: fragments.Contract
  legalRequirements: LegalRequirementProperties[]
}

export default function ContractDetailsLegalRequirements({
  contract,
  legalRequirements,
}: ContractDetailsLegalRequirementsProps) {
  const snackbar = useSitelineSnackbar()
  const [isEditing, setIsEditing] = useState(false)

  // Remove __typename from these objects as the update mutation does not accept them
  const noTypenameLegalRequirements = (requirements: LegalRequirementProperties[]) => {
    return _.cloneDeep(requirements).map((requirement) => {
      return {
        id: requirement.id,
        createdAt: getTimeNoTimeZone(requirement.createdAt),
        name: requirement.name,
        expirationFrequency: requirement.expirationFrequency,
        hasDocuments: requirement.documents.length > 0,
        startDate: requirement.startDate,
        endDate: requirement.endDate,
        formTemplateId: requirement.formTemplate?.id ?? null,
      }
    })
  }
  const [newLegalRequirements, setNewLegalRequirements] = useState<LegalRequirementData[]>(
    noTypenameLegalRequirements(legalRequirements)
  )

  const [updateLegalRequirement] = useUpdateLegalRequirementsMutation()

  const save = async () => {
    const requirementStartDateIncorrect = newLegalRequirements.some((req) => {
      const startDateUndefined = _.isNil(req.startDate)
      // No start date should be specified for requirements that never expire or have a user entered
      // expiration date
      const startDateNotNeeded = isStandardLegalRequirement(req.expirationFrequency)
      return startDateUndefined !== startDateNotNeeded
    })
    if (requirementStartDateIncorrect) {
      snackbar.showError(
        'Requirement start date must be specified if it has a recurring expiration'
      )
      return
    }

    const requirementNames = newLegalRequirements.map((req) => req.name)

    const requirementNameNotSet = requirementNames.some(_.isEmpty)
    if (requirementNameNotSet) {
      snackbar.showError('All requirements must have names before saving.')
      return
    }

    if (requirementNames.length !== _.uniq(requirementNames).length) {
      snackbar.showError('All requirements must have unique names before saving.')
      return
    }

    const legalRequirementInput = newLegalRequirements.map((requirement) =>
      _.omit(requirement, 'hasDocuments', 'createdAt')
    )
    await updateLegalRequirement({
      variables: {
        input: {
          contractId: contract.id,
          requirements: legalRequirementInput,
        },
      },
    })
      .then((result) => {
        setIsEditing(false)
        if (!result.data) {
          return
        }
        const newRequirements = [...result.data.updateLegalRequirements.legalRequirements].filter(
          (requirement) => !requirement.isVendorRequirement
        )
        setNewLegalRequirements(noTypenameLegalRequirements(newRequirements))
      })
      .catch((err) => snackbar.showError(err.message))
  }

  const onCancel = () => {
    if (isEditing) {
      setNewLegalRequirements(noTypenameLegalRequirements(legalRequirements))
    }
    setIsEditing(!isEditing)
  }

  return (
    <LegalRequirements
      legalRequirements={newLegalRequirements}
      setLegalRequirements={setNewLegalRequirements}
      isEditing={isEditing}
      save={save}
      contract={contract}
      onCancel={onCancel}
    />
  )
}
