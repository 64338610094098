import { gql } from '@apollo/client'
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material'
import { useState } from 'react'
import { useSitelineSnackbar } from 'siteline-common-web'
import { v4 as uuidv4 } from 'uuid'
import { EditableCardCell } from '../../common/components/EditableCardRow'
import { IdentifierIconButton } from '../../common/components/IdentifierRow'
import * as fragments from '../../common/graphql/Fragments'
import {
  useClearCompanyContactsMutation,
  useCreateCompanyContactMutation,
  useUpdateCompanyContactMutation,
} from '../../common/graphql/apollo-operations'

gql`
  mutation createCompanyContact($input: CreateCompanyContactInput!) {
    createCompanyContact(input: $input) {
      ...CompanyContactProperties
    }
  }
  ${fragments.companyContact}
`

gql`
  mutation updateCompanyContact($input: UpdateCompanyContactInput!) {
    updateCompanyContact(input: $input) {
      ...CompanyContactProperties
    }
  }
  ${fragments.companyContact}
`

gql`
  mutation clearCompanyContacts($id: ID!) {
    clearCompanyContacts(id: $id) {
      ...CompanyForDetailsProperties
    }
  }
  ${fragments.companyForDetails}
`

interface NewContact {
  fullName: string
  email: string
  companyName: string
}

function getEmptyContact(): NewContact {
  return {
    fullName: '',
    email: '',
    companyName: '',
  }
}

interface CompanyDetailsCompanyContactsProps {
  company: fragments.DetailedCompany
}

/** A list of CompanyContacts on the given Company, as well as the ability to update/delete them  */
export function CompanyDetailsCompanyContacts({ company }: CompanyDetailsCompanyContactsProps) {
  const [createCompanyContact] = useCreateCompanyContactMutation({
    update(cache, { data }) {
      if (!data) {
        return
      }

      const newRef = cache.writeFragment({
        data: data.createCompanyContact,
        fragment: fragments.companyContact,
        fragmentName: 'CompanyContactProperties',
      })

      cache.modify({
        id: cache.identify(company),
        fields: {
          contacts() {
            return [...company.contacts, newRef]
          },
        },
      })
    },
  })
  const [updateCompanyContact] = useUpdateCompanyContactMutation()
  const [clearCompanyContacts] = useClearCompanyContactsMutation()
  const [showAddNew, setShowAddNew] = useState<boolean>(false)
  const [newContact, setNewContact] = useState<NewContact>(getEmptyContact())
  const snackbar = useSitelineSnackbar()

  const existingContacts = company.contacts
  const collator = new Intl.Collator()
  const sortedContacts = [...existingContacts]
    .sort((a, b) => collator.compare(a.fullName, b.fullName))
    .sort((a, b) => collator.compare(a.companyName, b.companyName))

  const handleCreate = () => {
    if (!newContact.fullName || !newContact.email || !newContact.companyName) {
      snackbar.showError('All fields must be filled out for company contact')
      return
    }

    createCompanyContact({
      variables: { input: { ...newContact, companyId: company.id } },
      optimisticResponse: {
        createCompanyContact: {
          id: uuidv4(),
          ...newContact,
          archivedAt: null,
          __typename: 'CompanyContact',
        },
        __typename: 'Mutation',
      },
    })
    setShowAddNew(false)
    setNewContact(getEmptyContact())
  }

  const handleDeleteCompanyContacts = () => {
    const confirmed = window.confirm('Are you sure you want to delete all company contacts?')
    if (!confirmed) {
      return
    }

    clearCompanyContacts({ variables: { id: company.id } })
  }

  return (
    <Card>
      <CardHeader
        title="Contacts"
        action={
          <Button color="error" onClick={() => handleDeleteCompanyContacts()}>
            Delete all contacts
          </Button>
        }
      />
      <Divider />
      <CardContent style={{ padding: 0 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Company Name</TableCell>
              <TableCell>Full Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Active</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedContacts.map((contact) => (
              <TableRow key={contact.id}>
                <EditableCardCell
                  value={contact.companyName}
                  formatValue={(value) => value}
                  editComponent={(value, setValue) => (
                    <TextField
                      value={value}
                      onChange={(ev) => setValue(ev.target.value)}
                      size="small"
                    />
                  )}
                  readOnly={false}
                  variables={(value: string) => ({
                    input: {
                      id: contact.id,
                      companyName: value,
                    },
                  })}
                  mutate={updateCompanyContact}
                />
                <EditableCardCell
                  value={contact.fullName}
                  formatValue={(value) => value}
                  editComponent={(value, setValue) => (
                    <TextField
                      value={value}
                      onChange={(ev) => setValue(ev.target.value)}
                      size="small"
                    />
                  )}
                  readOnly={false}
                  variables={(value) => ({
                    input: {
                      id: contact.id,
                      fullName: value,
                    },
                  })}
                  mutate={updateCompanyContact}
                />
                <EditableCardCell
                  value={contact.email}
                  formatValue={(value) => value}
                  editComponent={(value, setValue) => (
                    <TextField
                      value={value}
                      onChange={(ev) => setValue(ev.target.value)}
                      size="small"
                    />
                  )}
                  readOnly={false}
                  variables={(value) => ({
                    input: {
                      id: contact.id,
                      email: value,
                    },
                  })}
                  mutate={updateCompanyContact}
                />
                <EditableCardCell
                  value={contact.archivedAt === null ? true : false}
                  formatValue={(value) => value.toString()}
                  editComponent={(value, setValue) => (
                    <Checkbox
                      checked={value}
                      onChange={(ev) => setValue(ev.target.checked)}
                      size="small"
                    />
                  )}
                  readOnly={false}
                  variables={(value) => ({
                    input: {
                      id: contact.id,
                      isActive: value,
                    },
                  })}
                  mutate={updateCompanyContact}
                />
                <TableCell>
                  <IdentifierIconButton id={contact.id} />
                </TableCell>
              </TableRow>
            ))}
            {showAddNew && (
              <TableRow>
                <TableCell>
                  <TextField
                    value={newContact.companyName}
                    onChange={(ev) =>
                      setNewContact({ ...newContact, companyName: ev.target.value })
                    }
                    size="small"
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    value={newContact.fullName}
                    onChange={(ev) => setNewContact({ ...newContact, fullName: ev.target.value })}
                    size="small"
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    value={newContact.email}
                    onChange={(ev) => setNewContact({ ...newContact, email: ev.target.value })}
                    size="small"
                  />
                </TableCell>
                <TableCell />
                <TableCell>
                  <Button color="primary" variant="contained" onClick={handleCreate}>
                    Create
                  </Button>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell colSpan={4} style={{ border: 'none' }}>
                <Button size="small" onClick={() => setShowAddNew(!showAddNew)}>
                  {showAddNew ? 'Cancel' : 'Add Company Contact'}
                </Button>
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </CardContent>
    </Card>
  )
}
