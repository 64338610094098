import { Button, Dialog, DialogActions, DialogContent, Typography } from '@mui/material'
import copy from 'copy-to-clipboard'
import _ from 'lodash'
import { useCallback } from 'react'
import ReactJson, { OnCopyProps } from 'react-json-view'
import { makeStylesFast, useSitelineSnackbar } from 'siteline-common-web'
import { WriteSyncOperation } from './WriteSyncOperationRow'

const useStyles = makeStylesFast(() => ({
  content: {
    // Set a minimum width, otherwise the dialog jitters when hovering because of the clipboard button
    minWidth: '700px',
  },
}))

type WriteSyncOperationPayloadProps = {
  operation: WriteSyncOperation
  open: boolean
  onClose: () => void
}

/**
 * Dialog that allows to view the payload/result of a write sync (JSON)
 */
export function WriteSyncOperationPayload({
  operation,
  open,
  onClose,
}: WriteSyncOperationPayloadProps) {
  const classes = useStyles()
  const snackbar = useSitelineSnackbar()

  const handleCopy = useCallback(
    (clip: OnCopyProps) => {
      let stringToCopy: string
      if (_.isString(clip.src)) {
        stringToCopy = clip.src
      } else {
        stringToCopy = JSON.stringify(clip.src)
      }
      copy(stringToCopy)
      snackbar.showSuccess(`Copied to clipboard`)
    },
    [snackbar]
  )

  return (
    <Dialog maxWidth="lg" open={open} onClose={onClose}>
      <DialogContent className={classes.content}>
        <Typography variant="h5" sx={{ marginBottom: 2 }}>
          Payload
        </Typography>
        <ReactJson
          name={false}
          src={operation.payload}
          displayDataTypes={false}
          displayObjectSize={false}
          quotesOnKeys={false}
          enableClipboard={handleCopy}
        />
        <Typography variant="h5" sx={{ marginTop: 2, marginBottom: 2 }}>
          Result
        </Typography>
        <ReactJson
          name={false}
          src={operation.result ?? {}}
          displayDataTypes={false}
          displayObjectSize={false}
          quotesOnKeys={false}
          enableClipboard={handleCopy}
        />
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={onClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  )
}
