import { forwardRef, useMemo } from 'react'
// eslint-disable-next-line no-restricted-imports
import { Document, DocumentProps, Page, pdfjs } from 'react-pdf'
import LinkService from 'react-pdf/dist/esm/LinkService'
import { ScrollPageIntoViewArgs } from 'react-pdf/dist/esm/shared/types'

// Import CSS required for react-pdf
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import 'react-pdf/dist/esm/Page/TextLayer.css'

// Configure worker
// Note that we don't use a CDN anymore because we need to inject a polyfill
// Note that we use the legacy build because the modern build uses Promise.withResolvers without polyfill.
// See https://github.com/mozilla/pdf.js/wiki/Frequently-Asked-Questions#which-browsersenvironments-are-supported
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/legacy/build/pdf.worker.min.mjs',
  import.meta.url
).toString()

type DocumentRefParams = {
  linkService: React.RefObject<LinkService>
  pages: React.RefObject<HTMLDivElement[]>
  viewer: React.RefObject<{
    scrollPageIntoView: (args: ScrollPageIntoViewArgs) => void
  }>
}

const DocumentWithStandardFonts = forwardRef<DocumentRefParams, DocumentProps>(
  function DocumentWithStandardFonts(props, ref) {
    const optionsWithStandardFonts = useMemo((): DocumentProps['options'] => {
      return {
        ...props.options,
        // https://github.com/wojtekmaj/react-pdf?tab=readme-ov-file#setting-up-react-pdf-1
        standardFontDataUrl: `${import.meta.env.BASE_URL}standardFonts/`,
        // https://github.com/wojtekmaj/react-pdf?tab=readme-ov-file#setting-up-react-pdf
        cMapUrl: `${import.meta.env.BASE_URL}cmaps/`,
      }
    }, [props.options])
    return <Document ref={ref} options={optionsWithStandardFonts} {...props} />
  }
)

export { DocumentWithStandardFonts as Document, Page }
