import _ from 'lodash'
import {
  PayAppRequirementCondition,
  UpdatePayAppRequirementInput,
} from '../graphql/apollo-operations'

/**
 * Note: This matches the equivalent function in common/src/utils/pay-app-requirements.ts
 * Given an array of conditions, check whether any conflicting conditions are provided.
 */
export function findInvalidConditions(
  conditions: PayAppRequirementCondition[]
): PayAppRequirementCondition[] | undefined {
  const invalidConditionCombinations = [
    // A pay app cannot both be progress only and retention only
    [PayAppRequirementCondition.PROGRESS_ONLY, PayAppRequirementCondition.RETENTION_ONLY],
    // A pay app cannot both have no progress remaining and some progress remaining
    [
      PayAppRequirementCondition.NO_PROGRESS_REMAINING,
      PayAppRequirementCondition.SOME_PROGRESS_REMAINING,
    ],
    // A pay app cannot both have no retention held and some retention held
    [PayAppRequirementCondition.NO_RETENTION_HELD, PayAppRequirementCondition.SOME_RETENTION_HELD],
  ]

  const invalidConditionCombination = invalidConditionCombinations.find((invalidCombination) =>
    invalidCombination.every((condition) => conditions.includes(condition))
  )

  return invalidConditionCombination
}

// Finds an earlier pay app requirement that has a strict subset of the conditions.
// ex: "if (a) {...} else if (a && b) {...}"" means that the else if won't ever be hit
export function hasSupersetConditions(
  currentRequirement: UpdatePayAppRequirementInput,
  previousRequirements: UpdatePayAppRequirementInput[]
): UpdatePayAppRequirementInput | undefined {
  if (currentRequirement.conditions.length === 0) {
    // The fallback requirement is always valid
    return
  }

  return previousRequirements.find((earlierRequirement) => {
    return _.difference(earlierRequirement.conditions, currentRequirement.conditions).length === 0
  })
}
