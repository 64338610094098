import { Card, CardContent, CardHeader, Divider, Theme } from '@mui/material'
import _ from 'lodash'
import ReactJson from 'react-json-view'
import { makeStylesFast, useSitelineSnackbar } from 'siteline-common-web'
import {
  ContractProperties,
  useUpdateContractMutation,
} from '../../common/graphql/apollo-operations'

const useStyles = makeStylesFast((theme: Theme) => ({
  card: {
    paddingBottom: 0,
  },
  cardContent: {
    padding: theme.spacing(2),
    '& .validation-failure': {
      display: 'none',
    },
  },
}))

interface ContractDetailsTemplateVariablesProps {
  contract: ContractProperties
}

export default function ContractDetailsTemplateVariables({
  contract,
}: ContractDetailsTemplateVariablesProps) {
  const classes = useStyles()
  const snackbar = useSitelineSnackbar()
  const [updateContractMutation] = useUpdateContractMutation()

  const onUpdate = (newValue: Record<string, unknown>) => {
    try {
      validate(newValue)
    } catch (err) {
      snackbar.showError(err.message)
      return false
    }

    snackbar.showLoading('Saving template variables...')
    updateContractMutation({
      variables: {
        input: {
          id: contract.id,
          contractTemplateVariables: newValue,
        },
      },
    })
      .then(() => snackbar.showSuccess())
      .catch((err) => snackbar.showError(err.message))

    return newValue
  }

  const validate = (newValue: Record<string, unknown>) => {
    if (!_.isObject(newValue)) {
      throw new Error('Value must be an object')
    }
    _.each(newValue, (value, key) => {
      const isKeyValid = /^[a-zA-Z0-9]+$/.test(key)
      if (!isKeyValid) {
        throw new Error(`"${key}" is not a valid key`)
      }
      if (!_.isString(value)) {
        throw new Error(`Value for key "${key}" must be a string`)
      }
    })
  }

  return (
    <Card className={classes.card}>
      <CardHeader
        title="Contract Template Variables"
        subheader={
          <span>
            Accessible in the <code>contract</code> key
          </span>
        }
      />
      <Divider />
      <CardContent className={classes.cardContent}>
        <ReactJson
          name="contract"
          displayDataTypes={false}
          displayObjectSize={false}
          quotesOnKeys={false}
          enableClipboard={false}
          src={contract.contractTemplateVariables as object}
          theme="shapeshifter:inverted"
          defaultValue=""
          onAdd={(add) => onUpdate(add.updated_src as Record<string, unknown>)}
          onEdit={(add) => onUpdate(add.updated_src as Record<string, unknown>)}
          onDelete={(add) => onUpdate(add.updated_src as Record<string, unknown>)}
          validationMessage={''}
        />
      </CardContent>
    </Card>
  )
}
