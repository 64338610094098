import SaveAltIcon from '@mui/icons-material/SaveAlt'
import SearchIcon from '@mui/icons-material/Search'
import { Button, Card, CardContent, CardHeader, InputAdornment, TextField } from '@mui/material'
import copy from 'copy-to-clipboard'
import _ from 'lodash'
import { ReactElement, useEffect, useState } from 'react'
import ReactJson from 'react-json-view'
import { pdfTypes, templateVariablesDocumentation } from 'siteline-common-all'
import { loadTemplateVariables, saveAs, useSitelineSnackbar } from 'siteline-common-web'
import { PayAppAutocomplete } from '../../../common/components/PayAppAutocomplete'

export function FormTemplateDocumentation() {
  const [templateVariables, setTemplateVariables] = useState<pdfTypes.TemplateVariables>(
    templateVariablesDocumentation
  )
  const [filter, setFilter] = useState('')
  const snackbar = useSitelineSnackbar()
  const [payAppId, setPayAppId] = useState<string | null>(null)

  const save = (name: string, obj: Record<string, unknown>) => {
    const json = JSON.stringify(obj)
    const blob = new Blob([json], { type: 'application/json' })
    const filename = `template-variables-${name}.json`
    saveAs(blob, filename)
  }

  // Filters template variables by path or value.
  const applyFilter = (
    obj: Record<string, unknown>,
    filter: string,
    basePath: string[] = []
  ): Record<string, unknown> | undefined => {
    const result = _.mapValues(obj, (value, key) => {
      // String value (leaf)
      if (_.isString(value)) {
        const pathString = [...basePath, key].join('.').toLowerCase()
        const inPath = pathString.includes(filter.toLowerCase())
        const inValue = value.toLowerCase().includes(filter.toLowerCase())
        return inPath || inValue ? value : undefined
      }

      // Array value
      if (_.isArray(value)) {
        const result = _.compact(
          value.map((item, index) => applyFilter(item, filter, [...basePath, `${key}[${index}]`]))
        )
        return result.length > 0 ? result : undefined
      }

      // Object value
      if (_.isObject(value)) {
        const result = applyFilter(value as Record<string, unknown>, filter, [...basePath, key])
        const withoutEmpty = _.pickBy(result, (value) => !_.isEmpty(value))
        return _.values(withoutEmpty).length > 0 ? withoutEmpty : undefined
      }

      return undefined
    })
    const withoutEmpty = _.pickBy(result, (value) => !_.isEmpty(value))
    return _.values(withoutEmpty).length > 0 ? withoutEmpty : undefined
  }

  useEffect(() => {
    loadTemplateVariables(payAppId ?? undefined)
      .then((vars) => setTemplateVariables(vars))
      .catch(() => setTemplateVariables(templateVariablesDocumentation))
  }, [payAppId])

  const jsonView = (obj: Record<string, unknown>): ReactElement => {
    return (
      <ReactJson
        name={null}
        enableClipboard={(clip) => {
          const key = _.tail(clip.namespace).join('.').replace(/.0/, '[0]')
          copy(key)
          snackbar.showSuccess(`Copied key to clipboard`)
        }}
        displayDataTypes={false}
        displayObjectSize={false}
        quotesOnKeys={false}
        src={applyFilter(obj, filter) as object}
        theme="shapeshifter:inverted"
        collapsed
      />
    )
  }

  return (
    <Card>
      <CardContent>
        <PayAppAutocomplete payAppId={payAppId} onPayAppIdChange={setPayAppId} />
        <TextField
          style={{ marginTop: 20 }}
          fullWidth
          size="small"
          variant="outlined"
          placeholder="Search"
          value={filter}
          onChange={(ev) => setFilter(ev.target.value)}
          slotProps={{
            input: {
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            },
          }}
        />
      </CardContent>
      {payAppId && templateVariables.targetPayApp && (
        <>
          <CardHeader
            title={
              <span>
                Pay App <code>{payAppId}</code>
              </span>
            }
          />
          <CardContent>
            {jsonView(templateVariables.targetPayApp)}
            <Button
              startIcon={<SaveAltIcon />}
              onClick={() => save(`pay-app-${payAppId}`, templateVariables.targetPayApp ?? {})}
            >
              Save as JSON
            </Button>
          </CardContent>
        </>
      )}
      {(!payAppId || !templateVariables.targetPayApp) && (
        <>
          <CardHeader title="Pay App (Lump Sum)" />
          <CardContent>{jsonView(templateVariables.payAppLumpSum)}</CardContent>
          <CardHeader title="Pay App (Unit Price)" />
          <CardContent>{jsonView(templateVariables.payAppUnitPrice)}</CardContent>
          <CardHeader title="Pay App (T&M)" />
          <CardContent>{jsonView(templateVariables.payAppTimeAndMaterials)}</CardContent>
          <CardHeader title="Pay App (Quick)" />
          <CardContent>{jsonView(templateVariables.payAppQuick)}</CardContent>
        </>
      )}
      <CardHeader title="Lien Waiver" />
      <CardContent>{jsonView(templateVariables.lienWaiver)}</CardContent>
      <CardHeader title="LegalRequirement" />
      <CardContent>{jsonView(templateVariables.legalRequirement)}</CardContent>
      <CardHeader title="ChangeOrderRequest" />
      <CardContent>{jsonView(templateVariables.changeOrderRequest)}</CardContent>
      <CardHeader title="ChangeOrderLog" />
      <CardContent>{jsonView(templateVariables.changeOrderLog)}</CardContent>
    </Card>
  )
}
