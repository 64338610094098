import { gql } from '@apollo/client'
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Container,
  Grid2,
  MenuItem,
  Select,
  TextField,
  Theme,
  Typography,
} from '@mui/material'
import _ from 'lodash'
import { FormEvent, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { makeStylesFast, useSitelineSnackbar } from 'siteline-common-web'
import Page from '../../common/components/Page'
import * as fragments from '../../common/graphql/Fragments'
import {
  FormTemplateProperties,
  FormTemplateType,
  useCreateFormTemplateMutation,
} from '../../common/graphql/apollo-operations'

const useStyles = makeStylesFast((theme: Theme) => ({
  root: {
    padding: theme.spacing(3, 0),
  },
  input: {
    marginBottom: theme.spacing(2),
  },
  actions: {
    padding: theme.spacing(2),
  },
  uploadPaper: {
    height: 148,
    padding: theme.spacing(2),
    border: '1px dashed rgba(0, 0, 0, 0.12)',
    cursor: 'pointer',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  addAttachment: {
    height: 116,
    textAlign: 'center',
    '& span': {
      display: 'block',
    },
  },
  formTemplatePreview: {
    height: 800,
    backgroundSize: 'contain',
    marginBottom: theme.spacing(1),
  },
}))

gql`
  mutation createFormTemplate($input: CreateFormTemplateInput!) {
    createFormTemplate(input: $input) {
      ...FormTemplateProperties
    }
  }
  ${fragments.formTemplate}
`

export default function FormTemplateCreate() {
  const classes = useStyles()

  const [name, setName] = useState('')
  const [type, setType] = useState<FormTemplateType>(FormTemplateType.PAY_APP_LUMP_SUM)
  const snackbar = useSitelineSnackbar()
  const [createFormTemplateMutation] = useCreateFormTemplateMutation()
  const navigate = useNavigate()

  const validateAndSubmit = async () => {
    if (name.length === 0) {
      throw new Error('Form template name must be provided')
    }

    const response = await createFormTemplateMutation({
      variables: {
        input: {
          userVisibleName: name,
          type,
        },
      },
    })

    if (!response.data) {
      throw new Error('No data in response')
    }

    return response.data.createFormTemplate
  }

  const onSubmit = (ev: FormEvent): void => {
    ev.preventDefault()
    snackbar.showLoading()
    validateAndSubmit()
      .then((formTemplate: FormTemplateProperties) => {
        snackbar.showSuccess()
        navigate(`/templates/${formTemplate.id}`)
      })
      .catch((err) => {
        snackbar.showError(err.message)
      })
  }

  const title = 'Create a form template'
  return (
    <Page className={classes.root} title={title}>
      <Container maxWidth={false}>
        <Grid2 container spacing={2}>
          <Grid2 size={{ xs: 12 }}>
            <Typography variant="h4">{title}</Typography>
          </Grid2>
          <Grid2 size={{ xs: 6 }}>
            <form onSubmit={onSubmit}>
              <Card>
                <CardHeader title="Form template information" />

                <CardContent>
                  {/* Name */}
                  <TextField
                    required
                    fullWidth
                    id="template-name"
                    label="User Visible Name"
                    className={classes.input}
                    value={name}
                    onChange={(ev) => setName(ev.target.value)}
                  />

                  {/* Template type */}
                  <Select
                    value={type}
                    onChange={(ev) => setType(ev.target.value as FormTemplateType)}
                  >
                    {_.values(FormTemplateType).map((type) => (
                      <MenuItem key={type} value={type}>
                        {type}
                      </MenuItem>
                    ))}
                  </Select>
                </CardContent>
                <CardActions className={classes.actions}>
                  <Button variant="contained" color="primary" type="submit">
                    Create Form Template
                  </Button>
                </CardActions>
              </Card>
            </form>
          </Grid2>
        </Grid2>
      </Container>
    </Page>
  )
}
