import chroma from 'chroma-js'
import _ from 'lodash'
import { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState } from 'react'
import Draggable, { DraggableData, DraggableEvent } from 'react-draggable'
import { Resizable, ResizeCallbackData } from 'react-resizable'
import 'react-resizable/css/styles.css'
import { safeDivide } from 'siteline-common-all'
import {
  FormTemplateAnnotationType,
  colors,
  getFormTemplateFontFamily,
  makeStylesFast,
} from 'siteline-common-web'
import stringToColor from 'string-to-color'
import {
  AnnotationOverrideProperties,
  FormTemplateAnnotationProperties,
  GetFormTemplateQuery,
  TextAlignment,
  UpdateFormAnnotationInput,
} from '../../../common/graphql/apollo-operations'
import { applyOverride } from '../../../common/util/FormTemplate'
import { ImageAnnotationContent } from './ImageAnnotationContent'
import { SignatureAnnotationContent } from './SignatureAnnotationContent'

export const FORM_TEMPLATE_ANNOTATION_Z_INDEX = 999

const useStyles = makeStylesFast(() => ({
  annotation: {
    position: 'absolute',
    boxSizing: 'border-box',
    cursor: 'move',
  },
}))

type FormTemplateAnnotationLayerProps = {
  template: GetFormTemplateQuery['formTemplate']
  annotation: FormTemplateAnnotationProperties
  override: AnnotationOverrideProperties | null
  pageWidth: number
  pageHeight: number
  isSelected: boolean
  showAllAnnotationNames: boolean
  /** If multiple annotations are selected, this is the one others will match position to */
  isAnchorAnnotation: boolean
  onSelect: (asMultipleSelection: boolean) => void
  onUpdate: (annotations: UpdateFormAnnotationInput[]) => void
  readOnly: boolean
}

export type AnnotationGeometry = {
  xStart: number
  yStart: number
  width: number
  height: number
}

export type FormTemplateAnnotationLayerHandle = {
  translate: (x: number, y: number) => void
  setGeometry: (geometry: Partial<AnnotationGeometry>) => void
  getGeometry: () => AnnotationGeometry
}

export const FormTemplateAnnotationLayer = forwardRef<
  FormTemplateAnnotationLayerHandle,
  FormTemplateAnnotationLayerProps
>(function FormTemplateAnnotationLayer(props, ref) {
  const {
    annotation: baseAnnotation,
    template,
    override,
    pageWidth,
    pageHeight,
    isSelected,
    showAllAnnotationNames,
    isAnchorAnnotation,
    onSelect,
    onUpdate,
    readOnly,
  } = props

  const annotation = override ? applyOverride(baseAnnotation, override) : baseAnnotation
  const baseColor = useMemo(() => {
    let baseColor = '#3498db'
    if (annotation.syncTag && annotation.syncTag.length > 0) {
      baseColor = stringToColor(annotation.syncTag)
    }
    return baseColor
  }, [annotation.syncTag])

  const classes = useStyles()
  const [isHovered, setIsHovered] = useState(false)
  const [isDragging, setIsDragging] = useState(false)

  const backgroundColor = useMemo(() => {
    if (isAnchorAnnotation) {
      return colors.blue30
    }
    if (isSelected) {
      return chroma(baseColor).darken(0.5).alpha(0.5).css()
    }
    if (isHovered) {
      return chroma(baseColor).alpha(0.4).css()
    }
    return chroma(baseColor).alpha(0.2).css()
  }, [baseColor, isAnchorAnnotation, isHovered, isSelected])

  const border = useMemo(() => {
    if (isAnchorAnnotation) {
      return `1px solid ${colors.blue40}`
    }
    if (isSelected) {
      return `1px solid ${chroma(baseColor).darken(0.5).css()}`
    }
    return 'dashed 1px black'
  }, [baseColor, isAnchorAnnotation, isSelected])

  const initialGeometry = useMemo(
    (): AnnotationGeometry => ({
      xStart: annotation.xStart,
      yStart: annotation.yStart,
      width: annotation.width,
      height: annotation.height,
    }),
    [annotation.height, annotation.width, annotation.xStart, annotation.yStart]
  )
  const [geometry, setGeometry] = useState<AnnotationGeometry>(initialGeometry)

  useEffect(() => {
    // Use debounced function here so the update can be canceled if the user moves
    // the annotation more before a mutation completes
    setGeometry(initialGeometry)
  }, [initialGeometry])

  useImperativeHandle(
    ref,
    () => ({
      translate: (x: number, y: number) => {
        setGeometry((geometry) => ({
          ...geometry,
          xStart: geometry.xStart + x,
          yStart: geometry.yStart + y,
        }))
      },
      setGeometry: (geometry: Partial<AnnotationGeometry>) => {
        setGeometry((oldGeometry) => ({ ...oldGeometry, ...geometry }))
      },
      getGeometry: () => geometry,
    }),
    [geometry]
  )

  const simplifyGeometry = useCallback(
    (geometry: AnnotationGeometry) => ({
      xStart: _.round(geometry.xStart, 2),
      yStart: _.round(geometry.yStart, 2),
      width: _.round(geometry.width, 2),
      height: _.round(geometry.height, 2),
    }),
    []
  )

  // Note that this is also called on click (during the mouse-down event)
  const onDragStart = useCallback(
    (ev: DraggableEvent) => {
      ev.stopPropagation()
      onSelect(ev.shiftKey)
    },
    [onSelect]
  )

  // Only called when actively dragging
  const onDrag = useCallback(
    (ev: DraggableEvent, data: DraggableData) => {
      setIsDragging(true)
      setGeometry({
        ...geometry,
        xStart: safeDivide(data.deltaX, pageWidth, 0) * 100 + geometry.xStart,
        yStart: safeDivide(data.deltaY, pageHeight, 0) * 100 + geometry.yStart,
      })
    },
    [geometry, pageHeight, pageWidth]
  )

  // Ignored when not dragging, so that we don't redundantly call `onUpdate`
  const onDragStop = useCallback(() => {
    setIsDragging(false)
    if (!isDragging) {
      return
    }

    const simplifiedGeometry = simplifyGeometry(geometry)
    setGeometry(simplifiedGeometry)
    onUpdate([
      {
        id: annotation.id,
        ...simplifiedGeometry,
      },
    ])
  }, [annotation.id, geometry, isDragging, onUpdate, simplifyGeometry])

  const onResizeStart = useCallback(() => {
    onSelect(false)
  }, [onSelect])

  const onResizeStop = useCallback(() => {
    const simplifiedGeometry = simplifyGeometry(geometry)
    setGeometry(simplifiedGeometry)
    onUpdate([
      {
        id: annotation.id,
        ...simplifiedGeometry,
      },
    ])
  }, [annotation.id, geometry, onUpdate, simplifyGeometry])

  const cssTextAlignment = useCallback((textAlignment: TextAlignment) => {
    switch (textAlignment) {
      case TextAlignment.LEFT:
        return 'left'
      case TextAlignment.CENTER:
        return 'center'
      case TextAlignment.RIGHT:
        return 'right'
    }
  }, [])

  const onResize = useCallback(
    (ev: unknown, { size }: ResizeCallbackData) => {
      setGeometry({
        ...geometry,
        width: safeDivide(size.width, pageWidth, 0) * 100,
        height: safeDivide(size.height, pageHeight, 0) * 100,
      })
    },
    [geometry, pageHeight, pageWidth]
  )

  return (
    <Draggable
      cancel=".react-resizable-handle"
      onStart={onDragStart}
      onDrag={onDrag}
      onStop={onDragStop}
      position={{
        x: (geometry.xStart * pageWidth) / 100,
        y: (geometry.yStart * pageHeight) / 100,
      }}
      disabled={readOnly}
    >
      <Resizable
        key={annotation.id}
        width={(geometry.width * pageWidth) / 100}
        height={(geometry.height * pageHeight) / 100}
        minConstraints={[10, 10]}
        onResize={readOnly ? undefined : onResize}
        onResizeStart={readOnly ? undefined : onResizeStart}
        onResizeStop={readOnly ? undefined : onResizeStop}
        handle={readOnly ? <></> : undefined}
      >
        <div
          style={{
            width: (geometry.width * pageWidth) / 100,
            height: (geometry.height * pageHeight) / 100,
            zIndex: FORM_TEMPLATE_ANNOTATION_Z_INDEX + (isSelected ? 1 : 0),
            backgroundColor,
            border,
          }}
          // Used for identifying annotations when dragging to select
          id={annotation.id}
          className={classes.annotation}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          // Used for ignoring clicks on an annotation when starting drag-to-select
          data-disableselect={true}
        >
          <div
            style={{
              position: 'relative',
              width: '100%',
              height: '100%',
              color: `#${annotation.fontColor}`,
              ...getFormTemplateFontFamily(annotation.fontFamily),
              textAlign: cssTextAlignment(annotation.textAlignment),
              fontSize: (geometry.height * pageHeight) / 100 - 2,
              whiteSpace: 'nowrap',
            }}
            // When annotations are editable (readOnly === false), the draggable container handles
            // selecting the annotation on click.
            // When annotations are read-only (readOnly === true), we need an onClick handle on the
            // annotation itself.
            onClick={() => {
              if (readOnly) {
                onSelect(false)
              }
            }}
            // Intercept the onMouseDown in readOnly so that the PDF container doesn't deselect annotations
            onMouseDown={(ev) => {
              if (readOnly) {
                ev.preventDefault()
                ev.stopPropagation()
              }
            }}
          >
            {annotation.type === FormTemplateAnnotationType.IMAGE && annotation.imageType && (
              <ImageAnnotationContent
                baseColor={baseColor}
                template={template}
                annotation={annotation}
              />
            )}
            {annotation.type === FormTemplateAnnotationType.SIGNATURE &&
              annotation.signatureType && (
                <SignatureAnnotationContent template={template} annotation={annotation} />
              )}
            {annotation.type !== FormTemplateAnnotationType.IMAGE &&
              annotation.type !== FormTemplateAnnotationType.SIGNATURE && (
                <div
                  style={{
                    display: showAllAnnotationNames || isSelected || isHovered ? 'inline' : 'none',
                    // Name of the annotation often exceeds its horizontal bounds, overlapping with other
                    // annotations and messing with their selection. Instead, we disable user interaction
                    // with the name and force interaction on the container instead (which has a fixed geometry)
                    pointerEvents: 'none',
                  }}
                >
                  {annotation.userVisibleName}
                </div>
              )}
          </div>
        </div>
      </Resizable>
    </Draggable>
  )
})
