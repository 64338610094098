import { gql } from '@apollo/client'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import StarIcon from '@mui/icons-material/Star'
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  IconButton,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
  Tooltip,
} from '@mui/material'
import _ from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { makeStylesFast, useSitelineSnackbar } from 'siteline-common-web'
import { useMergeGeneralContractorCompaniesMutation } from '../../common/graphql/apollo-operations'
import { formatLocationOneLine } from '../../common/util/Location'
import { DuplicateGroup } from './GcDedupe'

const useStyles = makeStylesFast((theme: Theme) => ({
  card: {
    marginBottom: theme.spacing(2),
  },
  row: {
    '& .destinationButton': {
      visibility: 'hidden',
    },
    '&:hover .destinationButton': {
      visibility: 'visible',
    },
  },
  checkbox: {},
  mergeIcon: {
    padding: theme.spacing(1),
  },
}))

gql`
  mutation mergeGeneralContractorCompanies($input: MergeGeneralContractorCompaniesInput!) {
    mergeGeneralContractorCompanies(input: $input)
  }
`

type GcDedupeDuplicateGroupProps = {
  group: DuplicateGroup
  onSkip: () => void
}

/**
 * GC dedupe page that finds GCs and deduplicates them for a subcontractor
 */
export function GcDedupeDuplicateGroup({ group, onSkip }: GcDedupeDuplicateGroupProps) {
  const classes = useStyles()
  const snackbar = useSitelineSnackbar()

  const initialDestinationId = useMemo(() => {
    const max = _.maxBy(group.companies, (company) => company.contractCount)
    return max?.company.id ?? group.companies[0].company.id
  }, [group.companies])

  const initialSourceIds = useMemo(
    () =>
      _.without(
        group.companies.map((row) => row.company.id),
        initialDestinationId
      ),
    [group.companies, initialDestinationId]
  )
  const [sourceIds, setSourceIds] = useState<string[]>(initialSourceIds)
  useEffect(() => setSourceIds(initialSourceIds), [initialSourceIds])

  const [destinationId, setDestinationId] = useState<string>(initialDestinationId)
  useEffect(() => setDestinationId(initialDestinationId), [initialDestinationId])

  const [merge] = useMergeGeneralContractorCompaniesMutation()

  const handleCheckboxChange = useCallback(
    (id: string, checked: boolean) => {
      if (checked) {
        setSourceIds(_.uniq([...sourceIds, id]))
      } else {
        setSourceIds(_.without(sourceIds, id))
      }
    },
    [sourceIds]
  )

  const handleMerge = useCallback(() => {
    const destination = group.companies.find((row) => row.company.id === destinationId)
    if (!destination) {
      return
    }
    const confirmed = window.confirm(
      `Are you sure you want to merge ${sourceIds.length} companies into '${destination.company.name}'?`
    )
    if (!confirmed) {
      return
    }
    merge({
      variables: {
        input: { destinationId, sourceIds },
      },
    })
      .then(() => {
        onSkip()
        snackbar.showSuccess('Merged companies')
      })
      .catch((err) => snackbar.showError(err.message))
  }, [destinationId, group.companies, merge, onSkip, snackbar, sourceIds])

  const handleSetDestination = useCallback(
    (id: string) => {
      const oldDestinationId = destinationId
      setDestinationId(id)
      setSourceIds([..._.without(sourceIds, id), oldDestinationId])
    },
    [destinationId, sourceIds]
  )

  return (
    <Card className={classes.card}>
      <CardContent>
        <Table key={group.id} size="small">
          <TableHead>
            <TableCell width="12%"></TableCell>
            <TableCell width="15%"># Contracts</TableCell>
            <TableCell>GC name</TableCell>
          </TableHead>
          <TableBody>
            {group.companies.map((row) => (
              <TableRow key={row.company.id} className={classes.row}>
                <TableCell>
                  {row.company.id === destinationId && (
                    <>
                      <Tooltip title="Other companies will be merged into this one" placement="top">
                        <IconButton>
                          <StarIcon sx={{ color: 'gold' }} />
                        </IconButton>
                      </Tooltip>
                      <Checkbox
                        className={classes.checkbox}
                        size="small"
                        checked
                        disabled
                      ></Checkbox>
                    </>
                  )}
                  {row.company.id !== destinationId && (
                    <>
                      <Tooltip title="Merge into this company" placement="top">
                        <IconButton
                          className="destinationButton"
                          onClick={() => handleSetDestination(row.company.id)}
                        >
                          <StarIcon />
                        </IconButton>
                      </Tooltip>
                      <Checkbox
                        className={classes.checkbox}
                        size="small"
                        checked={sourceIds.includes(row.company.id)}
                        disabled={row.company.id === destinationId}
                        onChange={(ev, checked) => handleCheckboxChange(row.company.id, checked)}
                      ></Checkbox>
                    </>
                  )}
                </TableCell>
                <TableCell>{row.contractCount}</TableCell>
                <TableCell>
                  <Link
                    component={RouterLink}
                    to={`/companies/${row.company.id}`}
                    underline="hover"
                  >
                    {row.company.name}
                  </Link>
                  <Tooltip
                    placement="top"
                    title={
                      <>
                        Addresses:
                        <br />
                        {row.company.locations.map((location) => (
                          <>
                            {formatLocationOneLine(location)}
                            <br />
                          </>
                        ))}
                      </>
                    }
                  >
                    <InfoOutlinedIcon
                      fontSize="small"
                      sx={{ verticalAlign: 'middle', color: 'grey', marginLeft: 1 }}
                    />
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CardContent>
      <CardActions>
        <Button onClick={() => handleMerge()} disabled={sourceIds.length === 0} color="error">
          Merge {sourceIds.length + 1} companies
        </Button>
        <Button onClick={() => onSkip()}>Skip</Button>
      </CardActions>
    </Card>
  )
}
