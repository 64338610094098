import { Tooltip, TooltipProps } from '@mui/material'
import { PropsWithChildren, forwardRef, useMemo, useState } from 'react'

/**
 * Controlled MUI tooltip for improved render performance
 * See https://github.com/mui/material-ui/issues/27057
 */
export const SitelineTooltip = forwardRef<HTMLDivElement, PropsWithChildren<TooltipProps>>(
  function SitelineTooltip(props, ref) {
    const { children, className, style, ...tooltipProps } = props
    const [renderTooltip, setRenderTooltip] = useState<boolean>(false)

    const divStyle = useMemo(
      () => ({
        display: 'flex',
        alignItems: 'center',
        ...style,
      }),
      [style]
    )

    // Render the tooltip component only if hovering and if there's actually a tooltip
    // title to display
    const shouldRenderTooltip = renderTooltip && props.title

    return (
      <div
        ref={ref}
        onMouseEnter={() => !renderTooltip && setRenderTooltip(true)}
        className={className}
        style={divStyle}
      >
        {!shouldRenderTooltip && children}
        {shouldRenderTooltip && <Tooltip {...tooltipProps}>{children}</Tooltip>}
      </div>
    )
  }
)
