import { Container, Grid2, Theme, Typography } from '@mui/material'
import { makeStylesFast } from 'siteline-common-web'
import Page from '../../common/components/Page'
import DemoToolsMarkPayAppsAsPaid from './DemoMarkPayAppsAsPaid'
import DemoToolsDeleteLienWaivers from './DemoToolsDeleteLienWaivers'
import DemoToolsSubmitLienWaivers from './DemoToolsSubmitLienWaivers'

const useStyles = makeStylesFast((theme: Theme) => ({
  root: {
    paddingTop: theme.spacing(2),
  },
}))

/**
 * Tools for demo purposes (AEs)
 */
export default function DemoTools() {
  const classes = useStyles()
  return (
    <Page title="Demo tools" className={classes.root}>
      <Container maxWidth={false}>
        <Grid2 container spacing={2}>
          <Grid2 size={{ xs: 4 }} className="header">
            <Typography variant="h4">Demo tools</Typography>
          </Grid2>
          <Grid2 size={{ xs: 8 }} />
          <Grid2 size={{ xs: 4 }}>
            <DemoToolsMarkPayAppsAsPaid />
          </Grid2>
          <Grid2 size={{ xs: 4 }}>
            <DemoToolsDeleteLienWaivers />
          </Grid2>
          <Grid2 size={{ xs: 4 }}>
            <DemoToolsSubmitLienWaivers />
          </Grid2>
        </Grid2>
      </Container>
    </Page>
  )
}
