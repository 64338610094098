import { LocationInput, LocationProperties } from '../graphql/apollo-operations'

export const formatLocationOneLine = (location: LocationProperties | LocationInput): string => {
  let formatted = ''
  if (location.street1) {
    formatted += `${location.street1}, `
  }
  if (location.street2) {
    formatted += `${location.street2}, `
  }
  formatted += `${location.city}, ${location.state}`
  if (location.postalCode) {
    formatted += ` ${location.postalCode}`
  }
  return formatted
}

export const formatLocationManyLines = (location: LocationProperties | LocationInput): string => {
  let formatted = ''
  if (location.street1) {
    formatted += `${location.street1}, `
  }
  if (location.street2) {
    formatted += `${location.street2}`
  }
  if (formatted.length > 0) {
    formatted += '\n'
  }
  formatted += `${location.city}, ${location.state}`
  if (location.postalCode) {
    formatted += ` ${location.postalCode}`
  }
  return formatted
}
