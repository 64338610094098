import { gql } from '@apollo/client'
import { Autocomplete, TextField } from '@mui/material'
import _ from 'lodash'
import moment from 'moment-timezone'
import { useMemo } from 'react'
import { useDebouncedSearch } from 'siteline-common-web'
import {
  SearchResult,
  SearchResultType,
  usePayAppForAutocompleteQuery,
  useSearchQuery,
} from '../graphql/apollo-operations'

gql`
  query payAppForAutocomplete($id: ID!) {
    payApp(id: $id) {
      id
      billingEnd
      timeZone
      contract {
        id
        project {
          id
          name
        }
        company {
          id
          name
        }
      }
    }
  }
`

type PayAppAutocompleteProps = {
  payAppId: string | null
  onPayAppIdChange: (payAppId: string | null) => void
}

/**
 * Autocomplete across all pay apps in the system.
 * Used to select a pay app to preview data with (eg: template variables).
 */
export function PayAppAutocomplete({ payAppId, onPayAppIdChange }: PayAppAutocompleteProps) {
  const { search, debouncedSearch, onSearch } = useDebouncedSearch()
  const { data: payAppsData } = useSearchQuery({
    skip: debouncedSearch.length < 3,
    variables: {
      query: debouncedSearch,
      type: SearchResultType.PAY_APP,
    },
  })
  const { data: payAppData } = usePayAppForAutocompleteQuery({
    variables: {
      id: payAppId ?? '',
    },
    skip: !payAppId,
  })
  const results = useMemo(() => {
    const autocompleted = payAppsData?.search ?? []
    if (!payAppData) {
      return autocompleted
    }
    const companyName = _.truncate(payAppData.payApp.contract.company.name, { length: 20 })
    const projectName = _.truncate(payAppData.payApp.contract.project.name, { length: 20 })
    const date = moment
      .tz(payAppData.payApp.billingEnd, payAppData.payApp.timeZone)
      .format('MMM YYYY')
    const selected: SearchResult = {
      __typename: 'SearchResult',
      type: SearchResultType.PAY_APP,
      id: payAppData.payApp.id,
      description: [companyName, projectName, date].join(' • '),
      score: 1,
    }
    return _.uniqBy([...autocompleted, selected], (payApp) => payApp.id)
  }, [payAppData, payAppsData?.search])

  const value = useMemo(() => {
    const found = results.find((result) => result.id === payAppId)
    return found ?? null
  }, [payAppId, results])

  return (
    <Autocomplete
      fullWidth
      size="small"
      options={results}
      getOptionLabel={(result) => `${result.description} • ${result.id}`}
      renderInput={(params) => <TextField {...params} label="Pay App" variant="outlined" />}
      value={value}
      inputValue={search}
      onInputChange={(ev, value) => onSearch(value)}
      onChange={(event, newValue) => {
        onPayAppIdChange(newValue?.id ?? null)
      }}
    />
  )
}
