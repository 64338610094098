import ImageIcon from '@mui/icons-material/Image'
import chroma from 'chroma-js'
import { makeStylesFast } from 'siteline-common-web'
import {
  FormTemplateAnnotationImageType,
  FormTemplateAnnotationProperties,
  GetFormTemplateQuery,
} from '../../../common/graphql/apollo-operations'
import { AnnotationWithOverrides } from '../../../common/util/FormTemplate'

const useStyles = makeStylesFast(() => ({
  image: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    width: '100%',
    pointerEvents: 'none',
  },
}))

type ImageAnnotationContentProps = {
  baseColor: string
  annotation: FormTemplateAnnotationProperties | AnnotationWithOverrides
  template: GetFormTemplateQuery['formTemplate']
}

/**
 * Image content of an image annotation
 */
export function ImageAnnotationContent({
  baseColor,
  annotation,
  template,
}: ImageAnnotationContentProps) {
  const classes = useStyles()
  if (template.requestingCompany) {
    if (
      annotation.imageType === FormTemplateAnnotationImageType.NOTARY_SIGNATURE &&
      template.requestingCompany.notarySignature
    ) {
      return (
        <img
          className={classes.image}
          src={template.requestingCompany.notarySignature.url}
          alt="Notary signature"
        />
      )
    }
    if (
      annotation.imageType === FormTemplateAnnotationImageType.NOTARY_STAMP &&
      template.requestingCompany.notaryStamp
    ) {
      return (
        <img
          className={classes.image}
          src={template.requestingCompany.notaryStamp.url}
          alt="Notary stamp"
        />
      )
    }
    if (
      annotation.imageType === FormTemplateAnnotationImageType.SUBCONTRACTOR_LOGO &&
      template.requestingCompany.picture
    ) {
      return (
        <img
          className={classes.image}
          src={template.requestingCompany.picture.url}
          alt="Company logo"
        />
      )
    }
    if (
      annotation.imageType === FormTemplateAnnotationImageType.SECONDARY_SIGNATURE &&
      template.requestingCompany.secondarySignature
    ) {
      return (
        <img
          className={classes.image}
          src={template.requestingCompany.secondarySignature.url}
          alt="Secondary signature"
        />
      )
    }
  }

  return (
    <ImageIcon
      fontSize="inherit"
      className={classes.image}
      sx={{ color: chroma(baseColor).alpha(0.3).css() }}
    />
  )
}
