import { gql } from '@apollo/client'
import {
  Alert,
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material'
import { useCallback, useMemo, useState } from 'react'
import { isValidEmail } from 'siteline-common-all'
import { fuseSearch, useSitelineSnackbar } from 'siteline-common-web'
import {
  CompanyIntegrationProperties,
  IntegrationOnePasswordItemsQuery,
  useEnableIntegrationCredentialAutoRotationMutation,
  useIntegrationOnePasswordItemsQuery,
} from '../../common/graphql/apollo-operations'

gql`
  query integrationOnePasswordItems {
    integrationOnePasswordItems {
      vaultId
      itemId
      title
    }
  }
`

gql`
  mutation enableIntegrationCredentialAutoRotation(
    $input: EnableIntegrationCredentialAutoRotationInput!
  ) {
    enableIntegrationCredentialAutoRotation(input: $input) {
      id
      credentialsUpdatedAt
      credentialsReadyForAutoRotation
    }
  }
`
type OnePasswordItem = IntegrationOnePasswordItemsQuery['integrationOnePasswordItems'][number]
const collator = new Intl.Collator()

interface UpdateTexturaCredentialModalProps {
  companyIntegration: CompanyIntegrationProperties
  open: boolean
  onClose: () => void
}

/** Modal to update a credentials username and password */
export function UpdateTexturaCredentialModal({
  companyIntegration,
  open,
  onClose,
}: UpdateTexturaCredentialModalProps) {
  const [emailAddress, setEmailAddress] = useState<string>('')
  const [onePasswordItem, setOnePasswordItem] = useState<OnePasswordItem | null>(null)
  const snackbar = useSitelineSnackbar()
  const { data } = useIntegrationOnePasswordItemsQuery()
  const [updateCredentials] = useEnableIntegrationCredentialAutoRotationMutation()

  const onePasswordItems = useMemo(() => {
    const items = data?.integrationOnePasswordItems ?? []
    return items.toSorted((a, b) => collator.compare(a.title, b.title))
  }, [data])

  const isValidEmailAddress = useMemo(() => {
    if (emailAddress.length === 0) {
      return true
    }
    return isValidEmail(emailAddress)
  }, [emailAddress])

  const handleSubmit = useCallback(() => {
    if (!onePasswordItem || emailAddress.length === 0 || !isValidEmailAddress) {
      return
    }

    snackbar.showLoading('Updating credentials...')
    updateCredentials({
      variables: {
        input: {
          companyIntegrationId: companyIntegration.id,
          emailAddress,
          onePasswordItemId: onePasswordItem.itemId,
          onePasswordVaultId: onePasswordItem.vaultId,
        },
      },
    })
      .then(() => snackbar.showSuccess('Credentials updated'))
      .catch((error) => snackbar.showError(error.message))

    onClose()
  }, [
    companyIntegration.id,
    emailAddress,
    isValidEmailAddress,
    onClose,
    onePasswordItem,
    snackbar,
    updateCredentials,
  ])

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Manage Textura Credentials</DialogTitle>
      <DialogContent>
        <Alert severity="info" style={{ marginTop: 8 }}>
          Select the 1Password item that contains the credentials for the Textura account.
        </Alert>
        <Autocomplete
          getOptionLabel={(option) => option.title}
          options={onePasswordItems}
          includeInputInList
          onChange={(ev, value) => setOnePasswordItem(value)}
          filterOptions={(options, { inputValue }) => {
            return fuseSearch(options, inputValue, ['title'])
          }}
          value={onePasswordItem}
          multiple={false}
          renderInput={(params) => <TextField {...params} size="small" label="1Password item" />}
          // Required to provide an explicit child key
          // See https://stackoverflow.com/a/69396153
          renderOption={(props, option) => (
            <li {...props} key={option.itemId}>
              {option.title}
            </li>
          )}
          sx={{ marginTop: 2 }}
        />
        <TextField
          fullWidth
          size="small"
          label="Email address for the Textura account"
          helperText={
            isValidEmailAddress
              ? 'This is usually the admin email used in the Siteline app. Eg: cpc@siteline.com'
              : 'Email address is invalid'
          }
          error={!isValidEmailAddress}
          type="email"
          value={emailAddress}
          onChange={(ev) => setEmailAddress(ev.target.value)}
          sx={{ marginTop: 2 }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()}>Cancel</Button>
        <Button onClick={handleSubmit} color="primary">
          Link credentials
        </Button>
      </DialogActions>
    </Dialog>
  )
}
