import { Container, Theme, Typography } from '@mui/material'
import { makeStylesFast } from 'siteline-common-web'
import Page from '../../common/components/Page'
import { FormTemplateDocumentation } from '../form-template-versions/form-template-version-create/FormTemplateDocumentation'

const useStyles = makeStylesFast((theme: Theme) => ({
  root: {
    padding: theme.spacing(3, 0),
    '& h4': {
      marginBottom: theme.spacing(1),
    },
  },
}))

export default function FormTemplateVariables() {
  const classes = useStyles()

  return (
    <Page className={classes.root} title="Form Template Variables">
      <Container maxWidth={false}>
        <Typography variant="h4">Form Template Variables</Typography>
        <FormTemplateDocumentation />
      </Container>
    </Page>
  )
}
