import CheckIcon from '@mui/icons-material/Check'
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule'
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty'
import { Link, TableCell, TableRow } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'
import { colors } from 'siteline-common-web'
import { ContractsForProcessingQuery } from '../../common/graphql/apollo-operations'

function ProcessingIcon({ selected, onboarded }: { selected: boolean; onboarded: boolean }) {
  if (!selected) {
    return <HorizontalRuleIcon sx={{ color: colors.grey30 }} />
  }
  if (!onboarded) {
    return <HourglassEmptyIcon sx={{ color: colors.orange40 }} />
  }
  return <CheckIcon sx={{ color: colors.grey30 }} />
}

type ProcessingContractRowProps = {
  contract: ContractsForProcessingQuery['paginatedContracts']['contracts'][number]
}

/**
 * A single contract in the list of contracts that have processing forms.
 */
export function ProcessingContractRow({ contract }: ProcessingContractRowProps) {
  return (
    <TableRow hover>
      <TableCell>
        <Link component={RouterLink} to={`/contracts/${contract.id}`} underline="hover">
          {contract.internalProjectNumber ?? contract.project.projectNumber} -{' '}
          {contract.project.name}
        </Link>
      </TableCell>
      <TableCell>{contract.company.name}</TableCell>
      <TableCell sx={{ width: 110 }}>
        <ProcessingIcon
          selected={contract.onboardedStatus.selectedPayAppForms}
          onboarded={contract.onboardedStatus.onboardedPayAppForms}
        />
      </TableCell>
      <TableCell sx={{ width: 110 }}>
        <ProcessingIcon
          selected={contract.onboardedStatus.selectedPrimaryLienWaivers}
          onboarded={contract.onboardedStatus.onboardedPrimaryLienWaiverForms}
        />
      </TableCell>
      <TableCell sx={{ width: 110 }}>
        <ProcessingIcon
          selected={contract.onboardedStatus.selectedVendorLienWaivers}
          onboarded={contract.onboardedStatus.onboardedVendorLienWaiverForms}
        />
      </TableCell>
      <TableCell sx={{ width: 110 }}>
        <ProcessingIcon
          selected={contract.onboardedStatus.selectedChangeOrderRequestForms}
          onboarded={contract.onboardedStatus.onboardedChangeOrderRequestForms}
        />
      </TableCell>
      <TableCell sx={{ width: 110 }}>
        <ProcessingIcon
          selected={contract.onboardedStatus.selectedChangeOrderLogForms}
          onboarded={contract.onboardedStatus.onboardedChangeOrderLogForms}
        />
      </TableCell>
    </TableRow>
  )
}
