import { gql } from '@apollo/client'
import CommentIcon from '@mui/icons-material/Comment'
import ConstructionIcon from '@mui/icons-material/Construction'
import DeleteIcon from '@mui/icons-material/Delete'
import FastForwardIcon from '@mui/icons-material/FastForward'
import FilterListIcon from '@mui/icons-material/FilterList'
import SettingsIcon from '@mui/icons-material/Settings'
import SkipNextIcon from '@mui/icons-material/SkipNext'
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  timelineItemClasses,
  TimelineSeparator,
} from '@mui/lab'
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Fade,
  Grid2,
  IconButton,
  Link,
  Paper,
  Theme,
  Tooltip,
  Typography,
} from '@mui/material'
import { clsx } from 'clsx'
import _ from 'lodash'
import moment from 'moment-timezone'
import { FormEvent, ReactNode, useCallback, useEffect, useMemo, useState } from 'react'
import ReactMarkdown from 'react-markdown'
import { DisplayTransformFunc, Mention, MentionsInput, SuggestionDataItem } from 'react-mentions'
import remarkGfm from 'remark-gfm'
import {
  FORM_TEMPLATE_INSTRUCTIONS_PREFIX,
  FORM_TEMPLATE_SKIPPED_VALIDATION_PREFIX,
  FORM_TEMPLATE_STATUS_CHANGE_PREFIX,
} from 'siteline-common-all'
import {
  colors,
  FOCUSED_SUGGESTION_CLASS,
  makeStylesFast,
  MENTION_CLASS,
  SitelineText,
  SUGGESTION_LI_CLASS,
  SUGGESTIONS_LIST_CONTAINER_CLASS,
  SUGGESTIONS_LIST_UL_CLASS,
  useSitelineSnackbar,
} from 'siteline-common-web'
import { getCurrentUser } from '../../client'
import {
  FormTemplateCommentProperties,
  FormTemplateProperties,
  useAddFormTemplateCommentMutation,
  useDeleteFormTemplateCommentMutation,
  useSitelineTeamMembersQuery,
} from '../../common/graphql/apollo-operations'
import * as fragments from '../../common/graphql/Fragments'

gql`
  mutation addFormTemplateComment($input: AddFormTemplateCommentInput!) {
    addFormTemplateComment(input: $input) {
      ...FormTemplateCommentProperties
    }
  }
  ${fragments.formTemplateComment}
`

gql`
  mutation deleteFormTemplateComment($id: ID!) {
    deleteFormTemplateComment(id: $id) {
      id
    }
  }
`

const EXPANDED_HEIGHT = 96
const COLLAPSED_HEIGHT = 40
const SUGGESTIONS_LIST_HEIGHT = 200

const useStyles = makeStylesFast((theme: Theme) => ({
  timeline: {
    padding: 0,
    '& .timelineSeparator': {
      marginTop: -4,
      '& .systemGeneratedTimelineDot': {
        backgroundColor: colors.grey40,
      },
      '& .timelineDot': {
        width: 24,
        height: 24,
        alignItems: 'center',
        justifyContent: 'center',
        boxShadow: 'none',
        '& .systemGeneratedIcon': {
          height: 16,
          width: 16,
        },
        '& .userCommentIcon': {
          height: 14,
          width: 14,
          color: 'white',
        },
      },
      '& .timelineConnector': {
        width: 1,
      },
    },
    '& .comment': {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      '& .delete': {
        display: 'none',
        height: 'fit-content',
      },
      '&:hover .delete': {
        display: 'flex',
        justifyContent: 'flex-end',
      },
      '& .writtenCommentCard': {
        backgroundColor: colors.grey10,
        padding: theme.spacing(1),
      },
    },
    '& .statusChangeExpression': {
      display: 'flex',
    },
  },
  filters: {
    color: colors.grey40,
    display: 'flex',
    alignItems: 'center',
    '& .filterListIcon': {
      marginRight: theme.spacing(0.5),
    },
    '& .MuiTypography-root': {
      textTransform: 'initial',
      fontWeight: 500,
    },
  },
  textbox: {
    // Necessary in order to show comment list over the card
    overflow: 'visible',
    '& .textbox, textarea': {
      borderRadius: 4,
      border: 'none',
      // Add additional horizontal padding to compensate for 1.5px line height
      padding: theme.spacing(1, 1.5),
      color: colors.grey90,
      minHeight: COLLAPSED_HEIGHT,
      backgroundColor: colors.grey20,
      '&.expanded': {
        minHeight: EXPANDED_HEIGHT,
        backgroundColor: colors.white,
      },
      '&::placeholder': {
        color: colors.grey50,
      },
      '& *': {
        // 1.5px line height centers the styles applied to mentions via react-mentions lib
        lineHeight: 1.5,
      },
      [`& .${SUGGESTIONS_LIST_CONTAINER_CLASS}`]: {
        // Override default margin top set by react mentions lib
        // (allows the user to see what they're typing while suggestions box is open)
        marginTop: '30px !important',
        maxHeight: SUGGESTIONS_LIST_HEIGHT,
        overflow: 'auto',
        borderRadius: 4,
        boxShadow: theme.shadows[10],
        zIndex: 1000,
      },
      [` .${SUGGESTIONS_LIST_UL_CLASS}`]: {
        position: 'relative',
        height: SUGGESTIONS_LIST_HEIGHT,
      },
      [`& .${SUGGESTION_LI_CLASS}`]: {
        padding: theme.spacing(1, 2),
      },
      [`& .${FOCUSED_SUGGESTION_CLASS}`]: {
        backgroundColor: colors.grey20,
      },
      [`& .${MENTION_CLASS}`]: {
        backgroundColor: colors.blue10,
      },
    },
  },
}))

// The result of element.querySelectorAll isn't typed as possibly undefined
// We have to typecast in order to handle undefined values
type QuerySelectorResult = NodeListOf<Element> | undefined

type FormTemplateCommentProps = {
  formTemplate: FormTemplateProperties
  comment: FormTemplateCommentProperties
  isLastComment: boolean
}

enum FormTemplateCommentType {
  COMMENT,
  SKIP_VALIDATION,
  STATUS_CHANGE,
  FORM_INSTRUCTIONS,
  OTHER,
}

const SCROLL_OPTIONS: ScrollIntoViewOptions = {
  behavior: 'smooth',
  block: 'nearest',
  inline: 'start',
}
const TEXTBOX_ID = 'mentionsTextbox'

// These two constants are for rewriting the Markdown links for @mentions, so they point to the user
// page in admin. We need a special regex here so we can capture the name as group1 and the userId
// as group2. We then re-write the markdown, inserting the proper /users/ link.
const mentionRegex = /@\[([^\]]+)\]\(([A-Za-z0-9]+)\)/g
const mentionReplacement = '@[$1](/users/$2)'

function FormTemplateComment({ comment, formTemplate, isLastComment }: FormTemplateCommentProps) {
  const snackbar = useSitelineSnackbar()
  const date = moment.tz(comment.createdAt, moment.tz.guess()).fromNow()
  const fullDate = moment
    .tz(comment.createdAt, moment.tz.guess())
    .format('MMMM D, YYYY [at] h:mm A')
  const user = getCurrentUser()

  const [deleteComment] = useDeleteFormTemplateCommentMutation({
    update: (cache) => {
      cache.modify({
        id: cache.identify(formTemplate),
        fields: {
          comments(existing) {
            return _.filter(existing, (existingComment) => existingComment.id !== comment.id)
          },
        },
      })
    },
  })

  const handleDelete = useCallback(() => {
    const confirmed = window.confirm('Are you sure you want to delete this comment?')
    if (!confirmed) {
      return
    }
    deleteComment({
      variables: {
        id: comment.id,
      },
    }).catch((err) => snackbar.showError(err.message))
  }, [snackbar, comment.id, deleteComment])

  const commentType = useMemo(() => {
    if (comment.isSystemGenerated) {
      if (comment.message.includes(FORM_TEMPLATE_STATUS_CHANGE_PREFIX)) {
        return FormTemplateCommentType.STATUS_CHANGE
      }
      if (comment.message.includes(FORM_TEMPLATE_SKIPPED_VALIDATION_PREFIX)) {
        return FormTemplateCommentType.SKIP_VALIDATION
      }
      if (comment.message.includes(FORM_TEMPLATE_INSTRUCTIONS_PREFIX)) {
        return FormTemplateCommentType.FORM_INSTRUCTIONS
      }
      return FormTemplateCommentType.OTHER
    }
    return FormTemplateCommentType.COMMENT
  }, [comment.isSystemGenerated, comment.message])

  // This is unfortunately hacky: we want to make status changes clearer in the UI but there isn't anything on the TemplateComment
  // type that gives us status-specific info. We can derive this information from the comment verbage
  const newTemplateStatus = useMemo(() => {
    if (commentType !== FormTemplateCommentType.STATUS_CHANGE) {
      return null
    }
    const statusChangeRegex = new RegExp(`${FORM_TEMPLATE_STATUS_CHANGE_PREFIX} (.+) to (.+)`)
    const regexMatch = comment.message.match(statusChangeRegex)
    return regexMatch ? regexMatch[2] : null
  }, [comment.message, commentType])

  const timelineIcon = useMemo(() => {
    switch (commentType) {
      case FormTemplateCommentType.COMMENT:
        return <CommentIcon className="userCommentIcon" />
      case FormTemplateCommentType.STATUS_CHANGE:
        return <SkipNextIcon className="systemGeneratedIcon" />
      case FormTemplateCommentType.SKIP_VALIDATION:
        return <FastForwardIcon className="systemGeneratedIcon" />
      case FormTemplateCommentType.OTHER:
        return <SettingsIcon className="systemGeneratedIcon" />
      case FormTemplateCommentType.FORM_INSTRUCTIONS:
        return <ConstructionIcon className="systemGeneratedIcon" />
    }
  }, [commentType])

  const commentMessage = useMemo(() => {
    let message = comment.message
    if (commentType === FormTemplateCommentType.SKIP_VALIDATION) {
      message = comment.message.replace(FORM_TEMPLATE_SKIPPED_VALIDATION_PREFIX, '')
    } else if (commentType === FormTemplateCommentType.FORM_INSTRUCTIONS) {
      message = comment.message.replace(FORM_TEMPLATE_INSTRUCTIONS_PREFIX, '')
    }

    // React markdown requires 2 \n characters to create a new line.
    // https://github.com/remarkjs/react-markdown/issues/273
    const messageWithUserMarkdown = message.replaceAll('\n', '\n\n')
    // Turns all user mentions of the form `@[name](userId)` into something that looks like
    // `@[name](/users/userId`) so any @mention's link to the user's page.
    return messageWithUserMarkdown.replace(mentionRegex, mentionReplacement)
  }, [comment.message, commentType])

  return (
    <TimelineItem>
      <TimelineSeparator className="timelineSeparator">
        <TimelineDot
          className={clsx('timelineDot', comment.isSystemGenerated && 'systemGeneratedTimelineDot')}
        >
          {timelineIcon}
        </TimelineDot>
        {!isLastComment && <TimelineConnector className="timelineConnector" />}
      </TimelineSeparator>
      <TimelineContent>
        <Grid2 container justifyContent="space-between" alignItems="flex-start">
          <Grid2>
            <Typography variant="body1" fontWeight={600}>
              {comment.user.firstName} {comment.user.lastName}
            </Typography>
            {commentType === FormTemplateCommentType.COMMENT && (
              <Typography variant="body2" color="secondary">
                commented
              </Typography>
            )}
            {commentType === FormTemplateCommentType.SKIP_VALIDATION && (
              <Typography variant="body2" color="secondary">
                skipped validation
              </Typography>
            )}
            {commentType === FormTemplateCommentType.FORM_INSTRUCTIONS && (
              <Typography variant="body2" color="secondary">
                entered form onboarding notes
              </Typography>
            )}
          </Grid2>
          <Grid2>
            <Tooltip title={fullDate} placement="right" arrow>
              <Typography variant="body2">{date}</Typography>
            </Tooltip>
          </Grid2>
        </Grid2>
        {commentType !== FormTemplateCommentType.STATUS_CHANGE && (
          <Grid2 container className="comment" justifyContent="space-between" alignItems="center">
            <Grid2 size={{ xs: 11 }}>
              <Paper elevation={0} className="writtenCommentCard">
                <ReactMarkdown
                  remarkPlugins={[remarkGfm]}
                  components={{
                    p: ({ children }) => <Typography variant="body1">{children}</Typography>,
                    a: ({ href, children }) => (
                      <Link href={href} target="_blank">
                        {children}
                      </Link>
                    ),
                  }}
                >
                  {commentMessage}
                </ReactMarkdown>
              </Paper>
            </Grid2>
            {user?.uid === comment.user.id && (
              <Grid2 size={{ xs: 1 }} className="delete">
                <IconButton onClick={handleDelete}>
                  <DeleteIcon />
                </IconButton>
              </Grid2>
            )}
          </Grid2>
        )}
        {commentType === FormTemplateCommentType.STATUS_CHANGE && (
          <div className="statusChangeExpression">
            <Typography>
              changed the status to <strong>{newTemplateStatus}</strong>
            </Typography>
          </div>
        )}
      </TimelineContent>
    </TimelineItem>
  )
}

enum CommentListFilter {
  ALL,
  COMMENTS_ONLY,
}

type FormTemplateCommentListProps = {
  formTemplate: FormTemplateProperties
}

/**
 * Comment list + input for templates
 */
export function FormTemplateCommentList({ formTemplate }: FormTemplateCommentListProps) {
  const [pendingComment, setPendingComment] = useState<string>('')
  const classes = useStyles()
  const snackbar = useSitelineSnackbar()
  const [filter, setFilter] = useState<CommentListFilter>(CommentListFilter.ALL)

  const { data: adminsData } = useSitelineTeamMembersQuery()
  const [addComment] = useAddFormTemplateCommentMutation({
    update: (cache, { data }) => {
      if (!data) {
        return
      }
      cache.modify({
        id: cache.identify(formTemplate),
        fields: {
          comments(existing) {
            return [...existing, data.addFormTemplateComment]
          },
        },
      })
    },
  })

  const handleSubmit = useCallback(
    (ev: FormEvent) => {
      ev.preventDefault()
      if (pendingComment === '') {
        return
      }
      addComment({
        variables: {
          input: {
            formTemplateId: formTemplate.id,
            message: pendingComment,
          },
        },
      })
        .then(() => {
          setPendingComment('')
        })
        .catch((err) => {
          snackbar.showError(err.message)
        })
    },
    [pendingComment, snackbar, addComment, formTemplate]
  )

  const handleCancel = useCallback((ev: FormEvent) => {
    ev.preventDefault()
    setPendingComment('')
  }, [])

  const sorted = useMemo(() => {
    const filtered = formTemplate.comments.filter(
      (comment) => filter === CommentListFilter.ALL || !comment.isSystemGenerated
    )
    return _.orderBy(filtered, (comment) => comment.createdAt, 'desc')
  }, [filter, formTemplate.comments])

  const mappedUsers = useMemo(
    () =>
      _.chain(adminsData?.sitelineTeamMembers ?? [])
        .uniqBy((user) => user.id)
        .map((user) => ({ id: user.id, display: `${user.firstName} ${user.lastName}` }))
        .orderBy((user) => user.display, 'asc')
        .value(),
    [adminsData?.sitelineTeamMembers]
  )

  const handleDisplayTransform = useCallback<DisplayTransformFunc>(
    (_id, display) => `@${display}`,
    []
  )

  const handleRenderSuggestion = useCallback(
    (_suggestion: SuggestionDataItem, _search: string, highlightedDisplay: ReactNode) => {
      return <SitelineText variant="body1">{highlightedDisplay}</SitelineText>
    },
    []
  )

  // The react-mentions library handles up/down keyboard navigation through the list of suggested
  // mentions, but it does not handle scrolling. If you navigate to an item below the list height,
  // you won't see what you're focusing on. We handle here by targeting the currently highlighted
  // element and using the scrollIntoView method Note: the keyboard event listener will run before
  // the next element is focused on. Therefore, we must target the sibling of the currently focused
  // list item.
  useEffect(() => {
    const textbox = document.getElementById(TEXTBOX_ID)
    textbox?.addEventListener('keydown', (event: KeyboardEvent) => {
      const highlightedSuggestions = document.querySelectorAll(
        `.${FOCUSED_SUGGESTION_CLASS}`
      ) as QuerySelectorResult
      const suggestions = document.querySelectorAll(
        `.${SUGGESTION_LI_CLASS}`
      ) as QuerySelectorResult
      if (!highlightedSuggestions?.length || !suggestions?.length) {
        return
      }
      const highlightedSuggestion = highlightedSuggestions[0]
      if (event.key === 'ArrowDown') {
        const nextSuggestion = highlightedSuggestion.nextElementSibling
        const firstSuggestion = suggestions[0]
        if (nextSuggestion) {
          nextSuggestion.scrollIntoView(SCROLL_OPTIONS)
        } else {
          firstSuggestion.scrollIntoView(SCROLL_OPTIONS)
        }
      }
      if (event.key === 'ArrowUp') {
        const previousSuggestion = highlightedSuggestion.previousElementSibling
        const lastSuggestion = suggestions[suggestions.length - 1]
        if (previousSuggestion) {
          previousSuggestion.scrollIntoView(SCROLL_OPTIONS)
        } else {
          lastSuggestion.scrollIntoView(SCROLL_OPTIONS)
        }
      }
    })
  }, [])

  const isAllActivitiesFilter = filter === CommentListFilter.ALL
  const isCommentsFilter = filter === CommentListFilter.COMMENTS_ONLY

  return (
    <Card className={classes.textbox}>
      <CardHeader
        title="Activity"
        action={
          <div className={classes.filters}>
            <FilterListIcon className="filterListIcon" />
            <Button
              variant="text"
              onClick={() => setFilter(CommentListFilter.ALL)}
              disabled={isAllActivitiesFilter}
            >
              <SitelineText variant="body1" color={isAllActivitiesFilter ? 'grey90' : 'grey50'}>
                All activity
              </SitelineText>
            </Button>
            <Button
              variant="text"
              onClick={() => setFilter(CommentListFilter.COMMENTS_ONLY)}
              disabled={isCommentsFilter}
            >
              <SitelineText variant="body1" color={isCommentsFilter ? 'grey90' : 'grey50'}>
                Comments only
              </SitelineText>
            </Button>
          </div>
        }
      />
      <CardContent>
        <form onSubmit={handleSubmit}>
          <MentionsInput
            placeholder="Leave a comment"
            onChange={(ev) => setPendingComment(ev.target.value)}
            value={pendingComment}
            spellCheck={false}
            allowSpaceInQuery
            id={TEXTBOX_ID}
            maxLength={1000}
            className="textbox"
          >
            <Mention
              trigger="@"
              data={mappedUsers}
              displayTransform={handleDisplayTransform}
              renderSuggestion={handleRenderSuggestion}
              className="mentioned"
              appendSpaceOnAdd
            />
          </MentionsInput>
          <Grid2 container padding={1} justifyContent="space-between" alignItems="center">
            <Grid2>
              <Typography color="secondary">**bold**, *italic*, ~strikethrough~</Typography>
              <Tooltip title="Team members tagged in your comment will be notified via email">
                <Typography color="secondary">Tag with @ to notify others</Typography>
              </Tooltip>
            </Grid2>
            <Grid2>
              <Fade in={!!pendingComment}>
                <Button color="secondary" onClick={handleCancel}>
                  Cancel
                </Button>
              </Fade>
              <Button type="submit" disabled={!pendingComment}>
                Comment
              </Button>
            </Grid2>
          </Grid2>
        </form>

        <Timeline
          className={classes.timeline}
          sx={{
            [`& .${timelineItemClasses.root}:before`]: {
              flex: 0,
              padding: 0,
            },
          }}
        >
          {sorted.map((comment, commentIndex) => (
            <FormTemplateComment
              comment={comment}
              isLastComment={commentIndex === sorted.length - 1}
              formTemplate={formTemplate}
              key={comment.id}
            />
          ))}
        </Timeline>
      </CardContent>
    </Card>
  )
}
