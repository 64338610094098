import { gql } from '@apollo/client'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Theme,
} from '@mui/material'
import { useCallback, useMemo, useState } from 'react'
import {
  CompanyUserRole,
  Permission,
  makeStylesFast,
  useSitelineSnackbar,
} from 'siteline-common-web'
import * as fragments from '../../common/graphql/Fragments'
import { DetailedCompany } from '../../common/graphql/Fragments'
import {
  GetCompanyForDetailsDocument,
  GetCompanyForDetailsQuery,
  GetCompanyForDetailsQueryVariables,
  useInviteCompanyUserMutation,
  useProjectsByCompanyQuery,
} from '../../common/graphql/apollo-operations'

gql`
  mutation inviteCompanyUser($input: InviteCompanyUserInput!) {
    inviteCompanyUser(input: $input) {
      ...CompanyUserProperties
    }
  }
  ${fragments.companyUser}
`

const useStyles = makeStylesFast((theme: Theme) => ({
  content: {
    minWidth: '500px',
  },
  input: {
    marginTop: theme.spacing(2),
  },
  warning: {
    marginTop: theme.spacing(2),
  },
}))

type AddSitelineAdminProps = {
  company: DetailedCompany
  open: boolean
  onClose: () => void
}

/** Dialog to add a Siteline admin to a company */
export function AddSitelineAdmin({ company, open, onClose }: AddSitelineAdminProps) {
  const classes = useStyles()
  const [inviteUser] = useInviteCompanyUserMutation()
  const snackbar = useSitelineSnackbar()
  const [email, setEmail] = useState<string>('')

  const { data } = useProjectsByCompanyQuery({
    variables: { id: company.id },
  })

  const isValidEmail = useMemo(() => email.endsWith('@siteline.com'), [email])

  const handleSubmit = useCallback(() => {
    if (!data || !isValidEmail) {
      return
    }

    const allContractIds = data.projectsByCompany
      .flatMap((project) => project.contracts)
      .map((contract) => contract.id)

    inviteUser({
      variables: {
        input: {
          companyId: company.id,
          companyUser: {
            role: CompanyUserRole.ADMINISTRATOR,
            user: {
              firstName: 'Siteline',
              lastName: 'Support',
              email,
            },
            permissions: Object.values(Permission),
            contractIds: allContractIds,
            isSitelineAdmin: true,
          },
        },
      },
      update(cache, { data }) {
        const result = cache.readQuery<
          GetCompanyForDetailsQuery,
          GetCompanyForDetailsQueryVariables
        >({
          query: GetCompanyForDetailsDocument,
          variables: { companyId: company.id },
        })
        if (!result || !data) {
          return
        }

        // Add the new company user to the cached company
        const updatedCompany = {
          ...result.company,
          users: [...result.company.users, data.inviteCompanyUser],
        }

        cache.writeQuery({
          query: GetCompanyForDetailsDocument,
          data: { company: updatedCompany },
        })
      },
    })
      .then(() => {
        snackbar.showSuccess()
        onClose()
      })
      .catch((err) => {
        snackbar.showError(err.message)
      })
  }, [data, inviteUser, company.id, email, snackbar, onClose, isValidEmail])

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Add Siteline Admin</DialogTitle>
      <DialogContent className={classes.content}>
        <TextField
          type="email"
          className={classes.input}
          label="Email address"
          variant="outlined"
          fullWidth
          value={email}
          onChange={(ev) => setEmail(ev.target.value)}
          error={email.length > 0 && !isValidEmail}
          helperText="Email address must end with @siteline.com"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSubmit}>Add Siteline Admin</Button>
      </DialogActions>
    </Dialog>
  )
}
