import { Alert, Card, CardContent, CardHeader, Divider, Table, TableBody } from '@mui/material'
import _ from 'lodash'
import { makeStylesFast } from 'siteline-common-web'
import { EditableCardRowText } from '../../../../common/components/EditableCardRow'
import {
  IntegrationType,
  useUpdateIntegrationMappingsMutation,
} from '../../../../common/graphql/apollo-operations'
import {
  Sage100ContractorIntegration,
  Sage300CreIntegration,
} from '../../../../common/util/Integration'

const useStyles = makeStylesFast(() => ({
  card: {
    paddingBottom: 0,
  },
  cardContent: {
    padding: 0,
    '&:last-child': {
      padding: 0,
    },
    '& .MuiFormControl-root': {
      width: '100%',
    },
  },
}))

type Hh2IntegrationDetailsProps = {
  integration: Sage100ContractorIntegration | Sage300CreIntegration
}

/**
 * Displays the mappings for an HH2 integration (Sage 100, Sage 300).
 * Allows you to modify them as well.
 */
export function Hh2IntegrationDetails({ integration }: Hh2IntegrationDetailsProps) {
  const classes = useStyles()
  const [updateMappings] = useUpdateIntegrationMappingsMutation()

  const integrationName =
    integration.type === IntegrationType.SAGE_100_CONTRACTOR
      ? 'Sage 100 Contractor'
      : 'Sage 300 CRE'

  return (
    <>
      <Card className={classes.card}>
        <CardHeader title={`${integrationName} Metadata`} />
        <Divider />
        {!integration.mappings.project.hh2ProjectId && (
          <CardContent>
            <Alert severity="error">
              Missing project ID. This is the HH2 API&apos;s &lsquo;Id&rsquo; from the Job Cost
              endpoints.
            </Alert>
          </CardContent>
        )}
        {!integration.mappings.contract.hh2ContractId && (
          <CardContent>
            <Alert severity="error">
              Missing contract ID. This is the HH2 API&apos;s &lsquo;Id&rsquo; from the Contracts
              endpoints.
            </Alert>
          </CardContent>
        )}
        <CardContent className={classes.cardContent}>
          <Table>
            <TableBody>
              <EditableCardRowText
                readOnly={false}
                value={integration.mappings.project.hh2ProjectId ?? ''}
                label={`${integrationName} HH2 Project ID`}
                variables={(value) => {
                  const newMappings = _.cloneDeep(integration.mappings)
                  newMappings.project.hh2ProjectId = value
                  return {
                    input: {
                      integrationId: integration.id,
                      mappings: newMappings as Record<string, unknown>,
                    },
                  }
                }}
                mutate={updateMappings}
              />
              <EditableCardRowText
                readOnly={false}
                value={integration.mappings.contract.hh2ContractId ?? ''}
                label={`${integrationName} HH2 Contract ID`}
                variables={(value) => {
                  const newMappings = _.cloneDeep(integration.mappings)
                  newMappings.contract.hh2ContractId = value
                  return {
                    input: {
                      integrationId: integration.id,
                      mappings: newMappings as Record<string, unknown>,
                    },
                  }
                }}
                mutate={updateMappings}
              />
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    </>
  )
}
