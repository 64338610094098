import {
  addIntegration,
  browserTracingIntegration,
  reactRouterV6BrowserTracingIntegration,
  init as sentryInit,
} from '@sentry/react'
import { useEffect } from 'react'
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom'

type InitSentryParams = {
  dsn: string
  tunnel: string
  environment: string
  release: string | undefined
}

export function initSentry({ dsn, tunnel, environment, release }: InitSentryParams) {
  sentryInit({
    dsn,
    tunnel,
    environment,
    release,

    // Sentry will automatically attach a "Baggage" header to fetch requests to all these URLs,
    // so that it can link issues/replays from web to backend services.
    // We don't use wildcard domains here because Logrocket – which is proxied via a siteline
    // subdomain - won't accept the Baggage header. We have to explicitly enumerate the subdomains
    // that we want to send traces to.
    tracePropagationTargets: [
      // Local
      'localhost',

      // Staging
      'staging.attachments.siteline.com',
      'staging.api.siteline.com',
      'staging.api-admin.siteline.com',
      'staging.pdf.siteline.com',
      'staging.reporting.siteline.com',
      'staging.subscriptions.siteline.com',
      'staging.subscriptions-admin.siteline.com',

      // Prod
      'attachments.siteline.com',
      'api.siteline.com',
      'api-admin.siteline.com',
      'pdf.siteline.com',
      'reporting.siteline.com',
      'subscriptions.siteline.com',
      'subscriptions-admin.siteline.com',
    ],

    integrations: [
      browserTracingIntegration(),
      reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
    ],
    tracesSampleRate: 0.5,

    // Record all sessions
    replaysSessionSampleRate: 1,
    replaysOnErrorSampleRate: 1,

    // Don't send events when running locally
    enabled: environment !== 'local',

    // Double the default depth used for formatting breadcrumbs
    normalizeDepth: 6,

    // Set a high max length value so that we can read full Joi errors
    maxValueLength: 500,
  })

  void addReplayIntegration()
}

async function addReplayIntegration(): Promise<void> {
  const { replayIntegration } = await import('@sentry/react')
  addIntegration(
    replayIntegration({
      // Record all network details
      networkDetailAllowUrls: [/^http:\/\//, /^https:\/\//],
      maskAllText: false,
      maskAllInputs: false,
      blockAllMedia: false,
    })
  )
}
