import ClearIcon from '@mui/icons-material/Clear'
import { IconButton, Theme } from '@mui/material'
import _ from 'lodash'
import { PropsWithChildren, useMemo } from 'react'
import 'react-resizable/css/styles.css'
import { makeStylesFast } from 'siteline-common-web'
import {
  AnnotationOverrideProperties,
  FormTemplateAnnotationProperties,
  UpdateFormAnnotationInput,
} from '../../../common/graphql/apollo-operations'

const useStyles = makeStylesFast((theme: Theme) => ({
  override: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row',
  },
  remove: {
    marginLeft: theme.spacing(-0.5),
  },
  value: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
}))

type AnnotationOverrideProps = PropsWithChildren<{
  annotation: FormTemplateAnnotationProperties
  override: AnnotationOverrideProperties
  field: keyof Omit<AnnotationOverrideProperties, 'id' | 'annotationPermanentId'>
  onUpdate: (inputs: UpdateFormAnnotationInput[]) => void
}>

/**
 * A single override in the annotation box. Can be deleted or adjusted.
 */
export function AnnotationOverride({
  annotation,
  override,
  field,
  children,
  onUpdate,
}: AnnotationOverrideProps) {
  const classes = useStyles()
  const hasOverride = useMemo(() => !_.isNil(override[field]), [field, override])

  const handleRemove = () => {
    const input: UpdateFormAnnotationInput = {
      id: annotation.id,
      [field]: null,
    }
    onUpdate([input])
  }

  if (!hasOverride) {
    return null
  }

  return (
    <div className={classes.override}>
      <div className={classes.remove}>
        <IconButton onClick={handleRemove}>
          <ClearIcon fontSize="small" />
        </IconButton>
      </div>
      <div className={classes.value}>{children}</div>
    </div>
  )
}
