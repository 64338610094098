import { SovLineItemProgressProperties } from '../graphql/apollo-operations'

export function sortSovLineItemProgress(
  a: SovLineItemProgressProperties,
  b: SovLineItemProgressProperties
) {
  return a.sovLineItem.sortOrder > b.sovLineItem.sortOrder ? 1 : -1
}

export function sortSovLineItemsByOrder(a: { sortOrder: number }, b: { sortOrder: number }) {
  return a.sortOrder > b.sortOrder ? 1 : -1
}
