import { ApolloCache, Reference, StoreObject } from '@apollo/client'
import { ToReferenceFunction } from '@apollo/client/cache/core/types/common'
import _ from 'lodash'

type EvictFunction = typeof ApolloCache.prototype.evict

/**
 * Runs evictions from the provided Apollo cache, with garbage collection at the end.
 * Use this function in place of using `cache.evict` directly, to not forget `cache.gc()`.
 */
export function evictWithGc<T>(cache: ApolloCache<T>, task: (evict: EvictFunction) => void) {
  // eslint-disable-next-line no-restricted-properties
  const evict = cache.evict.bind(cache)
  task(evict)
  // eslint-disable-next-line no-restricted-properties
  cache.gc()
}

/**
 * Transforms an array of store objects or references into an array of references.
 * This is helpful for type safety in `cache.modify`.
 */
export function toReferences(
  refs: readonly (StoreObject | string | Reference)[],
  toReference: ToReferenceFunction
): Reference[] {
  return _.compact(refs.map((ref) => toReference(ref)))
}
