import { gql } from '@apollo/client'
import {
  Alert,
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Theme,
} from '@mui/material'
import { useCallback, useMemo, useState } from 'react'
import { makeStylesFast, useSitelineSnackbar } from 'siteline-common-web'
import * as fragments from '../../../common/graphql/Fragments'
import { useAddIntegrationMutation } from '../../../common/graphql/apollo-operations'
import { ContractForDetails } from '../ContractDetails'

gql`
  mutation addIntegration($input: AddIntegrationInput!) {
    addIntegration(input: $input) {
      ...IntegrationProperties
    }
  }
  ${fragments.integration}
`

const useStyles = makeStylesFast((theme: Theme) => ({
  content: {
    minWidth: '500px',
  },
  input: {
    marginTop: theme.spacing(2),
  },
  warning: {
    marginTop: theme.spacing(2),
  },
}))

type AddContractIntegrationModalProps = {
  contract: ContractForDetails
  open: boolean
  onClose: () => void
}

/** Modal to add a contract integration */
export function AddContractIntegrationModal({
  contract,
  open,
  onClose,
}: AddContractIntegrationModalProps) {
  const [companyIntegrationId, setCompanyIntegrationId] = useState<string | null>(null)
  const [addIntegration] = useAddIntegrationMutation()
  const classes = useStyles()
  const snackbar = useSitelineSnackbar()

  const sortedIntegrations = useMemo(() => {
    const collator = new Intl.Collator()
    return [...contract.company.companyIntegrations].sort((a, b) =>
      collator.compare(a.longName, b.longName)
    )
  }, [contract.company.companyIntegrations])

  const selectedIntegration = useMemo(() => {
    return contract.company.companyIntegrations.find(
      (integration) => integration.id === companyIntegrationId
    )
  }, [contract.company.companyIntegrations, companyIntegrationId])

  const handleSubmit = useCallback(() => {
    if (!selectedIntegration) {
      return
    }

    addIntegration({
      variables: {
        input: {
          contractId: contract.id,
          companyIntegrationId: selectedIntegration.id,
        },
      },
      update: (cache, { data }) => {
        if (!data) {
          return
        }
        cache.modify({
          id: cache.identify(contract),
          fields: {
            integrations(existing) {
              return [...existing, data.addIntegration]
            },
          },
        })
      },
    })
      .then(() => {
        snackbar.showSuccess('Integration added')
        onClose()
      })
      .catch((err) => {
        snackbar.showError(err.message)
      })
  }, [addIntegration, contract, onClose, selectedIntegration, snackbar])

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Add contract integration</DialogTitle>
      <DialogContent className={classes.content}>
        <Autocomplete
          className={classes.input}
          getOptionLabel={(option) => option.shortName}
          options={sortedIntegrations}
          onChange={(ev, value) => setCompanyIntegrationId(value?.id ?? null)}
          value={selectedIntegration}
          multiple={false}
          renderInput={(params) => <TextField label="Integration" {...params} />}
          getOptionDisabled={(option) => {
            const alreadyExists = contract.integrations.some(
              (integration) => integration.companyIntegration.id === option.id
            )
            const isArchived = option.archivedAt !== null
            return alreadyExists || isArchived
          }}
        />
        <Alert severity="warning" className={classes.warning}>
          Please run this by an engineer first as they will need to validate that the project is
          setup properly. Specifically, they need to ensure that SOVs exactly match for GC
          integrations and all mappings are setup properly.
        </Alert>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSubmit}>Add contract integration</Button>
      </DialogActions>
    </Dialog>
  )
}
