import { CircularProgress, Theme } from '@mui/material'
import clsx from 'clsx'
import { colors } from '../themes/Styles'
import { makeStylesFast } from '../themes/fast-styles'

const useStyles = makeStylesFast((theme: Theme) => ({
  root: {
    height: 14,
    width: 14,
    marginRight: theme.spacing(0.5),
    color: colors.grey50,
  },
}))

/** A spinner styled to show up to the left of a button label */
export function ButtonLabelSpinner({ className }: { className?: string }) {
  const classes = useStyles()

  return <CircularProgress size="small" className={clsx(classes.root, className)} />
}
