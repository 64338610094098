import { gql } from '@apollo/client'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import {
  Alert,
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Theme,
  Tooltip,
} from '@mui/material'
import _ from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'
import {
  CompanyIntegrationMetadataSageIntacct,
  DEFAULT_IMPORT_INTEGRATION_PROJECT_NAME_SOURCE,
  ImportIntegrationChangeOrdersMethod,
  ImportIntegrationComboJobMethod,
  ImportIntegrationProjectNameSource,
  SAGE_INTACCT_INVOICE_TYPE_REGULAR,
  supportedComboJobImportMethodsForIntegration,
  supportsImportingSeparateChangeOrderLineItems,
  supportsReadingLineItems,
} from 'siteline-common-all'
import {
  IntegrationType,
  makeStylesFast,
  SitelineText,
  useSitelineSnackbar,
} from 'siteline-common-web'
import * as fragments from '../../common/graphql/Fragments'
import { DetailedCompany } from '../../common/graphql/Fragments'
import {
  CompanyIntegrationProperties,
  useAddCompanyIntegrationMutation,
  useGetIntegrationInvoiceTypesQuery,
  useUpdateCompanyIntegrationMutation,
} from '../../common/graphql/apollo-operations'
import { formatLocationOneLine } from '../../common/util/Location'

gql`
  mutation addCompanyIntegration($input: AddCompanyIntegrationInput!) {
    addCompanyIntegration(input: $input) {
      ...CompanyIntegrationProperties
    }
  }
  ${fragments.companyIntegration}
`

gql`
  mutation updateCompanyIntegration($input: UpdateCompanyIntegrationInput!) {
    updateCompanyIntegration(input: $input) {
      ...CompanyIntegrationProperties
    }
  }
  ${fragments.companyIntegration}
`

gql`
  query getIntegrationInvoiceTypes($input: IntegrationInvoiceTypesInput!) {
    integrationInvoiceTypes(input: $input) {
      invoiceType
      hasAutoNumberingEnabled
    }
  }
`

const useStyles = makeStylesFast((theme: Theme) => ({
  content: {
    minWidth: '500px',
  },
  input: {
    marginTop: theme.spacing(2),
  },
  warning: {
    marginTop: theme.spacing(2),
  },
}))

type AddOrUpdateCompanyIntegrationModalProps = {
  company: DetailedCompany
  companyIntegration: CompanyIntegrationProperties | null
  open: boolean
  onClose: () => void
}

/** Modal to update a credentials username and password */
export function AddOrUpdateCompanyIntegrationModal({
  company,
  companyIntegration,
  open,
  onClose,
}: AddOrUpdateCompanyIntegrationModalProps) {
  const { data: invoiceTypeData, loading: invoiceTypeLoading } = useGetIntegrationInvoiceTypesQuery(
    {
      variables: { input: { companyIntegrationId: companyIntegration?.id ?? '' } },
      // We only use invoice types for Sage Intacct
      // making this query for any other integration will fail
      skip: !companyIntegration || companyIntegration.type !== IntegrationType.SAGE_INTACCT,
    }
  )

  const initialType = useMemo(() => companyIntegration?.type ?? null, [companyIntegration?.type])
  const initialLabel = useMemo(() => companyIntegration?.label ?? '', [companyIntegration?.label])
  const initialLocationId = useMemo(
    () => companyIntegration?.location?.id ?? null,
    [companyIntegration?.location?.id]
  )
  const initialShouldImportNewProjectSov = useMemo(
    () => companyIntegration?.shouldImportNewProjectSov ?? true,
    [companyIntegration?.shouldImportNewProjectSov]
  )
  const initialImportChangeOrdersMethod =
    companyIntegration?.importChangeOrdersMethod ??
    ImportIntegrationChangeOrdersMethod.MERGE_ORIGINAL_LINE_ITEMS
  const initialImportComboJobMethod =
    companyIntegration?.importComboJobMethod ??
    ImportIntegrationComboJobMethod.SINGLE_PROJECT_FLAT_SOV
  const initialImportProjectNameSource =
    companyIntegration?.importProjectNameSource ?? DEFAULT_IMPORT_INTEGRATION_PROJECT_NAME_SOURCE
  const isUpdatingIntegration = companyIntegration !== null

  const initialSageIntacctMetadata = useMemo(
    () =>
      companyIntegration && companyIntegration.type === IntegrationType.SAGE_INTACCT
        ? (companyIntegration.metadata as CompanyIntegrationMetadataSageIntacct)
        : undefined,
    [companyIntegration]
  )

  const shouldShowInvoiceTypeDropdown = useMemo(() => {
    return (
      companyIntegration &&
      companyIntegration.type === IntegrationType.SAGE_INTACCT &&
      companyIntegration.credentialsUpdatedAt
    )
  }, [companyIntegration])

  const [type, setType] = useState<IntegrationType | null>(initialType)
  const [label, setLabel] = useState<string>(initialLabel)
  const [locationId, setLocationId] = useState<string | null>(initialLocationId)
  const [sageIntacctInvoiceType, setSageIntacctInvoiceType] = useState<string | undefined>(
    initialSageIntacctMetadata?.invoiceType
  )
  // I don't really expect to hit the condition of not having an autonumbering enabled
  // boolean available, since it's returned from Sage Intacct alongside the invoice type value
  // If that happens somehow, default to true so that if our guess is wrong, we can throw an
  // error and have the customer try to re-sync
  const [sageIntacctHasAutoNumberingEnabled, setSageIntacctHasAutonumberingEnabled] =
    useState<boolean>(initialSageIntacctMetadata?.hasAutoNumberingEnabled ?? true)
  const [shouldImportNewProjectSov, setShouldImportNewProjectSov] = useState<boolean>(
    initialShouldImportNewProjectSov
  )
  const [importChangeOrdersMethod, setImportChangeOrdersMethod] =
    useState<ImportIntegrationChangeOrdersMethod>(initialImportChangeOrdersMethod)
  const [importComboJobMethod, setImportComboJobMethod] = useState<ImportIntegrationComboJobMethod>(
    initialImportComboJobMethod
  )
  const [importProjectNameSource, setImportProjectNameSource] =
    useState<ImportIntegrationProjectNameSource>(initialImportProjectNameSource)
  const [addIntegration] = useAddCompanyIntegrationMutation()
  const [updateIntegration] = useUpdateCompanyIntegrationMutation()
  const classes = useStyles()
  const snackbar = useSitelineSnackbar()

  const availableTypes = useMemo(() => {
    const collator = new Intl.Collator()
    const types = Object.values(IntegrationType) as IntegrationType[]
    return types.sort(collator.compare)
  }, [])
  const supportedComboJobImportMethods = useMemo(
    () =>
      companyIntegration
        ? supportedComboJobImportMethodsForIntegration(companyIntegration.type)
        : [],
    [companyIntegration]
  )

  const conflictingIntegrations = useMemo(() => {
    if (!type) {
      return []
    }
    return company.companyIntegrations
      .filter((integration) => integration.type === type)
      .filter((integration) => companyIntegration?.id !== integration.id)
  }, [company.companyIntegrations, companyIntegration?.id, type])

  const selectedLocation = useMemo(() => {
    if (!locationId) {
      return null
    }
    return company.locations.find((location) => location.id === locationId) ?? null
  }, [company.locations, locationId])

  // Reset form values when integration changes
  useEffect(() => {
    setType(initialType)
    setLabel(initialLabel)
    setLocationId(initialLocationId)
    setShouldImportNewProjectSov(initialShouldImportNewProjectSov)
    setImportChangeOrdersMethod(initialImportChangeOrdersMethod)
  }, [
    initialLabel,
    initialType,
    initialLocationId,
    initialShouldImportNewProjectSov,
    initialImportChangeOrdersMethod,
  ])

  const invoiceTypesResponse = useMemo(() => {
    return invoiceTypeData?.integrationInvoiceTypes ?? []
  }, [invoiceTypeData?.integrationInvoiceTypes])

  // Reset automatic numbering when invoice type changes
  useEffect(() => {
    const autoNumbering = invoiceTypesResponse.find(
      (invoiceType) => invoiceType.invoiceType === sageIntacctInvoiceType
    )?.hasAutoNumberingEnabled
    if (autoNumbering) {
      setSageIntacctHasAutonumberingEnabled(autoNumbering)
    }
  }, [sageIntacctInvoiceType, invoiceTypesResponse])

  const invoiceTypes = useMemo(() => {
    return (
      invoiceTypesResponse
        .map((invoiceType) => invoiceType.invoiceType)
        // We don't want to write to the regular invoice type because Sage Intacct's normal
        // contract billing flow does not write to there. We write to the same place as the
        // normal contract billing flow. Once the invoices are posted from within Sage Intacct,
        // they will have type Regular
        .filter((invoiceType) => invoiceType !== SAGE_INTACCT_INVOICE_TYPE_REGULAR)
    )
  }, [invoiceTypesResponse])

  const handleSubmit = useCallback(() => {
    if (!type) {
      return
    }
    const trimmedLabel = label.trim()
    if (trimmedLabel.length === 0 && conflictingIntegrations.length > 0) {
      snackbar.showError(
        'Labels is required because there already is an integration of the same type'
      )
      return
    }

    if (companyIntegration) {
      updateIntegration({
        variables: {
          input: {
            id: companyIntegration.id,
            label: trimmedLabel.length > 0 ? trimmedLabel : null,
            locationId,
            shouldImportNewProjectSov,
            ...(type === IntegrationType.SAGE_INTACCT &&
              _.isString(sageIntacctInvoiceType) && {
                metadata: {
                  ...initialSageIntacctMetadata,
                  invoiceType: sageIntacctInvoiceType,
                  hasAutoNumberingEnabled: sageIntacctHasAutoNumberingEnabled,
                },
              }),
            importChangeOrdersMethod,
            importComboJobMethod,
            importProjectNameSource,
          },
        },
      })
        .then(() => {
          snackbar.showSuccess('Integration updated')
          onClose()
        })
        .catch((err) => {
          snackbar.showError(err.message)
        })
    } else {
      addIntegration({
        variables: {
          input: {
            companyId: company.id,
            type,
            label: trimmedLabel.length > 0 ? trimmedLabel : null,
            locationId,
            shouldImportNewProjectSov,
            ...(type === IntegrationType.SAGE_INTACCT &&
              _.isString(sageIntacctInvoiceType) && {
                metadata: {
                  ...initialSageIntacctMetadata,
                  invoiceType: sageIntacctInvoiceType,
                  hasAutoNumberingEnabled: sageIntacctHasAutoNumberingEnabled,
                },
              }),
            importChangeOrdersMethod,
            importComboJobMethod,
            importProjectNameSource,
          },
        },
        update: (cache, { data }) => {
          if (!data) {
            return
          }
          cache.modify({
            id: cache.identify(company),
            fields: {
              companyIntegrations(existing) {
                return [...existing, data.addCompanyIntegration]
              },
            },
          })
        },
      })
        .then(() => {
          snackbar.showSuccess('Integration added')
          onClose()
        })
        .catch((err) => {
          snackbar.showError(err.message)
        })
    }
  }, [
    type,
    label,
    conflictingIntegrations.length,
    companyIntegration,
    snackbar,
    updateIntegration,
    locationId,
    shouldImportNewProjectSov,
    sageIntacctInvoiceType,
    initialSageIntacctMetadata,
    sageIntacctHasAutoNumberingEnabled,
    importChangeOrdersMethod,
    importComboJobMethod,
    importProjectNameSource,
    onClose,
    addIntegration,
    company,
  ])

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{isUpdatingIntegration ? 'Update integration' : 'Add integration'}</DialogTitle>
      <DialogContent className={classes.content}>
        <FormControl fullWidth className={classes.input}>
          <InputLabel id="integrationType">Integration type</InputLabel>
          <Select
            labelId="integrationType"
            value={type}
            label="Integration type"
            onChange={(ev) => setType(ev.target.value as IntegrationType)}
            disabled={isUpdatingIntegration}
          >
            {availableTypes.map((type) => (
              <MenuItem key={type} value={type}>
                {type}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {(conflictingIntegrations.length > 0 || companyIntegration) && (
          <TextField
            className={classes.input}
            label="Label"
            variant="outlined"
            fullWidth
            value={label}
            onChange={(ev) => setLabel(ev.target.value)}
          />
        )}
        <Autocomplete
          className={classes.input}
          getOptionLabel={(option) => option.nickname ?? formatLocationOneLine(option)}
          options={company.locations}
          includeInputInList
          onChange={(ev, value) => setLocationId(value?.id ?? null)}
          value={selectedLocation}
          multiple={false}
          renderInput={(params) => <TextField label="Office (currently unused)" {...params} />}
        />
        {shouldShowInvoiceTypeDropdown && !invoiceTypeLoading && (
          <Autocomplete
            className={classes.input}
            disableClearable={true}
            options={invoiceTypes}
            includeInputInList
            onChange={(ev, value) => setSageIntacctInvoiceType(value)}
            value={sageIntacctInvoiceType}
            multiple={false}
            renderInput={(params) => (
              <TextField
                label={
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    Sage Intacct Invoice type *
                    <Tooltip
                      title="Choose an invoice type to write invoices to in this customer's Sage Intacct instance"
                      placement="top"
                    >
                      <InfoOutlinedIcon style={{ marginLeft: 8 }} fontSize="small" />
                    </Tooltip>
                  </div>
                }
                {...params}
                InputProps={{ ...params.InputProps }}
              />
            )}
          />
        )}
        {type && supportsImportingSeparateChangeOrderLineItems(type) && (
          <FormControl fullWidth className={classes.input}>
            <InputLabel id="importChangeOrdersMethod">Import SOV change orders method</InputLabel>
            <Select
              labelId="importChangeOrdersMethod"
              value={importChangeOrdersMethod}
              label="Import SOV change orders method"
              onChange={(ev) =>
                setImportChangeOrdersMethod(ev.target.value as ImportIntegrationChangeOrdersMethod)
              }
            >
              <MenuItem value={ImportIntegrationChangeOrdersMethod.MERGE_ORIGINAL_LINE_ITEMS}>
                Mark SOV line items as COs (default)
              </MenuItem>
              <MenuItem value={ImportIntegrationChangeOrdersMethod.CREATE_NEW_LINE_ITEMS}>
                Import COs as new separate line items
              </MenuItem>
            </Select>
          </FormControl>
        )}
        {type && supportedComboJobImportMethods.length > 0 && (
          <FormControl fullWidth className={classes.input}>
            <InputLabel id="importComboJobsMethod">Import combo jobs method</InputLabel>
            <Select
              labelId="importComboJobsMethod"
              value={importComboJobMethod}
              label="Import SOV change orders method"
              onChange={(ev) =>
                setImportComboJobMethod(ev.target.value as ImportIntegrationComboJobMethod)
              }
            >
              {supportedComboJobImportMethods.includes(
                ImportIntegrationComboJobMethod.SINGLE_PROJECT_FLAT_SOV
              ) && (
                <MenuItem value={ImportIntegrationComboJobMethod.SINGLE_PROJECT_FLAT_SOV}>
                  Single project with non-grouped SOV (default)
                </MenuItem>
              )}
              {supportedComboJobImportMethods.includes(
                ImportIntegrationComboJobMethod.SINGLE_PROJECT_GROUPED_SOV
              ) && (
                <MenuItem value={ImportIntegrationComboJobMethod.SINGLE_PROJECT_GROUPED_SOV}>
                  Single project with SOV grouped by job
                </MenuItem>
              )}
              {supportedComboJobImportMethods.includes(
                ImportIntegrationComboJobMethod.MULTIPLE_PROJECTS
              ) && (
                <MenuItem value={ImportIntegrationComboJobMethod.MULTIPLE_PROJECTS}>
                  Multiple projects, each with an SOV containing line items linked to that job
                </MenuItem>
              )}
              {supportedComboJobImportMethods.includes(
                ImportIntegrationComboJobMethod.MERGED_PARENT_CHILD_GROUPED_SOV
              ) && (
                <MenuItem value={ImportIntegrationComboJobMethod.MERGED_PARENT_CHILD_GROUPED_SOV}>
                  Combine parent/child jobs into single project with grouped SOV
                </MenuItem>
              )}
            </Select>
          </FormControl>
        )}
        {type && (
          <FormControl fullWidth className={classes.input}>
            <InputLabel id="importProjectNameSource">Project name source</InputLabel>
            <Select
              labelId="importProjectNameSource"
              value={importProjectNameSource}
              label="Project name source"
              onChange={(ev) =>
                setImportProjectNameSource(ev.target.value as ImportIntegrationProjectNameSource)
              }
            >
              <MenuItem value={ImportIntegrationProjectNameSource.JOB_NAME}>
                Job name (default)
              </MenuItem>
              <MenuItem value={ImportIntegrationProjectNameSource.CONTRACT_NAME}>
                Contract name
              </MenuItem>
            </Select>
          </FormControl>
        )}
        {type && supportsReadingLineItems(type) && (
          <FormControlLabel
            control={
              <Switch
                checked={shouldImportNewProjectSov}
                onChange={(evt) => setShouldImportNewProjectSov(evt.target.checked)}
              />
            }
            label={
              <SitelineText
                variant="body1"
                endIcon={
                  <Tooltip title="Disable this setting only if the SOV integration is not working for this customer, e.g. for Sage 100 customers who don't bill by cost code">
                    <InfoOutlinedIcon fontSize="small" />
                  </Tooltip>
                }
              >
                Import SOV when creating new projects
              </SitelineText>
            }
            sx={{ marginTop: 1.5 }}
          />
        )}
        {!isUpdatingIntegration && conflictingIntegrations.length > 0 && (
          <Alert severity="warning" className={classes.warning}>
            {type} is already added to this company. If you do want multiple integrations of the
            same type, make sure you enter a different label for each.
          </Alert>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSubmit}>
          {isUpdatingIntegration ? 'Update integration' : 'Add integration'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
