import { gql } from '@apollo/client'
import { FormControl, InputLabel, MenuItem, Select, Theme } from '@mui/material'
import { useMemo } from 'react'
import { makeNumericCollator } from 'siteline-common-all'
import { makeStylesFast } from 'siteline-common-web'
import { useDemoCompaniesQuery } from '../../common/graphql/apollo-operations'

gql`
  query demoCompanies {
    demoCompanies {
      id
      name
      nickname
    }
  }
`

const useStyles = makeStylesFast((theme: Theme) => ({
  root: {
    marginTop: theme.spacing(3),
  },
}))

type DemoCompanySelectorProps = {
  companyId: string | null
  onCompanyIdChange: (companyId: string | null) => void
}

const collator = makeNumericCollator()

/**
 * Selects a demo company, for use in demo tools.
 */
export default function DemoCompanySelector({
  companyId,
  onCompanyIdChange,
}: DemoCompanySelectorProps) {
  const classes = useStyles()
  const { data } = useDemoCompaniesQuery()
  const demoCompanies = useMemo(() => {
    const companies = [...(data?.demoCompanies ?? [])]
    return companies.sort((a, b) => collator.compare(a.name, b.name))
  }, [data?.demoCompanies])
  return (
    <FormControl fullWidth className={classes.root} size="small">
      <InputLabel id="demoCompanyId">Demo company</InputLabel>
      <Select
        labelId="demoCompanyId"
        value={companyId}
        label="Demo company"
        onChange={(ev) => onCompanyIdChange(ev.target.value)}
      >
        {demoCompanies.map((company) => (
          <MenuItem key={company.id} value={company.id}>
            {company.nickname ?? company.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
