import {
  Alert,
  Autocomplete,
  Button,
  Card,
  CardContent,
  CardHeader,
  Link,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import _ from 'lodash'
import { ReactElement, useState } from 'react'
import { StoredFileType, getTemplateStatusPrettyName } from 'siteline-common-all'
import { useSitelineSnackbar } from 'siteline-common-web'
import { getCurrentUser } from '../../client'
import { API_ENV } from '../../common/config/constants'
import {
  FormTemplateProperties,
  FormTemplateStatus,
  useSitelineTeamMembersQuery,
  useUpdateFormTemplateOriginalFileMutation,
  useUpdateFormTemplateStatusMutation,
  useUpdateFormTemplateValidatorMutation,
} from '../../common/graphql/apollo-operations'
import { SplitFormTemplateDialog } from '../form-template-split/SplitFormTemplateDialog'
import { CloneFormTemplateDialog } from './FormTemplateInfo'
import { SkipFormTemplateValidationDialog } from './SkipFormTemplateValidationDialog'

enum StatusStep {
  UPLOAD_FORM,
  SPLIT_FORM,
  PREP_BUILD,
  BUILD,
  ASSIGN_VALIDATOR,
  VALIDATE,
  FINALIZING,
  COMPLETE,
  CANCELED,
  DUPLICATE,
}

interface StatusStepInfo {
  step: StatusStep
  title: string
  ownerName: string
  text: string | ReactElement
  forwardAction: ReactElement | null
  secondaryAction: ReactElement | null
  backwardAction: ReactElement | null
}

/**
 * A chain of steps in the build process.
 */
type StepChain = {
  // Each step in the flow. If there is an error, it is inserted after the last regular flow step that was completed
  items: StatusStep[]

  // Current step
  currentStep: StatusStep

  // Previous step
  previousStep: StatusStep | null

  // Steps that can be transitioned to
  availableSteps: StatusStep[]

  // Extra steps outside of the regular flow (cancel, duplicate)
  extraSteps: StatusStep[]
}

type StatusStepActions = {
  handleUploadForm: (file: File | null) => void
  handleCloneTemplate: () => void
  handleSplitPdf: () => void
  handleCreateTemplateVersion: () => void
  handleSelectValidator: (validatorId: string) => void
  handleChangeStatus: (status: FormTemplateStatus, showConfirmMessage?: boolean) => void
  handleSkipValidation: () => void
}

const defaultFlow = [
  StatusStep.UPLOAD_FORM,
  StatusStep.SPLIT_FORM,
  StatusStep.PREP_BUILD,
  StatusStep.BUILD,
  StatusStep.ASSIGN_VALIDATOR,
  StatusStep.VALIDATE,
  StatusStep.FINALIZING,
  StatusStep.COMPLETE,
]
const errorSteps = [StatusStep.CANCELED, StatusStep.DUPLICATE]

/**
 * Given the current step and the template status logs,
 * this returns all the steps that can be transitioned to.
 */
function availableSteps(step: StatusStep, formTemplate: FormTemplateProperties): StatusStep[] {
  const sorted = _.orderBy(formTemplate.statusLogs, (log) => log.statusUpdatedAt, 'desc')
  const previousStatus = sorted.length >= 2 ? sorted[1].status : null
  const previousStep = previousStatus ? getCurrentStatusStep(formTemplate, previousStatus) : null

  // If form is locked, we can't transition to anything else
  if (step === StatusStep.COMPLETE) {
    return []
  }

  // Is step is canceled / duplicate, only allow reverting to last status
  if (errorSteps.includes(step)) {
    return previousStep ? [previousStep] : []
  }

  // In the default flow, allow all previous steps, and 1 step after
  const stepIndex = defaultFlow.indexOf(step)
  return [..._.slice(defaultFlow, 0, stepIndex), defaultFlow[stepIndex + 1], ...errorSteps]
}

function getCurrentStatusStep(
  formTemplate: FormTemplateProperties,
  currentStatus?: FormTemplateStatus
): StatusStep {
  const status = currentStatus ?? formTemplate.status
  switch (status) {
    case FormTemplateStatus.WAITING_ON_ORIGINAL_FILE:
      return StatusStep.UPLOAD_FORM
    case FormTemplateStatus.PREPARING_FOR_BUILD:
      return StatusStep.SPLIT_FORM
    case FormTemplateStatus.READY_FOR_BUILD:
      return StatusStep.PREP_BUILD
    case FormTemplateStatus.BUILDING:
      return StatusStep.BUILD
    case FormTemplateStatus.READY_FOR_VALIDATION: {
      if (!formTemplate.validator) {
        return StatusStep.ASSIGN_VALIDATOR
      }
      return StatusStep.VALIDATE
    }
    case FormTemplateStatus.VALIDATED:
      return StatusStep.FINALIZING
    case FormTemplateStatus.CANCELED:
      return StatusStep.CANCELED
    case FormTemplateStatus.DUPLICATE:
      return StatusStep.DUPLICATE
    case FormTemplateStatus.COMPLETE:
      return StatusStep.COMPLETE
  }
}

function getStatusStepInfo(
  step: StatusStep,
  formTemplate: FormTemplateProperties,
  actions: StatusStepActions,
  admins: { id: string; email: string | undefined }[],
  currentUserId: string
): StatusStepInfo {
  const getName = (user: { firstName: string; lastName: string }) => {
    return `${user.firstName} ${user.lastName}`
  }
  const getPreviousStatus = () => {
    const sortedLogs = _.orderBy(formTemplate.statusLogs, (log) => log.statusUpdatedAt, 'desc')
    return sortedLogs[1].status
  }
  const hasVersions = formTemplate.versions.length > 0
  const skippedValidation = formTemplate.skippedValidation
  const isFormTemplateOwner = formTemplate.owner.id === currentUserId

  switch (step) {
    case StatusStep.UPLOAD_FORM:
      return {
        step: StatusStep.UPLOAD_FORM,
        title: 'Upload original submission',
        ownerName: getName(formTemplate.builder),
        text: 'Upload the original form that was provided by the subcontractor',
        forwardAction: (
          <input
            type="file"
            name="change"
            onChange={(ev) => actions.handleUploadForm(ev.target.files?.[0] ?? null)}
          />
        ),
        secondaryAction: null,
        backwardAction: null,
      }
    case StatusStep.SPLIT_FORM:
      return {
        step: StatusStep.SPLIT_FORM,
        title: 'Split submission into forms',
        ownerName: getName(formTemplate.builder),
        text: (
          <Typography component="div">
            <ul>
              <li>
                <a href={formTemplate.originalFile?.url} target="_blank" rel="noreferrer">
                  Download
                </a>{' '}
                the form template original file.
              </li>
              <li>
                If the downloaded file represents multiple forms, break it apart into each
                individual form. Then, click &quot;Clone template&quot; and create a new form
                template for each individual form.
              </li>
              <li>
                Verify that the form doesn&apos;t already exist by checking{' '}
                <a
                  href={`/templates/match?formTemplateId=${formTemplate.id}&useOriginalFile=true`}
                  target="_blank"
                  rel="noreferrer"
                >
                  Form match
                </a>
                .
              </li>
            </ul>
          </Typography>
        ),
        forwardAction: (
          <Button
            variant="contained"
            onClick={() => actions.handleChangeStatus(FormTemplateStatus.READY_FOR_BUILD)}
          >
            Ready to build
          </Button>
        ),
        secondaryAction:
          formTemplate.originalFile && formTemplate.originalFile.type === StoredFileType.PDF ? (
            <Button variant="outlined" color="secondary" onClick={actions.handleSplitPdf}>
              Split PDF
            </Button>
          ) : (
            <Button variant="outlined" color="secondary" onClick={actions.handleCloneTemplate}>
              Clone template
            </Button>
          ),
        backwardAction: (
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => actions.handleChangeStatus(FormTemplateStatus.DUPLICATE, true)}
          >
            Mark as duplicate
          </Button>
        ),
      }
    case StatusStep.PREP_BUILD:
      return {
        step: StatusStep.PREP_BUILD,
        title: 'Start building',
        ownerName: getName(formTemplate.builder),
        text: 'The form is ready to be built',
        forwardAction: (
          <Button variant="contained" onClick={actions.handleCreateTemplateVersion}>
            Create form template version
          </Button>
        ),
        secondaryAction: hasVersions ? (
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => actions.handleChangeStatus(FormTemplateStatus.BUILDING)}
          >
            Skip to building
          </Button>
        ) : null,
        backwardAction: (
          <Button
            onClick={() => actions.handleChangeStatus(FormTemplateStatus.PREPARING_FOR_BUILD)}
          >
            Back
          </Button>
        ),
      }
    case StatusStep.BUILD:
      return {
        step: StatusStep.BUILD,
        title: 'Build',
        ownerName: getName(formTemplate.builder),
        text: `
          Edit the form template version to include the proper annotations. When the form is ready
          for validation, the validator or owner will be notified. Make sure to address any comments
          before proceeding.
        `,
        forwardAction: (
          <Button
            variant="contained"
            onClick={() => actions.handleChangeStatus(FormTemplateStatus.READY_FOR_VALIDATION)}
          >
            Ready to validate
          </Button>
        ),
        secondaryAction: null,
        backwardAction: (
          <Button onClick={() => actions.handleChangeStatus(FormTemplateStatus.READY_FOR_BUILD)}>
            Back
          </Button>
        ),
      }
    case StatusStep.ASSIGN_VALIDATOR:
      return {
        step: StatusStep.ASSIGN_VALIDATOR,
        title: 'Assign validator',
        ownerName: getName(formTemplate.owner),
        text: `
          Assign a validator for this form template. If the GC needs the form immediately, you can
          skip validation and come back and validate later. This is not recommended unless
          essential.
        `,
        forwardAction: (
          <Autocomplete
            size="small"
            disableClearable
            options={admins.map((user) => user.id)}
            value={formTemplate.validator?.id}
            onChange={(event, value) => actions.handleSelectValidator(value)}
            sx={{ width: 200 }}
            getOptionLabel={(userId) => {
              const admin = admins.find((user) => user.id === userId)
              return admin?.email ?? ''
            }}
            renderInput={(params) => <TextField {...params} variant="outlined" hiddenLabel />}
          />
        ),
        secondaryAction: skippedValidation ? null : (
          <Tooltip
            title={isFormTemplateOwner ? '' : 'Only the form template owner can skip validation'}
          >
            <div>
              <Button
                variant="outlined"
                color="secondary"
                onClick={actions.handleSkipValidation}
                disabled={!isFormTemplateOwner}
              >
                Skip validation
              </Button>
            </div>
          </Tooltip>
        ),
        backwardAction: (
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => actions.handleChangeStatus(FormTemplateStatus.BUILDING)}
          >
            Back
          </Button>
        ),
      }
    case StatusStep.VALIDATE:
      return {
        step: StatusStep.VALIDATE,
        title: 'Validate',
        ownerName: formTemplate.validator
          ? getName(formTemplate.validator)
          : 'No validator assigned',
        text: (
          <Typography component="div">
            <p>
              Validate the form to verify it matches the expected output. You may do this in a few
              ways:
            </p>
            <ol>
              <li>
                Plug in the validation project and ensure that the form values are what you expect
              </li>
              <li>Check the template variables themselves to ensure the correct ones are set</li>
              <li>Plug in an existing project and check that the values are what you expect </li>
            </ol>
            <p>
              For any errors you find, either fix them directly or leave a comment. If there are no
              issues, mark the form as Validated. If there are issues, they will bump the form back
              to Building.
            </p>
            <p>
              If the GC needs the form immediately, you can skip validation and come back and
              validate later. This is not recommended unless essential.
            </p>
          </Typography>
        ),
        forwardAction: (
          <Button
            variant="contained"
            onClick={() => actions.handleChangeStatus(FormTemplateStatus.VALIDATED)}
          >
            Mark as validated
          </Button>
        ),
        secondaryAction: skippedValidation ? null : (
          <Tooltip
            title={isFormTemplateOwner ? '' : 'Only the form template owner can skip validation'}
          >
            <div>
              <Button
                variant="outlined"
                color="secondary"
                onClick={actions.handleSkipValidation}
                disabled={!isFormTemplateOwner}
              >
                Skip validation
              </Button>
            </div>
          </Tooltip>
        ),
        backwardAction: (
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => actions.handleChangeStatus(FormTemplateStatus.BUILDING)}
          >
            Send back to builder
          </Button>
        ),
      }
    case StatusStep.FINALIZING:
      return {
        step: StatusStep.FINALIZING,
        title: 'Final review',
        ownerName: getName(formTemplate.owner),
        text: `
            Mark the form as complete after doing final check to make sure everything is set correctly.
            Then, add the form to the relevant projects. If you see any issues, you can leave comments and
            bump the form back to the builder.
          `,
        forwardAction: (
          <Button
            variant="contained"
            onClick={() => actions.handleChangeStatus(FormTemplateStatus.COMPLETE, true)}
          >
            Mark as complete
          </Button>
        ),
        secondaryAction: null,
        backwardAction: (
          <Button onClick={() => actions.handleChangeStatus(FormTemplateStatus.BUILDING)}>
            Send back to builder
          </Button>
        ),
      }
    case StatusStep.COMPLETE:
      return {
        step: StatusStep.COMPLETE,
        title: 'Finished',
        ownerName: getName(formTemplate.owner),
        text: '',
        forwardAction: (
          <Button
            variant="outlined"
            color="error"
            onClick={() => actions.handleChangeStatus(FormTemplateStatus.READY_FOR_VALIDATION)}
          >
            Undo
          </Button>
        ),
        secondaryAction: null,
        backwardAction: null,
      }
    case StatusStep.CANCELED:
      return {
        step: StatusStep.CANCELED,
        title: 'Canceled',
        ownerName: getName(formTemplate.owner),
        text: 'The form building was canceled.',
        forwardAction: (
          <Button
            variant="contained"
            onClick={() => actions.handleChangeStatus(getPreviousStatus())}
          >
            Undo
          </Button>
        ),
        secondaryAction: null,
        backwardAction: null,
      }
    case StatusStep.DUPLICATE:
      return {
        step: StatusStep.DUPLICATE,
        title: 'Duplicate',
        ownerName: getName(formTemplate.owner),
        text: (
          <>
            The form was marked as a duplicate of{' '}
            <Link target="_blank" href={`/templates/${formTemplate.id}`}>
              {formTemplate.duplicateTemplate?.userVisibleName}
            </Link>
            .
          </>
        ),
        forwardAction: (
          <Button
            variant="contained"
            onClick={() => actions.handleChangeStatus(getPreviousStatus())}
          >
            Undo
          </Button>
        ),
        secondaryAction: null,
        backwardAction: null,
      }
  }
}

/**
 * Builds the status chain that can be displayed in the UI.
 */
function buildStepChain(formTemplate: FormTemplateProperties): StepChain {
  const sortedLogs = _.orderBy(formTemplate.statusLogs, (log) => log.statusUpdatedAt, 'desc')
  const currentStep = getCurrentStatusStep(formTemplate)
  const previousStep =
    sortedLogs.length > 1 ? getCurrentStatusStep(formTemplate, sortedLogs[1].status) : null

  // Build the chain by starting with the default step flow, then insert the current step if
  // if is an error.
  const flow = [...defaultFlow]
  if (errorSteps.includes(currentStep) && previousStep) {
    const previousStepIndex = flow.indexOf(previousStep)
    flow.splice(previousStepIndex + 1, 0, currentStep)
  }

  // Compile a list of statuses that can be transitioned to
  const available = availableSteps(currentStep, formTemplate)
  const extra = available.filter((status) => errorSteps.includes(status))

  return {
    items: flow,
    currentStep,
    previousStep,
    availableSteps: available,
    extraSteps: extra,
  }
}

type FormTemplateStatusStepsProps = {
  formTemplate: FormTemplateProperties
}

/**
 * Information about what a user should do in each form template status.
 */
export function FormTemplateStatusSteps({ formTemplate }: FormTemplateStatusStepsProps) {
  const snackbar = useSitelineSnackbar()
  const currentUser = getCurrentUser()
  const [updateStatus] = useUpdateFormTemplateStatusMutation()
  const [updateOriginalFile] = useUpdateFormTemplateOriginalFileMutation()
  const [updateValidator] = useUpdateFormTemplateValidatorMutation()
  const [cloneDialogOpen, setCloneDialogOpen] = useState<boolean>(false)
  const [splitPdfDialogOpen, setSplitPdfDialogOpen] = useState<boolean>(false)
  const [skipValidationDialogOpen, setSkipValidationDialogOpen] = useState<boolean>(false)
  const { data: adminsData } = useSitelineTeamMembersQuery()
  const admins = _.orderBy(adminsData?.sitelineTeamMembers ?? [], (user) => user.firstName, ['asc'])

  const chain = buildStepChain(formTemplate)
  const activeStep: number = chain.items.indexOf(chain.currentStep)

  // Handle uploading the original file
  const handleUploadForm = (file: File | null) => {
    if (!file) {
      return
    }
    const confirmed = window.confirm('Are you sure you want to update the original file?')
    if (!confirmed) {
      return
    }
    snackbar.showLoading()
    updateOriginalFile({
      variables: {
        input: {
          id: formTemplate.id,
          file,
        },
      },
    })
      .then(() => snackbar.showSuccess())
      .catch((err) => snackbar.showError(err.message))
  }

  const handleCloneTemplate = () => setCloneDialogOpen(true)
  const handleSplitPdf = () => setSplitPdfDialogOpen(true)

  // Navigate to the create template version page
  const handleCreateTemplateVersion = () => {
    window.open(`/templates/${formTemplate.id}/versions/create`)
  }

  // Allow selecting the validator for this form template
  const handleSelectValidator = (validatorId: string | null) => {
    if (!validatorId) {
      return
    }
    updateValidator({
      variables: {
        input: {
          id: formTemplate.id,
          userId: validatorId,
        },
      },
    })
      .then(() => snackbar.showSuccess())
      .catch((err) => snackbar.showError(err.message))
  }

  // Handle moving forward or backwards in status
  const handleChangeStatus = (status: FormTemplateStatus, showConfirmMessage = false) => {
    if (status === formTemplate.status) {
      return
    }
    if (showConfirmMessage) {
      const statusName = getTemplateStatusPrettyName(status, false)
      const confirmed = window.confirm(
        `Are you sure you want to mark this template as ${statusName}?`
      )
      if (!confirmed) {
        return
      }
    }

    updateStatus({
      variables: {
        input: {
          id: formTemplate.id,
          status,
        },
      },
    })
      .then(() => snackbar.showSuccess())
      .catch((err) => snackbar.showError(err.message))
  }

  const handleSkipValidation = () => {
    if (formTemplate.status !== FormTemplateStatus.READY_FOR_VALIDATION) {
      snackbar.showError('Template can only skip validation if it is ready to validate')
      return
    }

    setSkipValidationDialogOpen(true)
  }

  const actions: StatusStepActions = {
    handleUploadForm,
    handleCloneTemplate,
    handleSplitPdf,
    handleCreateTemplateVersion,
    handleSelectValidator,
    handleChangeStatus,
    handleSkipValidation,
  }

  return (
    <Card>
      <CardHeader title="Build status" />
      {API_ENV !== 'production' && (
        <Alert severity="warning" sx={{ margin: '0px 16px' }}>
          Templates are now built in prod
        </Alert>
      )}
      <CardContent>
        <Stepper activeStep={activeStep} orientation="vertical">
          {chain.items.map((item, index) => {
            const stepInfo = getStatusStepInfo(
              item,
              formTemplate,
              actions,
              admins,
              currentUser?.uid ?? ''
            )
            return (
              <Step key={index}>
                <StepLabel error={errorSteps.includes(item)}>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div>{stepInfo.title}</div>
                    <div>{stepInfo.ownerName}</div>
                  </div>
                </StepLabel>
                <StepContent>
                  {_.isString(stepInfo.text) ? (
                    <Typography>{stepInfo.text}</Typography>
                  ) : (
                    stepInfo.text
                  )}
                  <div style={{ display: 'flex', marginTop: 8 }}>
                    <div style={{ paddingRight: 8 }}>
                      {stepInfo.forwardAction && stepInfo.forwardAction}
                    </div>
                    <div style={{ paddingRight: 8 }}>
                      {stepInfo.secondaryAction && stepInfo.secondaryAction}
                    </div>
                    <div>{stepInfo.backwardAction && stepInfo.backwardAction}</div>
                  </div>
                </StepContent>
              </Step>
            )
          })}
        </Stepper>
      </CardContent>
      <CloneFormTemplateDialog
        open={cloneDialogOpen}
        onClose={() => setCloneDialogOpen(false)}
        formTemplate={formTemplate}
      />
      <SplitFormTemplateDialog
        open={splitPdfDialogOpen}
        onClose={() => setSplitPdfDialogOpen(false)}
        formTemplate={formTemplate}
      />
      <SkipFormTemplateValidationDialog
        open={skipValidationDialogOpen}
        onClose={() => setSkipValidationDialogOpen(false)}
        formTemplate={formTemplate}
      />
    </Card>
  )
}
