import { gql } from '@apollo/client'
import { Card, CardContent, CardHeader } from '@mui/material'
import { useSitelineSnackbar } from 'siteline-common-web'
import * as fragments from '../../common/graphql/Fragments'
import { DetailedCompany } from '../../common/graphql/Fragments'
import {
  useUpdateDefaultPrimaryLienWaiverFormsMutation,
  useUpdateDefaultVendorLienWaiverFormsMutation,
} from '../../common/graphql/apollo-operations'
import {
  LienWaiverTemplateSet,
  LienWaiverTemplateSetValues,
} from '../lien-waiver-template-set/LienWaiverTemplateSet'

gql`
  mutation updateDefaultPrimaryLienWaiverForms($input: UpdateDefaultLienWaiverFormsInput!) {
    updateDefaultPrimaryLienWaiverForms(input: $input) {
      ...CompanyForDetailsProperties
    }
  }
  ${fragments.companyForDetails}
`

gql`
  mutation updateDefaultVendorLienWaiverForms($input: UpdateDefaultLienWaiverFormsInput!) {
    updateDefaultVendorLienWaiverForms(input: $input) {
      ...CompanyForDetailsProperties
    }
  }
  ${fragments.companyForDetails}
`

interface CompanyDetailsContentProps {
  company: DetailedCompany
}

export default function CompanyDetailsLienWaivers({ company }: CompanyDetailsContentProps) {
  const [updatePrimaryLienWaivers] = useUpdateDefaultPrimaryLienWaiverFormsMutation()
  const [updateVendorLienWaivers] = useUpdateDefaultVendorLienWaiverFormsMutation()
  const snackbar = useSitelineSnackbar()

  let primaryLienWaiverSet: LienWaiverTemplateSetValues | null = null
  if (company.defaultPrimaryLienWaivers) {
    primaryLienWaiverSet = {
      conditionalFinalVariant: company.defaultPrimaryLienWaivers.conditionalFinalVariant,
      conditionalProgressVariant: company.defaultPrimaryLienWaivers.conditionalProgressVariant,
      unconditionalFinalVariant: company.defaultPrimaryLienWaivers.unconditionalFinalVariant,
      unconditionalProgressVariant: company.defaultPrimaryLienWaivers.unconditionalProgressVariant,
    }
  }

  let vendorLienWaiverSet: LienWaiverTemplateSetValues | null = null
  if (company.defaultVendorLienWaivers) {
    vendorLienWaiverSet = {
      conditionalFinalVariant: company.defaultVendorLienWaivers.conditionalFinalVariant,
      conditionalProgressVariant: company.defaultVendorLienWaivers.conditionalProgressVariant,
      unconditionalFinalVariant: company.defaultVendorLienWaivers.unconditionalFinalVariant,
      unconditionalProgressVariant: company.defaultVendorLienWaivers.unconditionalProgressVariant,
    }
  }

  const onUpdatePrimaryLienWaivers = (lienWaiverTemplates: LienWaiverTemplateSetValues | null) => {
    return updatePrimaryLienWaivers({
      variables: {
        input: {
          companyId: company.id,
          lienWaivers: {
            conditionalFinalVariantId: lienWaiverTemplates?.conditionalFinalVariant?.id,
            conditionalProgressVariantId: lienWaiverTemplates?.conditionalProgressVariant?.id,
            unconditionalFinalVariantId: lienWaiverTemplates?.unconditionalFinalVariant?.id,
            unconditionalProgressVariantId: lienWaiverTemplates?.unconditionalProgressVariant?.id,
          },
        },
      },
    })
      .then(() => {
        snackbar.showSuccess()
        return true
      })
      .catch((err) => {
        snackbar.showError(err.message)
        return false
      })
  }

  const onUpdateVendorLienWaivers = (lienWaiverTemplates: LienWaiverTemplateSetValues | null) => {
    return updateVendorLienWaivers({
      variables: {
        input: {
          companyId: company.id,
          lienWaivers: {
            conditionalFinalVariantId: lienWaiverTemplates?.conditionalFinalVariant?.id,
            conditionalProgressVariantId: lienWaiverTemplates?.conditionalProgressVariant?.id,
            unconditionalFinalVariantId: lienWaiverTemplates?.unconditionalFinalVariant?.id,
            unconditionalProgressVariantId: lienWaiverTemplates?.unconditionalProgressVariant?.id,
          },
        },
      },
    })
      .then(() => {
        snackbar.showSuccess()
        return true
      })
      .catch((err) => {
        snackbar.showError(err.message)
        return false
      })
  }

  return (
    <Card>
      <CardHeader title="Default Lien Waiver Templates" />
      <CardContent>
        <LienWaiverTemplateSet
          title="Primary"
          lienWaiverSet={primaryLienWaiverSet}
          onChange={onUpdatePrimaryLienWaivers}
        />
      </CardContent>
      <CardContent>
        <LienWaiverTemplateSet
          title="Lower-tier"
          lienWaiverSet={vendorLienWaiverSet}
          onChange={onUpdateVendorLienWaivers}
        />
      </CardContent>
    </Card>
  )
}
