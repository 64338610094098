import _ from 'lodash'
import type { Moment } from 'moment-timezone'
import {
  AggregateLienWaiverStatus,
  LienWaiverType,
  VendorLienWaiverReminderFrequency,
} from '../enums.js'
import { dollarNumberFormat } from './numbers.js'

export const DEFAULT_VENDOR_LIEN_WAIVER_REMINDER_FREQUENCY =
  VendorLienWaiverReminderFrequency.WEEKLY

export enum LienWaiverProgressType {
  PROGRESS = 'progress',
  FINAL = 'final',
}

/**
 * Formats a lien waiver amount to insert in a form annotation value.
 * 4 cases:
 *  - Amount only: "$1,000"
 *  - Text only: "Paid to date"
 *  - Amount + text: "$1,000 - Paid to date"
 *  - None of the above: ""
 */
export function formatLienWaiverAmount(amount?: number, text?: string): string {
  const hasAmount = _.isNumber(amount)
  const cleanText = _.trim(text ?? '')
  const hasText = cleanText.length > 0

  // Amount + text
  if (hasAmount && hasText) {
    return `${dollarNumberFormat.format(amount / 100)} - ${cleanText}`
  }

  // Amount only
  if (hasAmount) {
    return dollarNumberFormat.format(amount / 100)
  }

  // Text only
  if (hasText) {
    return cleanText
  }

  // Nothing
  return ''
}

// Return the copy to use to describe a lien waiver type
export function stringForLienWaiverType(type: LienWaiverType): string {
  switch (type) {
    case LienWaiverType.CONDITIONAL_PROGRESS_PAYMENT:
      return 'Conditional Progress'
    case LienWaiverType.CONDITIONAL_FINAL_PAYMENT:
      return 'Conditional Final'
    case LienWaiverType.UNCONDITIONAL_PROGRESS_PAYMENT:
      return 'Unconditional Progress'
    case LienWaiverType.UNCONDITIONAL_FINAL_PAYMENT:
      return 'Unconditional Final'
  }
}

/** Returns a default subject line for a vendor lien waiver request */
function defaultLienWaiverRequestSubject({
  projectNumber,
  projectName,
  type,
  throughDate,
  subcontractorName,
  subTierVendorName,
}: {
  projectNumber: string
  projectName: string
  type: LienWaiverType
  throughDate: Moment
  subcontractorName: string
  subTierVendorName?: string
}): string {
  const formattedThroughDate = throughDate.format('MM/D/YY')
  const lienWaiverType = stringForLienWaiverType(type)
  const subTierInsertion = subTierVendorName ? ` ${subTierVendorName}` : ''
  const baseSubject = `${projectNumber} ${projectName} -${subTierInsertion} ${lienWaiverType} through ${formattedThroughDate} requested by ${subcontractorName}`
  return baseSubject
}

const REMINDER_PREFIX = '[Reminder]'

/** Returns the subject line to use for a lien waiver request */
export function getLienWaiverRequestSubject({
  lastSubject,
  isReminder,
  projectNumber,
  projectName,
  type,
  throughDate,
  subcontractorName,
  subTierVendorName,
}: {
  lastSubject: string | null
  isReminder: boolean
  projectNumber: string
  projectName: string
  type: LienWaiverType
  throughDate: Moment
  subcontractorName: string
  subTierVendorName?: string
}): string {
  let baseSubject = lastSubject
  if (!baseSubject) {
    baseSubject = defaultLienWaiverRequestSubject({
      projectNumber,
      projectName,
      type,
      throughDate,
      subcontractorName,
      subTierVendorName,
    })
  }
  if (isReminder) {
    // Avoid adding multiple "Reminder" prefixes, if one is already included in the subject
    return baseSubject.includes(REMINDER_PREFIX) ? baseSubject : `${REMINDER_PREFIX} ${baseSubject}`
  }
  return baseSubject
}

export enum LegalDocumentStatus {
  // No requests have been sent
  NOT_REQUESTED = 'NOT_REQUESTED',
  // Only one request has been sent
  REQUESTED = 'REQUESTED',
  // Multiple requests have been sent
  REMINDED = 'REMINDED',
  // At least one request has been viewed
  VIEWED = 'VIEWED',
  // A vendor contact has digitally signed or uploaded a document
  SIGNED = 'SIGNED',
  // A vendor contact has completed the document (for a vendor document)
  COMPLETED = 'COMPLETED',
  // A document was uploaded by the sub
  UPLOADED = 'UPLOADED',
  // The document has been synced to Textura
  SYNCED = 'SYNCED',
  // The document has been submitted via email
  SUBMITTED = 'SUBMITTED',
}

/**
 * Returns an aggregate status for a list of lien waiver statuses, based on the status of the lien
 * request furthest from being completed
 */
export function getAggregateLienWaiverStatus(
  allStatuses: LegalDocumentStatus[]
): AggregateLienWaiverStatus {
  // Filter out lien waivers that haven't been requested (or uploaded), since we don't count them
  // in the tracker-level totals
  const statuses = allStatuses.filter((status) => status !== LegalDocumentStatus.NOT_REQUESTED)
  if (statuses.length === 0) {
    return AggregateLienWaiverStatus.NONE_REQUESTED
  }
  if (
    statuses.includes(LegalDocumentStatus.REQUESTED) ||
    statuses.includes(LegalDocumentStatus.REMINDED)
  ) {
    return AggregateLienWaiverStatus.REQUESTED
  }
  if (statuses.includes(LegalDocumentStatus.VIEWED)) {
    return AggregateLienWaiverStatus.VIEWED
  }
  if (
    statuses.every((status) =>
      [LegalDocumentStatus.SUBMITTED, LegalDocumentStatus.SYNCED].includes(status)
    )
  ) {
    return AggregateLienWaiverStatus.SUBMITTED
  }
  return AggregateLienWaiverStatus.SIGNED
}
