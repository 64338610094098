import {
  Alert,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Link,
  Table,
  TableBody,
} from '@mui/material'
import _ from 'lodash'
import { makeStylesFast } from 'siteline-common-web'
import { EditableCardRowText } from '../../../../common/components/EditableCardRow'
import { useUpdateIntegrationMappingsMutation } from '../../../../common/graphql/apollo-operations'
import { GcPayIntegration } from '../../../../common/util/Integration'

const useStyles = makeStylesFast(() => ({
  card: {
    paddingBottom: 0,
  },
  cardContent: {
    padding: 0,
    '&:last-child': {
      padding: 0,
    },
    '& .MuiFormControl-root': {
      width: '100%',
    },
  },
}))

type GcPayIntegrationDetailsProps = {
  integration: GcPayIntegration
}

export function GcPayIntegrationDetails({ integration }: GcPayIntegrationDetailsProps) {
  const classes = useStyles()
  const [updateMappings] = useUpdateIntegrationMappingsMutation()

  return (
    <>
      <Card className={classes.card}>
        <CardHeader title="GCPay Metadata" />
        <Divider />
        {!integration.mappings.project.gcPayProjectId && (
          <CardContent>
            <Alert severity="error">
              Missing project ID.
              <p>
                Go to the&nbsp;
                <Link target="_blank" href="https://gcpay.com/main/projects.do" underline="hover">
                  GCPay poject list
                </Link>
                , click on the relevant project, and copy the project ID from the URL bar.
              </p>
              <p>
                For instance, if the URL is&nbsp;
                <code>https://gcpay.com/main/project/Mjg1NzEzfDk2MDM4/index.do</code>, then the
                project ID is <code>Mjg1NzEzfDk2MDM4</code>.
              </p>
            </Alert>
          </CardContent>
        )}
        {!integration.mappings.contract.gcPaySovId && (
          <CardContent>
            <Alert severity="error">
              Missing Schedule of Values ID.
              <p>
                Go to the GCPay project, view the Schedule of Values, then copy the SOV ID from the
                URL bar.
              </p>
              <p>
                For instance, if the URL is&nbsp;
                <code>
                  https://gcpay.com/main/project/MzAxOTExfDEwMDY2Mw/sov/index.do?id=1039085
                </code>
                , then the SOV ID is <code>1039085</code>.
              </p>
            </Alert>
          </CardContent>
        )}
        <CardContent>
          <Alert severity="info">
            Make sure our GCPay account has the following permissions on the project:{' '}
            <code>Project Manager, Compliance Officer, Lien Officer, Editor</code>. See{' '}
            <Link
              href="https://help.gcpay.com/support/solutions/articles/51000172169-how-do-i-assign-a-user-project-permissions-"
              underline="hover"
            >
              the GCPay documentation
            </Link>{' '}
            for more information.
          </Alert>
        </CardContent>
        <CardContent className={classes.cardContent}>
          <Table>
            <TableBody>
              <EditableCardRowText
                readOnly={false}
                value={integration.mappings.project.gcPayProjectId ?? ''}
                label="GCPay Project ID"
                variables={(value) => {
                  const newMappings = _.cloneDeep(integration.mappings)
                  newMappings.project.gcPayProjectId = value
                  return {
                    input: {
                      integrationId: integration.id,
                      mappings: newMappings as Record<string, unknown>,
                    },
                  }
                }}
                mutate={updateMappings}
              />
              <EditableCardRowText
                readOnly={false}
                value={integration.mappings.contract.gcPaySovId ?? ''}
                label="GCPay SOV ID"
                variables={(value) => {
                  const newMappings = _.cloneDeep(integration.mappings)
                  newMappings.contract.gcPaySovId = value
                  return {
                    input: {
                      integrationId: integration.id,
                      mappings: newMappings as Record<string, unknown>,
                    },
                  }
                }}
                mutate={updateMappings}
              />
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    </>
  )
}
