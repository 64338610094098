import { setExtra } from '@sentry/react'

type InitializeLogRocketParams = {
  project: string
  serverURL: string
  release: string | undefined
}

export async function initializeLogRocket({
  project,
  serverURL,
  release,
}: InitializeLogRocketParams): Promise<void> {
  const { default: LogRocket } = await import('logrocket')

  LogRocket.init(project, {
    serverURL,
    release,
    network: {
      requestSanitizer: (request) => {
        // Firebase Auth Login (remove request body entirely)
        // Create account: https://www.googleapis.com/identitytoolkit/v3/relyingparty/emailLinkSignin
        // Signin: https://www.googleapis.com/identitytoolkit/v3/relyingparty/verifyPassword
        // Set or change password: https://www.googleapis.com/identitytoolkit/v3/relyingparty/setAccountInfo
        // Renew token: https://securetoken.googleapis.com/v1/token
        if (
          request.url.match(/www.googleapis.com\/identitytoolkit/) ||
          request.url.match(/securetoken.googleapis.com/)
        ) {
          request.body = 'SANITIZED'
        }

        // Remove authorization header regardless of where the request comes from
        request.headers['authorization'] = 'SANITIZED'

        return request
      },
    },
    dom: {
      disableWebAnimations: true,
    },
  })

  // Add logrocket session to sentry
  LogRocket.getSessionURL((url) => {
    setExtra('logRocketSessionUrl', url)
  })
}
