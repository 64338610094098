import { gql } from '@apollo/client'
import StarIcon from '@mui/icons-material/Star'
import { Card, CardContent, CardHeader, colors, Grid2, Theme, Typography } from '@mui/material'
import _ from 'lodash'
import { useCallback, useMemo, useState } from 'react'
import { cleanCompanyName } from 'siteline-common-all'
import { fuseSearch, makeStylesFast } from 'siteline-common-web'
import { v4 as uuidv4 } from 'uuid'
import { CompanyAutocomplete } from '../../common/components/CompanyAutocomplete'
import Page from '../../common/components/Page'
import {
  GeneralContractorsForDedupeQuery,
  useGeneralContractorsForDedupeQuery,
} from '../../common/graphql/apollo-operations'
import * as fragments from '../../common/graphql/Fragments'
import { GcDedupeDuplicateGroup } from './GcDedupeDuplicateGroup'

const useStyles = makeStylesFast((theme: Theme) => ({
  root: {
    padding: theme.spacing(3),
    '& h4': {
      marginBottom: theme.spacing(1),
    },
    '& .variable-set': {
      marginRight: theme.spacing(2),
      width: 200,
    },
    '& .annotations': {
      color: colors.grey[700],
      fontSize: 12,
      marginBottom: theme.spacing(0.5),
    },
  },
  uploadPaper: {
    height: 148,
    padding: theme.spacing(2),
    border: '1px dashed rgba(0, 0, 0, 0.12)',
    cursor: 'pointer',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  fileDrag: {
    border: '1px solid rgba(0, 0, 0, 0.54)',
  },
  addAttachment: {
    height: 116,
    textAlign: 'center',
    '& span': {
      display: 'block',
    },
  },
  actions: {
    padding: theme.spacing(2),
  },
}))

gql`
  query generalContractorsForDedupe($companyId: ID!) {
    generalContractorsForDedupe(companyId: $companyId) {
      company {
        id
        name
        locations {
          ...LocationProperties
        }
      }
      contractCount
    }
  }
  ${fragments.location}
`

type GeneralContractor = GeneralContractorsForDedupeQuery['generalContractorsForDedupe'][number]
export type DuplicateGroup = {
  id: string
  companies: GeneralContractor[]
}

function getDuplicates(rows: GeneralContractor[]): DuplicateGroup[] {
  const duplicates: DuplicateGroup[] = []

  const isTaggedAsDuplicate = (row: GeneralContractor): boolean => {
    const ids = _.chain(duplicates)
      .map((group) => group.companies)
      .flatten()
      .map((company) => company.company.id)
      .value()
    return ids.includes(row.company.id)
  }

  for (const row of rows) {
    if (isTaggedAsDuplicate(row)) {
      continue
    }
    const others = rows
      .filter((otherRow) => otherRow.company.id !== row.company.id)
      .map((row) => {
        return { ...row, cleanName: cleanCompanyName(row.company.name) }
      })
    const result = fuseSearch(others, cleanCompanyName(row.company.name), ['cleanName'], {
      threshold: 0.15,
    })
    if (result.length > 0) {
      const companies = _.orderBy([row, ...result], (company) => company.contractCount, 'desc')
      duplicates.push({ id: uuidv4(), companies })
    }
  }

  return duplicates
}

/**
 * GC dedupe page that finds GCs and deduplicates them for a subcontractor
 */
export function GcDedupe() {
  const classes = useStyles()
  const [skippedGroupIds, setSkippedGroupIds] = useState<string[]>([])

  const [companyId, setCompanyId] = useState<string | null>(null)
  const { data } = useGeneralContractorsForDedupeQuery({
    variables: { companyId: companyId ?? '' },
    skip: !companyId,
  })

  const duplicates = useMemo(() => {
    const generalContractors = data?.generalContractorsForDedupe ?? []
    return getDuplicates([...generalContractors])
  }, [data?.generalContractorsForDedupe])

  const handleSkip = useCallback(
    (id: string) => {
      setSkippedGroupIds([...skippedGroupIds, id])
    },
    [skippedGroupIds]
  )

  const filteredDuplicates = useMemo(
    () => duplicates.filter((group) => !skippedGroupIds.includes(group.id)),
    [duplicates, skippedGroupIds]
  )

  return (
    <Page className={classes.root} title="Form match">
      <Typography variant="h4">GC Dedupe</Typography>
      <Grid2 container spacing={2}>
        <Grid2 size={{ xs: 4 }}>
          <Card>
            <CardHeader subheader="Select a subcontractor to dedupe GCs for" />
            <CardContent>
              <CompanyAutocomplete
                fullWidth
                label="Company"
                companyId={companyId}
                setCompanyId={setCompanyId}
                sx={{ marginTop: 1 }}
              />
            </CardContent>
          </Card>
          <Card sx={{ marginTop: 2 }}>
            <CardContent>
              <Typography>
                After selecting a subcontractor, you&apos;ll see a list of potential duplicate
                GCs/owners across its contracts. You can choose to merge these companies into a
                single one, or skip the duplicates. Merging works as follows:
              </Typography>
              <ul>
                <li>
                  Selected companies will be merged into the company with{' '}
                  <StarIcon fontSize="small" sx={{ verticalAlign: 'middle' }} /> next to it.
                </li>
                <li>GC/Owner names on contracts will be updated to the starred company</li>
                <li>
                  All references to the old companies will be updated to reference the starred one,
                  including contracts and templates.
                </li>
                <li>Selected companies that are not starred will be removed completely.</li>
              </ul>
            </CardContent>
          </Card>
        </Grid2>
        <Grid2 size={{ xs: 8 }}>
          {filteredDuplicates.map((group) => (
            <GcDedupeDuplicateGroup
              key={group.id}
              group={group}
              onSkip={() => handleSkip(group.id)}
            />
          ))}
        </Grid2>
      </Grid2>
    </Page>
  )
}
