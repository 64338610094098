import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material'
import { IdentifierIconButton } from '../../common/components/IdentifierRow'
import * as fragments from '../../common/graphql/Fragments'

interface CompanyDetailsVendorContactsProps {
  company: fragments.DetailedCompany
}

export function CompanyDetailsVendorContacts({ company }: CompanyDetailsVendorContactsProps) {
  const existingVendors = company.vendors
  const collator = new Intl.Collator()
  const sortedVendors = [...existingVendors].sort((a, b) => collator.compare(a.name, b.name))

  return (
    <Card>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <CardHeader title="Vendor Contacts" />
        </AccordionSummary>
        <CardContent style={{ padding: 0 }}>
          <AccordionDetails style={{ padding: 0 }}>
            <Divider />
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Vendor Name</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Active</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedVendors.map((vendor) => {
                  const contacts = vendor.contacts
                  const sortedContacts = [...contacts].sort((a, b) =>
                    collator.compare(a.fullName, b.fullName)
                  )
                  return sortedContacts.map((contact) => (
                    <TableRow key={contact.id}>
                      <TableCell>{vendor.name}</TableCell>
                      <TableCell>{contact.fullName}</TableCell>
                      <TableCell>{contact.email}</TableCell>
                      <TableCell>
                        <Checkbox
                          checked={contact.archivedAt === null ? true : false}
                          size="small"
                          disabled
                        />
                      </TableCell>
                      <TableCell>
                        <IdentifierIconButton id={contact.id} />
                      </TableCell>
                    </TableRow>
                  ))
                })}
              </TableBody>
            </Table>
          </AccordionDetails>
        </CardContent>
      </Accordion>
    </Card>
  )
}
