import { IntegrationType, getIntegrationProjectId } from 'siteline-common-all'
import { Integration } from '../types'

/** Get the associated 3rd party website for a given integration type */
export function getIntegrationWebsite(
  integration: Pick<Integration, 'type' | 'mappings'>
): string | undefined {
  const GC_PAY_URL = 'https://gcpay.com/'
  const TEXTURA_URL = 'https://cpm.texturacorp.com/tpm/tpm-ui'
  const TEST_URL = 'https://example.com/'
  const PROCORE_URL = 'https://procore.com/'

  const projectId = getIntegrationProjectId(integration)
  let thirdPartyUrl: string | undefined
  switch (integration.type) {
    case IntegrationType.GC_PAY: {
      const gcPayUrl = new URL(GC_PAY_URL)
      if (projectId) {
        gcPayUrl.pathname = `/main/project/${projectId}/index.do`
      }
      thirdPartyUrl = gcPayUrl.toString()
      break
    }
    case IntegrationType.TEXTURA: {
      const texturaUrl = new URL(TEXTURA_URL)
      if (projectId) {
        texturaUrl.pathname = `/tpm/tpm-ui`
        texturaUrl.searchParams.set('projectID', projectId)
        texturaUrl.searchParams.set('root', 'projectHome')
      }
      thirdPartyUrl = texturaUrl.toString()
      break
    }
    case IntegrationType.TEST:
      thirdPartyUrl = TEST_URL
      break
    case IntegrationType.PROCORE:
      thirdPartyUrl = PROCORE_URL
      break
    case IntegrationType.ACUMATICA:
    case IntegrationType.QUICKBOOKS_ENTERPRISE_FILE:
    case IntegrationType.FOUNDATION_FILE:
    case IntegrationType.FOUNDATION:
    case IntegrationType.COMPUTER_EASE_FILE:
    case IntegrationType.SAGE_100_CONTRACTOR:
    case IntegrationType.SAGE_100_CONTRACTOR_AGAVE:
    case IntegrationType.SAGE_300_CRE:
    case IntegrationType.SAGE_300_CRE_AGAVE:
    case IntegrationType.SAGE_INTACCT:
    case IntegrationType.SPECTRUM:
    case IntegrationType.VISTA:
      thirdPartyUrl = undefined
      break
  }

  return thirdPartyUrl
}
