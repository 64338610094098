import { Typography, TypographyProps, useTheme } from '@mui/material'
import { Variant as MuiVariant } from '@mui/material/styles/createTypography'
import { clsx } from 'clsx'
import { CSSProperties, MouseEvent, PropsWithChildren, ReactNode, forwardRef, useMemo } from 'react'
import { colors } from '../themes/Styles'

export const colorStyles = {
  blue30: { color: colors.blue30 },
  blue50: { color: colors.blue50 },
  blue70: { color: colors.blue70 },
  green30: { color: colors.green30 },
  green50: { color: colors.green50 },
  green70: { color: colors.green70 },
  grey20: { color: colors.grey20 },
  grey30: { color: colors.grey30 },
  grey40: { color: colors.grey40 },
  grey50: { color: colors.grey50 },
  grey60: { color: colors.grey60 },
  grey70: { color: colors.grey70 },
  grey90: { color: colors.grey90 },
  red70: { color: colors.red70 },
  white: { color: colors.white },
  yellow30: { color: colors.yellow30 },
  yellow50: { color: colors.yellow50 },
  yellow70: { color: colors.yellow70 },
  purple50: { color: colors.purple50 },
  red50: { color: colors.red50 },
}

export type SitelineVariant =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'secondary'
  | 'smallText'
  | 'button'
  | 'body1'
  | 'body2'
  | 'caption'
  | 'label'

type BoldProps = { fontWeight: number }

const boldMap: Record<SitelineVariant, BoldProps> = {
  // Lato Bold
  h1: { fontWeight: 600 },
  h2: { fontWeight: 600 },
  h3: { fontWeight: 600 },
  h4: { fontWeight: 600 },
  h5: { fontWeight: 600 },
  button: { fontWeight: 600 },
  smallText: { fontWeight: 700 },
  caption: { fontWeight: 700 },
  label: { fontWeight: 600 },
  body1: { fontWeight: 600 },
  body2: { fontWeight: 600 },
  secondary: { fontWeight: 600 },
}

const iconSpacingMap: Record<SitelineVariant, number> = {
  h1: 2,
  h2: 2,
  h3: 1,
  h4: 1,
  h5: 0.5,
  secondary: 0.5,
  smallText: 1,
  button: 1,
  body1: 1,
  body2: 1,
  caption: 0.5,
  label: 0,
}

const typeMap: Record<SitelineVariant, MuiVariant> = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  secondary: 'body2',
  smallText: 'h6',
  button: 'button',
  body1: 'body1',
  body2: 'body2',
  caption: 'caption',
  label: 'subtitle1',
}

const variantMapping: Partial<Record<MuiVariant, string>> = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'span',
  h6: 'span', // SmallText
  subtitle1: 'span', // Label
  body1: 'p', // Body regular
  body2: 'span', // Secondary
}

export type SitelineTextProps = {
  variant: SitelineVariant
  color?: keyof typeof colorStyles
  className?: string
  style?: CSSProperties
  align?: TypographyProps['align']
  bold?: boolean
  onClick?: (event: MouseEvent<HTMLSpanElement>) => void
  showTitle?: boolean
  startIcon?: ReactNode
  endIcon?: ReactNode
  noWrap?: TypographyProps['noWrap']
}

function computeStyles(props: SitelineTextProps) {
  const boldStyles = props.bold ? boldMap[props.variant] : {}
  // Don't use a pointer for the button variant unless an `onClick` is provided
  const cursorStyles = props.variant === 'button' && !props.onClick ? { cursor: 'inherit' } : {}
  const colorStyles = props.color ? { color: colors[props.color] } : {}
  let iconStyles = {}
  if (props.startIcon || props.endIcon) {
    iconStyles = {
      display: 'flex',
      alignItems: 'center',
    }
  }
  return {
    ...props.style,
    ...boldStyles,
    ...cursorStyles,
    ...colorStyles,
    ...iconStyles,
  }
}

export const SitelineText = forwardRef<typeof Typography, PropsWithChildren<SitelineTextProps>>(
  function SitelineText(props, ref) {
    const theme = useTheme()
    const iconSpacing = theme.spacing(iconSpacingMap[props.variant])
    const styles = useMemo(() => computeStyles(props), [props])
    const startIconStyle = useMemo(
      () => ({ display: 'flex', marginRight: iconSpacing }),
      [iconSpacing]
    )
    const endIconStyle = useMemo(
      () => ({ display: 'flex', marginLeft: iconSpacing }),
      [iconSpacing]
    )

    return (
      // @ts-expect-error https://github.com/mui/material-ui/issues/32420
      <Typography
        ref={ref}
        align={props.align ? props.align : 'inherit'}
        variant={typeMap[props.variant]}
        className={clsx(props.className && props.className, `Siteline-${props.variant}`)}
        style={styles}
        variantMapping={variantMapping}
        onClick={props.onClick}
        title={props.showTitle ? (props.children as string) : undefined}
        noWrap={props.noWrap}
      >
        {props.startIcon && (
          <span className="startIcon" style={startIconStyle}>
            {props.startIcon}
          </span>
        )}
        {props.children}
        {props.endIcon && (
          <span className="endIcon" style={endIconStyle}>
            {props.endIcon}
          </span>
        )}
      </Typography>
    )
  }
)
