// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import type { Options } from 'p-map'
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import pMapWithOptions from 'p-map'

/**
 * Maps a list of items with promises.
 * In 99% of cases, we don't need concurrency control and we can use Promise.all directly, saving
 * as much as 40% of CPU time for large queries.
 * When concurrency control is needed, we delegate to p-map.
 */
export async function pMap<I, O>(
  iterator: I[],
  mapper: (item: I, index: number) => Promise<O> | O,
  options?: Options
): Promise<O[]> {
  if (options) {
    return pMapWithOptions(iterator, mapper, options)
  }
  return Promise.all(iterator.map(mapper))
}
