import { gql } from '@apollo/client'
import { Button, Link, TableCell, TableRow, Tooltip } from '@mui/material'
import { useCallback } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { useSitelineSnackbar } from 'siteline-common-web'
import * as fragments from '../../common/graphql/Fragments'
import {
  CompanyUserProperties,
  UserStatus,
  useDisableUserMutation,
  useEnableUserMutation,
} from '../../common/graphql/apollo-operations'

gql`
  mutation disableUser($input: DisableUserInput!) {
    disableUser(input: $input) {
      ...UserProperties
    }
  }
  ${fragments.user}
`

gql`
  mutation enableUser($input: EnableUserInput!) {
    enableUser(input: $input) {
      ...UserProperties
    }
  }
  ${fragments.user}
`

interface CompanyUsersProps {
  companyId: string
  companyUser: CompanyUserProperties
}

export default function CompanyUserRow({ companyId, companyUser }: CompanyUsersProps) {
  const snackbar = useSitelineSnackbar()
  const [disableUserMutation] = useDisableUserMutation()
  const [enableUserMutation] = useEnableUserMutation()

  const disableAccount = useCallback(() => {
    if (!window.confirm('Are you sure you want to disable this user?')) {
      return
    }
    snackbar.showLoading()
    disableUserMutation({
      variables: {
        input: {
          userId: companyUser.user.id,
          companyId,
        },
      },
    })
      .then(() => snackbar.showSuccess())
      .catch((err) => snackbar.showError(err.message))
  }, [companyUser.user.id, disableUserMutation, snackbar, companyId])

  const enableAccount = useCallback(() => {
    if (!window.confirm('Are you sure you want to enable this user?')) {
      return
    }
    snackbar.showLoading()
    enableUserMutation({
      variables: {
        input: {
          userId: companyUser.user.id,
          companyId,
        },
      },
    })
      .then(() => snackbar.showSuccess())
      .catch((err) => snackbar.showError(err.message))
  }, [companyUser.user.id, enableUserMutation, snackbar, companyId])

  return (
    <TableRow hover>
      <TableCell>
        <Link component={RouterLink} to={`/users/${companyUser.user.id}`} underline="hover">
          {companyUser.user.firstName} {companyUser.user.lastName}
        </Link>
      </TableCell>
      <TableCell>{companyUser.role}</TableCell>
      <TableCell>{companyUser.user.email}</TableCell>
      <TableCell>{companyUser.user.jobTitle ?? ''}</TableCell>
      <TableCell>{companyUser.user.phoneNumber ?? ''}</TableCell>
      <TableCell>
        {companyUser.status === UserStatus.ACTIVE && (
          <Button color="secondary" onClick={disableAccount}>
            Disable
          </Button>
        )}
        {companyUser.status === UserStatus.DISABLED && (
          <Tooltip title="This will not re-add them to their previous projects.">
            <Button color="secondary" onClick={enableAccount}>
              Enable
            </Button>
          </Tooltip>
        )}
      </TableCell>
    </TableRow>
  )
}
