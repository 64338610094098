import { Autocomplete, Box, Chip, TextField, Tooltip, autocompleteClasses } from '@mui/material'
import { SyntheticEvent, useCallback, useMemo, useState } from 'react'
import { NotaryTag } from 'siteline-common-all'
import { DetailedCompany } from '../../common/graphql/Fragments'
import { useUpdateCompanyMutation } from '../../common/graphql/apollo-operations'

interface NotaryTypeTagProps {
  company: DetailedCompany
}

type notaryTagsWithLabels = { id: NotaryTag; label: string; tooltip?: string }

export default function NotaryTagMultiSelect({ company }: NotaryTypeTagProps) {
  const notaryTags: notaryTagsWithLabels[] = useMemo(
    () => [
      {
        id: NotaryTag.DIGITAL_NOTARY_SIGNATURE,
        label: 'Digital notary signature',
        tooltip:
          'Special case for Perimeter Floors: they want the digital signature in the notary section',
      },
      { id: NotaryTag.DIGITAL_SIGNATURE, label: 'Digital signature' },
      { id: NotaryTag.IMAGE_NOTARY, label: 'Image notary' },
      { id: NotaryTag.IMAGE_SECONDARY_SIGNATURE, label: 'Image secondary signature' },
      { id: NotaryTag.IMAGE_SIGNATURE, label: 'Image signature' },
      { id: NotaryTag.NOTARY, label: 'Notary' },
      { id: NotaryTag.NOT_APPLICABLE, label: 'Not applicable' },
      { id: NotaryTag.STANDARD_NOTARY, label: 'Standard notary' },
      { id: NotaryTag.TO_BE_DETERMINED, label: 'TBD' },
      { id: NotaryTag.WET_SIGNATURE, label: 'Wet signature' },
    ],
    []
  )
  const [updateCompany] = useUpdateCompanyMutation()
  const initialChips = useMemo(
    () =>
      company.notaryTags.map((tag) => {
        const label = notaryTags.filter((item) => item.id === tag)
        return { id: tag, label: label[0].label }
      }),
    [company.notaryTags, notaryTags]
  )
  const handleChange = useCallback(
    (event: SyntheticEvent, allNotaryTags: notaryTagsWithLabels[]) => {
      setChips(allNotaryTags)
      const updatedTagList = allNotaryTags.map((item) => item.id)
      updateCompany({ variables: { input: { id: company.id, notaryTags: updatedTagList } } })
    },
    [company.id, updateCompany]
  )
  const [chips, setChips] = useState<notaryTagsWithLabels[]>(initialChips)
  const shouldShowPlaceholder = chips.length === 0
  return (
    <Autocomplete
      sx={{ m: 1 }}
      multiple
      size="small"
      options={notaryTags}
      getOptionLabel={(option) => option.label}
      renderOption={(props, option) => (
        <Tooltip title={option.tooltip ?? ''} followCursor>
          <Box
            sx={{
              borderRadius: '8px',
              margin: '5px',
              [`&.${autocompleteClasses.option}`]: {
                padding: '8px',
              },
            }}
            component="li"
            {...props}
          >
            <div>{option.label}</div>
          </Box>
        </Tooltip>
      )}
      renderTags={(value, getTagProps) =>
        value.map((option, index: number) => (
          <Tooltip key={option.label} title={option.tooltip ?? ''}>
            <Chip variant="outlined" label={option.label} {...getTagProps({ index })} />
          </Tooltip>
        ))
      }
      isOptionEqualToValue={(option, value) => option.id === value.id}
      value={chips}
      disableCloseOnSelect
      onChange={handleChange}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label="Notary type tags"
          placeholder={shouldShowPlaceholder ? 'Notary type' : undefined}
        />
      )}
    />
  )
}
