import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material'
import _ from 'lodash'
import { useNavigate } from 'react-router-dom'
import { makeStylesFast } from 'siteline-common-web'
import { DetailedPayApp } from '../../common/graphql/Fragments'

const useStyles = makeStylesFast(() => ({
  cardContent: {
    padding: 0,
    '&:last-child': {
      padding: 0,
    },
  },
}))

interface PayAppDetailsPinnedTemplatesProps {
  payApp: DetailedPayApp
}

export default function PayAppDetailsPinnedTemplates({
  payApp,
}: PayAppDetailsPinnedTemplatesProps) {
  const classes = useStyles()
  const navigate = useNavigate()

  const pinnedTemplates = _.orderBy(payApp.formTemplates, (template) => template.order, 'asc')
  return (
    <Card>
      <CardHeader title="Pinned Templates" />
      <CardContent className={classes.cardContent}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Order</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {pinnedTemplates.map((pinnedTemplate) => {
              const templateVersion = pinnedTemplate.formTemplateVersion
              const formTemplate = templateVersion.formTemplate
              return (
                <TableRow key={pinnedTemplate.order}>
                  <TableCell>{`${formTemplate.userVisibleName} v${templateVersion.versionNumber}`}</TableCell>
                  <TableCell>{pinnedTemplate.order}</TableCell>
                  <TableCell>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => navigate(`/templates/${formTemplate.id}/edit`)}
                    >
                      View
                    </Button>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  )
}
