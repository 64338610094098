import { gql } from '@apollo/client'
import {
  Autocomplete,
  Button,
  Card,
  CardContent,
  Fade,
  FormControl,
  Grid2,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Theme,
  Tooltip,
  Typography,
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import _ from 'lodash'
import moment, { Moment } from 'moment-timezone'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { CartesianGrid, Label, Line, LineChart, ResponsiveContainer, XAxis, YAxis } from 'recharts'
import {
  FormTemplateStatus,
  FormWorkerRole,
  SitelineText,
  colors,
  makeStylesFast,
} from 'siteline-common-web'
import uniqolor from 'uniqolor'
import { CompanyAutocomplete } from '../../common/components/CompanyAutocomplete'
import Page from '../../common/components/Page'
import {
  FormTurnaroundDataQuery,
  useFormTurnaroundDataQuery,
  useSitelineTeamMembersQuery,
} from '../../common/graphql/apollo-operations'

const useStyles = makeStylesFast((theme: Theme) => ({
  root: {
    padding: theme.spacing(3),
    '& .chartLine path': {
      transition: theme.transitions.create('stroke-opacity'),
    },
    '& .section': {
      boxShadow: theme.shadows[2],
      borderRadius: 8,
      padding: theme.spacing(2),
      '& .inputRow': {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        marginTop: 16,
        gap: 16,
      },
    },
  },
  hoverLegend: {
    position: 'absolute',
    top: 48,
    right: 56,
    backgroundColor: colors.white,
    boxShadow: theme.shadows[2],
    borderRadius: 8,
    padding: theme.spacing(1, 1.5),
  },
  legend: {
    borderRadius: 8,
    padding: theme.spacing(2),
    boxShadow: theme.shadows[2],
    width: '100%',
    '& .heading': {
      marginBottom: theme.spacing(1),
    },
    '& .dot': {
      width: 16,
      height: 16,
      borderRadius: '50%',
    },
  },
  tooltip: {
    maxWidth: 500,
    maxHeight: 500,
    overflow: 'auto',
  },
  tooltipRow: {
    display: 'flex',
    justifyContent: 'space-between',
    cursor: 'pointer',
    '&:hover': {
      color: colors.blue30,
    },
  },
}))

gql`
  query formTurnaroundData($input: FormTurnaroundDataInput!) {
    formTurnaroundData(input: $input) {
      forms {
        startDate
        endDate
        formTemplate {
          id
          userVisibleName
          builderId
          requestingCompanyId
          validatorId
          ownerId
        }
      }
      companies {
        id
        name
      }
      users {
        id
        firstName
        lastName
      }
    }
  }
`

type FormTurnaroundData = FormTurnaroundDataQuery['formTurnaroundData']
type FormTurnaroundFormData = FormTurnaroundData['forms'][number]
type MinimalFormTemplate = FormTurnaroundFormData['formTemplate']

function getFormTurnaroundDays(turnaroundForm: FormTurnaroundFormData) {
  const timeZone = moment.tz.guess()
  const startDate = moment.tz(turnaroundForm.startDate, timeZone)
  const endDate = moment.tz(turnaroundForm.endDate, timeZone)
  return endDate.diff(startDate, 'days', true)
}

function formTemplateGroupingId(formTemplate: MinimalFormTemplate, groupByType: ViewByType) {
  switch (groupByType) {
    case 'Requesting company':
      return formTemplate.requestingCompanyId
    case 'Builder':
      return formTemplate.builderId
    case 'Validator':
      return formTemplate.validatorId
    case 'Owner':
      return formTemplate.ownerId
  }
}

function formWorkerRoleLabel(formWorkerRole: FormWorkerRole) {
  switch (formWorkerRole) {
    case FormWorkerRole.BUILDER:
      return 'Builder'
    case FormWorkerRole.VALIDATOR:
      return 'Validator'
    case FormWorkerRole.OWNER:
      return 'Owner'
  }
}

function formTemplateStatusLabel(status: FormTemplateStatus) {
  switch (status) {
    case FormTemplateStatus.BUILDING:
      return 'Building'
    case FormTemplateStatus.CANCELED:
      return 'Canceled'
    case FormTemplateStatus.COMPLETE:
      return 'Complete'
    case FormTemplateStatus.DUPLICATE:
      return 'Duplicate'
    case FormTemplateStatus.PREPARING_FOR_BUILD:
      return 'Preparing for build'
    case FormTemplateStatus.READY_FOR_BUILD:
      return 'Ready to build'
    case FormTemplateStatus.READY_FOR_VALIDATION:
      return 'Ready for validation'
    case FormTemplateStatus.VALIDATED:
      return 'Validated'
    case FormTemplateStatus.WAITING_ON_ORIGINAL_FILE:
      return 'Waiting on original file'
  }
}

type ViewByInterval = 'Day' | 'Week' | 'Month'
type ViewByType = 'Requesting company' | 'Owner' | 'Validator' | 'Builder'
const granularityByInterval = {
  Day: 'day' as const,
  Week: 'week' as const,
  Month: 'month' as const,
}
type FormWorkerRoleWithAny = FormWorkerRole | 'Any'

/** Dashboard for analyzing form template turnaround speed */
export function FormTemplateTurnaroundDashboard() {
  const classes = useStyles()
  const navigate = useNavigate()
  const location = useLocation()
  const { data: adminsData } = useSitelineTeamMembersQuery()
  const teamMembers = useMemo(
    () => _.orderBy(adminsData?.sitelineTeamMembers ?? [], (user) => user.firstName, ['asc']),
    [adminsData?.sitelineTeamMembers]
  )
  const timeZone = moment.tz.guess()

  const search = new URLSearchParams(location.search)
  const teamMemberId = search.get('teamMemberId')
  const teamMemberRole = (search.get('role') ?? 'Any') as FormWorkerRoleWithAny
  const requestingCompanyId = search.get('requestingCompanyId')

  const defaultStartDate = useMemo(() => moment.tz(timeZone).subtract(3, 'months'), [timeZone])
  const [startDate, setStartDate] = useState<Moment>(defaultStartDate)
  const [endDate, setEndDate] = useState<Moment>(moment.tz(timeZone))
  const [viewByInterval, setViewByInterval] = useState<ViewByInterval>('Week')
  const [groupByType, setGroupByType] = useState<ViewByType>('Requesting company')
  const [hoverGroupingId, setHoverGroupingId] = useState<string | null>(null)
  const [sortBy, setSortBy] = useState<'name' | 'turnaround-desc' | 'turnaround-asc'>(
    'turnaround-desc'
  )
  const [startStatus, setStartStatus] = useState<FormTemplateStatus | null>(null)
  const [endStatus, setEndStatus] = useState<FormTemplateStatus | null>(null)

  const handleSearchParamChange = useCallback(
    (param: string, value: string | null) => {
      const search = new URLSearchParams(location.search)
      if (value) {
        search.set(param, value)
      } else {
        search.delete(param)
      }
      navigate({ search: search.toString() })
    },
    [location.search, navigate]
  )

  const handleTeamMemberIdChange = useCallback(
    (teamMemberId: string | null) => handleSearchParamChange('teamMemberId', teamMemberId),
    [handleSearchParamChange]
  )
  const handleRoleChange = useCallback(
    (role: FormWorkerRoleWithAny) => handleSearchParamChange('role', role),
    [handleSearchParamChange]
  )
  const handleRequestingCompanyIdChange = useCallback(
    (companyId: string | null) => handleSearchParamChange('requestingCompanyId', companyId),
    [handleSearchParamChange]
  )

  const handleResetFilters = useCallback(() => {
    setStartDate(defaultStartDate)
    setEndDate(moment.tz(timeZone))
    setViewByInterval('Week')
    setGroupByType('Requesting company')
    setStartStatus(null)
    setEndStatus(null)
    // Need to do one single search param change, as otherwise they would conflict and the final
    // URL wouldn't reflect all the individual changes
    const search = new URLSearchParams(location.search)
    search.delete('teamMemberId')
    search.set('role', 'Any')
    search.delete('requestingCompanyId')
    navigate({ search: search.toString() })
  }, [defaultStartDate, location.search, navigate, timeZone])

  // Reset the role if the team member is cleared
  useEffect(() => {
    if (!teamMemberId) {
      handleRoleChange('Any')
    }
  }, [handleRoleChange, teamMemberId])

  const { data } = useFormTurnaroundDataQuery({
    variables: {
      input: {
        formWorkerId: teamMemberId ?? undefined,
        // Include role only if there's also a team member
        ...(teamMemberId && {
          formWorkerRole: teamMemberRole === 'Any' ? undefined : teamMemberRole,
        }),
        requestingCompanyId: requestingCompanyId ?? undefined,
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
        startStatus: startStatus || undefined,
        endStatus: endStatus || undefined,
      },
    },
  })
  const formTurnaroundForms = useMemo(
    () => data?.formTurnaroundData.forms ?? [],
    [data?.formTurnaroundData.forms]
  )

  const totalAverageTurnaround = useMemo(() => {
    return formTurnaroundForms.length
      ? _.round(_.meanBy(formTurnaroundForms, getFormTurnaroundDays), 1)
      : null
  }, [formTurnaroundForms])

  const dates = useMemo(() => {
    const numTicks = endDate.diff(startDate, granularityByInterval[viewByInterval])
    const tickRange = _.range(0, numTicks)
    return tickRange.map((tick, index) => {
      const range = startDate.clone().add(tick, granularityByInterval[viewByInterval])
      const startOfRange = range.clone().startOf(granularityByInterval[viewByInterval])
      const endOfRange = range.clone().endOf(granularityByInterval[viewByInterval])
      let formattedDate = ''
      switch (viewByInterval) {
        case 'Day':
          formattedDate =
            startOfRange.date() === 1 || index === tickRange.length - 1
              ? startOfRange.format('MMM D')
              : startOfRange.format('D')
          break
        case 'Week':
          formattedDate = `Week of ${startOfRange.format('MMM D')}`
          break
        case 'Month':
          formattedDate = startOfRange.format('MMM')
          break
      }
      return { startOfRange, endOfRange, formattedDate }
    })
  }, [endDate, startDate, viewByInterval])
  const ticks = useMemo(() => dates.map(({ formattedDate }) => formattedDate), [dates])
  const groupingLabels = useMemo(() => {
    switch (groupByType) {
      case 'Requesting company': {
        const companies = data?.formTurnaroundData.companies ?? []
        return _.zipObject(
          companies.map((company) => company.id),
          companies.map((company) => company.name)
        )
      }
      case 'Builder':
      case 'Validator':
      case 'Owner': {
        const users = data?.formTurnaroundData.users ?? []
        return _.zipObject(
          users.map((user) => user.id),
          users.map((user) => `${user.firstName} ${user.lastName}`)
        )
      }
    }
  }, [data?.formTurnaroundData.companies, data?.formTurnaroundData.users, groupByType])
  const groupingIds = useMemo(
    () =>
      _.chain(formTurnaroundForms)
        .map(({ formTemplate }) => formTemplateGroupingId(formTemplate, groupByType))
        .compact()
        .uniq()
        .value(),
    [formTurnaroundForms, groupByType]
  )
  const turnaroundByGroupingId = useMemo(
    () =>
      _.zipObject(
        groupingIds,
        groupingIds.map((id) => {
          const forms = formTurnaroundForms.filter(
            ({ formTemplate }) => formTemplateGroupingId(formTemplate, groupByType) === id
          )
          return forms.length ? _.round(_.meanBy(forms, getFormTurnaroundDays), 1) : 0
        })
      ),
    [formTurnaroundForms, groupingIds, groupByType]
  )
  const sortedGroupingIds = useMemo(
    () =>
      _.orderBy(
        groupingIds,
        (id) => {
          switch (sortBy) {
            case 'name':
              return _.get(groupingLabels, id, '')
            case 'turnaround-asc':
            case 'turnaround-desc':
              return _.get(turnaroundByGroupingId, id, 0)
          }
        },
        [sortBy === 'turnaround-desc' ? 'desc' : 'asc']
      ),
    [groupingIds, groupingLabels, sortBy, turnaroundByGroupingId]
  )
  const groupingColors = useMemo(
    () =>
      _.zipObject(
        groupingIds,
        groupingIds.map((id) => uniqolor(id, { lightness: 80 }).color)
      ),
    [groupingIds]
  )
  const lineChartData = useMemo(() => {
    return dates.map(({ startOfRange, endOfRange, formattedDate }) => {
      const formsInRange = formTurnaroundForms.filter((form) => {
        return moment.tz(form.startDate, timeZone).isBetween(startOfRange, endOfRange, 'day', '[]')
      })
      const groupedForms = _.groupBy(formsInRange, ({ formTemplate }) =>
        formTemplateGroupingId(formTemplate, groupByType)
      )
      const dataPoints: Record<string, number> = {}
      sortedGroupingIds.forEach((groupingId) => {
        const forms = _.get(groupedForms, groupingId, [])
        const averageTurnaround = forms.length ? _.meanBy(forms, getFormTurnaroundDays) : 0
        dataPoints[groupingId] = _.round(averageTurnaround, 1)
      })
      return {
        date: formattedDate,
        ...dataPoints,
      }
    })
  }, [dates, formTurnaroundForms, sortedGroupingIds, timeZone, groupByType])

  const makeFormStatusFilter = useCallback(
    (type: 'start' | 'end') => {
      let label: string
      let status: FormTemplateStatus | 'Created'
      switch (type) {
        case 'start':
          label = 'From status'
          status = startStatus || 'Created'
          break
        case 'end':
          label = 'To status'
          status = endStatus || FormTemplateStatus.COMPLETE
          break
      }
      const onStatusChange = type === 'start' ? setStartStatus : setEndStatus
      return (
        <FormControl>
          <InputLabel id={`${type}-status`}>{label}</InputLabel>
          <Select<FormTemplateStatus | 'Created'>
            labelId={`${type}-status`}
            value={status}
            label={label}
            style={{ maxHeight: 40, minWidth: 80 }}
            onChange={(ev) => {
              if (ev.target.value === 'Created') {
                onStatusChange(null)
                return
              }
              onStatusChange(ev.target.value as FormTemplateStatus)
            }}
          >
            {type === 'start' && <MenuItem value="Created">Created</MenuItem>}
            {Object.values(FormTemplateStatus).map((status) => (
              <MenuItem key={status} value={status}>
                {formTemplateStatusLabel(status)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )
    },
    [endStatus, startStatus]
  )

  return (
    <Page className={classes.root} title="Form turnaround dashboard">
      <div style={{ display: 'flex' }}>
        <Typography variant="h4">Form Turnaround Dashboard</Typography>
        <div style={{ flex: 1 }} />
        <Button
          variant="contained"
          color="secondary"
          style={{ justifySelf: 'flex-end' }}
          onClick={handleResetFilters}
        >
          Reset dashboard
        </Button>
      </div>
      <Grid2 container spacing={2}>
        <Grid2 size={{ xs: 12 }} sx={{ marginTop: 2 }}>
          <Card>
            <CardContent style={{ display: 'flex', flexDirection: 'column', rowGap: 16 }}>
              <div className="section">
                <SitelineText variant="body2" bold>
                  Filter by
                </SitelineText>
                <div className="inputRow">
                  <Autocomplete
                    size="small"
                    disablePortal
                    options={teamMembers.map((user) => user.id)}
                    value={teamMemberId}
                    onChange={(event, value) => handleTeamMemberIdChange(value)}
                    sx={{ width: 250 }}
                    getOptionLabel={(userId) => {
                      const user = teamMembers.find((user) => user.id === userId)
                      return user?.email ?? ''
                    }}
                    renderInput={(params) => <TextField {...params} label="Team member" />}
                  />
                  <FormControl disabled={!teamMemberId}>
                    <InputLabel id="role">Role</InputLabel>
                    <Select<FormWorkerRoleWithAny>
                      labelId="role"
                      value={teamMemberRole}
                      label="Role"
                      style={{ maxHeight: 40, minWidth: 80 }}
                      onChange={(ev) => handleRoleChange(ev.target.value as FormWorkerRoleWithAny)}
                    >
                      <MenuItem value="Any">Any role</MenuItem>
                      {Object.values(FormWorkerRole).map((type) => (
                        <MenuItem key={type} value={type}>
                          {formWorkerRoleLabel(type)}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <CompanyAutocomplete
                    size="small"
                    companyId={requestingCompanyId}
                    setCompanyId={(companyId) => {
                      handleRequestingCompanyIdChange(companyId)
                    }}
                    sx={{ width: 250 }}
                    label="Requesting company"
                  />
                  {makeFormStatusFilter('start')}
                  {makeFormStatusFilter('end')}
                </div>
              </div>
              <div className="section">
                <SitelineText variant="body2" bold>
                  View by
                </SitelineText>
                <div className="inputRow">
                  <DatePicker
                    label="Start date"
                    format="MM/DD/YYYY"
                    value={startDate}
                    onChange={(date) => {
                      if (!date) {
                        return
                      }
                      setStartDate(date)
                    }}
                    slotProps={{ textField: { size: 'small' } }}
                  />
                  <DatePicker
                    label="End date"
                    format="MM/DD/YYYY"
                    value={endDate}
                    onChange={(date) => {
                      if (!date) {
                        return
                      }
                      setEndDate(date)
                    }}
                    slotProps={{ textField: { size: 'small' } }}
                  />
                  <FormControl>
                    <InputLabel id="interval">Interval</InputLabel>
                    <Select
                      labelId="interval"
                      value={viewByInterval}
                      label="Interval"
                      style={{ maxHeight: 40 }}
                      onChange={(ev) => setViewByInterval(ev.target.value as ViewByInterval)}
                    >
                      {['Month', 'Week', 'Day'].map((type) => (
                        <MenuItem key={type} value={type}>
                          {type}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl>
                    <InputLabel id="groupByType">Group by</InputLabel>
                    <Select
                      labelId="groupByType"
                      value={groupByType}
                      label="groupBy"
                      style={{ maxHeight: 40 }}
                      onChange={(ev) => setGroupByType(ev.target.value as ViewByType)}
                    >
                      {['Requesting company', 'Owner', 'Builder', 'Validator'].map((type) => (
                        <MenuItem key={type} value={type}>
                          {type}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </div>
              <div style={{ position: 'relative' }}>
                <ResponsiveContainer height={500} width="100%" style={{ marginTop: 32 }}>
                  <LineChart data={lineChartData} margin={{ bottom: 32, left: 32, right: 32 }}>
                    <CartesianGrid />
                    <XAxis
                      dataKey="date"
                      ticks={ticks}
                      type="category"
                      domain={['dataMin', 'dataMax']}
                    />
                    <YAxis>
                      <Label
                        angle={-90}
                        value="Turnaround time (days)"
                        offset={8}
                        position="left"
                        orientation="vertical"
                      />
                    </YAxis>
                    {sortedGroupingIds.map((key) => (
                      <Line
                        key={key}
                        type="monotone"
                        dataKey={key}
                        dot={false}
                        className="chartLine"
                        // Generate a random color for each line
                        stroke={_.get(groupingColors, key, key)}
                        strokeOpacity={hoverGroupingId === key || !hoverGroupingId ? 1 : 0.1}
                        onMouseEnter={() => setHoverGroupingId(key)}
                        onMouseLeave={() => setHoverGroupingId(null)}
                        strokeWidth={5}
                      />
                    ))}
                  </LineChart>
                </ResponsiveContainer>
                <Fade in={hoverGroupingId !== null}>
                  <div>
                    {hoverGroupingId && (
                      <div className={classes.hoverLegend}>
                        {_.get(groupingLabels, hoverGroupingId, '')}
                      </div>
                    )}
                  </div>
                </Fade>
              </div>
              <div className={classes.legend}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <div>
                    <SitelineText variant="body1" bold className="heading">
                      Legend
                    </SitelineText>
                    <SitelineText
                      variant="body1"
                      color={totalAverageTurnaround ? 'grey90' : 'grey50'}
                      style={{ marginBottom: 8 }}
                    >
                      Total avg turnaround:{' '}
                      <span style={{ fontWeight: 600 }}>
                        {totalAverageTurnaround ? `${totalAverageTurnaround} days` : '–'}
                      </span>
                    </SitelineText>
                    {sortedGroupingIds.map((id) => {
                      const forms = _.chain(formTurnaroundForms)
                        .filter(
                          ({ formTemplate }) =>
                            formTemplateGroupingId(formTemplate, groupByType) === id
                        )
                        .orderBy((form) => _.round(getFormTurnaroundDays(form), 1), 'desc')
                        .value()
                      return (
                        <Tooltip
                          classes={{ tooltip: classes.tooltip }}
                          key={id}
                          title={
                            <div>
                              {forms.map((form) => {
                                return (
                                  <div
                                    key={form.formTemplate.id}
                                    className={classes.tooltipRow}
                                    onClick={() =>
                                      window.open(`/templates/${form.formTemplate.id}`, '_blank')
                                    }
                                  >
                                    <div
                                      style={{
                                        whiteSpace: 'pre',
                                        textOverflow: 'ellipsis',
                                        overflow: 'hidden',
                                        maxWidth: '75%',
                                        marginRight: 12,
                                      }}
                                    >
                                      {form.formTemplate.userVisibleName.trim()}
                                    </div>
                                    <div>{_.round(getFormTurnaroundDays(form), 1)} days</div>
                                  </div>
                                )
                              })}
                            </div>
                          }
                          placement="right"
                        >
                          <div
                            style={{ marginBottom: 4 }}
                            onMouseEnter={() => setHoverGroupingId(id)}
                            onMouseLeave={() => setHoverGroupingId(null)}
                          >
                            <SitelineText
                              variant="body1"
                              startIcon={
                                <div
                                  className="dot"
                                  style={{ backgroundColor: _.get(groupingColors, id, id) }}
                                />
                              }
                            >
                              <span style={{ fontWeight: 600 }}>
                                {_.get(groupingLabels, id, id)}
                              </span>
                              <span style={{ color: colors.grey50, marginLeft: 8 }}>
                                {_.get(turnaroundByGroupingId, id, 0)
                                  ? ` Avg turnaround: ${turnaroundByGroupingId[id]} days`
                                  : ''}
                              </span>
                            </SitelineText>
                          </div>
                        </Tooltip>
                      )
                    })}
                  </div>
                  <FormControl>
                    <InputLabel id="sortBy">Sort by</InputLabel>
                    <Select
                      labelId="sortBy"
                      value={sortBy}
                      label="Sort by"
                      onChange={(ev) =>
                        setSortBy(ev.target.value as 'name' | 'turnaround-asc' | 'turnaround-desc')
                      }
                    >
                      <MenuItem value="name">
                        {groupByType === 'Requesting company' ? 'Company name' : 'Name'}
                      </MenuItem>
                      <MenuItem value="turnaround-asc">Avg turnaround (asc)</MenuItem>
                      <MenuItem value="turnaround-desc">Avg turnaround (desc)</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>
            </CardContent>
          </Card>
        </Grid2>
      </Grid2>
    </Page>
  )
}
