import { ArrowCircleLeft, ArrowCircleRight } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import { ReactNode } from 'react'
import { SitelineText } from 'siteline-common-web'
import { CHART_ICON_FONT_SIZE } from './BillingTotalsDashboard'

interface ChartTitleAndArrowsProps {
  title: string | ReactNode
  startIndex: number
  onStartIndexChange: (startIndex: number) => void
  numCompaniesInChart: number
  numCompaniesPerPage: number
}

/** Header for a dashboard chart with title content and arrows for navigating through data */
export function ChartTitleAndArrows({
  title,
  startIndex,
  onStartIndexChange,
  numCompaniesInChart,
  numCompaniesPerPage,
}: ChartTitleAndArrowsProps) {
  const hasPreviousPage = startIndex > 0
  const hasNextPage = startIndex + numCompaniesPerPage < numCompaniesInChart

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        gap: 16,
        padding: '16px 24px',
      }}
    >
      {typeof title === 'string' && <SitelineText variant="h5">{title}</SitelineText>}
      {typeof title !== 'string' && title}
      <div style={{ flexGrow: 1 }} />
      <div style={{ display: 'flex' }}>
        <IconButton
          onClick={() => onStartIndexChange(Math.max(0, startIndex - numCompaniesPerPage))}
          disabled={!hasPreviousPage}
        >
          <ArrowCircleLeft style={{ fontSize: CHART_ICON_FONT_SIZE }} />
        </IconButton>
        <IconButton
          onClick={() =>
            onStartIndexChange(
              Math.min(startIndex + numCompaniesPerPage, numCompaniesInChart - numCompaniesPerPage)
            )
          }
          disabled={!hasNextPage}
        >
          <ArrowCircleRight style={{ fontSize: CHART_ICON_FONT_SIZE }} />
        </IconButton>
      </div>
    </div>
  )
}
