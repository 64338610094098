import { Reference, gql } from '@apollo/client'
import { AddOutlined } from '@mui/icons-material'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Menu,
  MenuItem,
  Switch,
  TextField,
} from '@mui/material'
import { bindMenu, bindToggle } from 'material-ui-popup-state'
import { usePopupState } from 'material-ui-popup-state/hooks'
import { useCallback, useEffect, useMemo, useState } from 'react'
import 'react-resizable/css/styles.css'
import { FormTemplateVariant, useSitelineSnackbar } from 'siteline-common-web'
import * as fragments from '../../../common/graphql/Fragments'
import {
  useDeleteTemplateVariantMutation,
  useTemplateVariantForAutocompleteQuery,
  useUpdateTemplateVariantMutation,
} from '../../../common/graphql/apollo-operations'
import { TemplateVariantSettingOverride } from './TemplateVariantSettingOverride'

gql`
  mutation updateTemplateVariant($input: UpdateTemplateVariantInput!) {
    updateTemplateVariant(input: $input) {
      ...FormTemplateVariantProperties
    }
  }
  ${fragments.formTemplateVariant}
`

gql`
  mutation deleteTemplateVariant($id: ID!) {
    deleteTemplateVariant(id: $id) {
      id
    }
  }
`

type EditTemplateVariantDialogProps = {
  variantId: string
  open: boolean
  onClose: () => void
  onDeleted: () => void
}

export function EditTemplateVariantDialog({
  variantId,
  open,
  onClose,
  onDeleted,
}: EditTemplateVariantDialogProps) {
  const popupState = usePopupState({ variant: 'popover', popupId: 'addOverride' })
  const { data } = useTemplateVariantForAutocompleteQuery({
    variables: { id: variantId },
  })

  const initialInternalName = useMemo(() => data?.formTemplateVariant.internalName ?? '', [data])
  const [internalName, setInternalName] = useState<string>(initialInternalName)

  const initialUserVisibleName = useMemo(
    () => data?.formTemplateVariant.userVisibleName ?? '',
    [data]
  )
  const [userVisibleName, setUserVisibleName] = useState<string>(initialUserVisibleName)

  const initialHidesZeroDollarAmounts = useMemo(
    () => data?.formTemplateVariant.hidesZeroDollarAmounts ?? null,
    [data]
  )
  const [hidesZeroDollarAmounts, setHidesZeroDollarAmounts] = useState<boolean | null>(
    initialHidesZeroDollarAmounts
  )

  const initialRoundPercentages = useMemo(
    () => data?.formTemplateVariant.roundPercentages ?? null,
    [data]
  )
  const [roundPercentages, setRoundPercentages] = useState<boolean | null>(initialRoundPercentages)

  const initialUseCompanyNotarySignatureIfAvailable = useMemo(
    () => data?.formTemplateVariant.useCompanyNotarySignatureIfAvailable ?? null,
    [data]
  )
  const [useCompanyNotarySignatureIfAvailable, setUseCompanyNotarySignatureIfAvailable] = useState<
    boolean | null
  >(initialUseCompanyNotarySignatureIfAvailable)

  const isDefaultVariant = data?.formTemplateVariant.isDefaultVariant ?? false

  const snackbar = useSitelineSnackbar()
  const [updateVariant] = useUpdateTemplateVariantMutation()
  const [deleteVariant] = useDeleteTemplateVariantMutation()

  // Whenever the initial user visible name changes, update the local state
  useEffect(() => {
    setInternalName(initialInternalName)
    setUserVisibleName(initialUserVisibleName)
    setHidesZeroDollarAmounts(initialHidesZeroDollarAmounts)
    setRoundPercentages(initialRoundPercentages)
    setUseCompanyNotarySignatureIfAvailable(initialUseCompanyNotarySignatureIfAvailable)
  }, [
    initialHidesZeroDollarAmounts,
    initialInternalName,
    initialRoundPercentages,
    initialUserVisibleName,
    initialUseCompanyNotarySignatureIfAvailable,
  ])

  const handleUpdate = useCallback(() => {
    const cleanInternalName = internalName.trim()
    const cleanUserVisibleName = userVisibleName.trim()
    if (internalName.length === 0) {
      return
    }

    snackbar.showLoading('Updating variant...')
    updateVariant({
      variables: {
        input: {
          id: variantId,
          internalName: cleanInternalName,
          userVisibleName: cleanUserVisibleName.length > 0 ? cleanUserVisibleName : null,
          hidesZeroDollarAmounts,
          roundPercentages,
          useCompanyNotarySignatureIfAvailable,
        },
      },
    })
      .then(() => {
        snackbar.showSuccess('Variant updated')
        onClose()
      })
      .catch((err) => {
        snackbar.showError(err.message)
      })
  }, [
    internalName,
    userVisibleName,
    snackbar,
    updateVariant,
    variantId,
    hidesZeroDollarAmounts,
    roundPercentages,
    onClose,
    useCompanyNotarySignatureIfAvailable,
  ])

  const handleDelete = useCallback(() => {
    if (!data?.formTemplateVariant) {
      return
    }
    const confirmed = window.confirm('Are you sure you want to delete this variant?')
    if (!confirmed) {
      return
    }

    snackbar.showLoading('Deleting variant...')
    deleteVariant({
      variables: { id: variantId },
      update: (cache) => {
        cache.modify({
          id: cache.identify(data.formTemplateVariant.template),
          fields: {
            variants(existing: readonly Reference[], { readField }) {
              return existing.filter((ref) => readField('id', ref) !== variantId)
            },
          },
        })
      },
    })
      .then(() => {
        snackbar.showSuccess('Variant deleted')
        onDeleted()
      })
      .catch((err) => {
        snackbar.showError(err.message)
      })
  }, [data?.formTemplateVariant, snackbar, deleteVariant, variantId, onDeleted])

  type AvailableOverride = {
    name: string
    key: keyof Pick<
      FormTemplateVariant,
      'hidesZeroDollarAmounts' | 'roundPercentages' | 'useCompanyNotarySignatureIfAvailable'
    >
    onClick: () => void
  }
  const availableOverrides = useMemo((): AvailableOverride[] => {
    const available: AvailableOverride[] = []

    if (hidesZeroDollarAmounts === null) {
      available.push({
        name: 'Hide zero-dollar amounts',
        key: 'hidesZeroDollarAmounts',
        onClick: () => setHidesZeroDollarAmounts(false),
      })
    }

    if (roundPercentages === null) {
      available.push({
        name: 'Round percentages',
        key: 'roundPercentages',
        onClick: () => setRoundPercentages(false),
      })
    }

    if (useCompanyNotarySignatureIfAvailable === null) {
      available.push({
        name: 'Skip notary flow',
        key: 'useCompanyNotarySignatureIfAvailable',
        onClick: () => setUseCompanyNotarySignatureIfAvailable(true),
      })
    }

    return available
  }, [hidesZeroDollarAmounts, roundPercentages, useCompanyNotarySignatureIfAvailable])

  const handleAvailableOverride = useCallback(
    (annotation: AvailableOverride) => {
      annotation.onClick()
      popupState.close()
    },
    [popupState]
  )

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Update variant</DialogTitle>
      <DialogContent>
        <TextField
          required
          fullWidth
          sx={{ marginTop: 1 }}
          value={internalName}
          onChange={(ev) => setInternalName(ev.target.value)}
          label="Internal name"
          helperText="E.g.: 'Notary', 'Blue fields', 'Required signature'"
          disabled={isDefaultVariant}
        />
        <TextField
          fullWidth
          sx={{ marginTop: 2 }}
          value={userVisibleName}
          onChange={(ev) => setUserVisibleName(ev.target.value)}
          label="User-visible name (optional)"
          helperText="Shows in the main app when signing forms. Falls back to template name if blank."
          disabled={isDefaultVariant}
        />
        {!isDefaultVariant && (
          <Button startIcon={<AddOutlined />} {...bindToggle(popupState)} sx={{ marginTop: 2 }}>
            Add override
          </Button>
        )}
        <Menu {...bindMenu(popupState)}>
          {availableOverrides.map((override) => (
            <MenuItem key={override.name} onClick={() => handleAvailableOverride(override)}>
              {override.name}
            </MenuItem>
          ))}
          {availableOverrides.length === 0 && (
            <MenuItem disabled>No more overrides available</MenuItem>
          )}
        </Menu>
        {hidesZeroDollarAmounts !== null && (
          <TemplateVariantSettingOverride
            canRemove={!isDefaultVariant}
            onRemove={() => setHidesZeroDollarAmounts(null)}
          >
            <FormControlLabel
              control={
                <Switch
                  checked={hidesZeroDollarAmounts}
                  onChange={(ev, checked) => setHidesZeroDollarAmounts(checked)}
                />
              }
              label="Hide zero-dollar amounts"
            />
          </TemplateVariantSettingOverride>
        )}
        {roundPercentages !== null && (
          <TemplateVariantSettingOverride
            canRemove={!isDefaultVariant}
            onRemove={() => setRoundPercentages(null)}
          >
            <FormControlLabel
              control={
                <Switch
                  checked={roundPercentages}
                  onChange={(ev, checked) => setRoundPercentages(checked)}
                />
              }
              label="Round all percentages (excl. taxes / fees)"
            />
          </TemplateVariantSettingOverride>
        )}
        {useCompanyNotarySignatureIfAvailable !== null && (
          <TemplateVariantSettingOverride
            canRemove={!isDefaultVariant}
            onRemove={() => setUseCompanyNotarySignatureIfAvailable(null)}
          >
            <FormControlLabel
              control={
                <Switch
                  checked={useCompanyNotarySignatureIfAvailable}
                  onChange={(ev, checked) => setUseCompanyNotarySignatureIfAvailable(checked)}
                />
              }
              label="Skip notary flow if company has notary signature"
            />
          </TemplateVariantSettingOverride>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleDelete} color="error" disabled={isDefaultVariant}>
          Delete variant
        </Button>
        <Button onClick={handleUpdate}>Update variant</Button>
      </DialogActions>
    </Dialog>
  )
}
