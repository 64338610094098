import { StoredFileType } from '../enums.js'

// List taken from:
// https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Complete_list_of_MIME_types
export const ACCEPTED_FILE_TYPES: Partial<Record<string, StoredFileType>> = {
  'application/msword': StoredFileType.DOC,
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': StoredFileType.DOCX,
  'image/jpeg': StoredFileType.JPEG,
  'image/png': StoredFileType.PNG,
  'application/pdf': StoredFileType.PDF,
  'application/vnd.ms-excel': StoredFileType.XLS,
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': StoredFileType.XLSX,
}
