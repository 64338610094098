import * as developmentConfig from '../../common/config/config.development'
import * as productionConfig from '../../common/config/config.production'
import * as stagingConfig from '../../common/config/config.staging'

const apiEnvFromMeta = import.meta.env.VITE_API_ENV
export const API_ENV = typeof apiEnvFromMeta === 'string' ? apiEnvFromMeta : 'local'

const sentryReleaseFromMeta = import.meta.env.VITE_SENTRY_RELEASE
export const SENTRY_RELEASE =
  typeof sentryReleaseFromMeta === 'string' ? sentryReleaseFromMeta : undefined

let apiConfig
if (API_ENV === 'production') {
  apiConfig = productionConfig
} else if (API_ENV === 'staging') {
  apiConfig = stagingConfig
} else {
  apiConfig = developmentConfig
}

export const {
  apiBaseUrl,
  firebaseConfig,
  googleMapsApiKey,
  pdfBaseUrl,
  reportingBaseUrl,
  subscriptionsBaseUrl,
  logRocketApiUrl,
  sentryTunnel,
} = apiConfig

export const sentryDsn = 'https://a3efd857c49e48eeb384ff8ad13316ca@759ed53.siteline.com/5375023'
export const logRocketProject = '1lnfgz/siteline-admin'

export const IS_DEV = import.meta.env.DEV
const webProtocol = IS_DEV ? 'http' : 'https'
const socketsProtocol = IS_DEV ? 'ws' : 'wss'
export const config = {
  url: {
    GRAPHQL_API_URL: `${webProtocol}://${apiBaseUrl}/graphql`,
    SUBSCRIPTIONS_URL: `${socketsProtocol}://${subscriptionsBaseUrl}/subscriptions`,
  },
  googleMapsApiKey,
}
