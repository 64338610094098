import { gql } from '@apollo/client'
import { Paid } from '@mui/icons-material'
import { Button, Card, CardActions, CardContent, CardHeader, Typography } from '@mui/material'
import { useCallback, useState } from 'react'
import { useSitelineSnackbar } from 'siteline-common-web'
import { useMarkOldSubmittedPayAppsAsPaidMutation } from '../../common/graphql/apollo-operations'
import DemoCompanySelector from './DemoCompanySelector'

gql`
  mutation markOldSubmittedPayAppsAsPaid($input: MarkOldSubmittedPayAppsAsPaidInput!) {
    markOldSubmittedPayAppsAsPaid(input: $input)
  }
`

/**
 * Demo tool to mark old pay apps as paid
 */
export default function DemoToolsMarkPayAppsAsPaid() {
  const snackbar = useSitelineSnackbar()
  const [companyId, setCompanyId] = useState<string | null>(null)
  const [markOldSubmittedPayAppsAsPaid] = useMarkOldSubmittedPayAppsAsPaidMutation()

  const handleMarkOldSubmittedPayAppsAsPaid = useCallback(() => {
    if (!companyId) {
      return
    }
    const confirmed = window.confirm('Are you sure you want to mark old pay apps as paid?')
    if (!confirmed) {
      return
    }
    snackbar.showLoading('Marking old pay apps as paid')
    markOldSubmittedPayAppsAsPaid({
      variables: {
        input: { companyId },
      },
    })
      .then(() => snackbar.showSuccess())
      .catch((err) => snackbar.showError(err.message))
  }, [companyId, markOldSubmittedPayAppsAsPaid, snackbar])

  return (
    <Card>
      <CardHeader title="Mark as paid" />
      <CardContent>
        <Typography>
          Find all pay apps that were submitted &gt;75 days ago and mark them as paid with
          today&apos;s date.
        </Typography>
        <DemoCompanySelector companyId={companyId} onCompanyIdChange={setCompanyId} />
      </CardContent>
      <CardActions>
        <Button
          startIcon={<Paid />}
          disabled={!companyId}
          onClick={handleMarkOldSubmittedPayAppsAsPaid}
        >
          Mark old pay apps as paid
        </Button>
      </CardActions>
    </Card>
  )
}
