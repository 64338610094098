import { Container, Grid2, Theme, Typography } from '@mui/material'
import { makeStylesFast } from 'siteline-common-web'
import CompanyCard from '../../../common/components/CompanyCard'
import Page from '../../../common/components/Page'
import * as fragments from '../../../common/graphql/Fragments'
import { BillingType, CompanyProperties } from '../../../common/graphql/apollo-operations'
import { ContractPayApp, ProjectForSubcontractor } from '../ContractDetails'
import ContractDetailsPayAppRequirements from '../ContractDetailsPayAppRequirements'
import ContractDetailsPayApps from '../ContractDetailsPayApps'
import ContractDetailsUsers from '../ContractDetailsUsers'
import ContractDetailsInfo from '../lump-sum/ContractDetailsInfo'

const useStyles = makeStylesFast((theme: Theme) => ({
  root: {
    padding: theme.spacing(3, 0),
    '& .MuiCard-root': {
      marginBottom: theme.spacing(2),
    },
  },
}))

type ContractDetailsQuickBillProps = {
  company: CompanyProperties
  project: ProjectForSubcontractor
  payApps: ContractPayApp[]
  contract: fragments.Contract
}

export default function ContractDetailsQuickBill({
  company,
  project,
  payApps,
  contract,
}: ContractDetailsQuickBillProps) {
  const classes = useStyles()
  const pageTitle = `${project.name} - ${company.name}`
  return (
    <Page className={classes.root} title={pageTitle}>
      <Container maxWidth={false}>
        <Grid2 container spacing={2} className="grid">
          <Grid2 size={{ xs: 12 }}>
            <Typography variant="h4">{pageTitle}</Typography>
          </Grid2>
          <Grid2 size={{ xs: 6 }}>
            <CompanyCard company={company} />
          </Grid2>
          <Grid2 size={{ xs: 6 }}>
            <ContractDetailsInfo contract={contract} project={project} company={company} />
            <ContractDetailsPayAppRequirements
              contractId={contract.id}
              billingType={BillingType.QUICK}
              usesManualStoredMaterials={false}
              payAppRequirementGroups={[...contract.payAppRequirementGroups]}
            />
          </Grid2>
        </Grid2>
        <ContractDetailsPayApps payApps={payApps} timeZone={contract.timeZone} />
        <ContractDetailsUsers contract={contract} />
      </Container>
    </Page>
  )
}
