import { Button, Link, TableCell, TableRow, Typography } from '@mui/material'
import moment from 'moment-timezone'
import { Link as RouterLink } from 'react-router-dom'
import { SitelineText, colorStyles } from 'siteline-common-web'
import { ListFormTemplate } from '../../common/graphql/Fragments'
import { finishedFormTemplateStatus } from '../../common/util/FormTemplate'
import { FormTemplateStatusChip } from '../FormTemplateStatusChip'
import { getWaitingOnUserId } from './FormTemplateList'

type FormTemplateListRowProps = {
  formTemplate: ListFormTemplate
  onViewTemplate: () => void
}

/**
 * A single template row in the list of templates.
 */
export function FormTemplateListRow({ formTemplate, onViewTemplate }: FormTemplateListRowProps) {
  let dueDateColor: keyof typeof colorStyles = 'grey90'
  let dueDate: moment.Moment | null = null

  if (formTemplate.dueDate) {
    dueDate = moment.tz(formTemplate.dueDate, moment.tz.guess())

    // Only color the due date if the template is incomplete
    const completed = finishedFormTemplateStatus.includes(formTemplate.status)
    if (!completed) {
      const diff = dueDate.diff(moment.utc(), 'days')
      if (diff <= 3) {
        dueDateColor = 'red70'
      } else if (diff <= 7) {
        dueDateColor = 'yellow50'
      }
    }
  }

  const waitingOnUserId = getWaitingOnUserId(formTemplate)

  const builderFontWeight = formTemplate.builder.id === waitingOnUserId ? 700 : 400
  const validatorFontWeight = formTemplate.validator?.id === waitingOnUserId ? 700 : 400
  const ownerFontWeight = formTemplate.owner.id === waitingOnUserId ? 700 : 400

  return (
    <TableRow hover key={formTemplate.id}>
      <TableCell>
        <Link component={RouterLink} to={`/templates/${formTemplate.id}`} underline="hover">
          {formTemplate.userVisibleName}
        </Link>
      </TableCell>
      <TableCell>
        <FormTemplateStatusChip
          status={formTemplate.status}
          skippedValidation={formTemplate.skippedValidation}
        />
      </TableCell>
      <TableCell>
        {formTemplate.requestingCompany && (
          <Link
            component={RouterLink}
            to={`/companies/${formTemplate.requestingCompany.id}`}
            underline="hover"
          >
            {formTemplate.requestingCompany.name}
          </Link>
        )}
      </TableCell>
      <TableCell>
        <Typography variant="body2" fontWeight={ownerFontWeight}>
          {formTemplate.owner.firstName}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2" fontWeight={builderFontWeight}>
          {formTemplate.builder.firstName}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2" fontWeight={validatorFontWeight}>
          {formTemplate.validator?.firstName ?? ''}
        </Typography>
      </TableCell>
      <TableCell>
        {
          <SitelineText variant="body2">
            {moment.tz(formTemplate.createdAt, moment.tz.guess()).format('MM/DD/YYYY')}
          </SitelineText>
        }
      </TableCell>
      <TableCell>
        {dueDate ? (
          <SitelineText variant="body2" color={dueDateColor}>
            {dueDate.format('MM/DD/YYYY')}
          </SitelineText>
        ) : (
          ''
        )}
      </TableCell>
      <TableCell align="right">
        <Button
          size="small"
          variant="text"
          color="primary"
          onClick={() => onViewTemplate()}
          sx={{ whiteSpace: 'nowrap' }}
        >
          View
        </Button>
      </TableCell>
    </TableRow>
  )
}
