import { gql } from '@apollo/client'
import { Alert, Container, Grid2, Theme, Typography } from '@mui/material'
import { useParams } from 'react-router-dom'
import { makeStylesFast } from 'siteline-common-web'
import { Loader } from '../../common/components/Loader'
import Page from '../../common/components/Page'
import * as fragments from '../../common/graphql/Fragments'
import { useGetCompanyForDetailsQuery } from '../../common/graphql/apollo-operations'
import CompanyDetailsContent from '../../components/company-details/CompanyDetailsContent'

gql`
  query getCompanyForDetails($companyId: ID!) {
    company(id: $companyId) {
      ...CompanyForDetailsProperties
    }
  }
  ${fragments.companyForDetails}
`

const useStyles = makeStylesFast((theme: Theme) => ({
  root: {
    padding: theme.spacing(3, 0),
  },
}))

type CompanyDetailsParams = {
  companyId?: string
}

export default function CompanyDetails() {
  const classes = useStyles()
  const { companyId } = useParams() as CompanyDetailsParams

  if (!companyId) {
    throw new Error('No company ID provided')
  }

  const { data, error, loading } = useGetCompanyForDetailsQuery({
    variables: { companyId },
  })

  const title = data?.company.name ?? 'Company'

  return (
    <Page className={classes.root} title={title}>
      <Container maxWidth={false}>
        <Grid2 container spacing={2}>
          <Grid2 size={{ xs: 12 }}>
            <Typography variant="h4">{title}</Typography>
          </Grid2>
          {data?.company && <CompanyDetailsContent company={data.company} />}
        </Grid2>
        {error && <Alert severity="error">{error.message}</Alert>}
        {loading && <Loader />}
      </Container>
    </Page>
  )
}
