import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import {
  Alert,
  Button,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material'
import _ from 'lodash'
import moment from 'moment-timezone'
import { DAY_FORMAT } from 'siteline-common-all'
import { makeStylesFast, useSitelineSnackbar } from 'siteline-common-web'
import { TemplateAutocomplete } from '../../common/components/TemplateAutocomplete'
import * as fragments from '../../common/graphql/Fragments'
import {
  DocumentExpirationFrequency,
  FormTemplateType,
  IntegrationType,
} from '../../common/graphql/apollo-operations'
import { getIntegration, hasIntegration } from '../../common/util/Integration'
import { isStandardLegalRequirement } from '../../common/util/LegalRequirement'
import { getTimeNoTimeZone } from '../../common/util/Moment'
import { LegalRequirementData } from '../contract-details/lump-sum/ContractDetailsLegalRequirements'
import { LegalRequirementRowReadOnly } from './LegalRequirementRowReadOnly'

const useStyles = makeStylesFast(() => ({
  cardContent: {
    padding: 0,
    '&:last-child': {
      padding: 0,
    },
  },
  footerRow: {
    border: 'none',
  },
}))

function emptyLegalRequirementData(): LegalRequirementData {
  return {
    createdAt: getTimeNoTimeZone(),
    name: '',
    expirationFrequency: DocumentExpirationFrequency.MONTHLY,
    hasDocuments: false,
  }
}

interface LegalRequirementsProps {
  legalRequirements: LegalRequirementData[]
  setLegalRequirements: (selectedRequests: LegalRequirementData[]) => void
  isEditing: boolean
  contract: fragments.Contract
  save: () => Promise<void>
  onCancel: () => void
}

export default function LegalRequirements({
  legalRequirements: unsortedLegalRequirements,
  setLegalRequirements,
  isEditing,
  contract,
  save,
  onCancel,
}: LegalRequirementsProps) {
  const classes = useStyles()
  const snackbar = useSitelineSnackbar()
  const legalRequirements = _.orderBy(unsortedLegalRequirements, [(req) => req.createdAt], ['asc'])
  const hasGcPayIntegration = hasIntegration(contract, IntegrationType.GC_PAY)
  const texturaIntegration = getIntegration(contract, IntegrationType.TEXTURA)

  const removeRequirement = (index: number) => {
    const requirements = [...legalRequirements]
    requirements.splice(index, 1)
    setLegalRequirements(requirements)
  }

  const shouldShowStartDate = (requirement: LegalRequirementData) => {
    const isStandardRequirement = isStandardLegalRequirement(requirement.expirationFrequency)
    // Standard requirements don't need a start date
    return !isStandardRequirement
  }

  const isValidStartDate = (
    expirationFrequency: DocumentExpirationFrequency,
    newStartDateString?: string | null
  ) => {
    if (!newStartDateString) {
      return true
    }
    const startDate = moment.tz(newStartDateString, contract.timeZone)
    if (expirationFrequency === DocumentExpirationFrequency.MONTHLY) {
      if (startDate.date() >= 29) {
        snackbar.showError(
          'For monthly requirements with a start date on the 29th, 30th, or 31st, choose END_OF_MONTH instead.'
        )
        return false
      }
    }
    if (expirationFrequency === DocumentExpirationFrequency.QUARTERLY) {
      if (startDate.date() >= 29) {
        snackbar.showError(
          'Quarterly requirements with a start date on the 29th, 30th, or 31st, are not supported.'
        )
        return false
      }
    }
    return true
  }

  return (
    <Card>
      <CardHeader
        title="Legal Requirements"
        action={
          <Button color="primary" onClick={() => onCancel()}>
            {isEditing ? 'Cancel' : 'Edit'}
          </Button>
        }
      />
      {hasGcPayIntegration && (
        <CardContent>
          <Alert severity="warning">
            Legal Requirement name must match the GCPay compliance <code>Description</code>
            column. If <code>Description</code> column is empty, then the Legal Requirement name
            must match the <code>Type</code> column.
          </Alert>
        </CardContent>
      )}
      <CardContent className={classes.cardContent}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Legal Requirement</TableCell>
              <TableCell>Frequency</TableCell>
              {texturaIntegration && !isEditing && <TableCell>Recurring on Textura</TableCell>}
              <TableCell>Start date</TableCell>
              <TableCell>End date</TableCell>
              <TableCell>Form Template</TableCell>
              {texturaIntegration && !isEditing && (
                <TableCell>Textura Document Requirement Id</TableCell>
              )}
              {!isEditing && <TableCell style={{ width: '200px' }}>Actions</TableCell>}
              {isEditing && <TableCell>Delete</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {!isEditing &&
              legalRequirements.map((requirement, index) => (
                <LegalRequirementRowReadOnly
                  key={index}
                  requirement={requirement}
                  timeZone={contract.timeZone}
                  texturaIntegration={texturaIntegration}
                />
              ))}
            {isEditing &&
              legalRequirements.map((requirement, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <TextField
                      placeholder="Name"
                      value={requirement.name}
                      onChange={(ev) => {
                        const requirements = [...legalRequirements]
                        requirements[index].name = ev.target.value
                        setLegalRequirements(requirements)
                      }}
                      style={{ minWidth: 250 }}
                    />
                  </TableCell>
                  <TableCell>
                    {requirement.hasDocuments ? (
                      // Don't allow editing the expiration frequency if there are already documents
                      requirement.expirationFrequency
                    ) : (
                      <Select
                        value={requirement.expirationFrequency}
                        onChange={(ev) => {
                          const newFrequency = ev.target.value as DocumentExpirationFrequency
                          if (!isValidStartDate(newFrequency, requirement.startDate)) {
                            return
                          }
                          const requirements = [...legalRequirements]
                          requirements[index].expirationFrequency = newFrequency
                          if (isStandardLegalRequirement(newFrequency)) {
                            requirements[index].startDate = null
                          }
                          setLegalRequirements(requirements)
                        }}
                      >
                        {Object.keys(DocumentExpirationFrequency).map((frequency) => (
                          <MenuItem value={frequency} key={frequency}>
                            {frequency}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  </TableCell>
                  <TableCell>
                    {shouldShowStartDate(requirement) && (
                      <TextField
                        type="date"
                        value={moment
                          .tz(requirement.startDate ?? '', contract.timeZone)
                          .format(DAY_FORMAT)}
                        onChange={(ev) => {
                          const newStartDate = ev.target.value
                          if (!isValidStartDate(requirement.expirationFrequency, newStartDate)) {
                            return
                          }
                          const requirements = [...legalRequirements]
                          requirements[index].startDate = newStartDate
                          setLegalRequirements(requirements)
                        }}
                        slotProps={{
                          inputLabel: {
                            shrink: true,
                          },
                        }}
                      />
                    )}
                  </TableCell>
                  <TableCell>
                    <TextField
                      type="date"
                      value={moment
                        .tz(requirement.endDate ?? '', contract.timeZone)
                        .format(DAY_FORMAT)}
                      onChange={(ev) => {
                        const requirements = [...legalRequirements]
                        requirements[index].endDate = ev.target.value as DocumentExpirationFrequency
                        setLegalRequirements(requirements)
                      }}
                      slotProps={{
                        inputLabel: {
                          shrink: true,
                        },
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <TemplateAutocomplete
                      disabled={!isEditing}
                      type={FormTemplateType.LEGAL_DOCUMENT}
                      template={requirement.formTemplateId ?? null}
                      onTemplateChange={(value) => {
                        const requirements = [...legalRequirements]
                        requirements[index] = {
                          ...requirements[index],
                          formTemplateId: value?.id ?? null,
                        }
                        setLegalRequirements(requirements)
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <IconButton onClick={() => removeRequirement(index)} size="large">
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
          {isEditing && (
            <TableFooter>
              <TableRow>
                <TableCell colSpan={5}>
                  <Button
                    startIcon={<AddIcon />}
                    size="small"
                    onClick={() =>
                      setLegalRequirements([...legalRequirements, emptyLegalRequirementData()])
                    }
                  >
                    Add Legal Requirement
                  </Button>
                </TableCell>
                <TableCell align="right">
                  <Button color="primary" variant="contained" onClick={save}>
                    Save
                  </Button>
                </TableCell>
              </TableRow>
            </TableFooter>
          )}
        </Table>
      </CardContent>
    </Card>
  )
}
