import { gql } from '@apollo/client'
import _ from 'lodash'
import { useMemo, useState } from 'react'
import { MonthlyBillingType } from 'siteline-common-all'
import { BillingType, useSitelineSnackbar } from 'siteline-common-web'
import * as fragments from '../../common/graphql/Fragments'
import { DetailedCompany } from '../../common/graphql/Fragments'
import { useUpdateDefaultPayAppFormsMutation } from '../../common/graphql/apollo-operations'
import PayAppRequirementGroups, {
  PayAppRequirementGroupWithKey,
} from '../pay-app-requirement/PayAppRequirementGroups'

gql`
  mutation updateDefaultPayAppForms($input: UpdateDefaultPayAppFormsInput!) {
    updateDefaultPayAppForms(input: $input) {
      ...CompanyForDetailsProperties
    }
  }
  ${fragments.companyForDetails}
`

// Component to update or view the default company pay app requirements for the given billing type
export function CompanyDetailsPayAppRequirements({
  company,
  billingType,
  usesManualStoredMaterials,
}: {
  company: DetailedCompany
  billingType: MonthlyBillingType
  usesManualStoredMaterials: boolean
}) {
  const [updatePayAppGroups] = useUpdateDefaultPayAppFormsMutation()
  const snackbar = useSitelineSnackbar()

  const payAppRequirementGroups = useMemo(() => {
    switch (billingType) {
      case BillingType.LUMP_SUM:
        return company.defaultLumpSumRequirements ?? []
      case BillingType.UNIT_PRICE:
        return company.defaultUnitPriceRequirements ?? []
      case BillingType.TIME_AND_MATERIALS:
        return company.defaultTimeAndMaterialsRequirements ?? []
    }
  }, [
    billingType,
    company.defaultLumpSumRequirements,
    company.defaultUnitPriceRequirements,
    company.defaultTimeAndMaterialsRequirements,
  ])

  const orderedRequirementGroups = _.chain([...payAppRequirementGroups])
    .orderBy((a) => a.order, 'asc')
    .map((requirementGroup): PayAppRequirementGroupWithKey => {
      return {
        id: requirementGroup.id,
        key: requirementGroup.id,
        order: requirementGroup.order,
        requirements: requirementGroup.payAppRequirements.map((requirement) => ({
          id: requirement.id,
          key: requirement.id,
          groupOrder: requirement.groupOrder,
          templateVariantId: requirement.templateVariant?.id ?? null,
          conditions: requirement.conditions,
        })),
      }
    })
    .value()
  const [newPayAppRequirementGroups, setNewPayAppRequirementGroups] =
    useState<PayAppRequirementGroupWithKey[]>(orderedRequirementGroups)

  const [isEditing, setIsEditing] = useState(false)

  const handleSave = async () => {
    const requirementGroups = newPayAppRequirementGroups.map((group) => ({
      ..._.omit(group, 'key'),
      requirements: group.requirements.map((req) => _.omit(req, 'key')),
    }))
    setIsEditing(false)

    await updatePayAppGroups({
      variables: {
        input: {
          companyId: company.id,
          payAppRequirementGroups: requirementGroups,
          billingType,
        },
      },
    }).catch((err) => snackbar.showError(err.message))
  }

  const onCancel = () => {
    if (isEditing) {
      setNewPayAppRequirementGroups(_.cloneDeep(orderedRequirementGroups))
    }
    setIsEditing(!isEditing)
  }

  return (
    <PayAppRequirementGroups
      title={`Default ${billingType} Pay App Requirement Groups`}
      billingType={billingType}
      usesManualStoredMaterials={usesManualStoredMaterials}
      payAppRequirementGroups={newPayAppRequirementGroups}
      setPayAppRequirementGroups={setNewPayAppRequirementGroups}
      isEditing={isEditing}
      save={handleSave}
      onCancel={onCancel}
    />
  )
}
