import type { Moment } from 'moment-timezone'
import { BillingType, LienWaiverType, PayAppStatus } from '../enums.js'

/**
 * Generates a filename from a list of components.
 * Invalid characters are removed from each component. Valid characters are a-z, A-Z, 0-9.
 * Components are trimmed and are removed if empty.
 *
 * Examples:
 *  - ['foo', 'bar'] => 'foo_bar'
 *  - ['fo o', 'bar'] => 'foo_bar'
 *  - ['foo', 'bar'] and 'pdf' => 'foo_bar.pdf'
 *  - ['', 'foo', 'bar'] => 'foo_bar'
 */

export function getFilename(components: string[], extension?: string): string {
  const escaped = components
    .map((str) => str.trim())
    .filter((str) => str.length > 0)
    .map((str) => str.replace(/[\W_]/g, ''))
  return `${escaped.join('_')}${extension ? `.${extension}` : ''}`
}

/**
 * Generates a PDF filename with the provided argument string.
 * Invalid characters are removed. Valid characters are a-z, A-Z, 0-9, dashes and periods.
 * Replaces spaces with undersores.
 * Adds ".pdf" to the end of the filename unless it's already there.
 *
 * Examples:
 *  - 'foo bar' => 'foo_bar.pdf'
 *  - 'fo%obar' => 'foobar.pdf'
 *  - 'foobar.xlsx' => 'foobar_xlsx.pdf' // Note: this function is for PDFs
 *  - 'foobar.pdf' => 'foobar.pdf'
 */

export function formatPdfFilename(unformattedFileName: string): string {
  const trimmed = unformattedFileName.trim()
  const withoutExtension = trimmed.endsWith('.pdf') ? trimmed.slice(0, -4) : trimmed
  const components = withoutExtension.split(/[_ ]/)
  const escaped = components
    .map((str) => str.trim())
    .filter((str) => str.length > 0)
    .map((str) => str.replace(/[.]/g, '_'))
    .map((str) => str.replace(/[^\w-]/g, ''))
  return `${escaped.join('_')}.pdf`
}

/**
 * Returns the prefix of a lien waiver depending on its type.
 * Eg: unconditional final: UFLW.
 */
export function getLienWaiverPrefix(type: LienWaiverType): string {
  switch (type) {
    case LienWaiverType.CONDITIONAL_PROGRESS_PAYMENT:
      return 'CPLW'
    case LienWaiverType.CONDITIONAL_FINAL_PAYMENT:
      return 'CFLW'
    case LienWaiverType.UNCONDITIONAL_PROGRESS_PAYMENT:
      return 'UPLW'
    case LienWaiverType.UNCONDITIONAL_FINAL_PAYMENT:
      return 'UFLW'
  }
}

type GetLienWaiverFilenameFromComponentsParams = {
  type: LienWaiverType
  claimant: string
  projectName: string
  date: Moment
}

/**
 * Returns a filename from components for a lien-waiver PDF package.
 * Example: UPLW_ABCPlumbers_EmpireStateBuilding_Mar2020.pdf
 */
export function getLienWaiverFilenameFromComponents({
  type,
  claimant,
  projectName,
  date,
}: GetLienWaiverFilenameFromComponentsParams): string {
  const prefix = getLienWaiverPrefix(type)
  const formattedDate = date.format('MMMYYYY')
  return getFilename([prefix, claimant, projectName, formattedDate], 'pdf')
}

type GetPayAppFilenameFromComponentsParams = {
  status: PayAppStatus
  billingEnd: Moment
  billingType: BillingType
  companyName: string
  projectName: string
  projectNumber: string
  /** Extension is not included if not provided */
  extension?: string
}

/**
 * Returns a filename from components for a pay app PDF package.
 * Example: 1234_DRAFT_PayApp_EmpireStateBuilding_ABCPlumbers_Feb2020.pdf
 */
export function getPayAppFilenameFromComponents({
  companyName,
  billingType,
  projectName,
  projectNumber,
  status,
  billingEnd,
  extension,
}: GetPayAppFilenameFromComponentsParams): string {
  const date = billingEnd.clone().format('MMMYYYY')
  const isDraft = status === PayAppStatus.DRAFT || status === PayAppStatus.SYNC_FAILED
  const prefix = isDraft ? 'DRAFT' : ''

  let invoicePrefix: string
  switch (billingType) {
    case BillingType.UNIT_PRICE:
    case BillingType.LUMP_SUM:
    case BillingType.TIME_AND_MATERIALS:
      invoicePrefix = 'PayApp'
      break
    case BillingType.QUICK:
      invoicePrefix = 'Invoice'
      break
  }

  return getFilename(
    [projectNumber, prefix, invoicePrefix, projectName, companyName, date],
    extension
  )
}
