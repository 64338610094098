import { gql } from '@apollo/client'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Link,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@mui/material'
import _ from 'lodash'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import { CacheType, FormTemplateType } from 'siteline-common-all'
import { makeStylesFast, useSitelineSnackbar } from 'siteline-common-web'
import {
  EditableCardRowDate,
  EditableCardRowNumber,
  EditableCardRowSelect,
  EditableCardRowText,
} from '../../../common/components/EditableCardRow'
import { EditableFormTemplateCardRow } from '../../../common/components/EditableFormTemplateCardRow'
import IdentifierRow, { IdentifierIconButton } from '../../../common/components/IdentifierRow'
import * as fragments from '../../../common/graphql/Fragments'
import {
  CompanyProperties,
  CompanySovProperties,
  ContractStatus,
  LocationProperties,
  UpdateChangeOrderLogFormInput,
  UpdateChangeOrderRequestFormInput,
  useFinishedOnboardingFormsMutation,
  useUpdateChangeOrderLogFormMutation,
  useUpdateChangeOrderRequestFormMutation,
  useUpdateContractMutation,
} from '../../../common/graphql/apollo-operations'
import { formatLocationOneLine } from '../../../common/util/Location'
import { ProjectForSubcontractor } from '../ContractDetails'

const useStyles = makeStylesFast(() => ({
  card: {
    paddingBottom: 0,
  },
  cardContent: {
    padding: 0,
    '&:last-child': {
      padding: 0,
    },
  },
  nameCell: {
    display: 'flex',
    alignItems: 'center',
  },
  picture: {
    height: 200,
  },
  actions: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
}))

gql`
  mutation finishedOnboardingForms($id: ID!) {
    finishedOnboardingForms(id: $id) {
      id
    }
  }
`

interface ContractDetailsInfoProps {
  sov?: CompanySovProperties
  contract: fragments.DetailedPayAppContract
  project: ProjectForSubcontractor
  company: CompanyProperties
  withLink?: boolean
}

export default function ContractDetailsInfo({
  contract,
  project,
  company,
  withLink,
}: ContractDetailsInfoProps) {
  const classes = useStyles()
  const navigate = useNavigate()

  const snackbar = useSitelineSnackbar()
  const [updateContract] = useUpdateContractMutation()
  const [updateLocation] = useUpdateContractMutation()
  const [updateChangeOrderRequestForm] = useUpdateChangeOrderRequestFormMutation()
  const [updateChangeOrderLogForm] = useUpdateChangeOrderLogFormMutation()
  const [finishedOnboardingForms] = useFinishedOnboardingFormsMutation({
    update(cache, { data }) {
      if (!data) {
        return
      }

      cache.modify({
        id: cache.identify(contract),
        fields: {
          onboardedStatus() {
            return { ...contract.onboardedStatus, notifiedOnboardedForms: true }
          },
        },
      })
    },
  })

  const onFormsFinished = () => {
    if (
      !window.confirm(
        'Are you sure you want to notify all users that their forms are finished onboarding?'
      )
    ) {
      return
    }
    snackbar.showLoading()
    finishedOnboardingForms({
      variables: { id: contract.id },
    })
      .then(() => snackbar.showSuccess())
      .catch((error) => snackbar.showError(error.message))
  }

  const handleInvalidateCache = () => {
    const pattern = encodeURIComponent(`contracts:${contract.id}`)
    navigate(`/caching?cacheType=${CacheType.GENERIC}&pattern=${pattern}`)
  }

  const locationsById = _.keyBy(company.locations, (location) => location.id)
  const formatLocation = (locationId: string) => {
    const location: LocationProperties | undefined = locationsById[locationId]
    if (_.isUndefined(location)) {
      return ''
    }
    return formatLocationOneLine(location)
  }

  const finishedOnboardingFormsStatus =
    contract.onboardedStatus.onboardedPayAppForms &&
    contract.onboardedStatus.onboardedPrimaryLienWaiverForms &&
    contract.onboardedStatus.onboardedVendorLienWaiverForms &&
    contract.onboardedStatus.onboardedChangeOrderRequestForms &&
    !contract.onboardedStatus.notifiedOnboardedForms

  return (
    <Card className={classes.card}>
      <CardHeader
        title="Contract"
        action={
          <>
            {finishedOnboardingFormsStatus && (
              <Button color="primary" variant="contained" onClick={onFormsFinished}>
                Finished onboarding forms
              </Button>
            )}
            {withLink && (
              <Button
                startIcon={<OpenInNewIcon />}
                color="primary"
                component={RouterLink}
                size="small"
                to={`/contracts/${contract.id}`}
              >
                View
              </Button>
            )}
          </>
        }
      />
      <Divider />
      <CardContent className={classes.cardContent}>
        <Table>
          <TableBody>
            <IdentifierRow id={contract.id} />
            <TableRow>
              <TableCell>Project</TableCell>
              <TableCell>
                <Link component={RouterLink} to={`/projects/${project.id}`} underline="hover">
                  {project.name}
                </Link>
                <IdentifierIconButton id={project.id} />
              </TableCell>
              <TableCell />
            </TableRow>
            <TableRow>
              <TableCell>Billing Type</TableCell>
              <TableCell>{contract.billingType}</TableCell>
              <TableCell />
            </TableRow>
            <EditableCardRowSelect
              readOnly={false}
              value={contract.selectedAddress.id}
              label="Company Office"
              options={company.locations.map((location) => ({
                key: location.id,
                value: formatLocationOneLine(location),
              }))}
              formatValue={(locationId: string) => formatLocation(locationId)}
              variables={(locationId: string) => ({
                input: {
                  id: contract.id,
                  selectedAddress: locationId,
                },
              })}
              mutate={updateLocation}
            />
            <EditableCardRowSelect
              readOnly={false}
              label="Status"
              value={contract.status}
              options={Object.values(ContractStatus).map((status) => ({
                key: status,
                value: status,
              }))}
              mutate={updateContract}
              variables={(value) => ({
                input: {
                  id: contract.id,
                  status: value,
                },
              })}
            />
            <EditableCardRowNumber
              readOnly={false}
              label="Past pay app count"
              value={contract.pastPayAppCount}
              mutate={updateContract}
              variables={(value) => ({
                input: {
                  id: contract.id,
                  pastPayAppCount: value,
                },
              })}
            />
            <EditableCardRowText
              readOnly={false}
              label="Contract number"
              value={contract.contractNumber ?? ''}
              mutate={updateContract}
              variables={(value) => ({
                input: {
                  id: contract.id,
                  contractNumber: value,
                },
              })}
            />
            <EditableCardRowDate
              readOnly={false}
              label="Contract date"
              value={contract.contractDate}
              timeZone={contract.timeZone}
              mutate={updateContract}
              variables={(value) => ({
                input: {
                  id: contract.id,
                  contractDate: value,
                },
              })}
            />
            <EditableCardRowNumber
              readOnly={false}
              label="Days before pay app is due"
              value={contract.daysBeforePayAppDue}
              mutate={updateContract}
              variables={(value) => ({
                input: {
                  id: contract.id,
                  daysBeforePayAppDue: value,
                },
              })}
            />
            <EditableCardRowText
              readOnly={false}
              label="Internal Project Number"
              value={contract.internalProjectNumber ?? ''}
              mutate={updateContract}
              variables={(value) => ({
                input: {
                  id: contract.id,
                  internalProjectNumber: value,
                },
              })}
            />
            <EditableCardRowText
              readOnly={false}
              label="Vendor Number"
              value={contract.vendorNumber ?? ''}
              mutate={updateContract}
              variables={(value) => ({
                input: {
                  id: contract.id,
                  vendorNumber: value,
                },
              })}
            />

            <EditableFormTemplateCardRow
              defaultTemplateId={contract.changeOrderRequestTemplate?.id}
              variables={(value?: string | null) => {
                const input: UpdateChangeOrderRequestFormInput = {
                  contractId: contract.id,
                  changeOrderRequestFormTemplateId: value,
                }
                return {
                  input,
                }
              }}
              label="Default Change Order Request Template"
              templateType={FormTemplateType.CHANGE_ORDER_REQUEST}
              mutate={updateChangeOrderRequestForm}
              readOnly={false}
            />

            <EditableFormTemplateCardRow
              defaultTemplateId={contract.changeOrderLogTemplate?.id}
              variables={(value?: string | null) => {
                const input: UpdateChangeOrderLogFormInput = {
                  contractId: contract.id,
                  changeOrderLogFormTemplateId: value,
                }
                return {
                  input,
                }
              }}
              label="Change Order Log Template"
              templateType={FormTemplateType.CHANGE_ORDER_LOG}
              mutate={updateChangeOrderLogForm}
              readOnly={false}
            />
          </TableBody>
        </Table>
      </CardContent>
      <CardActions className={classes.actions} disableSpacing>
        <Button startIcon={<HighlightOffIcon />} onClick={handleInvalidateCache} color="secondary">
          Flush contract cache
        </Button>
      </CardActions>
    </Card>
  )
}
