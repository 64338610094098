import { gql } from '@apollo/client'
import SearchIcon from '@mui/icons-material/Search'
import {
  Alert,
  Button,
  Card,
  CardContent,
  Chip,
  CircularProgress,
  FormControl,
  Grid2,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Theme,
  Tooltip,
  Typography,
} from '@mui/material'
import _ from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'
import { CacheType } from 'siteline-common-all'
import {
  evictWithGc,
  makeStylesFast,
  useDebouncedSearch,
  useSitelineSnackbar,
} from 'siteline-common-web'
import Page from '../../common/components/Page'
import {
  useCacheKeysQuery,
  useDeleteCacheKeysMutation,
} from '../../common/graphql/apollo-operations'

const useStyles = makeStylesFast((theme: Theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  chip: {
    marginRight: theme.spacing(1),
  },
}))

gql`
  query cacheKeys($input: GetCacheKeysInput!) {
    cacheKeys(input: $input)
  }
`

gql`
  mutation deleteCacheKeys($input: DeleteCacheKeysInput!) {
    deleteCacheKeys(input: $input)
  }
`

const collator = new Intl.Collator()

export default function Caching() {
  const classes = useStyles()
  const location = useLocation()
  const navigate = useNavigate()

  const initialSearch = useMemo(() => {
    const params = new URLSearchParams(location.search)
    return params.get('pattern') ?? ''
  }, [location.search])
  const { search, debouncedSearch, onSearch } = useDebouncedSearch({
    initialSearch,
    normalize: false,

    // We don't want to make more calls than necessary and risk overloading Redis.
    delay: 1000,
  })

  const initialCacheType = useMemo(() => {
    const params = new URLSearchParams(location.search)
    return (params.get('cacheType') as CacheType | null) ?? CacheType.GENERIC
  }, [location.search])
  const [cacheType, setCacheType] = useState<CacheType>(initialCacheType)

  const snackbar = useSitelineSnackbar()
  const { data, loading } = useCacheKeysQuery({
    variables: {
      input: {
        type: cacheType,
        pattern: debouncedSearch,
      },
    },
    skip: debouncedSearch.length < 2,
  })
  const [deleteCacheKeys] = useDeleteCacheKeysMutation({
    update: (cache) => {
      evictWithGc(cache, (evict) => {
        evict({ id: 'ROOT_QUERY', fieldName: 'cacheKeys' })
      })
    },
  })
  const keys = data?.cacheKeys ?? []
  const filtered = _.take(keys, 200).sort(collator.compare)
  const hasMore = keys.length > filtered.length
  const icon = loading ? (
    <CircularProgress size={20} sx={{ color: 'black', marginRight: 1 }} />
  ) : (
    <SearchIcon sx={{ marginRight: 1 }} />
  )

  // Update the internal cache type state whenever the URL changes.
  useEffect(() => setCacheType(initialCacheType), [initialCacheType])

  const deletePattern = (pattern: string) => {
    snackbar.showLoading()
    deleteCacheKeys({
      variables: {
        input: {
          type: cacheType,
          pattern,
        },
      },
    })
      .then(() => snackbar.showSuccess())
      .catch((err) => snackbar.showError(err.message))
  }

  const deleteAll = () => {
    const confirmed = window.confirm(`Are you sure you want to delete ${keys.length} keys?`)
    if (!confirmed) {
      return
    }
    deletePattern(debouncedSearch)
  }

  const deleteOne = (key: string) => {
    const confirmed = window.confirm(`Are you sure you want to delete this key?\n\n${key}`)
    if (!confirmed) {
      return
    }
    deletePattern(key)
  }

  const searchFor = (cacheType: CacheType, pattern: string) => {
    const search = new URLSearchParams(location.search)
    search.set('cacheType', cacheType)
    search.set('pattern', pattern)
    navigate({ search: search.toString() })
  }

  return (
    <Page className={classes.root} title="Caching">
      <Typography variant="h4">Caching</Typography>
      <Grid2 container spacing={2}>
        <Grid2 size={{ xs: 12 }} sx={{ marginTop: 2 }}>
          <Card>
            <CardContent sx={{ display: 'flex' }}>
              <FormControl>
                <Select
                  variant="outlined"
                  value={cacheType}
                  onChange={(ev) => setCacheType(ev.target.value as CacheType)}
                  className="variable-set"
                  size="small"
                >
                  {Object.values(CacheType).map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl sx={{ flex: 1, marginLeft: 1 }}>
                <TextField
                  value={search}
                  onChange={(ev) => onSearch(ev.target.value)}
                  size="small"
                  slotProps={{
                    input: {
                      startAdornment: icon,
                    },
                  }}
                />
              </FormControl>
            </CardContent>
            <CardContent sx={{ flex: 1 }}>
              <Tooltip
                title={
                  <span>
                    Contains full PDF files that are returned directly if the requested pay app (or
                    other package types) was not modified. You might want to invalidate this cache
                    if:
                    <ul>
                      <li>
                        One of the underlying generators has changed (docxtemplater, libreoffice,
                        pdf-lib)
                      </li>
                      <li>Table of Contents template has changed</li>
                      <li>Fonts were changed in the siteline-unoconv container</li>
                      <li>A bug was found in the pdf / conversions cache key strategy</li>
                      <li>
                        Anything has changed in the rendering process, <strong>after</strong>{' '}
                        computing items
                      </li>
                    </ul>
                    Note that invalidating this cache means that all PDFs will now load much slower
                    until cached again.
                  </span>
                }
              >
                <Chip
                  size="small"
                  label="PDF"
                  className={classes.chip}
                  onClick={() => searchFor(CacheType.FILES, 'pdf:*')}
                />
              </Tooltip>
              <Tooltip
                title={
                  <span>
                    Contains XLSX/DOCX =&gt; PDF conversions through LibreOffice. Cache key is
                    computed by hashing the contents of the XLSX/DOCX file, which means that it will
                    return if the template and template variables did not change. You might want to
                    invalidate this cache if:
                    <ul>
                      <li>Libreoffice/unoconv was updated</li>
                      <li>Fonts were changed in the siteline-unoconv container</li>
                      <li>A bug was found in the conversions cache key strategy</li>
                    </ul>
                    Note that the Conversions cache only applies if the PDF cache does not (which
                    means the package is not yet cached in the full PDF cache). This means that you
                    might want to invalidate the PDF cache first.
                  </span>
                }
              >
                <Chip
                  size="small"
                  label="Conversions"
                  className={classes.chip}
                  onClick={() => searchFor(CacheType.FILES, 'conversions:*')}
                />
              </Tooltip>
              <Tooltip
                title={
                  <span>
                    Contains form templates and pay-app/legal-requirement attachments, keyed by
                    google storage path. Since paths are unique and immutable, there should never be
                    a case where you would want to flush this cache – however you still can, because
                    life brings unexpected situations.
                    <br />
                    <br />
                    Note that the Storage cache only applies if the PDF cache does not (which means
                    the package is not yet cached in the full PDF cache). This means that you might
                    want to invalidate the PDF cache first.
                  </span>
                }
              >
                <Chip
                  size="small"
                  label="Storage"
                  className={classes.chip}
                  onClick={() => searchFor(CacheType.FILES, 'storage:*')}
                />
              </Tooltip>
              <Tooltip
                title="Contains aggregated values keyed by contract ID.
                It allows fetching billing values for a contract / pay app / line item / sov very quickly without
                fetching a ton of entities from the database. Since all values are keyed by contract, it is trivial
                to invalidate them, and it must be done anytime something modifies the database in a way that billing
                or retention would be affected."
              >
                <Chip
                  size="small"
                  label="Contracts"
                  className={classes.chip}
                  onClick={() => searchFor(CacheType.GENERIC, 'contracts:*')}
                />
              </Tooltip>
              <Tooltip title="Contains integration-specific values, mostly cookies for hh2.">
                <Chip
                  size="small"
                  label="Integrations"
                  className={classes.chip}
                  onClick={() => searchFor(CacheType.GENERIC, 'integrations:*')}
                />
              </Tooltip>
            </CardContent>
            <CardContent>
              {hasMore && (
                <Alert severity="warning">
                  Only showing {filtered.length} of {keys.length} keys.
                </Alert>
              )}
              {keys.length > 0 && (
                <Button sx={{ marginTop: 2 }} color="warning" onClick={() => deleteAll()}>
                  Delete all {keys.length} keys
                </Button>
              )}
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Key</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filtered.map((key) => (
                    <TableRow key={key}>
                      <TableCell>
                        <code>{key}</code>
                      </TableCell>
                      <TableCell>
                        <Button size="small" color="warning" onClick={() => deleteOne(key)}>
                          Delete
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                  {filtered.length === 0 && (
                    <TableRow>
                      <TableCell>No matching key</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </CardContent>
          </Card>
        </Grid2>
      </Grid2>
    </Page>
  )
}
