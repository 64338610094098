import { gql } from '@apollo/client'
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
} from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'
import { makeStylesFast } from 'siteline-common-web'
import * as fragments from '../../common/graphql/Fragments'
import {
  CompanyProperties,
  ProjectsByCompanyQuery,
  useProjectsByCompanyQuery,
} from '../../common/graphql/apollo-operations'

gql`
  query projectsByCompany($id: ID!) {
    projectsByCompany(id: $id) {
      ...ProjectProperties
      contracts {
        id
        contractNumber
        company {
          id
          name
        }
      }
    }
  }
  ${fragments.project}
`

type Project = ProjectsByCompanyQuery['projectsByCompany'][number]

const useStyles = makeStylesFast((theme: Theme) => ({
  card: {
    marginBottom: theme.spacing(2),
  },
  cardContent: {
    padding: 0,
    '&:last-child': {
      padding: 0,
    },
    '& .MuiFormControl-root': {
      width: '100%',
    },
  },
  formTemplate: {
    width: '100%',
  },
  actions: {
    padding: theme.spacing(2),
  },
}))

type CompanyProjectsProps = {
  company: CompanyProperties
}

export default function CompanyProjects({ company }: CompanyProjectsProps) {
  const classes = useStyles()

  const { data, error, loading } = useProjectsByCompanyQuery({
    variables: { id: company.id },
  })

  if (loading || error || !data) {
    // Render an empty page while loading
    return <div />
  }

  const getContract = (project: Project) => {
    // Get the contract of a project that use this form template
    return project.contracts.find((contract) => contract.company.id === company.id)
  }

  const sortedProjects = [...data.projectsByCompany].sort((a, b) => (a.name > b.name ? 1 : -1))

  return (
    <Card className={classes.card}>
      <CardHeader title={'Projects'} />
      <Divider />
      <CardContent className={classes.cardContent}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Project Name</TableCell>
              <TableCell>Subcontractor Name</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedProjects.map((project) => {
              const contract = getContract(project)
              return (
                <TableRow hover key={project.id}>
                  <TableCell>
                    <Link component={RouterLink} to={`/projects/${project.id}`} underline="hover">
                      {project.name}
                    </Link>
                  </TableCell>
                  <TableCell>
                    {contract && (
                      <Link
                        component={RouterLink}
                        to={`/contracts/${contract.id}`}
                        underline="hover"
                      >
                        {contract.company.name}
                      </Link>
                    )}
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  )
}
