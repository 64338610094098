import AddIcon from '@mui/icons-material/Add'
import ClearIcon from '@mui/icons-material/Clear'
import DeleteIcon from '@mui/icons-material/Delete'
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight'
import {
  Button,
  Checkbox,
  Chip,
  IconButton,
  Link,
  MenuItem,
  Select,
  TableCell,
  TableRow,
  TextField,
  Theme,
  Tooltip,
} from '@mui/material'
import { useMemo } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { colors, makeStylesFast } from 'siteline-common-web'
import { IdentifierIconButton } from '../../common/components/IdentifierRow'
import { VariantAutocomplete } from '../../common/components/VariantAutocomplete'
import {
  FormTemplateType,
  PayAppRequirementCondition,
  UpdatePayAppRequirementInput,
  useTemplateVariantForAutocompleteQuery,
} from '../../common/graphql/apollo-operations'
import { getIsLatestTemplateVersionReadyForManualStoredMaterials } from '../../common/util/FormTemplate'
import { FormTemplateReadyForManualStoredMaterialsWarningChip } from '../FormTemplateReadyForManualStoredMaterialsWarningChip'
import { FormTemplateStatusChip } from '../FormTemplateStatusChip'
import { PayAppRequirementWithKey, USER_FRIENDLY_CONDITION_NAMES } from './PayAppRequirementGroups'

const useStyles = makeStylesFast((theme: Theme) => ({
  actions: {
    display: 'flex',
  },
  elseIf: {
    display: 'flex',
    alignItems: 'center',
    '& .MuiSvgIcon-root': {
      paddingRight: theme.spacing(0.5),
    },
  },
}))

export function EmptyRequirementWarning() {
  return (
    <Tooltip
      arrow
      title="If the conditions for this requirement are met, no template will show for this group"
    >
      <Chip
        label="Empty"
        size="medium"
        sx={{ backgroundColor: { backgroundColor: colors.orange10, color: colors.red50 } }}
      />
    </Tooltip>
  )
}

interface PayAppRequirementUneditableRowProps {
  requirement: UpdatePayAppRequirementInput
  isFirstRow: boolean
  isLastRow: boolean
  order: number
  noFallback: boolean
  groupIndex: number
  usesManualStoredMaterials: boolean
}

export function PayAppRequirementUneditableRow({
  requirement,
  isFirstRow,
  isLastRow,
  order,
  noFallback,
  groupIndex,
  usesManualStoredMaterials,
}: PayAppRequirementUneditableRowProps) {
  const classes = useStyles()

  const isMiddleRow = !isFirstRow && !isLastRow
  const { data: templateData } = useTemplateVariantForAutocompleteQuery({
    variables: {
      id: requirement.templateVariantId ?? '',
    },
    skip: !requirement.templateVariantId,
  })

  const variant = useMemo(
    () => templateData?.formTemplateVariant ?? null,
    [templateData?.formTemplateVariant]
  )

  const isReadyForManualStoredMaterials = useMemo(() => {
    if (variant) {
      return getIsLatestTemplateVersionReadyForManualStoredMaterials(variant.template)
    } else {
      return true
    }
  }, [variant])

  const variantTitle = useMemo(() => {
    if (!variant) {
      return ''
    }
    const variantLabel = variant.isDefaultVariant ? 'Default' : variant.internalName
    return `${variant.template.userVisibleName} (${variantLabel})`
  }, [variant])

  return (
    <TableRow>
      <TableCell>{isFirstRow ? `Group ${groupIndex + 1}` : ''}</TableCell>
      {isFirstRow && <TableCell>{order}</TableCell>}
      <TableCell>
        {((isLastRow && noFallback) || isMiddleRow) && (
          <div className={classes.elseIf}>
            <SubdirectoryArrowRightIcon />
            Else if
          </div>
        )}
        {!isFirstRow && isLastRow && !noFallback && (
          <div className={classes.elseIf}>
            <SubdirectoryArrowRightIcon />
            Fallback
          </div>
        )}
        <div>
          {requirement.conditions.length > 0
            ? requirement.conditions
                .map((condition) => USER_FRIENDLY_CONDITION_NAMES[condition])
                .join(', ')
            : 'Always appears'}
        </div>
      </TableCell>
      <TableCell>
        {variant && (
          <Link component={RouterLink} to={`/templates/${variant.template.id}`}>
            {variantTitle}
          </Link>
        )}
        {!requirement.templateVariantId && <EmptyRequirementWarning />}
      </TableCell>
      <TableCell>
        {variant && (
          <FormTemplateStatusChip
            status={variant.template.status}
            skippedValidation={variant.template.skippedValidation}
          />
        )}
        {variant && !isReadyForManualStoredMaterials && usesManualStoredMaterials && (
          <FormTemplateReadyForManualStoredMaterialsWarningChip />
        )}
      </TableCell>
      <TableCell>{requirement.id && <IdentifierIconButton id={requirement.id} />}</TableCell>
    </TableRow>
  )
}

interface PayAppRequirementEditableRowProps {
  payAppRequirement: PayAppRequirementWithKey
  setPayAppRequirement: (payAppRequirement: PayAppRequirementWithKey) => void
  isFirstRow: boolean
  isLastRow: boolean
  templateType: FormTemplateType
  order: number
  setOrder: (newOrder: number) => void
  noFallback: boolean
  setNoFallback: (noFallback: boolean) => void
  addRequirement: () => void
  removeRequirement: () => void
  groupIndex: number
  usesManualStoredMaterials: boolean
}

export function PayAppRequirementEditableRow({
  payAppRequirement,
  setPayAppRequirement,
  isFirstRow,
  isLastRow,
  templateType,
  order,
  setOrder,
  noFallback,
  setNoFallback,
  addRequirement,
  removeRequirement,
  groupIndex,
  usesManualStoredMaterials,
}: PayAppRequirementEditableRowProps) {
  const classes = useStyles()
  const isMiddleRow = !isFirstRow && !isLastRow

  const onRemoveFallback = () => {
    setNoFallback(true)
    removeRequirement()
  }

  const { data: templateData } = useTemplateVariantForAutocompleteQuery({
    variables: {
      id: payAppRequirement.templateVariantId ?? '',
    },
    skip: !payAppRequirement.templateVariantId,
  })

  const isReadyForManualStoredMaterials = useMemo(() => {
    if (templateData) {
      return getIsLatestTemplateVersionReadyForManualStoredMaterials(
        templateData.formTemplateVariant.template
      )
    } else {
      return true
    }
  }, [templateData])

  return (
    <TableRow>
      <TableCell>{isFirstRow ? `Group ${groupIndex + 1}` : ''}</TableCell>
      {isFirstRow && (
        <TableCell>
          <TextField
            size="small"
            placeholder="Order"
            type="number"
            value={order}
            onChange={(ev) => {
              setOrder(Number(ev.target.value))
            }}
          />
        </TableCell>
      )}
      {((isLastRow && noFallback) || isMiddleRow) && (
        <TableCell className={classes.elseIf}>
          <SubdirectoryArrowRightIcon />
          Else if
        </TableCell>
      )}
      {isLastRow && !noFallback && (
        <TableCell className={classes.elseIf}>
          <SubdirectoryArrowRightIcon />
          Fallback
        </TableCell>
      )}
      <TableCell>
        {(!isLastRow || noFallback) && (
          <>
            <Select
              size="small"
              multiple
              fullWidth
              value={[...payAppRequirement.conditions]}
              onChange={(ev) => {
                setPayAppRequirement({
                  ...payAppRequirement,
                  conditions: ev.target.value as PayAppRequirementCondition[],
                })
              }}
              renderValue={(selected) => {
                return (selected as PayAppRequirementCondition[])
                  .map((condition) => USER_FRIENDLY_CONDITION_NAMES[condition])
                  .join(', ')
              }}
            >
              {Object.values(PayAppRequirementCondition).map((condition) => (
                <MenuItem key={condition} value={condition}>
                  <Checkbox checked={payAppRequirement.conditions.includes(condition)} />
                  {USER_FRIENDLY_CONDITION_NAMES[condition]}
                </MenuItem>
              ))}
            </Select>
          </>
        )}
        {isLastRow && !noFallback && (
          <>
            <Button onClick={onRemoveFallback} startIcon={<ClearIcon />} size="small">
              Remove fallback
            </Button>
          </>
        )}
      </TableCell>
      <TableCell>
        <VariantAutocomplete
          variant={payAppRequirement.templateVariantId ?? null}
          templateType={templateType}
          onVariantChange={(value) => {
            setPayAppRequirement({
              ...payAppRequirement,
              templateVariantId: value?.id ?? null,
            })
          }}
        />
      </TableCell>
      <TableCell>
        {templateData && (
          <FormTemplateStatusChip
            status={templateData.formTemplateVariant.template.status}
            skippedValidation={templateData.formTemplateVariant.template.skippedValidation}
          />
        )}
        {templateData && !isReadyForManualStoredMaterials && usesManualStoredMaterials && (
          <FormTemplateReadyForManualStoredMaterialsWarningChip />
        )}
        {!payAppRequirement.templateVariantId && <EmptyRequirementWarning />}
      </TableCell>
      <TableCell className={classes.actions}>
        <IconButton onClick={() => addRequirement()} size="small">
          <AddIcon />
        </IconButton>
        {(!isLastRow || noFallback) && (
          <IconButton onClick={() => removeRequirement()} size="small">
            <DeleteIcon />
          </IconButton>
        )}
      </TableCell>
    </TableRow>
  )
}
