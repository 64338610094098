import { gql } from '@apollo/client'
import DeleteIcon from '@mui/icons-material/Delete'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Table,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material'
import { toReferences } from 'siteline-common-web'
import type { WritableDeep } from 'type-fest'
import { IdentifierIconButton } from '../../common/components/IdentifierRow'
import * as fragments from '../../common/graphql/Fragments'
import { Company, useDeleteVendorMutation } from '../../common/graphql/apollo-operations'

gql`
  mutation deleteVendor($id: ID!) {
    deleteVendor(id: $id) {
      id
    }
  }
`

interface CompanyDetailsVendorsProps {
  company: fragments.DetailedCompany
}

export default function CompanyDetailsVendors({ company }: CompanyDetailsVendorsProps) {
  const [deleteVendor] = useDeleteVendorMutation({
    update(cache, { data }) {
      if (!data) {
        return
      }

      cache.modify<WritableDeep<Company>>({
        id: cache.identify(company),
        fields: {
          vendors(existingRefs, { readField, toReference }) {
            const refs = toReferences(existingRefs, toReference)
            return refs.filter((ref) => readField('id', ref) !== data.deleteVendor.id)
          },
        },
      })
    },
  })

  const onDelete = (vendorId: string) => {
    if (!window.confirm('Are you sure you want to delete this vendor?')) {
      return
    }
    deleteVendor({
      variables: { id: vendorId },
      optimisticResponse: {
        deleteVendor: { id: vendorId, __typename: 'DeletionResult' },
        __typename: 'Mutation',
      },
    })
  }

  const collator = new Intl.Collator()
  const vendors = [...company.vendors].sort((a, b) => collator.compare(a.name, b.name))

  return (
    <Card>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <CardHeader title="Vendors" />
        </AccordionSummary>
        <CardContent style={{ padding: 0 }}>
          <AccordionDetails style={{ padding: 0 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Number of Contacts</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              {vendors.map((vendor) => (
                <TableRow key={vendor.id}>
                  <TableCell>{vendor.name}</TableCell>
                  <TableCell>{vendor.contacts.length}</TableCell>
                  <TableCell>
                    <IdentifierIconButton id={vendor.id} />
                    <IconButton onClick={() => onDelete(vendor.id)} size="large">
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </Table>
          </AccordionDetails>
        </CardContent>
      </Accordion>
    </Card>
  )
}
