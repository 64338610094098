import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material'
import moment from 'moment-timezone'
import { useState } from 'react'

type GetDateDialogProps = {
  timeZone: string
  open: boolean
  onClose: (date?: moment.Moment) => void
}

export function GetDateDialog({ timeZone, open, onClose }: GetDateDialogProps) {
  const [proposedAt, setProposedAt] = useState(moment.tz(timeZone))

  return (
    <Dialog open={open} onClose={() => onClose()}>
      <DialogTitle>Select a time for this action</DialogTitle>
      <DialogContent>
        <TextField
          type="datetime-local"
          value={proposedAt.format('YYYY-MM-DDTHH:mm')}
          onChange={(event) => setProposedAt(moment.tz(event.target.value, timeZone))}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose(proposedAt)} color="primary">
          Select time
        </Button>
      </DialogActions>
    </Dialog>
  )
}
