import { gql } from '@apollo/client'
import {
  Alert,
  Button,
  Container,
  Grid2,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Typography,
} from '@mui/material'
import moment from 'moment-timezone'
import { useParams } from 'react-router-dom'
import { makeStylesFast, useSitelineSnackbar } from 'siteline-common-web'
import { Loader } from '../../common/components/Loader'
import Page from '../../common/components/Page'
import * as fragments from '../../common/graphql/Fragments'
import {
  IntegrationType,
  LegalDocumentProperties,
  useGetLegalRequirementQuery,
  useSetLegalDocumentSyncedMutation,
} from '../../common/graphql/apollo-operations'
import { hasIntegration } from '../../common/util/Integration'

const useStyles = makeStylesFast((theme: Theme) => ({
  root: {
    padding: theme.spacing(3, 0),
  },
}))

gql`
  mutation setLegalDocumentSynced($id: ID!) {
    setLegalDocumentSynced(id: $id) {
      ...LegalRequirementProperties
      documents {
        ...LegalDocumentProperties
      }
    }
  }
  ${fragments.legalRequirement}
  ${fragments.legalDocument}
`

gql`
  query getLegalRequirement($id: ID!) {
    legalRequirement(id: $id) {
      ...LegalRequirementProperties
      documents {
        ...LegalDocumentProperties
      }
      contract {
        id
        integrations {
          ...IntegrationProperties
        }
      }
    }
  }
  ${fragments.legalRequirement}
  ${fragments.legalDocument}
  ${fragments.integration}
`

type LegalDocumentRowProps = {
  timeZone: string
  document: LegalDocumentProperties
  hasTexturaIntegration: boolean
  onMarkAsSynced: () => void
}

function LegalDocumentRow({
  document,
  timeZone,
  hasTexturaIntegration,
  onMarkAsSynced,
}: LegalDocumentRowProps) {
  const lastSync = document.lastSyncIfSuccessful
  const formatDate = (date: string) => {
    return moment.tz(date, timeZone).format('MMM DD, YYYY')
  }

  return (
    <TableRow hover>
      <TableCell>{document.name}</TableCell>
      <TableCell>{document.periodStart ? formatDate(document.periodStart) : 'N/A'}</TableCell>
      <TableCell>{document.periodEnd ? formatDate(document.periodEnd) : 'N/A'}</TableCell>
      {hasTexturaIntegration && (
        <>
          <TableCell>{lastSync ? formatDate(lastSync.createdAt) : 'Not Synced'}</TableCell>
          <TableCell align="right">
            {!lastSync && (
              <Button
                color="primary"
                size="small"
                onClick={() => onMarkAsSynced()}
                variant="outlined"
              >
                Set to Synced
              </Button>
            )}
          </TableCell>
        </>
      )}
    </TableRow>
  )
}

type LegalDocumentsProps = {
  requirementId?: string
}

export default function LegalDocuments() {
  const classes = useStyles()
  const { requirementId } = useParams() as LegalDocumentsProps
  const snackbar = useSitelineSnackbar()
  const [setLegalDocumentSynced] = useSetLegalDocumentSyncedMutation()

  if (!requirementId) {
    throw new Error('No requirement ID provided')
  }

  const { data, error, loading } = useGetLegalRequirementQuery({
    variables: { id: requirementId },
  })

  const legalRequirement = data?.legalRequirement

  if (!legalRequirement) {
    return null
  }

  const handleMarkAsSynced = (id: string) => {
    const confirmAction = window.confirm(
      'Are you sure you want to change this document status to synced?'
    )
    if (!confirmAction) {
      return
    }
    snackbar.showLoading()
    try {
      setLegalDocumentSynced({
        variables: { id },
      })
      snackbar.showSuccess()
    } catch (err) {
      snackbar.showError(err.message)
    }
  }

  const hasTexturaIntegration = hasIntegration(legalRequirement.contract, IntegrationType.TEXTURA)

  return (
    <Page className={classes.root} title="Legal-documents">
      <Container maxWidth={false}>
        <Grid2 alignItems="flex-end" container justifyContent="space-between" spacing={3}>
          <Grid2>
            <Typography variant="h4">{legalRequirement.name}</Typography>
          </Grid2>
        </Grid2>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Document name</TableCell>
                <TableCell>Period start</TableCell>
                <TableCell>Period end</TableCell>
                {hasTexturaIntegration && (
                  <>
                    <TableCell>Textura Sync Date</TableCell>
                    <TableCell align="right">Actions</TableCell>
                  </>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {legalRequirement.documents.map((document) => (
                <LegalDocumentRow
                  key={document.id}
                  document={document}
                  timeZone={legalRequirement.timeZone}
                  onMarkAsSynced={() => handleMarkAsSynced(document.id)}
                  hasTexturaIntegration={hasTexturaIntegration}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {error && <Alert severity="error">{error.message}</Alert>}
        {loading && <Loader />}
      </Container>
    </Page>
  )
}
