import { Button, Theme, Tooltip } from '@mui/material'
import { useCallback, useMemo } from 'react'
import { ProjectOnboardingFormType, makeStylesFast, useSitelineSnackbar } from 'siteline-common-web'
import * as fragments from '../../common/graphql/Fragments'
import {
  ContractFormTemplatesQuery,
  GetContractDocument,
  SubcontractorLienWaiverTemplateSetInput,
  useEnableLowerTierLienWaiversMutation,
  useEnablePrimaryLienWaiversMutation,
} from '../../common/graphql/apollo-operations'
import { LienWaiverTemplateSetValues } from './LienWaiverTemplateSet'

const useStyles = makeStylesFast((theme: Theme) => ({
  root: {
    margin: theme.spacing(0, -0.5),
    '& > *': {
      margin: theme.spacing(0, 0.5),
    },
    '& .quickAddButton': {
      minWidth: 0,
    },
  },
}))

type FormTemplateForContractDetails = ContractFormTemplatesQuery['contractFormTemplates'][number]
type LienWaiverType = keyof LienWaiverTemplateSetValues

interface QuickAddLienWaiverFormTemplateProps {
  contract: fragments.Contract
  formTemplate: FormTemplateForContractDetails
}

/**
 * Actions for quick-adding a lien waiver form template to a contract's primary or vendor
 * template set
 */
export default function QuickAddLienWaiverFormTemplate({
  contract,
  formTemplate,
}: QuickAddLienWaiverFormTemplateProps) {
  const classes = useStyles()
  const snackbar = useSitelineSnackbar()
  const [updatePrimaryLienWaivers] = useEnablePrimaryLienWaiversMutation({
    refetchQueries: [{ query: GetContractDocument, variables: { id: contract.id } }],
  })
  const [updateVendorLienWaivers] = useEnableLowerTierLienWaiversMutation({
    refetchQueries: [{ query: GetContractDocument, variables: { id: contract.id } }],
  })

  const lienWaiverType = useMemo(() => {
    const associatedContract = formTemplate.associatedContracts.find(
      (association) => association.contract.id === contract.id
    )
    const formType = associatedContract?.providedAsFormType
    switch (formType) {
      case ProjectOnboardingFormType.PRIMARY_LIEN_WAIVER:
      case ProjectOnboardingFormType.VENDOR_LIEN_WAIVER:
        return formType
      case ProjectOnboardingFormType.PAY_APP:
        return ProjectOnboardingFormType.PRIMARY_LIEN_WAIVER
      case ProjectOnboardingFormType.CHANGE_ORDER_LOG:
      case ProjectOnboardingFormType.CHANGE_ORDER_REQUEST:
      case ProjectOnboardingFormType.COMPLIANCE:
      case undefined:
      case null:
        return null
    }
  }, [formTemplate.associatedContracts, contract.id])

  const currentTemplateSet = useMemo(() => {
    if (!lienWaiverType) {
      return null
    }
    return lienWaiverType === ProjectOnboardingFormType.PRIMARY_LIEN_WAIVER
      ? contract.lienWaiverTemplates
      : contract.lowerTierLienWaiverTemplates
  }, [lienWaiverType, contract.lienWaiverTemplates, contract.lowerTierLienWaiverTemplates])

  const emptyLienWaiverTypes = useMemo(() => {
    const emptyTypes: LienWaiverType[] = []
    if (!currentTemplateSet?.conditionalProgressVariant) {
      emptyTypes.push('conditionalProgressVariant')
    }
    if (!currentTemplateSet?.conditionalFinalVariant) {
      emptyTypes.push('conditionalFinalVariant')
    }
    if (!currentTemplateSet?.unconditionalProgressVariant) {
      emptyTypes.push('unconditionalProgressVariant')
    }
    if (!currentTemplateSet?.unconditionalFinalVariant) {
      emptyTypes.push('unconditionalFinalVariant')
    }
    return emptyTypes
  }, [currentTemplateSet])

  const addLienWaiverTemplate = useCallback(
    async (type: LienWaiverType) => {
      const defaultVariant = formTemplate.variants.find((variant) => variant.isDefaultVariant)
      if (!defaultVariant) {
        return
      }
      const newTemplateSet: SubcontractorLienWaiverTemplateSetInput = {
        conditionalProgressVariantId:
          type === 'conditionalProgressVariant'
            ? defaultVariant.id
            : (currentTemplateSet?.conditionalProgressVariant?.id ?? null),
        conditionalFinalVariantId:
          type === 'conditionalFinalVariant'
            ? defaultVariant.id
            : (currentTemplateSet?.conditionalFinalVariant?.id ?? null),
        unconditionalProgressVariantId:
          type === 'unconditionalProgressVariant'
            ? defaultVariant.id
            : (currentTemplateSet?.unconditionalProgressVariant?.id ?? null),
        unconditionalFinalVariantId:
          type === 'unconditionalFinalVariant'
            ? defaultVariant.id
            : (currentTemplateSet?.unconditionalFinalVariant?.id ?? null),
      }
      const args = {
        variables: {
          input: {
            contractId: contract.id,
            templates: newTemplateSet,
          },
        },
      }
      const mutation: Promise<unknown> =
        lienWaiverType === ProjectOnboardingFormType.PRIMARY_LIEN_WAIVER
          ? updatePrimaryLienWaivers(args)
          : updateVendorLienWaivers(args)
      return mutation
        .then(() => {
          snackbar.showSuccess('Added to lien waiver set')
        })
        .catch((err) => {
          snackbar.showError(err.message)
        })
    },
    [
      contract.id,
      currentTemplateSet?.conditionalFinalVariant?.id,
      currentTemplateSet?.conditionalProgressVariant?.id,
      currentTemplateSet?.unconditionalFinalVariant?.id,
      currentTemplateSet?.unconditionalProgressVariant?.id,
      formTemplate.variants,
      lienWaiverType,
      snackbar,
      updatePrimaryLienWaivers,
      updateVendorLienWaivers,
    ]
  )

  const getButtonLabels = useCallback(
    (type: LienWaiverType): [string, string] => {
      if (!lienWaiverType) {
        return ['', '']
      }
      const templateSetName =
        lienWaiverType === ProjectOnboardingFormType.PRIMARY_LIEN_WAIVER ? 'Primary' : 'Vendor'
      switch (type) {
        case 'conditionalProgressVariant': {
          return [
            `${templateSetName} C.P.`,
            `Add as conditional progress ${templateSetName.toLowerCase()} LW`,
          ]
        }
        case 'conditionalFinalVariant': {
          return [
            `${templateSetName} C.F.`,
            `Add as conditional final ${templateSetName.toLowerCase()} LW`,
          ]
        }
        case 'unconditionalProgressVariant': {
          return [
            `${templateSetName} U.P.`,
            `Add as unconditional progress ${templateSetName.toLowerCase()} LW`,
          ]
        }
        case 'unconditionalFinalVariant': {
          return [
            `${templateSetName} U.F.`,
            `Add as unconditional final ${templateSetName.toLowerCase()} LW`,
          ]
        }
      }
    },
    [lienWaiverType]
  )

  if (!lienWaiverType || emptyLienWaiverTypes.length === 0) {
    return null
  }

  return (
    <div className={classes.root}>
      {emptyLienWaiverTypes.map((type) => {
        const [label, tooltip] = getButtonLabels(type)
        return (
          <Tooltip key={type} title={tooltip} placement="bottom-start" disableInteractive>
            <Button
              color="secondary"
              variant="outlined"
              size="small"
              onClick={() => addLienWaiverTemplate(type)}
              className="quickAddButton"
            >
              {label}
            </Button>
          </Tooltip>
        )
      })}
    </div>
  )
}
