import { Chip } from '@mui/material'
import { FormTemplateStatus, getTemplateStatusPrettyName } from 'siteline-common-all'
import { getTemplateStatusColor } from '../common/util/FormTemplate'

interface FormTemplateStatusChipProps {
  status: FormTemplateStatus
  skippedValidation: boolean
}

/** Chip that displays the status of a form template */
export function FormTemplateStatusChip({ status, skippedValidation }: FormTemplateStatusChipProps) {
  return (
    <Chip
      size="medium"
      sx={{
        backgroundColor: getTemplateStatusColor(status, skippedValidation),
        verticalAlign: 'text-top',
      }}
      label={getTemplateStatusPrettyName(status, skippedValidation)}
    />
  )
}
