import { makeStylesFast, SignatureAnnotationType } from 'siteline-common-web'
import {
  FormTemplateAnnotationProperties,
  GetFormTemplateQuery,
} from '../../../common/graphql/apollo-operations'
import { AnnotationWithOverrides } from '../../../common/util/FormTemplate'

const useStyles = makeStylesFast(() => ({
  image: {
    position: 'relative',
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    objectPosition: 'left',
    pointerEvents: 'none',
  },
}))

type SignatureAnnotationContentProps = {
  annotation: FormTemplateAnnotationProperties | AnnotationWithOverrides
  template: GetFormTemplateQuery['formTemplate']
}

/**
 * Content of an signature annotation
 */
export function SignatureAnnotationContent({
  annotation,
  template,
}: SignatureAnnotationContentProps) {
  const classes = useStyles()
  if (template.requestingCompany) {
    if (
      annotation.signatureType === SignatureAnnotationType.NOTARY &&
      template.requestingCompany.notarySignature
    ) {
      return (
        <img
          className={classes.image}
          src={template.requestingCompany.notarySignature.url}
          alt="Notary signature"
        />
      )
    }
  }
  return (
    <div
      style={{
        position: 'relative',
        width: '100%',
        height: '100%',
        // Name of the annotation often exceeds its horizontal bounds, overlapping with other
        // annotations and messing with their selection. Instead, we disable user interaction
        // with the name and force interaction on the container instead (which has a fixed geometry)
        pointerEvents: 'none',
      }}
    >
      <span>{annotation.userVisibleName}</span>
    </div>
  )
}
