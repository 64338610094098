import { gql } from '@apollo/client'
import {
  Alert,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@mui/material'
import { decimalToPercent, supportsRetentionTrackingLevel } from 'siteline-common-all'
import { makeStylesFast } from 'siteline-common-web'
import {
  EditableCardRowCheckbox,
  EditableCardRowSelect,
} from '../../../common/components/EditableCardRow'
import * as fragments from '../../../common/graphql/Fragments'
import { Contract } from '../../../common/graphql/Fragments'
import {
  PayAppStatus,
  RetentionTrackingLevel,
  useConvertRetentionTrackingLevelMutation,
  useUpdateContractMutation,
  useUpdateRoundRetentionMutation,
} from '../../../common/graphql/apollo-operations'
import { ContractPayApp } from '../ContractDetails'

gql`
  mutation convertRetentionTrackingLevel($input: ConvertRetentionTrackingLevelInput!) {
    convertRetentionTrackingLevel(input: $input) {
      ...ContractProperties
    }
  }
  ${fragments.contract}
`

gql`
  mutation updateRoundRetention($input: UpdateRoundRetentionInput!) {
    updateRoundRetention(input: $input) {
      ...ContractProperties
    }
  }
  ${fragments.contract}
`

const useStyles = makeStylesFast(() => ({
  card: {
    paddingBottom: 0,
  },
  cardContent: {
    padding: 0,
    '&:last-child': {
      padding: 0,
    },
  },
}))

interface ContractDetailsInfoProps {
  contract: Contract
  payApps: ContractPayApp[]
}

export default function ContractDetailsRetention({ contract, payApps }: ContractDetailsInfoProps) {
  const classes = useStyles()
  const [updateContract] = useUpdateContractMutation()
  const [updateRoundRetention] = useUpdateRoundRetentionMutation()
  const [convertRetentionTrackingLevel] = useConvertRetentionTrackingLevelMutation()
  const inProgressStatus = [
    PayAppStatus.DRAFT,
    PayAppStatus.SYNC_FAILED,
    PayAppStatus.SIGNED,
    PayAppStatus.SYNC_PENDING,
  ]
  const onlyDraftPayApp = payApps.every((payApp) => inProgressStatus.includes(payApp.status))
  const isRetentionLevelSupportedByIntegrations = contract.integrations.every((integration) =>
    supportsRetentionTrackingLevel(integration.type, contract.retentionTrackingLevel)
  )
  const hasPayApps = payApps.length > 0
  const cannotEditRetentionLevel = contract.retentionTrackingLevel === RetentionTrackingLevel.NONE

  return (
    <Card className={classes.card}>
      <CardHeader title="Retention" />
      <Divider />
      {!isRetentionLevelSupportedByIntegrations && (
        <CardContent>
          <Alert severity="error">
            Retention level {contract.retentionTrackingLevel} is not supported by the current
            contract integrations.
          </Alert>
        </CardContent>
      )}
      <CardContent className={classes.cardContent}>
        <Table>
          <TableBody>
            {/* Only allow changing round settings if there is no proposed pay app. */}
            <EditableCardRowCheckbox
              readOnly={payApps.length > 1 || !onlyDraftPayApp}
              label="Round retention to nearest dollar"
              value={contract.roundRetention}
              mutate={updateRoundRetention}
              variables={(value) => ({
                input: {
                  contractId: contract.id,
                  roundRetention: value,
                },
              })}
            />
            {hasPayApps ? (
              <EditableCardRowSelect
                readOnly={cannotEditRetentionLevel}
                label="Retention tracking level"
                value={contract.retentionTrackingLevel}
                options={Object.values(RetentionTrackingLevel).map((level) => ({
                  key: level,
                  value: level,
                }))}
                mutate={convertRetentionTrackingLevel}
                variables={(value) => ({
                  input: {
                    contractId: contract.id,
                    retentionTrackingLevel: value,
                  },
                })}
                confirmationText="Changing retention tracking level is not recommended unless necessary. Are you sure you want to continue?"
              />
            ) : (
              <EditableCardRowSelect
                readOnly={cannotEditRetentionLevel}
                label="Retention tracking level"
                value={contract.retentionTrackingLevel}
                options={Object.values(RetentionTrackingLevel).map((level) => ({
                  key: level,
                  value: level,
                }))}
                mutate={updateContract}
                variables={(value) => ({
                  input: {
                    id: contract.id,
                    retentionTrackingLevel: value,
                  },
                })}
              />
            )}
            <TableRow>
              <TableCell>Rentention %</TableCell>
              <TableCell>{decimalToPercent(contract.defaultRetentionPercent ?? 0, 2)}</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  )
}
