import _ from 'lodash'
import moment, { type Moment } from 'moment-timezone'
import { AgingIntervalType, BillingType, PayAppStatus } from '../enums.js'

export type MonthlyBillingType =
  | BillingType.LUMP_SUM
  | BillingType.UNIT_PRICE
  | BillingType.TIME_AND_MATERIALS

export function isMonthlyBillingType(billingType: BillingType): billingType is MonthlyBillingType {
  switch (billingType) {
    case BillingType.LUMP_SUM:
    case BillingType.UNIT_PRICE:
    case BillingType.TIME_AND_MATERIALS:
      return true
    case BillingType.QUICK:
      return false
  }
}

export type PayAppForSorting = {
  billingEnd: string | Date
  retentionOnly: boolean
}

function dateCacheKey(date: string | Date, timeZone: string): string {
  if (date instanceof Date) {
    return `${date.valueOf().toString()}-${timeZone}`
  } else {
    return `${date}-${timeZone}`
  }
}

/**
 * Returns the moment of a pay app's billing end date.
 * This is memoized because `moment.tz` is quite slow.
 */
const getBillingEnd = _.memoize(
  (payApp: PayAppForSorting, timeZone: string) => moment.tz(payApp.billingEnd, timeZone),
  (payApp, timeZone) => dateCacheKey(payApp.billingEnd, timeZone)
)

/**
 * Returns whether two pay apps have the same billing end day.
 * This is memoized because `moment.isSame` is quite slow.
 */
const isSameDate = _.memoize(
  (a: PayAppForSorting, b: PayAppForSorting, timeZone: string) => {
    const aBillingEnd = getBillingEnd(a, timeZone)
    const bBillingEnd = getBillingEnd(b, timeZone)
    return aBillingEnd.isSame(bBillingEnd, 'day')
  },
  (a, b, timeZone) => {
    const aKey = dateCacheKey(a.billingEnd, timeZone)
    const bKey = dateCacheKey(b.billingEnd, timeZone)
    return `${aKey}-${bKey}`
  }
)

/**
 * Returns a copy of a list of pay apps, sorted by billing end.
 *
 * ASC: oldest billing end (index 0) to newest billing end (last index)
 * DESC: newest billing end (index 0) to oldest billing end (last index)
 *
 * Insert the retention only pay apps as chronologically after
 * the progress pay apps - so for DESC, the retention pay apps are earlier in the list, for ASC
 * the retention pay apps are later in the list
 */
export function sortPayAppsByBillingEnd<T extends PayAppForSorting>(
  payApps: T[],
  order: 'asc' | 'desc',
  timeZone: string
): T[] {
  if (payApps.length === 0) {
    return []
  }

  // Move older pay apps earlier if order is ASC, else move them later. Do the reverse for newer.
  const moveOlderPayApps = order === 'asc' ? -1 : 1
  const moveNewerPayApps = order === 'asc' ? 1 : -1
  return [...payApps].sort((a, b) => {
    const sameDate = isSameDate(a, b, timeZone)
    if (sameDate) {
      return a.retentionOnly ? moveNewerPayApps : moveOlderPayApps
    }
    return a.billingEnd > b.billingEnd ? moveNewerPayApps : moveOlderPayApps
  })
}

/**
 * The default expected number of days for a pay app to be paid. Based on standard NET 30 payment
 * terms.
 */
export const DEFAULT_GC_PAYMENT_TERMS = 30

/**
 * Can pay app transition to submitted from current status
 * and current integration status?
 */
export function canMarkPayAppAsSubmitted(
  status: PayAppStatus,
  hasGcPortalIntegration: boolean
): boolean {
  return (
    status === PayAppStatus.DRAFT ||
    status === PayAppStatus.SIGNED ||
    (!hasGcPortalIntegration && status === PayAppStatus.SYNC_FAILED)
  )
}

/**
 * Can pay app transition to synced from current status
 * and current integration status?
 */
export function canMarkPayAppAsSynced(status: PayAppStatus): boolean {
  return (
    status === PayAppStatus.DRAFT ||
    status === PayAppStatus.SYNC_FAILED ||
    status === PayAppStatus.SYNC_PENDING
  )
}

/**
 * We use the following logic for default invoice code:
 * 1. Take the project number, which is internal project number, or GC # if no internal # exists
 * 2. Append a two-digit pay app number, e.g. <project-number>-01
 * 3. If this code is longer than the ERP supports, leave the input empty
 * We fall back to no default code because it's most important that the code we suggest is unique
 * and using only project # as a default would cause conflicts across pay apps on the same job.
 */
export function getDefaultInvoiceCode({
  internalProjectNumber,
  projectNumber,
  payAppNumber,
  maxInvoiceCodeLength,
  billingType,
}: {
  internalProjectNumber?: string | null
  projectNumber: string
  payAppNumber?: number
  maxInvoiceCodeLength?: number
  billingType: BillingType | undefined
}): string {
  const jobNumber = internalProjectNumber ?? projectNumber
  return generateDefaultCode({
    projectNumber: jobNumber,
    payAppNumber,
    maxLength: maxInvoiceCodeLength,
    billingType,
  })
}

// Separate out the internal logic for generating the default invoice code
// so we can have more control over which project number is used
export function generateDefaultCode({
  projectNumber,
  payAppNumber,
  maxLength,
  billingType,
}: {
  projectNumber: string | null
  payAppNumber?: number
  maxLength?: number
  billingType: BillingType | undefined
}) {
  if (!projectNumber || !_.isNumber(payAppNumber)) {
    return ''
  }
  let trimmedProjectNumber = projectNumber.trim()
  if (_.last(trimmedProjectNumber) === '-') {
    trimmedProjectNumber = trimmedProjectNumber.slice(0, -1)
  }
  const paddedPayAppNumber = _.padStart(String(payAppNumber), 2, '0')
  // Since quick bills only have a single pay app, use the project number alone as the default code
  const baseDefaultCode =
    billingType === BillingType.QUICK
      ? trimmedProjectNumber
      : [trimmedProjectNumber, paddedPayAppNumber].join('-')
  let defaultCode = baseDefaultCode.substring(0, maxLength)
  if (defaultCode !== baseDefaultCode) {
    defaultCode = ''
  }
  return defaultCode
}

type DefaultInvoiceDates = {
  invoiceDate: Moment
  dueDate: Moment
}

/**
 * Returns default dates for a pay app invoice based on the company's aging interval type and the
 * pay app dates
 */
export function getDefaultInvoiceDates({
  agingIntervalType,
  billingEnd,
  timeZone,
  paymentTerms,
  submittedAt,
}: {
  agingIntervalType: AgingIntervalType
  billingEnd: Moment
  timeZone: string
  paymentTerms: number | null
  /**
   * If the pay app was already submitted, we use the pay app date as the invoice date for
   * DATE_SUBMITTED; if the pay app isn't submitted yet, we use today's date.
   */
  submittedAt: Moment | null
}): DefaultInvoiceDates {
  // If payment terms are set on the contract, use those as the default interval for days until
  // due. Otherwise we use a fixed default.
  const defaultDueDateInterval = paymentTerms ?? DEFAULT_GC_PAYMENT_TERMS
  switch (agingIntervalType) {
    case AgingIntervalType.DATE_SUBMITTED: {
      const invoiceDate = submittedAt ?? moment.tz(timeZone)
      return {
        invoiceDate,
        dueDate: invoiceDate.clone().add(defaultDueDateInterval, 'days'),
      }
    }
    case AgingIntervalType.BILLING_END: {
      return {
        invoiceDate: billingEnd,
        dueDate: billingEnd.clone().add(defaultDueDateInterval, 'days'),
      }
    }
  }
}
