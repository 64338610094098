import AssignmentIcon from '@mui/icons-material/Assignment'
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined'
import CheckIcon from '@mui/icons-material/Check'
import {
  Button,
  ButtonProps,
  IconButton,
  IconButtonProps,
  TableCell,
  TableRow,
  Tooltip,
} from '@mui/material'
import copy from 'copy-to-clipboard'
import { useState } from 'react'

type IdentifierRowProps = {
  id: string
}

export function IdentifierButton({ id, ...rest }: IdentifierRowProps & ButtonProps) {
  const [didCopy, setDidCopy] = useState(false)
  const onCopy = () => {
    copy(id)
    setDidCopy(true)
    setTimeout(() => setDidCopy(false), 5000)
  }
  return (
    <Button
      variant="outlined"
      size="medium"
      endIcon={didCopy ? <CheckIcon htmlColor="#2ecc71" /> : <AssignmentIcon />}
      onClick={onCopy}
      fullWidth
      {...rest}
    >
      {id}
    </Button>
  )
}

export function IdentifierIconButton({ id, ...rest }: IdentifierRowProps & IconButtonProps) {
  const [didCopy, setDidCopy] = useState(false)
  const onCopy = () => {
    copy(id)
    setDidCopy(true)
    setTimeout(() => setDidCopy(false), 5000)
  }
  return (
    <Tooltip title="Copy ID" placement="top" arrow>
      <IconButton size="small" onClick={onCopy} {...rest}>
        {didCopy ? <CheckIcon htmlColor="#2ecc71" /> : <AssignmentOutlinedIcon />}
      </IconButton>
    </Tooltip>
  )
}

export default function IdentifierRow({ id }: IdentifierRowProps) {
  return (
    <TableRow>
      <TableCell>ID</TableCell>
      <TableCell colSpan={2}>
        <IdentifierButton id={id} fullWidth />
      </TableCell>
    </TableRow>
  )
}
