import { gql } from '@apollo/client'
import { Alert, Chip, Container, Grid2, Theme, Typography } from '@mui/material'
import { useParams } from 'react-router-dom'
import { makeStylesFast } from 'siteline-common-web'
import { Loader } from '../../common/components/Loader'
import Page from '../../common/components/Page'
import * as fragments from '../../common/graphql/Fragments'
import { useGetUserForDetailsQuery } from '../../common/graphql/apollo-operations'
import UserDetailsCompanies from './UserDetailsCompanies'
import UserDetailsNotificationPreferences from './UserDetailsNotificationPreferences'
import UserDetailsOverview from './UserDetailsOverview'
import UserDetailsProjects from './UserDetailsProjects'

gql`
  query getUserForDetails($userId: ID!) {
    user(id: $userId) {
      ...UserProperties
      companyUsers {
        id
        status
        role
        company {
          ...CompanyProperties
        }
      }
    }
  }
  ${fragments.user}
`

const useStyles = makeStylesFast((theme: Theme) => ({
  root: {
    padding: theme.spacing(3, 0),
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    '& .MuiChip-root': {
      marginLeft: theme.spacing(1),
    },
  },
}))

type UserDetailsParams = {
  userId?: string
}

export default function UserDetails() {
  const classes = useStyles()
  const { userId } = useParams() as UserDetailsParams

  if (!userId) {
    throw new Error('No user ID provided')
  }

  const { data, error, loading } = useGetUserForDetailsQuery({
    variables: { userId },
  })

  const name = data ? `${data.user.firstName} ${data.user.lastName}` : 'User'

  return (
    <Page className={classes.root} title={name}>
      <Container maxWidth={false}>
        <Grid2 container spacing={2}>
          <Grid2 size={{ xs: 12 }} className={classes.title}>
            <Typography variant="h4">{name}</Typography>
            {data?.user && !data.user.policiesAcceptedAt && (
              <Chip label="INVITED" color="default" style={{ backgroundColor: '#ffd745' }} />
            )}
          </Grid2>
          {data?.user && (
            <>
              <UserDetailsOverview user={data.user} />
              <UserDetailsCompanies user={data.user} />
              <UserDetailsProjects user={data.user} />
              <UserDetailsNotificationPreferences user={data.user} />
            </>
          )}
        </Grid2>
        {error && <Alert severity="error">{error.message}</Alert>}
        {loading && <Loader />}
      </Container>
    </Page>
  )
}
