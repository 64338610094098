import { gql } from '@apollo/client'
import { Close, InfoOutlined } from '@mui/icons-material'
import {
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material'
import _ from 'lodash'
import moment from 'moment-timezone'
import queryString from 'query-string'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { decimalToPercent } from 'siteline-common-all'
import { SitelineText, colors } from 'siteline-common-web'
import { useFormTrackerQuery } from '../../common/graphql/apollo-operations'
import { getXAxisProps } from '../../common/util/Chart'
import { NUM_COMPANIES_PER_CHART_PAGE } from './BillingTotalsDashboard'
import { ChartTitleAndArrows } from './ChartTitleAndArrows'
import { percentFormatter } from './ComplianceUsageChart'

gql`
  query formTracker($input: FormTrackerInput!) {
    formTracker(input: $input) {
      companyId
      companyName
      companyCreatedAt
      requestedFormsCount
      requestedFormIds
      completedFormsCount
      completedFormIds
      formsCompletedRatio
      sitelinePointOfContactId
      sitelinePointOfContactName
    }
  }
`

/**
 * Allow sorting in 3 ways:
 * companyName: Alphabetical by company name, ascending
 * customerAge: Ordered by company creation date, with newest companies first
 * formsCompleted: Ordered by the percent of forms completed, ascending
 */
type SortBy = 'companyName' | 'customerAge' | 'formsCompleted'
const INCOMPLETE_FORMS_KEY = 'Incomplete forms'
const COMPLETE_FORMS_KEY = 'Completed forms'
const FORMS_COMPLETED_RATIO_KEY = 'Percent completed'

interface FormTrackerDashboardProps {
  isSmallScreen: boolean
}

/** Dashboard for tracking percent of requested forms per customer that are completed */
export function FormTrackerDashboard({ isSmallScreen }: FormTrackerDashboardProps) {
  const location = useLocation()
  const navigate = useNavigate()
  const [startIndex, setStartIndex] = useState<number>(0)
  const { data, loading } = useFormTrackerQuery({
    variables: {
      input: {
        startDate:
          // Default to the last 3 months
          moment.utc().subtract(3, 'months').startOf('month').toISOString(),
      },
    },
  })

  const sortBy = useMemo(() => {
    return queryString.parse(location.search).sortBy as SortBy | null
  }, [location.search])
  const handleSortByChange = useCallback(
    (sortBy: SortBy, replace?: boolean) => {
      const search = new URLSearchParams(location.search)
      search.set('sortBy', sortBy)
      navigate({ search: search.toString() }, { replace })
      setStartIndex(0)
    },
    [location.search, navigate]
  )

  const accountOwner = useMemo(() => {
    return queryString.parse(location.search).accountOwner as string | null
  }, [location.search])
  const handleAccountOwnerChange = useCallback(
    (userId: string | null, replace?: boolean) => {
      const search = new URLSearchParams(location.search)
      if (userId) {
        search.set('accountOwner', userId)
      } else {
        search.delete('accountOwner')
      }
      navigate({ search: search.toString() }, { replace })
      setStartIndex(0)
    },
    [location.search, navigate]
  )

  // Default to sorting by percent completed
  useEffect(() => {
    if (!sortBy) {
      handleSortByChange('formsCompleted', true)
    }
  }, [handleSortByChange, sortBy])

  const filterCompanyIds = useMemo(() => {
    const { companies } = queryString.parse(location.search)
    let companyIds: string[] = []
    if (typeof companies === 'string') {
      companyIds = [companies]
    } else if (Array.isArray(companies)) {
      companyIds = _.compact(companies)
    }
    return _.chain(companyIds)
      .flatMap((companyId) => companyId.split(','))
      .map(decodeURIComponent)
      .value()
  }, [location.search])
  const handleFilterCompanyIds = useCallback(
    (companyIds: string[]) => {
      const search = new URLSearchParams(location.search)
      if (companyIds.length > 0) {
        search.set('companies', companyIds.join(','))
      } else {
        search.delete('companies')
      }
      navigate({ search: search.toString() })
      setStartIndex(0)
    },
    [location.search, navigate]
  )
  const formsData = useMemo(() => data?.formTracker ?? [], [data])

  const chartData = useMemo(() => {
    const companyData = formsData
      .filter(({ companyId }) => {
        return filterCompanyIds.length === 0 || filterCompanyIds.includes(companyId)
      })
      .filter((companyData) => {
        return !accountOwner || companyData.sitelinePointOfContactId === accountOwner
      })
      .map(
        ({
          companyName,
          companyId,
          companyCreatedAt,
          completedFormsCount,
          requestedFormsCount,
          formsCompletedRatio,
        }) => {
          return {
            companyId,
            companyName,
            companyCreatedAt: moment.utc(companyCreatedAt).unix(),
            [INCOMPLETE_FORMS_KEY]: requestedFormsCount - completedFormsCount,
            [COMPLETE_FORMS_KEY]: completedFormsCount,
            [FORMS_COMPLETED_RATIO_KEY]: formsCompletedRatio,
          }
        }
      )

    return _.chain(companyData)
      .compact()
      .orderBy([
        (data) => {
          switch (sortBy) {
            case 'companyName':
              return data['companyName']
            case 'customerAge':
              return -1 * Number(data['companyCreatedAt'])
            case 'formsCompleted':
              return Number(data[FORMS_COMPLETED_RATIO_KEY])
            case null:
              return ''
          }
        },
        // Use number of forms completed as a secondary sort
        (data) => Number(data[COMPLETE_FORMS_KEY]),
      ])
      .value()
  }, [accountOwner, filterCompanyIds, formsData, sortBy])

  const numCompaniesInChart = chartData.length
  const currentPageChartData = useMemo(
    () =>
      chartData.slice(
        startIndex,
        Math.min(startIndex + NUM_COMPANIES_PER_CHART_PAGE, numCompaniesInChart)
      ),
    [chartData, numCompaniesInChart, startIndex]
  )

  const sortedCompanies = useMemo(
    () =>
      _.chain(formsData)
        .map(({ companyName, companyId }) => ({ companyName, companyId }))
        .orderBy(({ companyName }) => companyName)
        .value(),
    [formsData]
  )

  const accountOwners = useMemo(
    () =>
      _.chain(formsData)
        .map(({ sitelinePointOfContactId, sitelinePointOfContactName }) => {
          return {
            id: sitelinePointOfContactId,
            name: sitelinePointOfContactName,
          }
        })
        .compact()
        .filter(
          (pointOfContact): pointOfContact is { id: string; name: string } =>
            _.isString(pointOfContact.id) && _.isString(pointOfContact.name)
        )
        .uniqBy(({ name }) => name)
        .sortBy(({ name }) => name)
        .value(),
    [formsData]
  )

  const yAxisDomain = useMemo(() => {
    // Use the max value across all companies, not only the ones currently visible in the chart
    const maxNumber = _.chain(chartData)
      .map((data) => data[COMPLETE_FORMS_KEY] + data[INCOMPLETE_FORMS_KEY])
      .max()
      .value()
    return [0, maxNumber]
  }, [chartData])

  const {
    labelLength: xAxisLabelLength,
    xAxisHeight,
    tickAngle,
    labelAnchor: xAxisLabelAnchor,
  } = useMemo(() => getXAxisProps(isSmallScreen), [isSmallScreen])

  return (
    <>
      <ChartTitleAndArrows
        title={
          <>
            <FormControl>
              <InputLabel id="sortBy">Sort by</InputLabel>
              <Select
                // Needed to trigger a re-mount when the sortBy value is initially set, so the Select
                // component updates with the correct value
                key={sortBy}
                labelId="sortBy"
                value={sortBy}
                label="Sort by"
                onChange={(ev) => {
                  handleSortByChange(ev.target.value as SortBy)
                }}
              >
                <MenuItem value="companyName">Company name</MenuItem>
                <MenuItem value="customerAge">Customer age</MenuItem>
                <MenuItem value="formsCompleted">% forms completed</MenuItem>
              </Select>
            </FormControl>
            <FormControl>
              <InputLabel id="companySelect">
                {filterCompanyIds.length > 0 ? 'Companies' : 'Filter by company'}
              </InputLabel>
              <Select
                style={{ width: 300 }}
                labelId="companySelect"
                value={filterCompanyIds}
                label={filterCompanyIds.length > 0 ? 'Companies' : 'Filter by company'}
                onChange={(ev) => {
                  handleFilterCompanyIds(ev.target.value as string[])
                }}
                multiple
                renderValue={() => `${filterCompanyIds.length} selected`}
                endAdornment={
                  filterCompanyIds.length > 0 ? (
                    <IconButton
                      onClick={() => handleFilterCompanyIds([])}
                      sx={{ marginRight: 1.5 }}
                    >
                      <Close fontSize="small" />
                    </IconButton>
                  ) : undefined
                }
              >
                {sortedCompanies.map(({ companyName, companyId }) => (
                  <MenuItem key={companyId} value={companyId}>
                    {companyName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl>
              <InputLabel id="accountOwner">Account owner</InputLabel>
              <Select
                key={accountOwner}
                style={{ minWidth: 150 }}
                labelId="accountOwner"
                value={accountOwner ?? 'All'}
                label="Account owner"
                onChange={(ev) => {
                  handleAccountOwnerChange(ev.target.value as string)
                }}
                endAdornment={
                  accountOwner ? (
                    <IconButton
                      onClick={() => handleAccountOwnerChange(null)}
                      sx={{ marginRight: 1.5 }}
                    >
                      <Close fontSize="small" />
                    </IconButton>
                  ) : undefined
                }
              >
                <MenuItem value="All">All</MenuItem>
                {accountOwners.map(({ id, name }) => (
                  <MenuItem key={id} value={id}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </>
        }
        startIndex={startIndex}
        onStartIndexChange={setStartIndex}
        numCompaniesInChart={numCompaniesInChart}
        numCompaniesPerPage={NUM_COMPANIES_PER_CHART_PAGE}
      />
      {!loading && (
        <>
          <SitelineText
            variant="body1"
            color="grey50"
            startIcon={<InfoOutlined fontSize="small" />}
            style={{ marginLeft: 24 }}
          >
            Includes form templates created in the last 3 months
          </SitelineText>
          <ResponsiveContainer height={500} width="100%" style={{ marginTop: 16 }}>
            <BarChart data={currentPageChartData} margin={{ bottom: 32, right: 32 }}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="companyName"
                tick={{ fontSize: 12 }}
                interval={0}
                tickFormatter={(companyName) =>
                  _.truncate(companyName, { length: xAxisLabelLength })
                }
                angle={tickAngle}
                height={xAxisHeight}
                textAnchor={xAxisLabelAnchor}
              />
              <YAxis domain={yAxisDomain} />
              <Tooltip
                formatter={(value, name) =>
                  name === FORMS_COMPLETED_RATIO_KEY
                    ? percentFormatter(decimalToPercent(Number(value), 0))
                    : value
                }
              />
              <Bar dataKey={COMPLETE_FORMS_KEY} stackId="forms" fill={colors.blue50} />
              <Bar dataKey={INCOMPLETE_FORMS_KEY} stackId="forms" fill={colors.red70} />
              {/* Include a hidden bar for percent completed so it's shown in the tooltip */}
              <Bar
                dataKey={FORMS_COMPLETED_RATIO_KEY}
                stackId="forms"
                fill={colors.blue70}
                fillOpacity={0}
              />
            </BarChart>
          </ResponsiveContainer>
        </>
      )}
      {loading && (
        <div
          style={{
            height: 500,
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress />
        </div>
      )}
    </>
  )
}
