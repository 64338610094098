import { gql } from '@apollo/client'

import { Button, Card, CardContent, CardHeader, TextField } from '@mui/material'
import { Theme } from '@mui/material/styles'
import clsx from 'clsx'
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react'
import {
  SitelineText,
  colors,
  makeStylesFast,
  useSitelineSnackbar,
  useToggle,
} from 'siteline-common-web'
import { clampToLines } from 'siteline-common-web/src/utils/CSS'
import { DetailedCompany } from '../../common/graphql/Fragments'
import {
  useCompanyInternalSitelineNotesQuery,
  useSetCompanyInternalSitelineNotesMutation,
} from '../../common/graphql/apollo-operations'
import NotaryTagMultiSelect from './NotaryTagMultiSelect'

gql`
  mutation setCompanyInternalSitelineNotes($input: SetCompanyInternalSitelineNotesInput!) {
    setCompanyInternalSitelineNotes(input: $input)
  }
`

gql`
  query companyInternalSitelineNotes($companyId: ID!) {
    companyInternalSitelineNotes(companyId: $companyId)
  }
`

const MAX_ROWS = 12

const useStyles = makeStylesFast((theme: Theme) => ({
  root: {
    position: 'relative',
    marginBottom: theme.spacing(2),
    '& .title': {
      display: 'flex',
      justifyContent: 'space-between',
      // Other sections have a margin bottom of 2, but our button in this
      // row takes up more height, so we'll shrink down the margin
      marginBottom: theme.spacing(-3),
    },
    '& .editButton, .saveOrCancel, .goTo': {
      marginTop: theme.spacing(-1),
    },
    '& .saveButton': {
      marginLeft: theme.spacing(2),
    },
    '& .grayButton': {
      color: colors.grey50,
    },
    '& .notes': {
      display: 'inline-block',
      marginTop: theme.spacing(-1.5),
    },
    '& .savedNotes': {
      whiteSpace: 'pre-wrap',
      wordWrap: 'break-word',
      hyphens: 'auto',
      width: '100%',
      '&:not(.isExpanded)': {
        ...clampToLines(MAX_ROWS),
      },
    },
    '& .draftNotes': {
      overflow: 'scroll',
      visibility: 'visible',
      position: 'relative',
      zIndex: 'auto',
      width: 'calc(100% - 8px)',
      '&:not(.isEditMode)': {
        position: 'absolute',
        zIndex: -1,
        visibility: 'hidden',
        width: '100%',
      },
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        border: `1px solid ${colors.blue40}`,
      },
    },
  },
}))

interface CompanyNotesProps {
  company: DetailedCompany
}

/**
 * Component displayed on the right hand side of project home, allows users
 * to leave important notes and FYIs on the project
 */
export function CompanyNotesCard({ company }: CompanyNotesProps) {
  const classes = useStyles()
  const snackbar = useSitelineSnackbar()

  const { data } = useCompanyInternalSitelineNotesQuery({
    variables: { companyId: company.id },
  })
  const [updateNotes] = useSetCompanyInternalSitelineNotesMutation()
  const initialInternalNotes = useMemo(
    () => data?.companyInternalSitelineNotes ?? '',
    [data?.companyInternalSitelineNotes]
  )

  const [isEditMode, handleEnterEditMode, handleExitEditMode] = useToggle()
  const [internalNotes, setInternalNotes] = useState<string>(initialInternalNotes)

  // When value from API changes, update the state
  useEffect(() => {
    setInternalNotes(initialInternalNotes)
  }, [initialInternalNotes])

  const handleEditClick = useCallback(() => {
    handleEnterEditMode()
  }, [handleEnterEditMode])

  const handleNotesUpdate = useCallback((event: ChangeEvent<HTMLTextAreaElement>) => {
    setInternalNotes(event.target.value)
  }, [])

  const handleExitNoteEditing = useCallback(() => {
    handleExitEditMode()
  }, [handleExitEditMode])

  const handleNotesSave = useCallback(async () => {
    const confirmed = window.confirm('Are you sure you want to update the company notes?')
    if (!confirmed) {
      return
    }
    try {
      await updateNotes({
        variables: {
          input: {
            companyId: company.id,
            internalSitelineNotes: internalNotes,
          },
        },
        optimisticResponse: {
          __typename: 'Mutation',
          setCompanyInternalSitelineNotes: true,
        },
      })
      snackbar.showSuccess('Successfully updated company notes')
    } catch (error) {
      snackbar.showError(error.message)
    }
    handleExitNoteEditing()
  }, [company.id, handleExitNoteEditing, internalNotes, snackbar, updateNotes])

  const handleNotesCancel = useCallback(() => {
    setInternalNotes(initialInternalNotes)
    handleExitNoteEditing()
  }, [handleExitNoteEditing, initialInternalNotes])

  const isEmptyState = internalNotes === ''

  return (
    <Card className={clsx(classes.root, { isEditMode })}>
      <CardHeader
        title="Internal Company Notes"
        action={
          <div className="title">
            {!isEditMode && (
              <Button
                variant="text"
                color="secondary"
                className={clsx('editButton', 'grayButton')}
                onClick={handleEditClick}
              >
                Edit notes
              </Button>
            )}
            {isEditMode && (
              <div className="saveOrCancel">
                <Button variant="text" color="secondary" onClick={handleNotesCancel}>
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleNotesSave}
                  className="saveButton"
                  disabled={initialInternalNotes === internalNotes}
                >
                  Save
                </Button>
              </div>
            )}
          </div>
        }
      />
      <CardContent>
        {!isEditMode && (
          <>
            {!isEmptyState && (
              <SitelineText variant="body1" className={clsx('savedNotes', 'notes')}>
                {internalNotes}
              </SitelineText>
            )}
            {isEmptyState && (
              <SitelineText variant="body2" color="grey50">
                Add internal company notes here
              </SitelineText>
            )}
          </>
        )}
        <TextField
          fullWidth
          variant="outlined"
          className={clsx('draftNotes', 'notes', { isEditMode })}
          value={internalNotes}
          multiline
          onChange={handleNotesUpdate}
          maxRows={MAX_ROWS}
        />
      </CardContent>
      <NotaryTagMultiSelect company={company} />
    </Card>
  )
}
