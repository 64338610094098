import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Link,
  List,
  ListItem,
  Typography,
} from '@mui/material'

export function TemplateVariablesUsage() {
  return (
    <Card>
      <CardHeader title="Using template variables" />
      <CardContent>
        <Typography variant="subtitle1">Basic Usage:</Typography>
        <pre style={{ fontSize: 14 }}>
          &#123;project.name&#125;
          <br />
          &#123;sov.lineItems[0].totalValue&#125;
          <br />
          &#123;g702.changeOrders.additions.previousApproved&#125;
        </pre>

        <Divider />

        <Typography variant="subtitle1" style={{ marginTop: 10 }}>
          Looping:
        </Typography>
        <List>
          <ListItem>
            <Link href="https://docxtemplater.com/demo/#xlsx" underline="hover">
              Looping in XLSX files
            </Link>
          </ListItem>
          <ListItem>
            <Link href="https://docxtemplater.com/demo/#loops" underline="hover">
              Looping in DOCX files
            </Link>
          </ListItem>
        </List>
        <pre style={{ fontSize: 14, marginTop: 0 }}>
          &#123;#sov&#125;&#123;#lineItems&#125;&#123;totalValue&#125;&#123;/lineItems&#125;&#123;/sov&#125;
        </pre>
      </CardContent>
    </Card>
  )
}
