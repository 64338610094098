import { Chip, Tooltip } from '@mui/material'
import { WriteSyncOperationStatus, integrationTypes } from 'siteline-common-all'
import { colors } from 'siteline-common-web'
import { WriteSyncOperation } from './WriteSyncOperationRow'

type WriteSyncOperationStatusChipProps = {
  operation: WriteSyncOperation
}

/**
 * Status chip for a single write sync operation, displayed in the list of write syncs.
 */
export function WriteSyncOperationStatusChip({ operation }: WriteSyncOperationStatusChipProps) {
  let color: string
  let label: string
  let tooltip: string | undefined
  switch (operation.status) {
    case WriteSyncOperationStatus.NOT_STARTED:
      label = 'Not started'
      color = colors.orange20
      tooltip =
        'This operation has not been processed. It might be stuck in the queue because of an internal error. You can mark the sync as failed if necessary.'
      break
    case WriteSyncOperationStatus.CANCELED:
      label = 'Canceled'
      color = colors.grey20
      break
    case WriteSyncOperationStatus.COMPLETED:
      if (operation.result) {
        const result = operation.result as integrationTypes.WriteSyncResult
        switch (result.type) {
          case 'success':
            label = 'Success'
            color = colors.green20
            break
          case 'failure':
            label = 'Failure'
            color = colors.red20
            tooltip = result.error
            break
          case 'queuedInHh2':
            label = 'Queued in HH2'
            color = colors.blue20
            break
        }
      } else {
        label = 'Completed'
        color = colors.grey20
      }
      break

    case WriteSyncOperationStatus.DEFERRED:
      label = 'Deferred'
      color = colors.yellow20
      break
    case WriteSyncOperationStatus.QUEUED_IN_HH2:
      label = 'Queued in HH2'
      color = colors.blue20
      break
    case WriteSyncOperationStatus.RUNNING:
      label = 'Running'
      color = colors.blue20
      break
  }

  return (
    <Tooltip title={tooltip ?? ''} arrow placement="top">
      <Chip
        size="small"
        sx={{
          backgroundColor: color,
          verticalAlign: 'text-top',
        }}
        label={label}
      />
    </Tooltip>
  )
}
