import { Chip } from '@mui/material'
import { colors } from 'siteline-common-web'

/** Chip that displays the status of a form template */
export function FormTemplateReadyForManualStoredMaterialsWarningChip() {
  return (
    <Chip
      size="medium"
      sx={{
        backgroundColor: colors.yellow20,
        verticalAlign: 'text-top',
      }}
      label="Not ready for manual stored materials"
    />
  )
}
