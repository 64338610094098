import { gql } from '@apollo/client'
import _ from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { useSitelineSnackbar } from 'siteline-common-web'
import * as fragments from '../../common/graphql/Fragments'
import {
  BillingType,
  PayAppRequirementGroupProperties,
  useUpdatePayAppRequirementGroupsMutation,
} from '../../common/graphql/apollo-operations'
import PayAppRequirementGroups, {
  PayAppRequirementGroupWithKey,
} from '../pay-app-requirement/PayAppRequirementGroups'

gql`
  mutation updatePayAppRequirementGroups($input: UpdatePayAppRequirementGroupsInput!) {
    updatePayAppRequirementGroups(input: $input) {
      ...ContractProperties
    }
  }
  ${fragments.contract}
`

interface ContractDetailsPayAppRequirementsProps {
  contractId: string
  billingType: BillingType
  usesManualStoredMaterials: boolean
  payAppRequirementGroups: PayAppRequirementGroupProperties[]
}

export default function ContractDetailsPayAppRequirements({
  contractId,
  billingType,
  usesManualStoredMaterials,
  payAppRequirementGroups,
}: ContractDetailsPayAppRequirementsProps) {
  const snackbar = useSitelineSnackbar()
  const orderedRequirementGroups = useMemo(
    () =>
      [...payAppRequirementGroups]
        .sort((a, b) => (a.order > b.order ? 1 : -1))
        .map((requirementGroup): PayAppRequirementGroupWithKey => {
          // Remove __typename from these objects as the update mutation does not accept them
          return {
            id: requirementGroup.id,
            key: requirementGroup.id,
            order: requirementGroup.order,
            requirements: requirementGroup.payAppRequirements.map((requirement) => ({
              id: requirement.id,
              key: requirement.id,
              groupOrder: requirement.groupOrder,
              templateVariantId: requirement.templateVariant?.id ?? null,
              conditions: requirement.conditions,
            })),
          }
        }),
    [payAppRequirementGroups]
  )
  const [newPayAppRequirementGroups, setNewPayAppRequirementGroups] =
    useState<PayAppRequirementGroupWithKey[]>(orderedRequirementGroups)

  // Update the pay app requirement groups if they change from the server
  useEffect(() => {
    setNewPayAppRequirementGroups(orderedRequirementGroups)
  }, [orderedRequirementGroups])

  const [isEditing, setIsEditing] = useState(false)
  const [updatePayAppRequirementGroups] = useUpdatePayAppRequirementGroupsMutation()

  const save = async () => {
    const requirementGroups = newPayAppRequirementGroups.map((group) => ({
      ..._.omit(group, 'key'),
      requirements: group.requirements.map((req) => _.omit(req, 'key')),
    }))
    setIsEditing(false)
    await updatePayAppRequirementGroups({
      variables: {
        input: {
          contractId,
          requirementGroups,
        },
      },
    }).catch((err) => snackbar.showError(err.message))
  }

  const onCancel = () => {
    if (isEditing) {
      setNewPayAppRequirementGroups(_.cloneDeep(orderedRequirementGroups))
    }
    setIsEditing(!isEditing)
  }

  return (
    <PayAppRequirementGroups
      billingType={billingType}
      usesManualStoredMaterials={usesManualStoredMaterials}
      payAppRequirementGroups={newPayAppRequirementGroups}
      setPayAppRequirementGroups={setNewPayAppRequirementGroups}
      isEditing={isEditing}
      save={save}
      onCancel={onCancel}
    />
  )
}
