import { Card, CardContent, CardHeader } from '@mui/material'
import { useEffect, useState } from 'react'
import { useSitelineSnackbar } from 'siteline-common-web'
import { Contract } from '../../../common/graphql/Fragments'
import {
  useDisableLowerTierLienWaiversMutation,
  useDisablePrimaryLienWaiversMutation,
  useEnableLowerTierLienWaiversMutation,
  useEnablePrimaryLienWaiversMutation,
} from '../../../common/graphql/apollo-operations'
import {
  LienWaiverTemplateSet,
  LienWaiverTemplateSetValues,
} from '../../lien-waiver-template-set/LienWaiverTemplateSet'

type ContractLienWaiverSettingsProps = {
  contract: Contract
}

export default function ContractLienWaiverSettings({ contract }: ContractLienWaiverSettingsProps) {
  const snackbar = useSitelineSnackbar()
  const [enablePrimaryLienWaivers] = useEnablePrimaryLienWaiversMutation()
  const [disablePrimaryLienWaivers] = useDisablePrimaryLienWaiversMutation()
  const [enableLowerTierLienWaivers] = useEnableLowerTierLienWaiversMutation()
  const [disableLowerTierLienWaivers] = useDisableLowerTierLienWaiversMutation()

  const [primaryLienWaiverSet, setPrimaryLienWaiverSet] =
    useState<LienWaiverTemplateSetValues | null>(contract.lienWaiverTemplates)
  const [lowerTierLienWaiverSet, setLowerTierLienWaiverSet] =
    useState<LienWaiverTemplateSetValues | null>(contract.lowerTierLienWaiverTemplates)

  // Update the lien waiver sets if the contract from the server changes
  useEffect(() => {
    setPrimaryLienWaiverSet(contract.lienWaiverTemplates)
    setLowerTierLienWaiverSet(contract.lowerTierLienWaiverTemplates)
  }, [contract.lienWaiverTemplates, contract.lowerTierLienWaiverTemplates])

  const onChange = (
    type: 'primary' | 'lowerTier',
    lienWaiverSet: LienWaiverTemplateSetValues | null
  ) => {
    if (type === 'primary') {
      setPrimaryLienWaiverSet(lienWaiverSet)
    } else {
      setLowerTierLienWaiverSet(lienWaiverSet)
    }

    // Enable settings
    if (lienWaiverSet) {
      snackbar.showLoading('Updating lien-waiver templates...')
      const args = {
        variables: {
          input: {
            contractId: contract.id,
            templates: {
              conditionalFinalVariantId: lienWaiverSet.conditionalFinalVariant?.id,
              conditionalProgressVariantId: lienWaiverSet.conditionalProgressVariant?.id,
              unconditionalFinalVariantId: lienWaiverSet.unconditionalFinalVariant?.id,
              unconditionalProgressVariantId: lienWaiverSet.unconditionalProgressVariant?.id,
            },
          },
        },
      }
      const mutation: Promise<unknown> =
        type === 'primary' ? enablePrimaryLienWaivers(args) : enableLowerTierLienWaivers(args)
      return mutation
        .then(() => {
          snackbar.showSuccess()
          return true
        })
        .catch((err) => {
          snackbar.showError(err.message)
          return false
        })

      // Disable settings
    } else {
      snackbar.showLoading('Disabling lien-waiver templates...')
      const args = {
        variables: {
          contractId: contract.id,
        },
      }
      const mutation: Promise<unknown> =
        type === 'primary' ? disablePrimaryLienWaivers(args) : disableLowerTierLienWaivers(args)
      return mutation
        .then(() => {
          snackbar.showSuccess()
          return true
        })
        .catch((err) => {
          snackbar.showError(err.message)
          return false
        })
    }
  }

  const onReset = (type: 'primary' | 'lowerTier') => {
    if (type === 'primary') {
      setPrimaryLienWaiverSet(contract.lienWaiverTemplates)
    } else {
      setLowerTierLienWaiverSet(contract.lowerTierLienWaiverTemplates)
    }
  }

  return (
    <Card>
      <CardHeader title="Lien Waiver Templates" />
      <CardContent>
        <LienWaiverTemplateSet
          title="Primary"
          lienWaiverSet={primaryLienWaiverSet}
          onChange={(lienWaiverSet: LienWaiverTemplateSetValues | null) =>
            onChange('primary', lienWaiverSet)
          }
          onReset={() => onReset('primary')}
        />
      </CardContent>
      <CardContent>
        <LienWaiverTemplateSet
          title="Lower-tier"
          lienWaiverSet={lowerTierLienWaiverSet}
          onChange={(lienWaiverSet: LienWaiverTemplateSetValues | null) =>
            onChange('lowerTier', lienWaiverSet)
          }
          onReset={() => onReset('lowerTier')}
        />
      </CardContent>
    </Card>
  )
}
