import { Card, CardContent, CardHeader, TextField, Theme } from '@mui/material'
import { makeStylesFast } from 'siteline-common-web'
import { LocationInput } from '../graphql/apollo-operations'
import { LocationAutocomplete } from './LocationAutocomplete'

const useStyles = makeStylesFast((theme: Theme) => ({
  locationValue: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1, 0),
    '& span': {
      paddingRight: theme.spacing(1),
      width: 150,
    },
    '& input': {
      width: 300,
    },
  },
}))

interface LocationValueProps {
  location: LocationInput | null
  field: keyof LocationInput
  disabled: boolean
  setValue: (value: string) => void
}

function LocationValue({ location, field, disabled, setValue }: LocationValueProps) {
  const classes = useStyles()

  return (
    <div className={classes.locationValue}>
      <TextField
        label={field}
        value={location ? location[field] : ''}
        onChange={(ev) => setValue(ev.target.value)}
        size="small"
        disabled={disabled}
      />
    </div>
  )
}

interface LocationCardProps {
  location: LocationInput | null
  setLocation: (location: LocationInput) => void
}

export function LocationCard({ location, setLocation }: LocationCardProps) {
  const setValue = (field: keyof LocationInput, value: string) => {
    if (!location) {
      return
    }
    setLocation({ ...location, [field]: value })
  }

  // NOTE: This makes the assumption that only 1 timezone exists in every city. This will break in
  // the following scenario:
  // 1. enter in an address in one of the handful of cities that have multiple timezones
  // 2. change the address manually (not in the autocomplete) to a different part of the city
  // This feels so unlikely that the assumption feels fair.
  const editableFields: (keyof LocationInput)[] = [
    'nickname',
    'street1',
    'street2',
    'postalCode',
    'county',
    'city',
    'state',
    'country',
  ]

  return (
    <Card>
      <CardHeader title="Location" />
      <CardContent>
        <LocationAutocomplete location={location} setLocation={setLocation} />
        {editableFields.map((field, index) => (
          <LocationValue
            key={index}
            location={location}
            field={field}
            // Once a location has been specified in the autocomplete, most fields become editable
            disabled={location === null}
            setValue={(value) => setValue(field, value)}
          />
        ))}
      </CardContent>
    </Card>
  )
}
