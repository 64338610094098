import { Button, Theme, Typography } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'
import { makeStylesFast } from 'siteline-common-web'
import Page from '../../common/components/Page'
import pageNotFound from '../../images/page-not-found.svg'

const useStyles = makeStylesFast((theme: Theme) => ({
  root: {
    padding: theme.spacing(3),
    paddingTop: '10vh',
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
  },
  imageContainer: {
    marginTop: theme.spacing(6),
    display: 'flex',
    justifyContent: 'center',
  },
  image: {
    maxWidth: '100%',
    width: 560,
    maxHeight: 300,
    height: 'auto',
  },
  buttonContainer: {
    marginTop: theme.spacing(6),
    display: 'flex',
    justifyContent: 'center',
  },
}))

export function NotFound() {
  const classes = useStyles()

  return (
    <Page className={classes.root} title="Error 404">
      <Typography align="center" variant="h3">
        404: The page you are looking for isn’t here
      </Typography>
      <Typography align="center" variant="subtitle2">
        You either tried some shady route or you came here by mistake. Whichever it is, try using
        the navigation
      </Typography>
      <div className={classes.imageContainer}>
        <img alt="Under development" className={classes.image} src={pageNotFound} />
      </div>
      <div className={classes.buttonContainer}>
        <Button color="primary" component={RouterLink} to="/" variant="outlined">
          Back to home
        </Button>
      </div>
    </Page>
  )
}
