import { gql } from '@apollo/client'
import { Search } from '@mui/icons-material'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import {
  Alert,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid2,
  InputAdornment,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Theme,
  Typography,
} from '@mui/material'
import _ from 'lodash'
import moment from 'moment-timezone'
import { useCallback, useMemo, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroller'
import {
  makeStylesFast,
  PaginatedListSortOrder,
  useDebouncedSearch,
  usePaginationState,
  useSitelineSnackbar,
  WriteSyncOperationsSortCriteria,
} from 'siteline-common-web'
import Page from '../../common/components/Page'
import {
  GetPaginatedWriteSyncOperationsInput,
  usePaginatedWriteSyncOperationsQuery,
} from '../../common/graphql/apollo-operations'
import { WriteSyncOperationRow } from './WriteSyncOperationRow'

const useStyles = makeStylesFast((theme: Theme) => ({
  root: {
    padding: theme.spacing(3, 0),
  },
  card: {
    marginTop: theme.spacing(4),
  },
}))

gql`
  query paginatedWriteSyncOperations($input: GetPaginatedWriteSyncOperationsInput!) {
    paginatedWriteSyncOperations(input: $input) {
      hasNext
      cursor
      totalCount
      operations {
        id
        createdAt
        updatedAt
        status
        payload
        result
        createdBy {
          id
          email
          firstName
        }
        integration {
          id
          type
          contract {
            id
            internalProjectNumber
            project {
              id
              name
              projectNumber
            }
            company {
              id
              name
            }
          }
        }
      }
    }
  }
`

export default function WriteSyncOperationList() {
  const snackbar = useSitelineSnackbar()
  const [refreshedAt, setRefreshedAt] = useState<string>(
    moment.tz(moment.tz.guess()).format('MM/DD/YYYY hh:mm')
  )

  const classes = useStyles()
  const { search, onSearch, debouncedSearch } = useDebouncedSearch()
  const [sortCriteria, setSortCriteria] = useState<WriteSyncOperationsSortCriteria>(
    WriteSyncOperationsSortCriteria.CREATED_AT
  )
  const [sortOrder, setSortOrder] = useState<PaginatedListSortOrder>(PaginatedListSortOrder.DESC)

  const input = useMemo(
    (): GetPaginatedWriteSyncOperationsInput => ({
      limit: 50,
      search: debouncedSearch,
      sort: {
        criteria: sortCriteria,
        order: sortOrder,
      },
    }),
    [debouncedSearch, sortCriteria, sortOrder]
  )

  const { data, error, loading, refetch, fetchMore } = usePaginatedWriteSyncOperationsQuery({
    variables: { input },
  })

  const operations = useMemo(() => data?.paginatedWriteSyncOperations.operations ?? [], [data])
  const cursor = data?.paginatedWriteSyncOperations.cursor
  const hasNext = data?.paginatedWriteSyncOperations.hasNext ?? false
  const totalCount = data?.paginatedWriteSyncOperations.totalCount ?? 0
  const loadMore = useCallback(() => {
    fetchMore({
      variables: {
        input: { cursor, ...input },
      },
    })
  }, [cursor, fetchMore, input])

  const { showSkeletons, showLoadingMoreRow, showNoFilterResultPlaceholder } = usePaginationState({
    entities: data?.paginatedWriteSyncOperations.operations ?? null,
    loading,
    hasNext,
    isSearching: false,
    isFiltering: true,
  })

  const handleRefetch = () => {
    snackbar.showLoading('Refetching operations')
    refetch()
      .then(() => {
        snackbar.showSuccess()
        setRefreshedAt(moment.tz(moment.tz.guess()).format('MM/DD/YYYY hh:mm'))
      })
      .catch((err) => snackbar.showError(err.message))
  }

  const makeSkeletonRow = useCallback(
    (index: number) => (
      <TableRow hover key={index.toString()}>
        <TableCell>
          <Skeleton variant="text" />
        </TableCell>
        <TableCell>
          <Skeleton variant="text" />
        </TableCell>
        <TableCell>
          <Skeleton variant="text" />
        </TableCell>
        <TableCell>
          <Skeleton variant="text" />
        </TableCell>
        <TableCell>
          <Skeleton variant="text" />
        </TableCell>
        <TableCell>
          <Skeleton variant="text" />
        </TableCell>
        <TableCell>
          <Skeleton variant="text" />
        </TableCell>
        <TableCell>
          <Skeleton variant="text" />
        </TableCell>
      </TableRow>
    ),
    []
  )

  const handleSort = useCallback(
    (newSortCriteria: WriteSyncOperationsSortCriteria) => {
      let newSortOrder: PaginatedListSortOrder
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (newSortCriteria !== sortCriteria) {
        newSortOrder = PaginatedListSortOrder.ASC
      } else if (sortOrder === PaginatedListSortOrder.ASC) {
        newSortOrder = PaginatedListSortOrder.DESC
      } else {
        newSortOrder = PaginatedListSortOrder.ASC
      }
      setSortCriteria(newSortCriteria)
      setSortOrder(newSortOrder)
    },
    [sortCriteria, sortOrder]
  )

  return (
    <Page className={classes.root} title="Write syncs">
      <Container maxWidth={false}>
        <Grid2 alignItems="flex-end" container justifyContent="space-between" spacing={3}>
          <Grid2>
            <Typography variant="h4">Write syncs</Typography>
          </Grid2>
          <Grid2 sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="subtitle2" sx={{ marginRight: 2 }}>
              Refreshed at {refreshedAt}
            </Typography>
            <Button
              variant="outlined"
              color="secondary"
              style={{ marginRight: 8 }}
              onClick={handleRefetch}
            >
              Refresh
            </Button>
          </Grid2>
        </Grid2>
        {error && <Alert severity="error">{error.message}</Alert>}
        <Card className={classes.card}>
          <CardHeader
            title="Write syncs"
            subheader={
              data ? (
                <span>{totalCount} records found. All times are in Pacific time.</span>
              ) : (
                <Skeleton variant="text" sx={{ width: 100 }} />
              )
            }
          />
          <CardContent>
            <TextField
              value={search}
              onChange={(ev) => onSearch(ev.target.value)}
              size="small"
              placeholder="Search by email, project name, project number, ..."
              slotProps={{
                input: {
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                },
              }}
              sx={{ width: '600px' }}
            />
          </CardContent>
          <InfiniteScroll threshold={0} loadMore={loadMore} hasMore={hasNext} useWindow={true}>
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Button
                        size="small"
                        sx={{ textTransform: 'none' }}
                        endIcon={
                          <>
                            {/* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition */}
                            {sortCriteria === WriteSyncOperationsSortCriteria.CREATED_AT &&
                              sortOrder === PaginatedListSortOrder.ASC && <ArrowDownwardIcon />}
                            {/* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition */}
                            {sortCriteria === WriteSyncOperationsSortCriteria.CREATED_AT &&
                              sortOrder === PaginatedListSortOrder.DESC && <ArrowUpwardIcon />}
                          </>
                        }
                        onClick={() => handleSort(WriteSyncOperationsSortCriteria.CREATED_AT)}
                      >
                        Created
                      </Button>
                    </TableCell>
                    <TableCell>Duration</TableCell>
                    <TableCell>Created by</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell>Integration</TableCell>
                    <TableCell>Contract</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {operations.map((operation) => (
                    <WriteSyncOperationRow key={operation.id} operation={operation} />
                  ))}
                  {showSkeletons && _.times(8, (index) => makeSkeletonRow(index))}
                  {showNoFilterResultPlaceholder && (
                    <TableRow>
                      <TableCell colSpan={7}>No operation matches these filters.</TableCell>
                    </TableRow>
                  )}
                  {showLoadingMoreRow && makeSkeletonRow(0)}
                </TableBody>
              </Table>
            </TableContainer>
          </InfiniteScroll>
        </Card>
      </Container>
    </Page>
  )
}
