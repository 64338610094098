import { gql } from '@apollo/client'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  Accordion,
  AccordionSummary,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material'
import _ from 'lodash'
import { useCallback, useMemo } from 'react'
import { useSitelineSnackbar } from 'siteline-common-web'
import { useUpdateOnboardedProjectContractStatusMutation } from '../../common/graphql/apollo-operations'
import { ContractForDetails } from './ContractDetails'

interface ContractDetailsOnboardedStatusProps {
  contract: ContractForDetails
}

type AssignableKey = Exclude<keyof ContractForDetails['onboardedStatus'], '__typename'>

gql`
  mutation updateOnboardedProjectContractStatus(
    $input: UpdateOnboardedProjectContractStatusInput!
  ) {
    updateOnboardedProjectContractStatus(input: $input) {
      id
      onboardedStatus {
        selectedPayAppForms
        onboardedPayAppForms
        selectedPrimaryLienWaivers
        onboardedPrimaryLienWaiverForms
        selectedVendorLienWaivers
        onboardedVendorLienWaiverForms
        selectedChangeOrderRequestForms
        onboardedChangeOrderRequestForms
        addedSov
        selectedRateTable
        addedTeammates
        addedGcContacts
        startedBilling
        notifiedOnboardedForms
      }
    }
  }
`

/**
 * Project onboarding status flags, which can be enabled/disabled individually.
 */
export default function ContractDetailsOnboardedStatus({
  contract,
}: ContractDetailsOnboardedStatusProps) {
  const [update] = useUpdateOnboardedProjectContractStatusMutation()
  const snackbar = useSitelineSnackbar()
  const sortedKeys = useMemo(() => {
    const keys = _.without(Object.keys(contract.onboardedStatus), '__typename') as AssignableKey[]
    return keys.sort()
  }, [contract.onboardedStatus])

  const handleChange = useCallback(
    (key: AssignableKey, checked: boolean) => {
      update({
        variables: {
          input: {
            contractId: contract.id,
            [key]: checked,
          },
        },
        optimisticResponse: {
          __typename: 'Mutation',
          updateOnboardedProjectContractStatus: {
            __typename: 'Contract',
            id: contract.id,
            onboardedStatus: {
              ...contract.onboardedStatus,
              [key]: checked,
            },
          },
        },
      })
        .then(() => snackbar.showSuccess('Status updated'))
        .catch((err) => snackbar.showError(err.message))
    },
    [contract.id, contract.onboardedStatus, snackbar, update]
  )

  return (
    <Card>
      <Accordion>
        <AccordionSummary
          sx={{ paddingLeft: 0, paddingVertical: 0 }}
          expandIcon={<ExpandMoreIcon />}
        >
          <CardHeader title="Onboarding Status" />
        </AccordionSummary>
        <CardContent style={{ padding: 0 }}>
          <Divider />
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Key</TableCell>
                <TableCell>Value</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedKeys.map((key) => (
                <TableRow hover key={key}>
                  <TableCell>{key}</TableCell>
                  <TableCell>
                    <Switch
                      checked={contract.onboardedStatus[key]}
                      onChange={(ev, checked) => handleChange(key, checked)}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </CardContent>
      </Accordion>
    </Card>
  )
}
