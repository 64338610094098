import { gql } from '@apollo/client'
import { Alert, Container, Grid2, Theme, Typography } from '@mui/material'
import { useParams } from 'react-router-dom'
import { makeStylesFast } from 'siteline-common-web'
import { Loader } from '../../common/components/Loader'
import Page from '../../common/components/Page'
import * as fragments from '../../common/graphql/Fragments'
import { useGetPayAppForDetailsQuery } from '../../common/graphql/apollo-operations'
import PayAppDetailsContent from '../../components/pay-app-details/PayAppDetailsContent'

gql`
  query getPayAppForDetails($payAppId: ID!) {
    payApp(id: $payAppId) {
      ...PayAppProperties
      hasPinnedTemplates
      billingType
      formTemplates {
        order
        formTemplateVersion {
          ...FormTemplateVersionProperties
          formTemplate {
            id
            userVisibleName
          }
        }
      }
      contract {
        ...ContractProperties
      }
    }
  }
  ${fragments.contract}
  ${fragments.payApp}
  ${fragments.company}
  ${fragments.location}
`

const useStyles = makeStylesFast((theme: Theme) => ({
  root: {
    padding: theme.spacing(3, 0),
  },
}))

type PayAppDetailsParams = {
  payAppId?: string
}

export default function PayAppDetails() {
  const classes = useStyles()
  const { payAppId } = useParams() as PayAppDetailsParams

  if (!payAppId) {
    throw new Error('No pay-app ID provided')
  }

  const { data, error, loading } = useGetPayAppForDetailsQuery({
    variables: { payAppId },
  })

  return (
    <Page className={classes.root} title="Pay-app">
      <Container maxWidth={false}>
        <Grid2 container spacing={2}>
          <Grid2 size={{ xs: 12 }}>
            <Typography variant="h4">Pay-app</Typography>
          </Grid2>
          {data?.payApp && <PayAppDetailsContent payApp={data.payApp} />}
        </Grid2>
        {error && <Alert severity="error">{error.message}</Alert>}
        {loading && <Loader />}
      </Container>
    </Page>
  )
}
