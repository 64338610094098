import { useAgaveLink } from '@agave-api/react-agave-link'
import { gql } from '@apollo/client'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
} from '@mui/material'
import { useEffect, useMemo, useState } from 'react'
import { IntegrationType, useSitelineSnackbar } from 'siteline-common-web'
import {
  CompanyIntegrationProperties,
  useAgaveLinkTokenLazyQuery,
  useUpdateAgaveIntegrationCredentialMutation,
} from '../../common/graphql/apollo-operations'

gql`
  query agaveLinkToken($companyIntegrationId: ID!) {
    agaveLinkToken(companyIntegrationId: $companyIntegrationId)
  }
`

gql`
  mutation updateAgaveIntegrationCredential($input: UpdateAgaveIntegrationCredentialInput!) {
    updateAgaveIntegrationCredential(input: $input) {
      id
      credentialsUpdatedAt
    }
  }
`

type AgaveCredentialModalProps = {
  companyId: string
  companyIntegration: CompanyIntegrationProperties
  open: boolean
  setOpen: (open: boolean) => void
}

/** Modal to update the Agave-based credentials. */
export function UpdateAgaveCredentialModal({
  companyId,
  companyIntegration,
  open,
  setOpen,
}: AgaveCredentialModalProps) {
  const snackbar = useSitelineSnackbar()
  const [linkToken, setLinkToken] = useState<string | null>(null)
  const [getAgaveLinkToken] = useAgaveLinkTokenLazyQuery()
  const [updateCredentials] = useUpdateAgaveIntegrationCredentialMutation()

  const initialUseSandbox = useMemo(() => {
    switch (companyIntegration.type) {
      case IntegrationType.PROCORE:
        return null
      case IntegrationType.ACUMATICA:
      case IntegrationType.SPECTRUM:
      case IntegrationType.VISTA:
      case IntegrationType.FOUNDATION:
      case IntegrationType.SAGE_INTACCT:
      case IntegrationType.SAGE_100_CONTRACTOR_AGAVE:
      case IntegrationType.SAGE_300_CRE_AGAVE:
        return false
      case IntegrationType.FOUNDATION_FILE:
      case IntegrationType.GC_PAY:
      case IntegrationType.QUICKBOOKS_ENTERPRISE_FILE:
      case IntegrationType.TEXTURA:
      case IntegrationType.TEST:
      case IntegrationType.SAGE_100_CONTRACTOR:
      case IntegrationType.SAGE_300_CRE:
      case IntegrationType.COMPUTER_EASE_FILE:
        throw new Error('Invalid integration type for Agave Link')
    }
  }, [companyIntegration.type])
  const [useSandbox, setUseSandbox] = useState<boolean | null>(initialUseSandbox)

  // When the integration type changes, reset the sandbox selection
  useEffect(() => setUseSandbox(initialUseSandbox), [initialUseSandbox])

  const linkProps = useMemo((): Partial<Parameters<typeof useAgaveLink>[0]> => {
    switch (companyIntegration.type) {
      case IntegrationType.ACUMATICA:
        return { sourceSystem: 'acumatica' }
      case IntegrationType.SPECTRUM:
        return { sourceSystem: 'spectrum' }
      case IntegrationType.FOUNDATION:
        return { sourceSystem: 'foundation' }
      // This is used for both hosted and on-prem instances of Vista
      case IntegrationType.VISTA:
        return { sourceSystem: 'vista-hosted' }
      case IntegrationType.SAGE_INTACCT:
        return { sourceSystem: 'sage-intacct' }
      case IntegrationType.SAGE_100_CONTRACTOR_AGAVE:
        return { sourceSystem: 'sage-100-contractor' }
      case IntegrationType.SAGE_300_CRE_AGAVE:
        return { sourceSystem: 'sage-300-cre' }
      case IntegrationType.PROCORE:
        // Require cross-company access for Procore, because subs have access to multiple
        // GC companies and need to select a company when entering Procore.
        // See https://docs.agaveapi.com/source-systems/procore/faqs#1-how-do-i-support-authenticating-with-more-than-one-procore-company
        return {
          sourceSystem: 'procore',
          sourceSystemEnvironment: useSandbox ? 'sandbox' : undefined,
          sourceSystemOptions: {
            procore: {
              require_cross_company_access: true,
            },
          },
        }
      case IntegrationType.FOUNDATION_FILE:
      case IntegrationType.GC_PAY:
      case IntegrationType.QUICKBOOKS_ENTERPRISE_FILE:
      case IntegrationType.TEXTURA:
      case IntegrationType.TEST:
      case IntegrationType.SAGE_100_CONTRACTOR:
      case IntegrationType.SAGE_300_CRE:
      case IntegrationType.COMPUTER_EASE_FILE:
        throw new Error('Invalid integration type for Agave Link')
    }
  }, [companyIntegration.type, useSandbox])

  // Hack to get around issue where Agave Link component does not reset the overflow property
  // on the root <body> component, preventing scrolling from working after it closes
  const resetBodyOverflow = () => (document.body.style.overflow = 'auto')
  const { openLink, isReady } = useAgaveLink({
    ...linkProps,
    referenceId: companyId,
    linkToken,
    showProductionSourceSystems: true,
    onSuccess: (publicToken: string) => {
      updateCredentials({
        variables: {
          input: {
            companyIntegrationId: companyIntegration.id,
            publicToken,
          },
        },
      })
        .then(() => snackbar.showSuccess('Credentials updated'))
        .catch((error) => snackbar.showError(error.message))
        .finally(resetBodyOverflow)
    },
    onExit: (error?: string) => {
      snackbar.showError(error ?? 'An unknown error occurred talking to Agave. Try again.')
      resetBodyOverflow()
    },
  })

  useEffect(() => {
    setLinkToken(null)
    getAgaveLinkToken({
      variables: { companyIntegrationId: companyIntegration.id },
    }).then((data) => {
      setLinkToken(data.data?.agaveLinkToken ?? null)
    })
  }, [companyId, companyIntegration.id, getAgaveLinkToken, open])

  useEffect(() => {
    if (linkToken === null || isReady === false || open === false || useSandbox === null) {
      return
    }
    openLink()
    setOpen(false)
  }, [openLink, linkToken, isReady, open, setOpen, useSandbox])

  return (
    <>
      <Dialog
        open={open && useSandbox !== null}
        onClose={() => setOpen(false)}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>Connecting to Agave for {companyIntegration.type}</DialogTitle>
        <DialogContent>
          <LinearProgress />
        </DialogContent>
      </Dialog>
      <Dialog open={open && useSandbox === null}>
        <DialogTitle>Agave Environment</DialogTitle>
        <DialogContent>Would you like to use a production or sandbox environment?</DialogContent>
        <DialogActions>
          <Button onClick={() => setUseSandbox(true)}>Use Sandbox</Button>
          <Button onClick={() => setUseSandbox(false)}>Use Production</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
