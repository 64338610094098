import { setUser } from '@sentry/react'

type IdentifyProps = {
  id?: string
  email?: string
}

/**
 * Identifies a user on Sentry and LogRocket
 */
export async function identifyUser({ id, email }: IdentifyProps): Promise<void> {
  const { default: LogRocket } = await import('logrocket')

  // LogRocket
  if (id && email) {
    LogRocket.identify(id, { email })
  } else if (id) {
    LogRocket.identify(id)
  } else if (email) {
    LogRocket.identify({ email })
  }

  // Sentry
  setUser({ id, email })
}

/**
 * Removes users identification.
 * Note that LogRocket doesn't seem to support this.
 */
export async function removeUserIdentification(): Promise<void> {
  const { default: LogRocket } = await import('logrocket')
  LogRocket.identify({})
  setUser(null)
}
