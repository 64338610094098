import { gql } from '@apollo/client'
import LockIcon from '@mui/icons-material/Lock'
import { Button, Card, CardContent, Theme, Typography } from '@mui/material'
import { getApp } from 'firebase/app'
import { GoogleAuthProvider, getAuth, signInWithPopup } from 'firebase/auth'
import { FormEvent, useState } from 'react'
import { makeStylesFast } from 'siteline-common-web'
import Page from '../common/components/Page'
import { useCreateAdminUserMutation } from '../common/graphql/apollo-operations'

gql`
  mutation createAdminUser($id: ID!) {
    createAdminUser(id: $id) {
      id
    }
  }
`

const useStyles = makeStylesFast((theme: Theme) => ({
  layoutContainer: {
    minHeight: '100vh',
    display: 'flex',
    fontFamily: 'InterVariable',
  },
  layoutContent: {
    flexGrow: 1,
    maxWidth: '100%',
    overflowX: 'hidden',
    paddingTop: 64,
  },
  root: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(6, 2),
  },
  card: {
    width: theme.breakpoints.values.sm,
    maxWidth: '100%',
    overflow: 'visible',
    display: 'flex',
    position: 'relative',
    '& > *': {
      flexGrow: 1,
      flexBasis: '50%',
      width: '50%',
    },
  },
  content: {
    padding: theme.spacing(8, 4, 3, 4),
  },
  icon: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.common.white,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1),
    position: 'absolute',
    top: -32,
    left: theme.spacing(3),
    height: 64,
    width: 64,
    fontSize: 32,
  },
  signinForm: {
    marginTop: theme.spacing(3),
  },
  submitButton: {
    marginTop: theme.spacing(2),
    width: '100%',
  },
  errorMessage: {
    color: theme.palette.error.main,
    fontSize: '12px',
  },
}))

export default function Signin() {
  const classes = useStyles()
  const [loggingIn, setLoggingIn] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [createAdminUser] = useCreateAdminUserMutation()

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setErrorMessage('')
    setLoggingIn(true)

    const auth = getAuth(getApp())
    const provider = new GoogleAuthProvider()
    signInWithPopup(auth, provider)
      .then((data) => createAdminUser({ variables: { id: data.user.uid } }))
      .catch((error) => {
        setErrorMessage(error.message)
        setLoggingIn(false)
      })
  }

  return (
    <div className={classes.layoutContainer}>
      <div className={classes.layoutContent}>
        <Page className={classes.root} title="Login">
          <Card className={classes.card}>
            <CardContent className={classes.content}>
              <LockIcon className={classes.icon} />
              <Typography gutterBottom variant="h4">
                Sign in
              </Typography>
              <Typography variant="subtitle2">
                Please sign in through Google to access this dashboard.
              </Typography>
              <form className={classes.signinForm} onSubmit={handleSubmit}>
                <Button
                  className={classes.submitButton}
                  color="primary"
                  size="large"
                  type="submit"
                  variant="contained"
                  disabled={loggingIn}
                >
                  Sign in with Google
                </Button>
                <div className={classes.errorMessage}>{errorMessage}</div>
              </form>
            </CardContent>
          </Card>
        </Page>
      </div>
    </div>
  )
}
