import { gql } from '@apollo/client'
import { Search } from '@mui/icons-material'
import {
  Alert,
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid2,
  InputAdornment,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Theme,
  Typography,
} from '@mui/material'
import _ from 'lodash'
import { useCallback, useMemo } from 'react'
import InfiniteScroll from 'react-infinite-scroller'
import { makeStylesFast, useDebouncedSearch, usePaginationState } from 'siteline-common-web'
import Page from '../../common/components/Page'
import {
  GetPaginatedContractsInput,
  useContractsForProcessingQuery,
} from '../../common/graphql/apollo-operations'
import { ProcessingContractRow } from './ProcessingContractListRow'

const useStyles = makeStylesFast((theme: Theme) => ({
  root: {
    padding: theme.spacing(3, 0),
  },
  card: {
    marginTop: theme.spacing(4),
  },
  filters: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  searchBar: {
    width: 300,
    margin: theme.spacing(0),
    ...theme.typography.body1,
  },
}))

gql`
  query contractsForProcessing($input: GetPaginatedContractsInput!) {
    paginatedContracts(input: $input) {
      hasNext
      cursor
      totalCount
      contracts {
        id
        internalProjectNumber
        project {
          id
          projectNumber
          name
        }
        company {
          id
          name
        }
        onboardedStatus {
          selectedPayAppForms
          onboardedPayAppForms
          selectedPrimaryLienWaivers
          onboardedPrimaryLienWaiverForms
          selectedVendorLienWaivers
          onboardedVendorLienWaiverForms
          selectedChangeOrderRequestForms
          onboardedChangeOrderRequestForms
          selectedChangeOrderLogForms
          onboardedChangeOrderLogForms
        }
      }
    }
  }
`

/**
 * List of contracts that have the Processing Forms banner still visible in the main app.
 */
export function ProcessingContractList() {
  const classes = useStyles()
  const { search, onSearch, debouncedSearch } = useDebouncedSearch()
  const input = useMemo(
    (): GetPaginatedContractsInput => ({
      limit: 50,
      isProcessingForms: true,
      search: debouncedSearch,
    }),
    [debouncedSearch]
  )

  const { data, error, loading, fetchMore } = useContractsForProcessingQuery({
    variables: { input },
  })
  const contracts = useMemo(() => data?.paginatedContracts.contracts ?? [], [data])
  const cursor = data?.paginatedContracts.cursor
  const hasNext = data?.paginatedContracts.hasNext ?? false
  const totalCount = data?.paginatedContracts.totalCount ?? 0
  const loadMore = useCallback(() => {
    fetchMore({
      variables: {
        input: { cursor, ...input },
      },
    })
  }, [cursor, fetchMore, input])

  const { showSkeletons, showLoadingMoreRow, showColumnHeaders, showNoFilterResultPlaceholder } =
    usePaginationState({
      entities: data?.paginatedContracts.contracts ?? null,
      loading,
      hasNext,
      isSearching: false,
      isFiltering: true,
    })

  const makeSkeletonRow = useCallback(
    (index: number) => (
      <TableRow hover key={index.toString()}>
        <TableCell>
          <Skeleton variant="text" />
        </TableCell>
        <TableCell>
          <Skeleton variant="text" />
        </TableCell>
        <TableCell>
          <Skeleton variant="text" />
        </TableCell>
        <TableCell>
          <Skeleton variant="text" />
        </TableCell>
        <TableCell>
          <Skeleton variant="text" />
        </TableCell>
        <TableCell>
          <Skeleton variant="text" />
        </TableCell>
        <TableCell>
          <Skeleton variant="text" />
        </TableCell>
      </TableRow>
    ),
    []
  )

  return (
    <Page className={classes.root} title="Processing contracts">
      <Container maxWidth={false}>
        <Grid2 alignItems="flex-end" container justifyContent="space-between" spacing={3}>
          <Grid2>
            <Typography variant="h4">Contracts with form banner</Typography>
          </Grid2>
        </Grid2>
        {error && <Alert severity="error">{error.message}</Alert>}
        <Card className={classes.card}>
          <CardHeader
            title="Contracts"
            subheader={
              data ? (
                <span>{totalCount} records found.</span>
              ) : (
                <Skeleton variant="text" sx={{ width: 100 }} />
              )
            }
          />
          <CardContent>
            <TextField
              value={search}
              onChange={(ev) => onSearch(ev.target.value)}
              size="small"
              placeholder="Search by project name, project number, ..."
              slotProps={{
                input: {
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                },
              }}
              sx={{ width: '600px' }}
            />
          </CardContent>
          <InfiniteScroll threshold={0} loadMore={loadMore} hasMore={hasNext} useWindow={true}>
            <TableContainer>
              <Table size="small">
                {showColumnHeaders && (
                  <TableHead>
                    <TableRow>
                      <TableCell>Contract</TableCell>
                      <TableCell>Company</TableCell>
                      <TableCell>Pay app</TableCell>
                      <TableCell>Primary LW</TableCell>
                      <TableCell>Vendor LW</TableCell>
                      <TableCell>COR</TableCell>
                      <TableCell>CO Log</TableCell>
                    </TableRow>
                  </TableHead>
                )}
                <TableBody>
                  {contracts.map((contract) => (
                    <ProcessingContractRow contract={contract} key={contract.id} />
                  ))}
                  {showSkeletons && _.times(8, (index) => makeSkeletonRow(index))}
                  {showNoFilterResultPlaceholder && (
                    <TableRow>
                      <TableCell colSpan={7}>No contract matches these filters.</TableCell>
                    </TableRow>
                  )}
                  {showLoadingMoreRow && makeSkeletonRow(0)}
                </TableBody>
              </Table>
            </TableContainer>
          </InfiniteScroll>
        </Card>
      </Container>
    </Page>
  )
}
