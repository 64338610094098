import { gql } from '@apollo/client'
import Close from '@mui/icons-material/Close'
import {
  Alert,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material'
import _ from 'lodash'
import moment from 'moment-timezone'
import queryString from 'query-string'
import { useCallback, useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  CartesianGrid,
  Label,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import { MONTH_FORMAT, decimalToPercent, safeDivide } from 'siteline-common-all'
import { SitelineText, colors } from 'siteline-common-web'
import uniqolor from 'uniqolor'
import { useTimeToValueQuery } from '../../common/graphql/apollo-operations'

gql`
  query timeToValue {
    timeToValue {
      company {
        id
        nickname
        name
        expectedNumberOfProjects
        sitelinePointOfContact {
          id
          firstName
          lastName
        }
      }
      billingByMonth {
        month
        cumulativeContractsBilled
      }
    }
  }
`

/** Dashboard for tracking customer time to value */
export function TimeToValueDashboard() {
  const location = useLocation()
  const navigate = useNavigate()
  const { data, loading } = useTimeToValueQuery({ fetchPolicy: 'cache-and-network' })

  const accountOwner = useMemo(() => {
    return queryString.parse(location.search).accountOwner as string | null
  }, [location.search])
  const handleAccountOwnerChange = useCallback(
    (userId: string | null, replace?: boolean) => {
      const search = new URLSearchParams(location.search)
      if (userId) {
        search.set('accountOwner', userId)
      } else {
        search.delete('accountOwner')
      }
      navigate({ search: search.toString() }, { replace })
    },
    [location.search, navigate]
  )

  const timeToValueData = useMemo(() => data?.timeToValue ?? [], [data])

  const chartData = useMemo(() => {
    const dataWithMonths = timeToValueData
      .filter((data) => !accountOwner || data.company.sitelinePointOfContact?.id === accountOwner)
      .flatMap(({ company, billingByMonth }) => {
        const { id: companyId, name, nickname } = company
        return billingByMonth.map(({ month, cumulativeContractsBilled }) => ({
          companyId,
          companyName: nickname ?? name,
          month,
          cumulativeContractsBilled,
        }))
      })
    const dataByMonth = _.groupBy(dataWithMonths, (data) => data.month)
    return _.chain(dataByMonth)
      .entries()
      .map(([month, companyData]) => {
        const monthData: Record<string, string | number> = { month }
        companyData.forEach((companyData) => {
          monthData[companyData.companyName] = companyData.cumulativeContractsBilled
        })
        return monthData
      })
      .orderBy(({ month }) => moment.utc(month, MONTH_FORMAT).unix())
      .value()
  }, [accountOwner, timeToValueData])

  const accountOwners = useMemo(
    () =>
      _.chain(timeToValueData)
        .map(({ company }) => {
          return {
            id: company.sitelinePointOfContact?.id,
            name: company.sitelinePointOfContact
              ? `${company.sitelinePointOfContact.firstName} ${company.sitelinePointOfContact.lastName}`
              : null,
          }
        })
        .filter(
          (pointOfContact): pointOfContact is { id: string; name: string } =>
            _.isString(pointOfContact.id) && _.isString(pointOfContact.name)
        )
        .uniqBy(({ name }) => name)
        .sortBy(({ name }) => name)
        .value(),
    [timeToValueData]
  )

  const companyNames = useMemo(
    () =>
      _.chain(chartData)
        .flatMap((data) => Object.keys(data))
        .without('month')
        .uniq()
        .value(),
    [chartData]
  )
  const companyColors = useMemo(
    () =>
      _.zipObject(
        companyNames,
        companyNames.map((id) => uniqolor(id, { lightness: 50 }).color)
      ),
    [companyNames]
  )

  return (
    <>
      <SitelineText
        variant="body1"
        color="grey50"
        style={{ marginTop: 24, marginBottom: 24, marginLeft: 12 }}
      >
        When new customers join, CSMs should be adding their expected number of projects via the
        company admin page. This dashboard helps track initial progress toward that benchmark.
        <br />
        This chart shows all companies that have not yet billed 60% of their &quot;number of
        projects expected&quot;. Months with no changes may be skipped.
      </SitelineText>
      <FormControl>
        <InputLabel id="accountOwner">Account owner</InputLabel>
        <Select
          key={accountOwner}
          style={{ minWidth: 250, marginBottom: 24 }}
          labelId="accountOwner"
          value={accountOwner ?? 'All'}
          label="Account owner"
          onChange={(ev) => {
            handleAccountOwnerChange(ev.target.value as string)
          }}
          endAdornment={
            accountOwner ? (
              <IconButton onClick={() => handleAccountOwnerChange(null)} sx={{ marginRight: 1.5 }}>
                <Close fontSize="small" />
              </IconButton>
            ) : undefined
          }
        >
          <MenuItem value="All">All</MenuItem>
          {accountOwners.map(({ id, name }) => (
            <MenuItem key={id} value={id}>
              {name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {!loading && chartData.length > 0 && (
        <ResponsiveContainer height={500} width="100%" style={{ marginTop: 16 }}>
          <LineChart data={chartData} margin={{ bottom: 32, left: 32, right: 32 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="month"
              tickFormatter={(month) => moment.utc(month, MONTH_FORMAT).format('MMM YYYY')}
              tick={{ fontSize: 12 }}
            />
            <YAxis>
              <Label
                angle={-90}
                value="cumulative # of projects billed"
                offset={8}
                position="left"
                orientation="vertical"
              />
            </YAxis>
            <Tooltip
              labelFormatter={(month) => moment.utc(month, MONTH_FORMAT).format('MMM YYYY')}
              formatter={(value, companyName) => {
                const company = timeToValueData.find(
                  (data) => (data.company.nickname ?? data.company.name) === companyName
                )
                if (company) {
                  const percentOfExpected = decimalToPercent(
                    safeDivide(Number(value), company.company.expectedNumberOfProjects ?? 0, 0),
                    1
                  )
                  return `${value} (${percentOfExpected}% of ${company.company.expectedNumberOfProjects})`
                }
                return value
              }}
            />
            {companyNames.map((companyName) => (
              <Line
                key={companyName}
                type="monotone"
                dataKey={companyName}
                stroke={_.get(companyColors, companyName, colors.blue50)}
                strokeWidth={2}
                connectNulls
              />
            ))}
            <Legend />
          </LineChart>
        </ResponsiveContainer>
      )}
      {loading && (
        <div
          style={{
            height: 500,
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress />
        </div>
      )}
      {!loading && chartData.length === 0 && (
        <Alert severity="warning">
          There are no companies to show, meaning you have not added a number of projects expected
          for enough companies, or all companies have billed at least 60% of their expected
          projects.
        </Alert>
      )}
    </>
  )
}
