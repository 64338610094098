import { gql } from '@apollo/client'
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
} from '@mui/material'
import { useState } from 'react'
import { makeStylesFast, useSitelineSnackbar } from 'siteline-common-web'
import type { WritableDeep } from 'type-fest'
import * as fragments from '../../../../common/graphql/Fragments'
import {
  Contract,
  useImportSovFromIntegrationMutation,
  useRefreshIntegrationMappingsMutation,
} from '../../../../common/graphql/apollo-operations'
import { TexturaIntegration } from '../../../../common/util/Integration'

const useStyles = makeStylesFast(() => ({
  checkboxes: {
    display: 'flex',
    flexDirection: 'column',
  },
}))

gql`
  mutation refreshIntegrationMappings($input: RefreshIntegrationMappingsInput!) {
    refreshIntegrationMappings(input: $input) {
      ...IntegrationProperties
    }
  }
  ${fragments.integration}
`

gql`
  mutation importSovFromIntegration($input: ImportSovFromIntegrationInput!) {
    importSovFromIntegration(input: $input) {
      ...SovProperties
    }
  }
  ${fragments.sov}
`

type TexturaRefreshDialogProps = {
  integration: TexturaIntegration
  open: boolean
  setOpen: (open: boolean) => void
  contract: fragments.Contract
}

export function TexturaRefreshDialog({
  integration,
  open,
  setOpen,
  contract,
}: TexturaRefreshDialogProps) {
  const classes = useStyles()
  const snackbar = useSitelineSnackbar()

  // If either the project or contract doesn't exist, they must be refreshed first in order to get
  // the other metadata
  const needsFirstRefresh =
    !integration.mappings.project.texturaProjectId ||
    !integration.mappings.contract.texturaContractId

  const [refreshProjectAndContract, setRefreshProjectAndContract] =
    useState<boolean>(needsFirstRefresh)
  const [refreshLegalRequirements, setRefreshLegalRequirements] =
    useState<boolean>(needsFirstRefresh)
  const [refreshVendors, setRefreshVendors] = useState<boolean>(needsFirstRefresh)
  const [refreshSov, setRefreshSov] = useState<boolean>(needsFirstRefresh)

  const [refreshMetadata, { loading: isRefreshing }] = useRefreshIntegrationMappingsMutation()
  const [importSov, { loading: isImportingSov }] = useImportSovFromIntegrationMutation({
    update(cache, { data }) {
      if (!data) {
        return
      }

      const newRef = cache.writeFragment({
        data: data.importSovFromIntegration,
        fragment: fragments.sov,
        fragmentName: 'SovProperties',
      })

      cache.modify<WritableDeep<Contract>>({
        id: cache.identify(contract),
        fields: {
          sov(existing, { INVALIDATE }) {
            return newRef ?? INVALIDATE
          },
        },
      })
    },
  })

  const onClickRefresh = async () => {
    snackbar.showLoading()
    await refreshMetadata({
      variables: {
        input: {
          integrationId: integration.id,
          refreshProjectAndContract,
          refreshLegalRequirements,
          refreshVendors,
        },
      },
    })
      .then(() => {
        if (!refreshSov) {
          snackbar.showSuccess('Successfully refresh Textura metadata')
        }
      })
      .catch((err) => {
        snackbar.showError(err.message)
        return
      })

    if (refreshSov) {
      await importSov({
        variables: {
          input: {
            integrationId: integration.id,
          },
        },
      })
        .then(() => {
          snackbar.showSuccess('Successfully refreshed Textura SOV')
        })
        .catch((err) => {
          snackbar.showError(err.message)
          return
        })
    }

    setOpen(false)
  }

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle>Refresh Textura Data For Project</DialogTitle>
      <DialogContent>
        {needsFirstRefresh && (
          <p>
            If there is no project or contract, you must first refresh the project and contract.
          </p>
        )}
        <div className={classes.checkboxes}>
          <FormControlLabel
            control={
              <Checkbox
                checked={refreshProjectAndContract}
                onChange={(ev, checked) => setRefreshProjectAndContract(checked)}
                // Must refresh project and contract if they don't exist
                disabled={needsFirstRefresh}
              />
            }
            label="Textura Project and Contract"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={refreshLegalRequirements}
                onChange={(ev, checked) => setRefreshLegalRequirements(checked)}
              />
            }
            label="Textura Legal Requirements"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={refreshVendors}
                onChange={(ev, checked) => setRefreshVendors(checked)}
              />
            }
            label="Textura Vendors"
          />
          <FormControlLabel
            control={
              <Checkbox checked={refreshSov} onChange={(ev, checked) => setRefreshSov(checked)} />
            }
            label="Textura Sov Line Items"
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onClickRefresh}>
          {isRefreshing || isImportingSov ? 'Refreshing...' : 'Refresh Textura Data'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
