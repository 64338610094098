import { gql } from '@apollo/client'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  FormControl,
  IconButton,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material'
import _ from 'lodash'
import { useEffect, useState } from 'react'
import { toReferences, useSitelineSnackbar } from 'siteline-common-web'
import type { WritableDeep } from 'type-fest'
import { v4 as uuidv4 } from 'uuid'
import { EditableCardCell } from '../../../common/components/EditableCardRow'
import * as fragments from '../../../common/graphql/Fragments'
import {
  CompanySovProperties,
  GetContractDocument,
  Query,
  SovLineItemGroupProperties,
  useCreateSovLineItemGroupMutation,
  useDeleteSovLineItemGroupMutation,
  useSetLineItemsInGroupMutation,
  useUpdateSovLineItemGroupMutation,
} from '../../../common/graphql/apollo-operations'
import { sortSovLineItemsByOrder } from '../../../common/util/PayApp'

gql`
  mutation createSovLineItemGroup($input: CreateSovLineItemGroupInput!) {
    createSovLineItemGroup(input: $input) {
      ...SovLineItemGroupProperties
    }
  }
  ${fragments.sovLineItemGroup}
`

gql`
  mutation updateSovLineItemGroup($input: UpdateSovLineItemGroupInput!) {
    updateSovLineItemGroup(input: $input) {
      ...SovLineItemGroupProperties
    }
  }
  ${fragments.sovLineItemGroup}
`

gql`
  mutation deleteSovLineItemGroup($id: ID!) {
    deleteSovLineItemGroup(id: $id) {
      id
    }
  }
`

gql`
  mutation setLineItemsInGroup($input: SetLineItemsInGroupInput!) {
    setLineItemsInGroup(input: $input) {
      ...SovLineItemGroupProperties
    }
  }
  ${fragments.sovLineItemGroup}
`

function LineItemGroupRow({
  group,
  sov,
}: {
  group: SovLineItemGroupProperties
  sov?: CompanySovProperties
}) {
  const sovLineItems = sov ? sov.lineItems : []
  const ungroupedItems = sovLineItems.filter((item) => _.isNil(item.sovLineItemGroup))
  const currentGroupLineItems = sovLineItems.filter(
    (item) => item.sovLineItemGroup?.id === group.id
  )

  const [isEditingLineItems, setIsEditingLineItems] = useState(false)
  const [selectedLineItemIds, setLineItemIds] = useState(
    currentGroupLineItems.map((item) => item.id)
  )
  const snackbar = useSitelineSnackbar()

  useEffect(() => {
    if (isEditingLineItems) {
      setLineItemIds(currentGroupLineItems.map((item) => item.id))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditingLineItems])

  const [setLineItemsInGroup] = useSetLineItemsInGroupMutation({
    refetchQueries: [
      {
        query: GetContractDocument,
        variables: { id: sov?.contract.id ?? '' },
      },
    ],
  })
  const [updateGroup] = useUpdateSovLineItemGroupMutation()
  const [deleteGroup] = useDeleteSovLineItemGroupMutation({
    update(cache, { data }) {
      if (!data) {
        return
      }

      cache.modify<WritableDeep<Query>>({
        fields: {
          sovLineItemGroups(existingRefs, { readField, toReference }) {
            const refs = toReferences(existingRefs, toReference)
            return refs.filter((ref) => readField('id', ref) !== data.deleteSovLineItemGroup.id)
          },
        },
      })
    },
  })

  const onDelete = (group: SovLineItemGroupProperties) => {
    if (!window.confirm('Are you sure you want to delete this group?')) {
      return
    }
    deleteGroup({
      variables: { id: group.id },
    })
      .then(() => setIsEditingLineItems(false))
      .catch((err) => snackbar.showError(err.message))
  }

  // The type of the native multi-select object isn't recognized by typescript so we're using
  // unknown here and casting to HTMLSelectElement below.
  const handleChange = (event: SelectChangeEvent<unknown>) => {
    const options = Array.from((event.target as HTMLSelectElement).options) as {
      selected: boolean
      value: string
    }[]
    const selectedIds = options.filter((option) => option.selected).map((option) => option.value)
    setLineItemIds(selectedIds)
  }

  const onSaveUpdatedLineItems = () => {
    if (!sov) {
      return
    }
    if (
      _.isEqual(currentGroupLineItems.map((item) => item.id).sort(), selectedLineItemIds.sort())
    ) {
      // If no change, no need to hit the resolver
      setIsEditingLineItems(false)
      return
    }

    setLineItemsInGroup({
      variables: {
        input: {
          groupId: group.id,
          sovLineItemIds: selectedLineItemIds,
        },
      },
    })
      .then(() => setIsEditingLineItems(false))
      .catch((err) => snackbar.showError(err.message))
  }

  const usableLineItems = [...currentGroupLineItems, ...ungroupedItems].sort(
    sortSovLineItemsByOrder
  )

  return (
    <TableRow key={group.id}>
      <EditableCardCell
        value={group.name}
        formatValue={(value) => value}
        editComponent={(value, setValue) => (
          <TextField value={value} onChange={(ev) => setValue(ev.target.value)} size="small" />
        )}
        readOnly={false}
        variables={(value) => ({
          input: {
            id: group.id,
            groupName: value,
          },
        })}
        mutate={updateGroup}
      />
      <EditableCardCell
        value={group.code}
        formatValue={(value) => value}
        editComponent={(value, setValue) => (
          <TextField value={value} onChange={(ev) => setValue(ev.target.value)} size="small" />
        )}
        readOnly={false}
        variables={(value) => ({
          input: {
            id: group.id,
            groupCode: value || null,
          },
        })}
        mutate={updateGroup}
      />
      {!isEditingLineItems && (
        <TableCell style={{ width: 250 }}>
          {currentGroupLineItems.length}
          {usableLineItems.length > 0 && sov && (
            <IconButton size="small" onClick={() => setIsEditingLineItems(true)}>
              <EditIcon />
            </IconButton>
          )}
        </TableCell>
      )}
      {isEditingLineItems && (
        <TableCell style={{ width: 250 }}>
          <FormControl>
            <Select
              native
              multiple
              value={selectedLineItemIds}
              onChange={handleChange}
              style={{ width: 250 }}
              inputProps={{
                id: 'select-multiple-native',
              }}
            >
              {usableLineItems.map((lineItem) => (
                <option key={lineItem.id} value={lineItem.id}>
                  {lineItem.name}
                </option>
              ))}
            </Select>
          </FormControl>
          <IconButton size="small" onClick={onSaveUpdatedLineItems}>
            <CheckIcon />
          </IconButton>
          <IconButton size="small" onClick={() => setIsEditingLineItems(false)}>
            <CloseIcon />
          </IconButton>
        </TableCell>
      )}
      <TableCell>
        <IconButton onClick={() => onDelete(group)} size="large">
          <DeleteIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  )
}

interface ContractDetailsLineItemGroupsProps {
  contractId: string
  sovLineItemGroups: SovLineItemGroupProperties[]
  sov?: CompanySovProperties
}

export default function ContractDetailsLineItemGroups({
  contractId,
  sovLineItemGroups,
  sov,
}: ContractDetailsLineItemGroupsProps) {
  const [newName, setNewName] = useState('')
  const [newCode, setNewCode] = useState<string | null>(null)
  const [showAddNew, setShowAddNew] = useState(false)

  const [createGroup] = useCreateSovLineItemGroupMutation({
    update(cache, { data }) {
      if (!data) {
        return
      }

      cache.modify({
        fields: {
          sovLineItemGroups(existingRefs, { toReference }) {
            const newRef = cache.writeFragment({
              data: data.createSovLineItemGroup,
              fragment: fragments.sovLineItemGroup,
              fragmentName: 'SovLineItemGroupProperties',
            })
            const refs = toReferences(existingRefs, toReference)
            return _.compact([...refs, newRef])
          },
        },
      })
    },
  })
  const snackbar = useSitelineSnackbar()

  const onCreate = () => {
    if (newName.length === 0) {
      snackbar.showError('Group name must be set before clicking save.')
    }
    createGroup({
      variables: {
        input: {
          groupName: newName,
          groupCode: newCode,
          contractId,
        },
      },
      optimisticResponse: {
        createSovLineItemGroup: {
          id: uuidv4(),
          name: newName,
          code: newCode,
          __typename: 'SovLineItemGroup',
        },
        __typename: 'Mutation',
      },
    })
    setShowAddNew(false)
    setNewName('')
    setNewCode(null)
  }

  return (
    <Card>
      <CardHeader title="SOV Line Item Groups" />
      <CardContent style={{ padding: 0 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Code</TableCell>
              <TableCell>Line Items</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sovLineItemGroups.map((group) => (
              <LineItemGroupRow key={group.id} group={group} sov={sov} />
            ))}
            {showAddNew && (
              <TableRow>
                <TableCell>
                  <TextField
                    value={newName}
                    onChange={(ev) => setNewName(ev.target.value)}
                    size="small"
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    value={newCode}
                    onChange={(ev) => setNewCode(ev.target.value)}
                    size="small"
                  />
                </TableCell>
                <TableCell>0</TableCell>
                <TableCell>
                  <Button color="primary" variant="contained" onClick={onCreate}>
                    Create
                  </Button>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell colSpan={4} style={{ border: 'none' }}>
                <Button size="small" onClick={() => setShowAddNew(!showAddNew)}>
                  {showAddNew ? 'Cancel' : 'Add SOV Line Item Group'}
                </Button>
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </CardContent>
    </Card>
  )
}
