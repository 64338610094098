import FileSaver from 'file-saver'

/**
 * Saves a file to the user's local disk.
 */
export function saveAs(data: Blob, filename: string) {
  // Disabling the linter because the @deprecated signature on FileSaver is set up incorrectly
  // eslint-disable-next-line @typescript-eslint/no-deprecated
  FileSaver.saveAs(data, filename, { autoBom: false })
}

/**
 * Saves a url to the user's local disk.
 */
export function saveUrlAs(url: string, filename: string) {
  // Disabling the linter because the @deprecated signature on FileSaver is set up incorrectly
  // eslint-disable-next-line @typescript-eslint/no-deprecated
  FileSaver.saveAs(url, filename, { autoBom: false })
}
