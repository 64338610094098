import {
  faDollarSign,
  faFeather,
  faICursor,
  faSignature,
  faStamp,
  faUserGroup,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ImageIcon from '@mui/icons-material/Image'
import {
  Alert,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Typography,
} from '@mui/material'
import _ from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'
import {
  FormTemplateAnnotationImageType,
  SignatureAnnotationType,
  getSignatureUserVisibleName,
} from 'siteline-common-all'
import {
  FormTemplateAnnotationMetadataFieldType,
  getDynamicFieldTags,
  makeStylesFast,
} from 'siteline-common-web'
import { v4 as uuidv4 } from 'uuid'
import {
  FormAnnotationInput,
  FormTemplateAnnotationProperties,
  FormTemplateAnnotationType,
  FormTemplateFont,
  FormTemplateProperties,
  FormTemplateType,
  FormTemplateVersionProperties,
  TextAlignment,
} from '../../../common/graphql/apollo-operations'
import { AnnotationGeometry } from './FormTemplateAnnotationLayer'
import { PreviewPosition, PreviewPositionSelector } from './PreviewPositionSelector'

const useStyles = makeStylesFast((theme: Theme) => ({
  actions: {
    padding: theme.spacing(2),
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
}))

type FormTemplateAnnotationListProps = {
  formTemplate: FormTemplateProperties
  formTemplateVersion: FormTemplateVersionProperties
  selectedAnnotationIds: string[]
  onSelectedAnnotationIdsChange: (annotationIds: string[]) => void
  annotations: FormTemplateAnnotationProperties[]
  createAnnotation: (annotationInput: Required<FormAnnotationInput>) => void
  previewPosition: PreviewPosition
  setPreviewPosition: (previewPosition: PreviewPosition) => void
}

export function FormTemplateAnnotationList({
  formTemplate,
  formTemplateVersion,
  annotations,
  selectedAnnotationIds,
  onSelectedAnnotationIdsChange,
  createAnnotation,
  previewPosition,
  setPreviewPosition,
}: FormTemplateAnnotationListProps) {
  const classes = useStyles()
  const [dynamicFieldTags, setDynamicFieldTags] = useState<string[] | null>(null)

  const defaultAnnotationGeometry: AnnotationGeometry = useMemo(
    () => ({
      xStart: 0,
      yStart: 0,
      width: 10,
      height: 2,
    }),
    []
  )

  const existingFieldTags = _.compact(annotations.map((annotation) => annotation.dynamicFieldTag))
  const missingFieldTags = useMemo(() => {
    if (dynamicFieldTags === null) {
      return []
    }
    return dynamicFieldTags.filter((tag) => !existingFieldTags.includes(tag))
  }, [dynamicFieldTags, existingFieldTags])

  const extraFieldTags = useMemo(() => {
    if (dynamicFieldTags === null) {
      return []
    }
    return existingFieldTags.filter((tag) => !dynamicFieldTags.includes(tag))
  }, [dynamicFieldTags, existingFieldTags])

  const addUserEnteredField = useCallback(
    (dynamicFieldTag: string | null = null) => {
      createAnnotation({
        id: uuidv4(),
        type: FormTemplateAnnotationType.USER_ENTERED_FIELD,
        fontColor: '000000',
        fontFamily: FormTemplateFont.ARIAL_NORMAL,
        pageNumber: 1,
        textAlignment: TextAlignment.LEFT,
        userVisibleName: 'Field',
        wrapText: false,
        copyDefaultValueFromPreviousAnnotationValue: false,
        doNotRetainOnReset: false,
        isOptional: false,
        defaultValueKey: 'project.name',
        dynamicFieldTag,
        fieldType: null,
        imageType: null,
        prefix: null,
        selectedKey: null,
        suffix: null,
        signatureType: null,
        ...defaultAnnotationGeometry,
      })
    },
    [createAnnotation, defaultAnnotationGeometry]
  )

  const addSignature = useCallback(
    (signatureType: SignatureAnnotationType) => {
      const userVisibleName = getSignatureUserVisibleName(signatureType)
      createAnnotation({
        id: uuidv4(),
        type: FormTemplateAnnotationType.SIGNATURE,
        signatureType,
        fontColor: '000000',
        fontFamily: FormTemplateFont.ARIAL_NORMAL,
        pageNumber: 1,
        textAlignment: TextAlignment.LEFT,
        wrapText: false,
        userVisibleName,
        xStart: defaultAnnotationGeometry.xStart,
        yStart: defaultAnnotationGeometry.yStart,
        width: 30,
        height: 3,
        copyDefaultValueFromPreviousAnnotationValue: false,
        doNotRetainOnReset: false,
        isOptional: false,
        defaultValueKey: null,
        dynamicFieldTag: null,
        fieldType: null,
        imageType: null,
        prefix: null,
        selectedKey: null,
        suffix: null,
      })
    },
    [createAnnotation, defaultAnnotationGeometry.xStart, defaultAnnotationGeometry.yStart]
  )

  const addLienWaiverAmount = useCallback(() => {
    createAnnotation({
      id: uuidv4(),
      type: FormTemplateAnnotationType.USER_ENTERED_FIELD,
      fontColor: '000000',
      fontFamily: FormTemplateFont.ARIAL_NORMAL,
      pageNumber: 1,
      textAlignment: TextAlignment.LEFT,
      wrapText: false,
      userVisibleName: 'Amount of Check',
      ...defaultAnnotationGeometry,
      defaultValueKey: 'currentPaymentDue',
      fieldType: FormTemplateAnnotationMetadataFieldType.LIEN_WAIVER_AMOUNT,
      doNotRetainOnReset: true,
      copyDefaultValueFromPreviousAnnotationValue: false,
      dynamicFieldTag: null,
      imageType: null,
      isOptional: false,
      prefix: null,
      selectedKey: null,
      suffix: null,
      signatureType: null,
    })
  }, [createAnnotation, defaultAnnotationGeometry])

  const addImage = useCallback(() => {
    createAnnotation({
      id: uuidv4(),
      type: FormTemplateAnnotationType.IMAGE,
      fontColor: '000000',
      fontFamily: FormTemplateFont.ARIAL_NORMAL,
      pageNumber: 1,
      textAlignment: TextAlignment.LEFT,
      wrapText: false,
      userVisibleName: 'Image',
      xStart: defaultAnnotationGeometry.xStart,
      yStart: defaultAnnotationGeometry.yStart,
      width: 10,
      height: 10,
      imageType: FormTemplateAnnotationImageType.SUBCONTRACTOR_LOGO,
      copyDefaultValueFromPreviousAnnotationValue: false,
      defaultValueKey: null,
      doNotRetainOnReset: false,
      dynamicFieldTag: null,
      fieldType: null,
      isOptional: false,
      prefix: null,
      selectedKey: null,
      suffix: null,
      signatureType: null,
    })
  }, [createAnnotation, defaultAnnotationGeometry.xStart, defaultAnnotationGeometry.yStart])

  const addMissingAnnotations = useCallback(() => {
    missingFieldTags.forEach((tag) => {
      addUserEnteredField(tag)
    })
  }, [addUserEnteredField, missingFieldTags])

  useEffect(() => {
    getDynamicFieldTags(formTemplateVersion.id)
      .then((tags) => setDynamicFieldTags(tags))
      .catch(() => setDynamicFieldTags([]))
  }, [formTemplateVersion.id])

  return (
    <Card>
      <CardHeader
        title="Dynamic Annotations"
        action={
          <PreviewPositionSelector
            previewPosition={previewPosition}
            setPreviewPosition={setPreviewPosition}
          />
        }
      />
      {missingFieldTags.length > 0 && (
        <CardContent>
          <Alert severity="error">
            The following dynamic tags are missing a matching annotation:
            <ul>
              {missingFieldTags.map((tag) => (
                <li key={tag}>
                  <code>${tag}</code>
                </li>
              ))}
            </ul>
            <Button variant="text" onClick={() => addMissingAnnotations()}>
              Add missing annotations
            </Button>
          </Alert>
        </CardContent>
      )}

      {extraFieldTags.length > 0 && (
        <CardContent>
          <Alert severity="warning">
            The following dynamic tags do not exist in the template and can be safely removed:
            <ul>
              {extraFieldTags.map((tag) => (
                <li key={tag}>
                  <code>${tag}</code>
                </li>
              ))}
            </ul>
          </Alert>
        </CardContent>
      )}

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Tag</TableCell>
              <TableCell>Type</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {annotations.map((annotation) => {
              return (
                <TableRow
                  key={annotation.type}
                  selected={selectedAnnotationIds.includes(annotation.id)}
                  hover
                  onClick={() => onSelectedAnnotationIdsChange([annotation.id])}
                  style={{ cursor: 'pointer' }}
                >
                  <TableCell>
                    <>
                      {annotation.dynamicFieldTag && <code>${annotation.dynamicFieldTag}</code>}
                      {!annotation.dynamicFieldTag && (
                        <Typography color="secondary">Not specified</Typography>
                      )}
                    </>
                  </TableCell>
                  <TableCell>{annotation.type}</TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <CardActions className={classes.actions} disableSpacing>
        <Button
          size="small"
          startIcon={<FontAwesomeIcon fixedWidth icon={faICursor} />}
          onClick={() => addUserEnteredField()}
        >
          Add Text field
        </Button>
        <Button
          size="small"
          startIcon={<FontAwesomeIcon fixedWidth icon={faSignature} />}
          onClick={() => addSignature(SignatureAnnotationType.DIGITAL)}
        >
          Add Signature
        </Button>
        {formTemplate.type !== FormTemplateType.CHANGE_ORDER_REQUEST && (
          <>
            <Button
              size="small"
              startIcon={<FontAwesomeIcon fixedWidth icon={faStamp} />}
              onClick={() => addSignature(SignatureAnnotationType.NOTARY)}
            >
              Add Notary signature
            </Button>
            <Button
              size="small"
              startIcon={<FontAwesomeIcon fixedWidth icon={faUserGroup} />}
              onClick={() => addSignature(SignatureAnnotationType.WITNESS)}
            >
              Add Witness signature
            </Button>
            <Button
              size="small"
              startIcon={<FontAwesomeIcon fixedWidth icon={faFeather} />}
              onClick={() => addSignature(SignatureAnnotationType.WET)}
            >
              Add Wet signature
            </Button>
          </>
        )}
        {formTemplate.type === FormTemplateType.LIEN_WAIVER && (
          <Button
            size="small"
            startIcon={<FontAwesomeIcon fixedWidth icon={faDollarSign} />}
            onClick={addLienWaiverAmount}
          >
            Add Lien-waiver amount
          </Button>
        )}
        <Button size="small" startIcon={<ImageIcon />} onClick={addImage}>
          Add Image
        </Button>
      </CardActions>
    </Card>
  )
}
