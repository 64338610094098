import { gql } from '@apollo/client'
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Link,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
} from '@mui/material'
import _ from 'lodash'
import { Link as RouterLink } from 'react-router-dom'
import { ContractListContractStatusFilter, makeStylesFast } from 'siteline-common-web'
import {
  FormTemplateProperties,
  useContractsWithTemplateQuery,
} from '../../common/graphql/apollo-operations'

gql`
  query contractsWithTemplate($input: GetPaginatedContractsInput!) {
    paginatedContracts(input: $input) {
      hasNext
      totalCount
      cursor
      contracts {
        id
        project {
          id
          name
        }
        company {
          id
          name
        }
      }
    }
  }
`

const useStyles = makeStylesFast((theme: Theme) => ({
  card: {
    marginBottom: theme.spacing(2),
  },
  cardContent: {
    padding: 0,
    '&:last-child': {
      padding: 0,
    },
    '& .MuiFormControl-root': {
      width: '100%',
    },
  },
  formTemplate: {
    width: '100%',
  },
  actions: {
    padding: theme.spacing(2),
  },
}))

type FormTemplateDetailsProjectsProps = {
  formTemplate: FormTemplateProperties
}

export default function FormTemplateDetailsContracts({
  formTemplate,
}: FormTemplateDetailsProjectsProps) {
  const classes = useStyles()
  const { data, loading } = useContractsWithTemplateQuery({
    variables: {
      input: {
        templateIds: [formTemplate.id],
        contractStatus: ContractListContractStatusFilter.ALL,
        limit: 20,
      },
    },
  })

  return (
    <Card className={classes.card}>
      <CardHeader title={'Projects using this form'} />
      <Divider />
      <CardContent className={classes.cardContent}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Contract</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading &&
              _.times(10, (index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Skeleton variant="text" />
                  </TableCell>
                </TableRow>
              ))}
            {data?.paginatedContracts.contracts.map((contract) => (
              <TableRow hover key={contract.id}>
                <TableCell>
                  <Link component={RouterLink} to={`/contracts/${contract.id}`} underline="hover">
                    {contract.project.name} • {contract.company.name}
                  </Link>
                </TableCell>
              </TableRow>
            ))}
            {data?.paginatedContracts.hasNext && (
              <TableRow>
                <TableCell>
                  Showing {data.paginatedContracts.contracts.length} out of{' '}
                  {data.paginatedContracts.totalCount} contracts
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  )
}
