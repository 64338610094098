import { gql } from '@apollo/client'
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid2,
  Paper,
  Theme,
  Typography,
} from '@mui/material'
import moment from 'moment-timezone'
import { useEffect, useState } from 'react'
import { makeStylesFast } from 'siteline-common-web'
import { geocodingService } from '../../common/components/LocationAutocomplete'
import { LocationCard } from '../../common/components/LocationCard'
import * as fragments from '../../common/graphql/Fragments'
import { DetailedCompany } from '../../common/graphql/Fragments'
import {
  LocationInput,
  LocationProperties,
  useCreateLocationRequestMutation,
  useUpdateLocationRequestMutation,
} from '../../common/graphql/apollo-operations'
import { formatLocationManyLines, formatLocationOneLine } from '../../common/util/Location'

const useStyles = makeStylesFast((theme: Theme) => ({
  card: {
    marginBottom: theme.spacing(2),
  },
  root: {
    backgroundColor: '#f5f5f5',
    padding: theme.spacing(2),
    '& .flex': {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'space-between',
    },
    '& .MuiTextField-root': {
      marginBottom: theme.spacing(2),
      width: '100%',
    },
  },
}))

gql`
  mutation updateLocationRequest($input: UpdateLocationInput!) {
    updateLocation(input: $input) {
      ...LocationProperties
    }
  }
  ${fragments.location}
`

gql`
  mutation createLocationRequest($input: CreateLocationInput!) {
    createLocation(input: $input) {
      ...LocationProperties
    }
  }
  ${fragments.location}
`

interface CompanyLocationProps {
  company: DetailedCompany
  location: LocationProperties
  isLocationNew: boolean
  removeLocation?: () => void
}

function CompanyLocation({
  company,
  location,
  isLocationNew,
  removeLocation,
}: CompanyLocationProps) {
  const classes = useStyles()
  const [isEditing, setIsEditing] = useState(false)
  const defaultState: LocationInput = {
    nickname: location.nickname,
    street1: location.street1,
    street2: location.street2,
    city: location.city,
    county: location.county,
    state: location.state,
    postalCode: location.postalCode,
    country: location.country || 'USA',
    coordinates: location.coordinates,
  }
  const [locationState, setLocationState] = useState<LocationInput>(defaultState)
  const [updateLocation] = useUpdateLocationRequestMutation()
  const [createLocation] = useCreateLocationRequestMutation({
    update(cache, { data }) {
      if (!data) {
        return
      }

      cache.modify({
        id: cache.identify(company),
        fields: {
          locations(existingLocations) {
            return [...existingLocations, data.createLocation]
          },
        },
      })
    },
  })

  useEffect(() => {
    if (isLocationNew) {
      setIsEditing(true)
    }
  }, [isLocationNew])

  const onEditOrCancel = () => {
    if (isLocationNew) {
      if (removeLocation) {
        removeLocation()
      }
    } else {
      // If we were editing already and we cancel, then we should reset the state back to default
      if (isEditing) {
        setLocationState(defaultState)
      }
      setIsEditing(!isEditing)
    }
  }

  const calculateLocationCoordinates = async () => {
    // Recalculate coordinates based on manual input
    const coordinates = locationState.coordinates
    if (coordinates.latitude !== 0 || coordinates.longitude !== 0) {
      return
    }

    const address = formatLocationOneLine(locationState)
    const geocodePromise: Promise<google.maps.GeocoderResult[]> = new Promise((resolve, reject) => {
      geocodingService
        .then((service) => {
          service.geocode({ address }, (results) => {
            resolve(results ?? [])
          })
        })
        .catch((error) => {
          reject(error)
        })
    })
    const results = await geocodePromise
    if (results.length > 0) {
      // Take the very first result, if one exists and update the coordinates state
      const result = results[0].geometry.location
      setLocationState({
        ...locationState,
        coordinates: {
          latitude: result.lat(),
          longitude: result.lng(),
        },
      })
    }
  }

  const save = async () => {
    setIsEditing(false)
    await calculateLocationCoordinates()
    if (isLocationNew) {
      await createLocation({
        variables: {
          input: {
            companyId: company.id,
            location: {
              ...locationState,
              nickname: locationState.nickname || null,
              street1: locationState.street1 || null,
              street2: locationState.street2 || null,
              postalCode: locationState.postalCode || null,
              county: locationState.county || null,
            },
          },
        },
      })
      if (removeLocation) {
        removeLocation()
      }
    } else {
      updateLocation({
        variables: {
          input: {
            id: location.id,
            location: {
              ...locationState,
              nickname: locationState.nickname || null,
              street1: locationState.street1 || null,
              street2: locationState.street2 || null,
              postalCode: locationState.postalCode || null,
              county: locationState.county || null,
            },
          },
        },
      })
    }
  }

  return (
    <Paper elevation={2} className={classes.root}>
      <div className="flex">
        <Typography variant="h6">
          {isEditing && isLocationNew && 'New Address'}
          {isEditing && !isLocationNew && 'Edit address'}
          {!isEditing && (locationState.nickname || locationState.street1)}
        </Typography>
        <Button variant="text" onClick={onEditOrCancel}>
          {isEditing ? 'Cancel' : 'Edit'}
        </Button>
      </div>
      {!isEditing && (
        <Typography variant="body2">{formatLocationManyLines(locationState)}</Typography>
      )}
      {isEditing && (
        <div>
          <LocationCard location={locationState} setLocation={setLocationState} />
          <Button variant="contained" color="primary" onClick={save}>
            Save
          </Button>
        </div>
      )}
    </Paper>
  )
}

interface CompanyLocationsProps {
  company: DetailedCompany
}

export default function CompanyLocations({ company }: CompanyLocationsProps) {
  const classes = useStyles()
  const [newLocations, setNewLocations] = useState<LocationProperties[]>([])

  const addEmptyLocation = () => {
    setNewLocations([
      ...newLocations,
      {
        __typename: 'Location',
        createdAt: moment.tz('America/Los_Angeles').toISOString(),
        id: '',
        nickname: '',
        street1: '',
        street2: '',
        city: '',
        county: '',
        state: '',
        country: '',
        postalCode: '',
        timeZone: '',
        coordinates: {
          latitude: 0,
          longitude: 0,
        },
      },
    ])
  }

  const removeLocation = (location: LocationProperties) => {
    setNewLocations(newLocations.filter((newLocation) => newLocation.id !== location.id))
  }

  // Sort locations by createdAt to be consistent
  const sortedOldLocations = [...company.locations].sort((a, b) =>
    moment.tz(a.createdAt, a.timeZone).isBefore(moment.tz(b.createdAt, b.timeZone)) ? -1 : 1
  )
  const sortedNewLocations = [...newLocations].sort((a, b) =>
    moment.tz(a.createdAt, a.timeZone).isBefore(moment.tz(b.createdAt, b.timeZone)) ? -1 : 1
  )

  return (
    <Card className={classes.card}>
      <CardHeader title="Locations" />
      <CardContent>
        <Grid2 container spacing={2}>
          {sortedOldLocations.map((location) => (
            <Grid2 size={{ xs: 6 }} key={location.id}>
              <CompanyLocation company={company} location={location} isLocationNew={false} />
            </Grid2>
          ))}
          {sortedNewLocations.map((location) => (
            <Grid2 size={{ xs: 6 }} key={location.id}>
              <CompanyLocation
                company={company}
                location={location}
                isLocationNew={true}
                removeLocation={() => removeLocation(location)}
              />
            </Grid2>
          ))}
          <Grid2 size={{ xs: 12 }}>
            <Button variant="outlined" onClick={addEmptyLocation}>
              Add Address
            </Button>
          </Grid2>
        </Grid2>
      </CardContent>
    </Card>
  )
}
