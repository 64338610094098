import { gql } from '@apollo/client'
import AddIcon from '@mui/icons-material/Add'
import InputIcon from '@mui/icons-material/Input'
import MenuIcon from '@mui/icons-material/Menu'
import SearchIcon from '@mui/icons-material/Search'
import {
  alpha,
  AppBar,
  Autocomplete,
  Button,
  Chip,
  CircularProgress,
  IconButton,
  InputBase,
  Menu,
  MenuItem,
  Theme,
  Toolbar,
  Typography,
} from '@mui/material'
import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'
import { getApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { useCallback, useMemo, useState } from 'react'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import { areNormalizedStringsEqual } from 'siteline-common-all'
import {
  makeStylesFast,
  SearchResultType,
  useDebouncedSearch,
  useSitelineSnackbar,
} from 'siteline-common-web'
import { useFilePicker } from 'use-file-picker'
import { API_ENV } from '../config/constants'
import {
  SearchResult,
  useCreateTestTemplateMutation,
  useSearchQuery,
} from '../graphql/apollo-operations'
import { CompanyIcon, FormTemplateIcon, PayAppIcon, ProjectIcon, UserIcon } from './Icons'

const useStyles = makeStylesFast((theme: Theme) => ({
  root: {
    boxShadow: 'none',
  },
  flexEnd: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'flex-end',
  },
  logoLink: {
    textDecoration: 'none',
  },
  logo: {
    color: 'white',
  },
  logoutButton: {
    marginLeft: theme.spacing(1),
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
    width: 560,
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
  },
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(1),
  },
  nested: {
    marginRight: theme.spacing(6),
  },
  chip: {
    backgroundColor: '#e0e0e0',
    marginLeft: theme.spacing(1),
  },
  menu: {
    marginLeft: theme.spacing(-2),
    '& .MuiSvgIcon-root': {
      color: 'white',
    },
  },
  progress: {
    color: 'white',
  },
  createButton: {
    marginLeft: theme.spacing(2),
  },
}))

gql`
  query search($query: String!, $type: SearchResultType) {
    search(query: $query, type: $type) {
      id
      type
      description
      score
    }
  }
`

gql`
  mutation createTestTemplate($input: CreateTestTemplateInput!) {
    createTestTemplate(input: $input) {
      id
      versions {
        id
      }
    }
  }
`

type AutocompleteOptionProps = {
  option: SearchResult
  inputValue: string
}

function AutocompleteOption({ option, inputValue }: AutocompleteOptionProps) {
  const classes = useStyles()
  let IconClass
  switch (option.type) {
    case SearchResultType.CONTRACT:
      IconClass = ProjectIcon
      break
    case SearchResultType.COMPANY:
      IconClass = CompanyIcon
      break
    case SearchResultType.FORM_TEMPLATE:
      IconClass = FormTemplateIcon
      break
    case SearchResultType.USER:
      IconClass = UserIcon
      break
    case SearchResultType.PAY_APP:
      IconClass = PayAppIcon
      break
  }

  const matches = match(option.description, inputValue, {
    insideWords: true,
    findAllOccurrences: true,
  })
  const parsed = parse(option.description, matches)
  const hasMatchingId = areNormalizedStringsEqual(inputValue, option.id)

  return (
    <>
      <IconClass className={classes.icon} />
      <span>
        {parsed.map((part, partIndex) => (
          <span key={partIndex}>
            {part.highlight ? <strong>{part.text}</strong> : <span>{part.text}</span>}
          </span>
        ))}
      </span>
      {hasMatchingId && <Chip label="Matching ID" size="small" className={classes.chip} />}
    </>
  )
}

export default function TopBar({ onOpenMenu }: { onOpenMenu?: () => void }) {
  const [createMenuAnchor, setCreateMenuAnchor] = useState<HTMLButtonElement | null>(null)
  const navigate = useNavigate()
  const classes = useStyles()
  const { search, debouncedSearch, onSearch } = useDebouncedSearch()
  const { data: searchData, loading: isSearching } = useSearchQuery({
    skip: debouncedSearch.length < 2,
    variables: { query: debouncedSearch },
  })
  const [createTestTemplate] = useCreateTestTemplateMutation()

  const results = useMemo(() => searchData?.search ?? [], [searchData])

  const { openFilePicker: openTestTemplateFilePicker } = useFilePicker({
    accept: ['.pdf', '.docx', '.xlsx'],
    readFilesContent: false,
    onFilesSelected: ({ plainFiles }) => {
      const file = plainFiles[0]
      snackbar.showLoading('Uploading template...')
      createTestTemplate({
        variables: {
          input: { file },
        },
      })
        .then((data) => {
          snackbar.closeAll()
          if (!data.data) {
            return
          }
          const templateId = data.data.createTestTemplate.id
          navigate(`/templates/${templateId}/edit`)
        })
        .catch((err) => {
          snackbar.showError(err.message)
        })
    },
  })
  const snackbar = useSitelineSnackbar()

  const handleLogout = () => {
    const auth = getAuth(getApp())
    auth.signOut()
    navigate('/')
  }

  const onResultSelected = (result: SearchResult | null) => {
    if (!result) {
      return
    }

    let url: string
    switch (result.type) {
      case SearchResultType.COMPANY:
        url = `/companies/${result.id}`
        break
      case SearchResultType.CONTRACT:
        url = `/contracts/${result.id}`
        break
      case SearchResultType.FORM_TEMPLATE:
        url = `/templates/${result.id}`
        break
      case SearchResultType.PAY_APP:
        url = `/payApps/${result.id}`
        break
      case SearchResultType.USER:
        url = `/users/${result.id}`
        break
    }

    navigate(url)
  }

  const closeAndGoTo = (url: string) => {
    setCreateMenuAnchor(null)
    navigate(url)
  }

  let chip = 'Admin'
  switch (API_ENV) {
    case 'production':
      chip = 'production'
      break
    case 'staging':
      chip = 'staging'
      break
    case 'local':
      chip = 'dev'
      break
  }

  const showCreateTestTemplate = API_ENV === 'local' || API_ENV === 'staging'
  const handleCreateTestTemplate = useCallback(() => {
    setCreateMenuAnchor(null)
    openTestTemplateFilePicker()
  }, [openTestTemplateFilePicker])

  return (
    <AppBar color="primary">
      <Toolbar>
        {onOpenMenu && (
          <IconButton onClick={onOpenMenu} className={classes.menu} size="large">
            <MenuIcon />
          </IconButton>
        )}
        <RouterLink to="/" className={classes.logoLink}>
          <Typography variant="h5" className={classes.logo}>
            Admin
            <Chip label={chip} size="small" className={classes.chip} />
          </Typography>
        </RouterLink>
        <Button
          onClick={(ev) => setCreateMenuAnchor(ev.currentTarget)}
          color="inherit"
          className={classes.createButton}
          startIcon={<AddIcon />}
        >
          Create
        </Button>
        <Menu
          anchorEl={createMenuAnchor}
          keepMounted
          open={createMenuAnchor !== null}
          onClose={() => setCreateMenuAnchor(null)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <MenuItem onClick={() => closeAndGoTo('/companies/create')}>
            <CompanyIcon className={classes.icon} /> Company
          </MenuItem>
          <MenuItem onClick={() => closeAndGoTo('/templates/create')}>
            <FormTemplateIcon className={classes.icon} /> Template
          </MenuItem>
          {showCreateTestTemplate && (
            <MenuItem onClick={handleCreateTestTemplate}>
              <FormTemplateIcon className={classes.icon} /> Quick Template (dev only)
            </MenuItem>
          )}
        </Menu>
        <Autocomplete
          options={results}
          filterOptions={(option) => option}
          inputValue={search}
          onInputChange={(event, newInputValue) => onSearch(newInputValue)}
          includeInputInList
          filterSelectedOptions
          getOptionLabel={() => ''}
          value={null}
          onChange={(event, newValue) => onResultSelected(newValue)}
          ListboxProps={{
            style: {
              maxHeight: 600,
            },
          }}
          renderInput={(params) => (
            <div className={classes.search} ref={params.InputProps.ref}>
              <div className={classes.searchIcon}>
                {isSearching ? (
                  <CircularProgress size={20} className={classes.progress} />
                ) : (
                  <SearchIcon />
                )}
              </div>
              <InputBase
                id={params.id}
                disabled={params.disabled}
                fullWidth={params.fullWidth}
                size={params.size}
                inputProps={params.inputProps}
                color="primary"
                placeholder="Search projects, companies, form templates, users..."
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                autoFocus
              />
            </div>
          )}
          renderOption={(props, option, state) => (
            <li {...props} key={option.id}>
              <AutocompleteOption option={option} inputValue={state.inputValue} />
            </li>
          )}
        />
        <div className={classes.flexEnd}>
          <Button
            className={classes.logoutButton}
            color="inherit"
            onClick={handleLogout}
            startIcon={<InputIcon />}
          >
            Sign out
          </Button>
        </div>
      </Toolbar>
    </AppBar>
  )
}
