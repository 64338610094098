import { Decimal } from 'decimal.js'
import _ from 'lodash'

// 600000 => "6,000.00"
export const dollarNumberFormat = new Intl.NumberFormat('en-US', {
  style: 'decimal',
  minimumFractionDigits: 2,
  maximumFractionDigits: 4,
})

// 600000 => "$6,000.00"
const dollarWithCurrencyNumberFormat = new Intl.NumberFormat('en-US', {
  style: 'currency',
  minimumFractionDigits: 2,
  maximumFractionDigits: 4,
  currency: 'USD',
})

/**
 * Given a cents value return the dollars equivalent.
 * i.e. Given 1234, return 12.34
 */
export function centsToDollars(cents: number): number {
  return _.round(cents / 100, 2)
}

/**
 * Given a dollar value return the cents equivalent.
 * i.e. Given 12.34, return 1234
 */
export function dollarsToCents(dollars: number, centsPrecision = 0): number {
  return _.round(dollars * 100, centsPrecision)
}

/**
 * Given a dollar value, return a unit price in cents.
 * Unit prices can have up to 4 decimals, which means they are stored as cents with 2 decimal places.
 * i.e. Given 12.3456, return 1234.56
 */
export function dollarsToUnitPriceCents(dollars: number | Decimal): Decimal {
  return new Decimal(dollars).times(100).toDecimalPlaces(2)
}

/**
 * Given a unit price in cents, returns a dollar amount.
 * Unit prices can have up to 4 decimals, which means they are stored as cents with 2 decimal places.
 * i.e. Given 1234.56, return 12.3456
 */
export function unitPriceCentsToDollars(unitPrice: number | Decimal): Decimal {
  return new Decimal(unitPrice).dividedBy(100).toDecimalPlaces(4)
}

/**
 * Given a decimal value and the number of digits after the decimal, return the percent equivalent.
 * i.e. Given 0.1 and 0, return 10
 * i.e. given 0.12345 and 2, return 12.35
 */
export function decimalToPercent(decimal: number, numDecimalDigits: number): number {
  return _.round(decimal * 100, numDecimalDigits)
}

/**
 * Given a percent value return the decimal equivalent. We round to 5 decimal spots to support
 * up to the thousandths place in the percentage value itself.
 * i.e. Given 10, return 0.1
 * i.e. Given 25.53 return 0.2523
 */
export function percentToDecimal(percent: number, precision = 5): number {
  return _.round(percent / 100, precision)
}

/**
 * Returns a dollar representation of an amount in cents, with or without currency, in the decimal format.
 */
export function formatCentsToDollars(cents: number, withCurrency = false): string {
  let cleanedCents = cents
  // Javascript has a concept of negative 0 (-0) which is distinct as an object from positive 0 (0)
  // despite them being the same value. This replaces the -0 with a positive 0 so that when we
  // format the dollar value, we don't end up with "-0.00" instead of just "0.00"
  if (cleanedCents === 0) {
    cleanedCents = 0
  }
  const formatter = withCurrency ? dollarWithCurrencyNumberFormat : dollarNumberFormat
  return formatter.format(cleanedCents / 100)
}

/**
 * Parses a currency amount and returns its cents value.
 * E.g: $1,234.56 => 123456
 *
 * More valid/invalid cases can be seen in numbers.test.ts
 */
export function parseAndValidateCurrencyAmount(currencyAmount: string): number {
  // Parse using a regex instead of a library.
  // We could not find a modern library that would do currency parsing reliably, so instead we assume
  // a US locale and use a regex to parse the string.
  // Inspired by https://github.com/openexchangerates/accounting.js/blob/74a2b12800f0483ffeebf05c00ea52f8dde3ac07/accounting.js#L197-L203
  const clean = currencyAmount
    // Replace bracketed values with negatives
    .replace(/\((.*)\)/, '-$1')
    // Strip out any cruft
    .replace(/[^0-9-.]/g, '')

  const parsed = parseFloat(clean)

  if (isNaN(parsed)) {
    throw new Error(`Could not parse currency amount ${currencyAmount}`)
  }

  return dollarsToCents(parsed)
}

/**
 * Prevents dividing by 0 and returning NaN. Instead, the caller provides a safe failureValue to be
 * used when dividing by 0 is attempted.
 */
export function safeDivide(numerator: number, denominator: number, failureValue: number): number {
  if (denominator === 0) {
    return failureValue
  }
  // eslint-disable-next-line no-restricted-syntax
  return _.round(numerator / denominator, 5)
}

/**
 * Prevents dividing by 0 and returning NaN. Instead, the caller provides a safe failureValue to be
 * used when dividing by 0 is attempted.
 */
export function safeDivideDecimal(
  numerator: number | Decimal,
  denominator: number | Decimal,
  failureValue: number | Decimal
): Decimal {
  const numeratorDecimal = new Decimal(numerator)
  const denominatorDecimal = new Decimal(denominator)
  if (denominatorDecimal.equals(0)) {
    return new Decimal(failureValue)
  }
  // eslint-disable-next-line no-restricted-syntax
  return numeratorDecimal.dividedBy(denominatorDecimal).toDecimalPlaces(5)
}

/**
 * Checks if a value is between 0 and the range, inclusive.
 *
 * We have to use an inRange check to make sure we appropriately account for negative values. We
 * can't use:
 *   1. Simple comparisons (value < range) because that breaks down if the total
 *      value is negative. i.e. if the value is -100 and the total value is -50, this
 *      should return false but it would return true.
 *   2. Absolute value based comparisons (Math.abs(value) < Math.abs(range)) because
 *      that breaks down if the total value is negative but the total billed is positive. i.e. if
 *      the new total billed is 50 and the total value is -100, this should return false but it
 *      would return true.
 *
 * NOTE: the _.inRange check is not inclusive so we have to check the boundaries as well
 *
 * @param value the value to be validated
 * @param start one of the boundaries of the range to check the value against
 * @param end the other boundary of the range. If not set, check the range between 0 and start
 */
export function isValueInRange(value: number, start: number, end = 0): boolean {
  return _.inRange(value, start, end) || value === start || value === end
}
