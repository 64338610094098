import {
  Alert,
  Button,
  Card,
  CardContent,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
  Typography,
} from '@mui/material'
import { useMemo, useState } from 'react'
import { UserStatus, makeStylesFast } from 'siteline-common-web'
import { DetailedCompany } from '../../common/graphql/Fragments'
import { AddSitelineAdmin } from './AddSitelineAdmin'
import CompanyUserRow from './CompanyUserRow'

const useStyles = makeStylesFast((theme: Theme) => ({
  card: {
    paddingBottom: 0,
  },
  cardContent: {},
  table: {
    margin: -theme.spacing(4),
  },
  nameCell: {
    display: 'flex',
    alignItems: 'center',
  },
  actions: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    '& > * + *': {
      marginLeft: 0,
    },
  },
  buttonIcon: {
    marginRight: theme.spacing(1),
  },
  lineItemName: {
    maxWidth: 300,
  },
  nameInput: {
    marginRight: theme.spacing(1),
    maxWidth: 100,
  },
  maxWidth: {
    width: 100,
  },
  menuItem: {
    padding: theme.spacing(0, 1, 0, 0.5),
  },
}))

interface CompanyUsersProps {
  company: DetailedCompany
}

export default function CompanyUsers({ company }: CompanyUsersProps) {
  const classes = useStyles()
  const [isAddingSitelineAdmin, setIsAddingSitelineAdmin] = useState(false)

  const sortedUsers = [...company.users].sort((a, b) => {
    const nameA = `${a.user.firstName} ${a.user.lastName}`
    const nameB = `${b.user.firstName} ${b.user.lastName}`
    return nameA < nameB ? -1 : 1
  })

  const sitelineAdmins = useMemo(() => {
    return company.users
      .filter((user) => user.status === UserStatus.ACTIVE)
      .filter((user) => user.isSitelineAdmin)
  }, [company.users])

  return (
    <Card>
      <CardHeader
        title="Users"
        action={
          <>
            {sitelineAdmins.length === 0 && (
              <Button onClick={() => setIsAddingSitelineAdmin(true)}>Add Siteline Admin</Button>
            )}
          </>
        }
      />
      <CardContent>
        <Alert severity="info">
          Users are now managed in the main app. Log in as an admin on the company and navigate to
          the company user settings.
        </Alert>
        {sitelineAdmins.length === 1 && (
          <Typography sx={{ marginTop: 2 }}>
            Siteline admin: <strong>{sitelineAdmins[0].user.email}</strong>
          </Typography>
        )}
        {sitelineAdmins.length > 1 && (
          <>
            <Typography>Siteline admins:</Typography>
            <ul>
              {sitelineAdmins.map((admin) => (
                <li key={admin.id}>{admin.user.email}</li>
              ))}
            </ul>
          </>
        )}
      </CardContent>
      <CardContent className={classes.table}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Role</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Job title</TableCell>
              <TableCell>Phone number</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedUsers.map((companyUser) => (
              <CompanyUserRow
                key={companyUser.id}
                companyId={company.id}
                companyUser={companyUser}
              />
            ))}
          </TableBody>
        </Table>
      </CardContent>
      <AddSitelineAdmin
        company={company}
        open={isAddingSitelineAdmin}
        onClose={() => setIsAddingSitelineAdmin(false)}
      />
    </Card>
  )
}
