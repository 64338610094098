import ToggleButton, { ToggleButtonProps } from '@mui/material/ToggleButton'
import Tooltip, { TooltipProps } from '@mui/material/Tooltip'
import { forwardRef } from 'react'

type TooltipToggleButtonProps = ToggleButtonProps & {
  TooltipProps: Omit<TooltipProps, 'children'>
}

/**
 * A toggle button that displays a tooltip when hovered.
 * Prevents rendering issues when wrapping a toggle button with a tooltip.
 */
export const TooltipToggleButton = forwardRef<HTMLButtonElement, TooltipToggleButtonProps>(
  function TooltipToggleButton({ TooltipProps, ...props }, ref) {
    return (
      <Tooltip {...TooltipProps}>
        <ToggleButton ref={ref} {...props} />
      </Tooltip>
    )
  }
)
