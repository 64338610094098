import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import { useSitelineSnackbar } from 'siteline-common-web'
import {
  CompanyIntegrationProperties,
  useUpdateIntegrationCredentialMutation,
} from '../../common/graphql/apollo-operations'

type UpdateHh2CredentialModalProps = {
  companyIntegration: CompanyIntegrationProperties
  open: boolean
  setOpen: (open: boolean) => void
}

/** Modal to update username/subdomain/password of a HH2 integration */
export function UpdateHh2CredentialModal({
  companyIntegration,
  open,
  setOpen,
}: UpdateHh2CredentialModalProps) {
  // Default hh2 username for new customers. This used to be 'ucm', they changed it to 'sync'.
  const initialUsername = 'sync'

  const [subdomain, setSubdomain] = useState<string>('')
  const [username, setUsername] = useState<string>(initialUsername)
  const [password, setPassword] = useState<string>('')
  const snackbar = useSitelineSnackbar()

  const [updateCredentials] = useUpdateIntegrationCredentialMutation()

  // Set default values depending on integration type
  useEffect(() => {
    setSubdomain('')
    setUsername(initialUsername)
    setPassword('')
  }, [companyIntegration.id])

  const handleSubmit = useCallback(() => {
    if (subdomain.length === 0 || username.length === 0 || password.length === 0) {
      return
    }

    updateCredentials({
      variables: {
        input: {
          companyIntegrationId: companyIntegration.id,
          username: subdomain,
          hh2Username: username,
          password,
        },
      },
    })
      .then(() => snackbar.showSuccess('Credentials updated'))
      .catch((error) => snackbar.showError(error.message))

    setOpen(false)
  }, [companyIntegration.id, password, setOpen, snackbar, subdomain, updateCredentials, username])

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle>Update HH2 Credentials for {companyIntegration.type}</DialogTitle>
      <DialogContent>
        <Alert severity="info">
          Please copy the credentials from the &apos;hh2 API Customer Credentials (Sage)&apos; note
          in 1Password.
        </Alert>
        <TextField
          fullWidth
          label="Username"
          value={username}
          onChange={(ev) => setUsername(ev.target.value)}
          style={{ marginTop: 16 }}
          helperText="This should be 'sync' for recent customers, 'ucm' for old ones."
        />
        <TextField
          fullWidth
          label="Subdomain"
          value={subdomain}
          onChange={(ev) => setSubdomain(ev.target.value)}
          style={{ marginTop: 16 }}
          helperText="Typically the name of the customer. Eg: 'aga' for AGA, or 'beaty' for Beaty Masonry."
        />
        <TextField
          fullWidth
          label="Password"
          type="password"
          value={password}
          onChange={(ev) => setPassword(ev.target.value)}
          style={{ marginTop: 16 }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)}>Cancel</Button>
        <Button onClick={handleSubmit} color="primary">
          Update HH2 credentials
        </Button>
      </DialogActions>
    </Dialog>
  )
}
