import { Edit } from '@mui/icons-material'
import AddIcon from '@mui/icons-material/Add'
import {
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
} from '@mui/material'
import _ from 'lodash'
import { useCallback, useMemo } from 'react'
import 'react-resizable/css/styles.css'
import { makeStylesFast, useToggle } from 'siteline-common-web'
import { FormTemplateProperties } from '../../../common/graphql/apollo-operations'
import { CreateTemplateVariantDialog } from './CreateTemplateVariantDialog'
import { EditTemplateVariantDialog } from './EditTemplateVariantDialog'

const useStyles = makeStylesFast((theme) => ({
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  more: {
    marginLeft: theme.spacing(1),
  },
}))

type TemplateVariantSelectorProps = {
  template: FormTemplateProperties
  variantId: string
  onVariantIdChange: (variantId: string) => void
  className?: string
}

/**
 * Variant selector. Includes a create button that opens a dialog to create a new variant.
 */
export function TemplateVariantSelector({
  template,
  variantId,
  onVariantIdChange,
  className,
}: TemplateVariantSelectorProps) {
  const classes = useStyles()
  const [isCreateDialogOpen, openCreateDialog, closeCreateDialog] = useToggle()
  const [isUpdateDialogOpen, openUpdateDialog, closeUpdateDialog] = useToggle()

  const variants = useMemo(() => {
    return _.orderBy(
      template.variants,
      [(variant) => (variant.isDefaultVariant ? -1 : 1), (variant) => variant.internalName],
      ['asc', 'desc']
    )
  }, [template.variants])

  const handleVariantIdChange = useCallback(
    (variantId: string) => {
      if (variantId === 'newVariant') {
        openCreateDialog()
      } else {
        onVariantIdChange(variantId)
      }
    },
    [onVariantIdChange, openCreateDialog]
  )

  const handleVariantCreated = useCallback(
    (variantId: string) => {
      onVariantIdChange(variantId)
      closeCreateDialog()
    },
    [closeCreateDialog, onVariantIdChange]
  )

  const handleVariantDeleted = useCallback(() => {
    const defaultVariant = variants.find((variant) => variant.isDefaultVariant)
    if (!defaultVariant) {
      return
    }
    onVariantIdChange(defaultVariant.id)
    closeUpdateDialog()
  }, [closeUpdateDialog, onVariantIdChange, variants])

  const selectedVariant = useMemo(
    () => variants.find((variant) => variant.id === variantId),
    [variantId, variants]
  )

  return (
    <>
      <div className={classes.row}>
        <FormControl className={className} sx={{ flex: 1 }}>
          <InputLabel id="variantLabel">Variant</InputLabel>
          <Select
            labelId="variantLabel"
            value={variantId}
            onChange={(ev) => handleVariantIdChange(ev.target.value)}
            size="small"
            variant="outlined"
            label="Variant"
          >
            {variants.map((variant) => (
              <MenuItem key={variant.id} value={variant.id}>
                {variant.isDefaultVariant ? 'Default settings' : variant.internalName}
              </MenuItem>
            ))}
            <Divider />
            <MenuItem value="newVariant">
              <ListItemIcon>
                <AddIcon />
              </ListItemIcon>
              <ListItemText>Create new variant</ListItemText>
            </MenuItem>
          </Select>
        </FormControl>
        {selectedVariant && (
          <IconButton className={classes.more} onClick={openUpdateDialog}>
            <Edit fontSize="small" />
          </IconButton>
        )}
      </div>
      <CreateTemplateVariantDialog
        open={isCreateDialogOpen}
        onClose={closeCreateDialog}
        onCreated={handleVariantCreated}
        template={template}
      />
      <EditTemplateVariantDialog
        open={isUpdateDialogOpen}
        onClose={closeUpdateDialog}
        onDeleted={handleVariantDeleted}
        variantId={variantId}
      />
    </>
  )
}
