import {
  Card,
  CardContent,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material'
import _ from 'lodash'
import { makeStylesFast } from 'siteline-common-web'
import { DollarNumberFormat, PercentNumberFormat } from '../../common/components/NumberFormat'
import { DetailedPayApp } from '../../common/graphql/Fragments'

const useStyles = makeStylesFast(() => ({
  cardContent: {
    padding: 0,
    '&:last-child': {
      padding: 0,
    },
  },
  lineItemName: {
    maxWidth: 300,
  },
}))

interface PayAppDetailsAmountLineItemProps {
  payApp: DetailedPayApp
}

export default function PayAppDetailsAmountLineItem({ payApp }: PayAppDetailsAmountLineItemProps) {
  const classes = useStyles()

  const sortedAmountLineItems = _.orderBy(
    payApp.amountLineItems,
    (amountLineItem) => amountLineItem.sortOrder,
    'asc'
  )

  return (
    <Card>
      <CardHeader title="Progress" />
      <CardContent className={classes.cardContent}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Description</TableCell>
              <TableCell>Amount</TableCell>
              <TableCell>Fee Percent</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedAmountLineItems.map((amountLineItem) => (
              <TableRow hover key={amountLineItem.id}>
                <TableCell>{amountLineItem.description}</TableCell>
                <TableCell>
                  <DollarNumberFormat value={amountLineItem.amount} />
                </TableCell>
                <TableCell>
                  <PercentNumberFormat value={amountLineItem.feePercent} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  )
}
