import { gql } from '@apollo/client'
import {
  Alert,
  Button,
  Card,
  CardContent,
  CardHeader,
  Table,
  TableCell,
  TableHead,
  TableRow,
  Theme,
  Tooltip,
} from '@mui/material'
import _ from 'lodash'
import { useCallback, useMemo } from 'react'
import {
  FormTemplateStatus,
  ProjectOnboardingFormType,
  colors,
  makeStylesFast,
  useSitelineSnackbar,
} from 'siteline-common-web'
import * as fragments from '../../common/graphql/Fragments'
import {
  BillingType,
  FormTemplateType,
  GetContractDocument,
  UpdatePayAppRequirementGroupInput,
  useContractFormTemplatesQuery,
  useEnableLowerTierLienWaiversMutation,
  useEnablePrimaryLienWaiversMutation,
  useIgnoreFormTemplateProvidedTypeMutation,
  useTemplateForAutocompleteLazyQuery,
  useUpdateChangeOrderRequestFormMutation,
  useUpdatePayAppRequirementGroupsMutation,
} from '../../common/graphql/apollo-operations'
import {
  payAppFormTemplateTypes,
  readableFormTemplateType,
  readableProjectOnboardingFormType,
} from '../../common/util/FormTemplate'
import { FormTemplateStatusChip } from '../FormTemplateStatusChip'
import QuickAddLienWaiverFormTemplate from '../lien-waiver-template-set/QuickAddLienWaiverFormTemplate'

gql`
  query contractFormTemplates($contractId: ID!) {
    contractFormTemplates(contractId: $contractId) {
      id
      createdAt
      type
      userVisibleName
      status
      skippedValidation
      variants {
        id
        isDefaultVariant
      }
      associatedContracts {
        id
        providedAsFormType
        ignoreProvidedAsFormType
        contract {
          id
        }
      }
    }
  }
`

gql`
  mutation ignoreFormTemplateProvidedType($input: IgnoreFormTemplateProvidedTypeInput!) {
    ignoreFormTemplateProvidedType(input: $input) {
      id
      associatedFormTemplates {
        id
        ignoreProvidedAsFormType
      }
      onboardedStatus {
        selectedPayAppForms
        onboardedPayAppForms
        selectedPrimaryLienWaivers
        onboardedPrimaryLienWaiverForms
        selectedVendorLienWaivers
        onboardedVendorLienWaiverForms
        selectedChangeOrderRequestForms
        onboardedChangeOrderRequestForms
        notifiedOnboardedForms
      }
    }
  }
`

const useStyles = makeStylesFast((theme: Theme) => ({
  cardContent: {
    padding: 0,
    '&:last-child': {
      padding: 0,
    },
    '& .MuiTableCell-root': {
      borderBottom: 0,
    },
    '& .MuiTableHead-root': {
      borderBottom: `1px solid ${colors.grey30}`,
    },
    '& .MuiTableBody-root': {
      borderBottom: `1px solid ${colors.grey30}`,
    },
    '& .emptyText': {
      margin: theme.spacing(0, 2, 2),
    },
  },
  lienWaiverActions: {
    margin: theme.spacing(0, -0.5),
    '& > *': {
      margin: theme.spacing(0, 0.5),
    },
  },
  formName: {
    color: colors.grey90,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  onboardingAlert: {
    display: 'flex',
    alignItems: 'center',
    '& .MuiAlert-message': {
      flexGrow: 1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  },
}))

interface ContractDetailsPendingFormTemplatesProps {
  contract: fragments.Contract
}

const EMPTY_LIEN_WAIVER_TEMPLATE_SET_INPUT = {
  conditionalProgressVariantId: null,
  conditionalFinalVariantId: null,
  unconditionalProgressVariantId: null,
  unconditionalFinalVariantId: null,
}

/**
 * Card on the contract page showing form templates that are linked to this contract
 * but have not been added yet as forms
 */
export default function ContractDetailsPendingFormTemplates({
  contract,
}: ContractDetailsPendingFormTemplatesProps) {
  const classes = useStyles()
  const snackbar = useSitelineSnackbar()
  const [getTemplate] = useTemplateForAutocompleteLazyQuery()
  const { data, error, loading } = useContractFormTemplatesQuery({
    variables: { contractId: contract.id },
  })
  const [updatePayAppRequirementGroups] = useUpdatePayAppRequirementGroupsMutation({
    refetchQueries: [{ query: GetContractDocument, variables: { id: contract.id } }],
  })
  const [updateChangeOrderRequestForm] = useUpdateChangeOrderRequestFormMutation({
    refetchQueries: [{ query: GetContractDocument, variables: { id: contract.id } }],
  })
  const [updatePrimaryLienWaivers] = useEnablePrimaryLienWaiversMutation({
    refetchQueries: [{ query: GetContractDocument, variables: { id: contract.id } }],
  })
  const [updateVendorLienWaivers] = useEnableLowerTierLienWaiversMutation({
    refetchQueries: [{ query: GetContractDocument, variables: { id: contract.id } }],
  })
  const [ignoreFormTemplateProvidedType] = useIgnoreFormTemplateProvidedTypeMutation({
    refetchQueries: [{ query: GetContractDocument, variables: { id: contract.id } }],
  })
  const payAppRequirementFormTemplateIds = useMemo(() => {
    const formTemplateIds = contract.payAppRequirementGroups.flatMap((group) =>
      group.payAppRequirements.map((requirement) => requirement.templateVariant?.template.id)
    )
    return _.compact(formTemplateIds)
  }, [contract])
  const primaryFormTemplateIds = useMemo(
    () => [
      contract.lienWaiverTemplates?.conditionalProgressVariant?.template.id,
      contract.lienWaiverTemplates?.conditionalFinalVariant?.template.id,
      contract.lienWaiverTemplates?.unconditionalProgressVariant?.template.id,
      contract.lienWaiverTemplates?.unconditionalFinalVariant?.template.id,
    ],
    [
      contract.lienWaiverTemplates?.conditionalFinalVariant?.template.id,
      contract.lienWaiverTemplates?.conditionalProgressVariant?.template.id,
      contract.lienWaiverTemplates?.unconditionalFinalVariant?.template.id,
      contract.lienWaiverTemplates?.unconditionalProgressVariant?.template.id,
    ]
  )
  const vendorFormTemplateIds = useMemo(
    () => [
      contract.lowerTierLienWaiverTemplates?.conditionalProgressVariant?.template.id,
      contract.lowerTierLienWaiverTemplates?.conditionalFinalVariant?.template.id,
      contract.lowerTierLienWaiverTemplates?.unconditionalProgressVariant?.template.id,
      contract.lowerTierLienWaiverTemplates?.unconditionalFinalVariant?.template.id,
    ],
    [contract]
  )
  const complianceFormTemplateIds = useMemo(() => {
    const formTemplateIds = contract.legalRequirements.map(
      (legalRequirement) => legalRequirement.formTemplate?.id
    )
    return _.chain(formTemplateIds).compact().uniq().value()
  }, [contract])
  const pendingFormTemplates = useMemo(() => {
    const formTemplates = data?.contractFormTemplates ?? []
    return formTemplates.filter((formTemplate) => {
      if (
        formTemplate.status === FormTemplateStatus.CANCELED ||
        formTemplate.status === FormTemplateStatus.DUPLICATE
      ) {
        return false
      }
      const associatedContract = formTemplate.associatedContracts.find(
        (association) => association.contract.id === contract.id
      )
      if (!associatedContract || associatedContract.ignoreProvidedAsFormType) {
        return false
      }
      switch (formTemplate.type) {
        case FormTemplateType.PAY_APP_TIME_AND_MATERIALS:
        case FormTemplateType.PAY_APP_LUMP_SUM:
        case FormTemplateType.PAY_APP_QUICK:
        case FormTemplateType.PAY_APP_UNIT_PRICE: {
          if (payAppRequirementFormTemplateIds.includes(formTemplate.id)) {
            return false
          }
          break
        }
        case FormTemplateType.LIEN_WAIVER: {
          const providedAsFormType = associatedContract.providedAsFormType
          switch (providedAsFormType) {
            case ProjectOnboardingFormType.PRIMARY_LIEN_WAIVER:
              if (primaryFormTemplateIds.includes(formTemplate.id)) {
                return false
              }
              break
            case ProjectOnboardingFormType.VENDOR_LIEN_WAIVER:
              if (vendorFormTemplateIds.includes(formTemplate.id)) {
                return false
              }
              break
            case ProjectOnboardingFormType.PAY_APP:
            case ProjectOnboardingFormType.COMPLIANCE:
            case ProjectOnboardingFormType.CHANGE_ORDER_LOG:
            case ProjectOnboardingFormType.CHANGE_ORDER_REQUEST:
            case null:
              break
          }
          break
        }
        case FormTemplateType.CHANGE_ORDER_REQUEST: {
          if (contract.changeOrderRequestTemplate?.id === formTemplate.id) {
            return false
          }
          break
        }
        case FormTemplateType.CHANGE_ORDER_LOG: {
          if (contract.changeOrderLogTemplate?.id === formTemplate.id) {
            return false
          }
          break
        }
        case FormTemplateType.LEGAL_DOCUMENT: {
          if (complianceFormTemplateIds.includes(formTemplate.id)) {
            return false
          }
          break
        }
      }
      return true
    })
  }, [
    data?.contractFormTemplates,
    contract.id,
    contract.changeOrderRequestTemplate?.id,
    contract.changeOrderLogTemplate?.id,
    payAppRequirementFormTemplateIds,
    primaryFormTemplateIds,
    vendorFormTemplateIds,
    complianceFormTemplateIds,
  ])

  const addPayAppRequirement = useCallback(
    async (formTemplateId: string) => {
      snackbar.showLoading()
      const data = await getTemplate({ variables: { id: formTemplateId } })
      if (!data.data) {
        snackbar.showError('Failed to load form template')
        return
      }
      const defaultVariant = data.data.formTemplate.variants.find(
        (variant) => variant.isDefaultVariant
      )
      if (!defaultVariant) {
        snackbar.showError('Template has no default variant')
        return
      }
      const newRequirementGroups: UpdatePayAppRequirementGroupInput[] = [
        ...contract.payAppRequirementGroups.map((requirementGroup) => ({
          id: requirementGroup.id,
          order: requirementGroup.order,
          requirements: requirementGroup.payAppRequirements.map((requirement) => ({
            id: requirement.id,
            groupOrder: requirement.groupOrder,
            templateVariantId: requirement.templateVariant?.id ?? null,
            conditions: requirement.conditions,
          })),
        })),
        {
          order: contract.payAppRequirementGroups.length + 1,
          requirements: [
            {
              templateVariantId: defaultVariant.id,
              groupOrder: 1,
              conditions: [],
            },
          ],
        },
      ]
      await updatePayAppRequirementGroups({
        variables: {
          input: {
            contractId: contract.id,
            requirementGroups: newRequirementGroups,
          },
        },
      })
        .then(() => snackbar.showSuccess('Added pay app requirement with this form template'))
        .catch((err) => snackbar.showError(err.message))
    },
    [
      contract.id,
      contract.payAppRequirementGroups,
      getTemplate,
      snackbar,
      updatePayAppRequirementGroups,
    ]
  )

  const updateChangeOrderRequestTemplate = useCallback(
    async (formTemplateId: string) => {
      updateChangeOrderRequestForm({
        variables: {
          input: { contractId: contract.id, changeOrderRequestFormTemplateId: formTemplateId },
        },
      })
        .then(() => snackbar.showSuccess('Updated change order request template'))
        .catch((err) => snackbar.showError(err.message))
    },
    [contract.id, snackbar, updateChangeOrderRequestForm]
  )

  // If there should be primary LW forms processing but none are pending, show an option to complete
  // that onboarding step
  const handleCompletePrimaryLienWaiverOnboarding = useMemo(() => {
    if (
      !contract.onboardedStatus.selectedPrimaryLienWaivers ||
      contract.onboardedStatus.onboardedPrimaryLienWaiverForms ||
      contract.lienWaiverTemplates
    ) {
      return null
    }
    const lienWaiverAssociatedTemplates = pendingFormTemplates.filter((formTemplate) => {
      const associatedContract = formTemplate.associatedContracts.find(
        (association) => association.contract.id === contract.id
      )
      return (
        formTemplate.type === FormTemplateType.LIEN_WAIVER ||
        associatedContract?.providedAsFormType === ProjectOnboardingFormType.PRIMARY_LIEN_WAIVER
      )
    })
    if (lienWaiverAssociatedTemplates.length > 0) {
      return null
    }
    return async () => {
      await updatePrimaryLienWaivers({
        variables: {
          input: { contractId: contract.id, templates: EMPTY_LIEN_WAIVER_TEMPLATE_SET_INPUT },
        },
      })
      snackbar.showSuccess('Primary lien waiver onboarding is marked complete')
    }
  }, [
    contract.id,
    contract.lienWaiverTemplates,
    contract.onboardedStatus.onboardedPrimaryLienWaiverForms,
    contract.onboardedStatus.selectedPrimaryLienWaivers,
    pendingFormTemplates,
    snackbar,
    updatePrimaryLienWaivers,
  ])

  // If there should be vendor LW forms processing but none are pending, show an option to complete
  // that onboarding step
  const handleCompleteVendorLienWaiverOnboarding = useMemo(() => {
    if (
      !contract.onboardedStatus.selectedVendorLienWaivers ||
      contract.onboardedStatus.onboardedVendorLienWaiverForms ||
      contract.lowerTierLienWaiverTemplates
    ) {
      return null
    }
    const lienWaiverAssociatedTemplates = pendingFormTemplates.filter((formTemplate) => {
      const associatedContract = formTemplate.associatedContracts.find(
        (association) => association.contract.id === contract.id
      )
      return (
        formTemplate.type === FormTemplateType.LIEN_WAIVER ||
        associatedContract?.providedAsFormType === ProjectOnboardingFormType.VENDOR_LIEN_WAIVER
      )
    })
    if (lienWaiverAssociatedTemplates.length > 0) {
      return null
    }
    return async () => {
      await updateVendorLienWaivers({
        variables: {
          input: { contractId: contract.id, templates: EMPTY_LIEN_WAIVER_TEMPLATE_SET_INPUT },
        },
      })
      snackbar.showSuccess('Vendor lien waiver onboarding is marked complete')
    }
  }, [
    contract.id,
    contract.lowerTierLienWaiverTemplates,
    contract.onboardedStatus.onboardedVendorLienWaiverForms,
    contract.onboardedStatus.selectedVendorLienWaivers,
    pendingFormTemplates,
    snackbar,
    updateVendorLienWaivers,
  ])

  // If there should be a COR form processing but none is pending, show an option to complete
  // that onboarding step
  const handleCompleteChangeOrderRequestOnboarding = useMemo(() => {
    if (
      !contract.onboardedStatus.selectedChangeOrderRequestForms ||
      contract.onboardedStatus.onboardedChangeOrderRequestForms ||
      contract.changeOrderRequestTemplate
    ) {
      return null
    }
    const changeOrderRequestAssociatedTemplates = pendingFormTemplates.filter((formTemplate) => {
      const associatedContract = formTemplate.associatedContracts.find(
        (association) => association.contract.id === contract.id
      )
      return (
        formTemplate.type === FormTemplateType.CHANGE_ORDER_REQUEST ||
        associatedContract?.providedAsFormType === ProjectOnboardingFormType.CHANGE_ORDER_REQUEST
      )
    })
    if (changeOrderRequestAssociatedTemplates.length > 0) {
      return null
    }
    return async () => {
      await updateChangeOrderRequestForm({
        variables: {
          input: { contractId: contract.id, changeOrderRequestFormTemplateId: null },
        },
      })
      snackbar.showSuccess('COR onboarding is marked complete')
    }
  }, [
    contract.changeOrderRequestTemplate,
    contract.id,
    contract.onboardedStatus.onboardedChangeOrderRequestForms,
    contract.onboardedStatus.selectedChangeOrderRequestForms,
    pendingFormTemplates,
    snackbar,
    updateChangeOrderRequestForm,
  ])

  const handleIgnoreAssociation = useCallback(
    (formTemplateAssociatedContractId: string) =>
      ignoreFormTemplateProvidedType({
        variables: { input: { formTemplateAssociatedContractId } },
      }),
    [ignoreFormTemplateProvidedType]
  )

  if (contract.billingType === BillingType.QUICK || error || loading) {
    return null
  }

  return (
    <Card>
      <CardHeader
        title="Pending form templates"
        subheader={
          pendingFormTemplates.length > 0
            ? 'These form templates are linked to this contract but have not yet been added below'
            : "There are no form templates attached to this contract that aren't already in use"
        }
      />
      <CardContent className={classes.cardContent}>
        <Table>
          {pendingFormTemplates.length > 0 && (
            <TableHead>
              <TableRow>
                <TableCell>Form template</TableCell>
                <TableCell style={{ width: 200 }}>Template status</TableCell>
                <TableCell style={{ width: 170 }}>Provided type</TableCell>
                <TableCell style={{ width: 150 }}>Internal type</TableCell>
                <TableCell>Quick add</TableCell>
                <TableCell style={{ width: 200 }} />
              </TableRow>
            </TableHead>
          )}
          {pendingFormTemplates.map((formTemplate) => {
            const isPayAppFormTemplate = payAppFormTemplateTypes.includes(formTemplate.type)
            const associatedContract = formTemplate.associatedContracts.find(
              (association) => association.contract.id === contract.id
            )
            const uploadedAs = associatedContract?.providedAsFormType
              ? readableProjectOnboardingFormType(associatedContract.providedAsFormType)
              : ''
            return (
              <TableRow key={formTemplate.id}>
                <TableCell>
                  <a
                    href={`/templates/${formTemplate.id}`}
                    target="_blank"
                    rel="noreferrer"
                    className={classes.formName}
                  >
                    {formTemplate.userVisibleName}
                  </a>
                </TableCell>
                <TableCell>
                  <FormTemplateStatusChip
                    status={formTemplate.status}
                    skippedValidation={formTemplate.skippedValidation}
                  />
                </TableCell>
                <TableCell>{uploadedAs}</TableCell>
                <TableCell>{readableFormTemplateType(formTemplate.type)}</TableCell>
                <TableCell>
                  {isPayAppFormTemplate && (
                    <Tooltip
                      title="Create a new pay app requirement group"
                      placement="bottom-start"
                      disableInteractive
                    >
                      <Button
                        color="secondary"
                        variant="outlined"
                        size="small"
                        onClick={() => addPayAppRequirement(formTemplate.id)}
                      >
                        New pay app requirement
                      </Button>
                    </Tooltip>
                  )}
                  {formTemplate.type === FormTemplateType.LIEN_WAIVER && (
                    <QuickAddLienWaiverFormTemplate
                      formTemplate={formTemplate}
                      contract={contract}
                    />
                  )}
                  {formTemplate.type === FormTemplateType.CHANGE_ORDER_REQUEST &&
                    contract.changeOrderRequestTemplate === null && (
                      <Button
                        color="secondary"
                        variant="outlined"
                        size="small"
                        onClick={() => updateChangeOrderRequestTemplate(formTemplate.id)}
                      >
                        Default COR template
                      </Button>
                    )}
                </TableCell>
                <TableCell>
                  {associatedContract && (
                    <Button
                      color="secondary"
                      variant="text"
                      size="small"
                      onClick={() => handleIgnoreAssociation(associatedContract.id)}
                    >
                      Ignore form
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            )
          })}
        </Table>
        {handleCompletePrimaryLienWaiverOnboarding && (
          <Alert severity="warning" className={classes.onboardingAlert}>
            <span>
              The primary LW onboarding step is incomplete, but there are no pending LW forms. Are
              primary LWs ready?
            </span>
            <Button variant="text" onClick={handleCompletePrimaryLienWaiverOnboarding}>
              Mark primary lien waivers onboarded
            </Button>
          </Alert>
        )}
        {handleCompleteVendorLienWaiverOnboarding && (
          <Alert severity="warning" className={classes.onboardingAlert}>
            <span>
              The vendor LW onboarding step is incomplete, but there are no pending LW forms. Are
              vendor LWs ready?
            </span>
            <Button variant="text" onClick={handleCompleteVendorLienWaiverOnboarding}>
              Mark vendor lien waivers onboarded
            </Button>
          </Alert>
        )}
        {handleCompleteChangeOrderRequestOnboarding && (
          <Alert severity="warning" className={classes.onboardingAlert}>
            <span>
              The COR onboarding step is incomplete, but there is no pending COR form. Is this step
              done?
            </span>
            <Button variant="text" onClick={handleCompleteChangeOrderRequestOnboarding}>
              Mark COR form onboarded
            </Button>
          </Alert>
        )}
      </CardContent>
    </Card>
  )
}
