import {
  Card,
  CardHeader,
  Divider,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material'
import moment from 'moment-timezone'
import { Link as RouterLink } from 'react-router-dom'
import { sortPayAppsByBillingEnd } from 'siteline-common-all'
import { ContractPayApp } from './ContractDetails'

interface ContractDetailsPayAppsProps {
  payApps: ContractPayApp[]
  timeZone: string
}

export default function ContractDetailsPayApps({ payApps, timeZone }: ContractDetailsPayAppsProps) {
  const sortedPayApps = sortPayAppsByBillingEnd([...payApps], 'desc', timeZone)

  return (
    <Card>
      <CardHeader title="Pay Apps" />
      <Divider />
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Type</TableCell>
            <TableCell>Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedPayApps.map((payApp) => (
            <TableRow hover key={payApp.id}>
              <TableCell>
                <Link to={`/payApps/${payApp.id}`} component={RouterLink}>
                  #{payApp.payAppNumber}{' '}
                  {moment.tz(payApp.billingEnd, payApp.timeZone).format('MMMM')}
                </Link>
              </TableCell>
              <TableCell>{payApp.retentionOnly ? 'Retention' : 'Progress'}</TableCell>
              <TableCell>{payApp.status}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Card>
  )
}
