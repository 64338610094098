type PaginationState = {
  // Whether to show multiple lines of skeletons
  showSkeletons: boolean

  // Whether to show the bottom loading row which indicates that we're loading more entities
  showLoadingMoreRow: boolean

  // Whether to show the "No entities" placeholder, which appears when not filtering/searching
  // and there are no entities at all in the system.
  showNoEntitiesPlaceholder: boolean

  // Whether to show the "No filter results" placeholder after filtering.
  showNoFilterResultPlaceholder: boolean

  // Whether to show the "No search results" placeholder after searching.
  showNoSearchResultPlaceholder: boolean

  // Whether to show the list column headers.
  showColumnHeaders: boolean
}

type PaginationStateProps<T> = {
  // Entities loaded from the server. Pass null if no entity has been loaded yet.
  entities: T[] | ReadonlyArray<T> | null

  // Whether there are more entities to load from the server.
  hasNext: boolean

  // Whether we're loading entities from the server. This could be the initial load or a subsequent load.
  loading: boolean

  // Whether filters are applied to the list.
  isFiltering: boolean

  // Whether a search is applied to the list.
  isSearching: boolean
}

/**
 * Hook that determines the UI state of a paginated list, given the current loading state of that list.
 * A typical paginated list contains:
 *  - Entities to display in an infinite scroll
 *  - Column headers to display
 *  - Skeletons to display while loading
 *  - A bottom loading row to display while loading more entities
 *  - A placeholder to display when there are no entities at all, while fetching all of them without filtering/searching
 *  - A placeholder to display when there are no entities after filtering
 *  - A placeholder to display when there are no entities after searching
 */
export function usePaginationState<T>({
  entities,
  hasNext,
  loading,
  isFiltering,
  isSearching,
}: PaginationStateProps<T>): PaginationState {
  const showSkeletons = entities === null && loading
  const showLoadingMoreRow = hasNext
  const showNoEntitiesPlaceholder =
    entities?.length === 0 && !isSearching && !isFiltering && !loading
  const showNoSearchResultPlaceholder = entities?.length === 0 && isSearching && !loading
  const showNoFilterResultPlaceholder =
    !showNoSearchResultPlaceholder && entities?.length === 0 && isFiltering && !loading
  const showColumnHeaders =
    !showNoEntitiesPlaceholder && !showNoSearchResultPlaceholder && !showNoFilterResultPlaceholder

  return {
    showSkeletons,
    showLoadingMoreRow,
    showColumnHeaders,
    showNoEntitiesPlaceholder,
    showNoFilterResultPlaceholder,
    showNoSearchResultPlaceholder,
  }
}
