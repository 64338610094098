import { gql } from '@apollo/client'
import { Autocomplete, AutocompleteProps, TextField } from '@mui/material'
import _ from 'lodash'
import { useCallback, useMemo, useState } from 'react'
import {
  FormTemplateStatus,
  FormTemplateType,
  useDebouncedSearch,
  useSitelineSnackbar,
} from 'siteline-common-web'
import {
  useTemplateForAutocompleteQuery,
  useTemplatesForAutocompleteQuery,
} from '../graphql/apollo-operations'

gql`
  query templatesForAutocomplete($input: GetPaginatedTemplatesInput!) {
    paginatedTemplates(input: $input) {
      templates {
        id
        type
        userVisibleName
        status
        skippedValidation
        variants {
          id
          internalName
          isDefaultVariant
        }
      }
    }
  }
`

gql`
  query templateForAutocomplete($id: ID!) {
    formTemplate(id: $id) {
      id
      userVisibleName
      type
      status
      skippedValidation
      variants {
        id
        internalName
        isDefaultVariant
      }
    }
  }
`

const collator = new Intl.Collator()

export type AutocompletedTemplate = {
  id: string
  type: FormTemplateType
  userVisibleName: string
  status: FormTemplateStatus
  skippedValidation: boolean
}

type TemplateAutocompleteProps = Omit<
  AutocompleteProps<AutocompletedTemplate, false, false, false>,
  'options' | 'renderInput'
> & {
  template: string | AutocompletedTemplate | null
  onTemplateChange: (id: AutocompletedTemplate | null) => void
  type: FormTemplateType | null
  label?: string
}

/**
 * Searches for templates
 */
export function TemplateAutocomplete({
  template,
  onTemplateChange,
  type,
  label,
  ...rest
}: TemplateAutocompleteProps) {
  const { search, debouncedSearch, onSearch } = useDebouncedSearch()
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const snackbar = useSitelineSnackbar()

  const { data: templatesData, loading } = useTemplatesForAutocompleteQuery({
    variables: {
      input: {
        search: debouncedSearch,
        type,
      },
    },
    skip: debouncedSearch.length < 3 || !isOpen,
  })

  const templateId = useMemo(() => {
    if (!template) {
      return null
    }
    if (_.isString(template)) {
      return template
    }
    return template.id
  }, [template])

  const { data: templateData } = useTemplateForAutocompleteQuery({
    variables: {
      id: templateId ?? '',
    },
    skip: !templateId,
  })

  const options = useMemo(() => {
    const autocompleted = templatesData?.paginatedTemplates.templates ?? []
    const options = templateData ? [...autocompleted, templateData.formTemplate] : autocompleted
    const unique = _.uniqBy(options, (template) => template.id)
    const sorted = unique.sort((a, b) =>
      collator.compare(a.userVisibleName.trim(), b.userVisibleName.trim())
    )
    return sorted
  }, [templateData, templatesData?.paginatedTemplates.templates])

  const selectedOption = useMemo(() => {
    const found = options.find((option) => option.id === templateId)
    return found ?? null
  }, [options, templateId])

  const onOptionSelected = useCallback(
    (option: AutocompletedTemplate | null) => {
      if (option && option.type !== type) {
        // It should only be possible to hit this case if the user searched by ID and the matching
        // form template is the incorrect type
        snackbar.showError('Incorrect form template type')
        onSearch('')
        onTemplateChange(null)
        return
      }
      onTemplateChange(option)
    },
    [onSearch, onTemplateChange, snackbar, type]
  )

  const getOptionLabel = useCallback((option: AutocompletedTemplate) => {
    let name = option.userVisibleName
    if (option.status !== FormTemplateStatus.COMPLETE) {
      name += ' (Incomplete)'
    }
    return name
  }, [])

  return (
    <Autocomplete
      {...rest}
      open={isOpen}
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
      size="small"
      loading={loading}
      inputValue={search}
      onInputChange={(ev, value) => onSearch(value)}
      getOptionLabel={getOptionLabel}
      filterOptions={(option) => option}
      options={options}
      includeInputInList
      onChange={(ev, value) => onOptionSelected(value)}
      value={selectedOption}
      multiple={false}
      renderInput={(params) => <TextField label={label} {...params} />}
      // Required to provide an explicit child key
      // See https://stackoverflow.com/a/69396153
      renderOption={(props, option) => (
        <li {...props} key={option.id}>
          {option.userVisibleName}
        </li>
      )}
    />
  )
}
