import { EmailType, NotificationType, Permission } from '../enums.js'

export const FIELD_GUEST_NOTIFICATIONS = [
  NotificationType.PAY_APP_PASTDUE,
  NotificationType.OPTED_OUT_OF_BILLING,
] as const
export const FIELD_GUEST_EMAILS = [
  EmailType.DAILY_PAY_APP,
  EmailType.OPTED_OUT_OF_BILLING,
  EmailType.ADDED_TO_PROJECT,
] as const

export const FIELD_PERMISSIONS = [Permission.EDIT_INVOICE]
