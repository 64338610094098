import { gql } from '@apollo/client'
import {
  Alert,
  Autocomplete,
  Button,
  Card,
  CardActions,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Link,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Theme,
} from '@mui/material'
import _ from 'lodash'
import { useState } from 'react'
import { makeStylesFast, useSitelineSnackbar } from 'siteline-common-web'
import {
  AutocompletedTemplate,
  TemplateAutocomplete,
} from '../../common/components/TemplateAutocomplete'
import * as fragments from '../../common/graphql/Fragments'
import {
  FormTemplateProperties,
  FormTemplateStatus,
  useMarkFormTemplateAsDuplicateMutation,
  useSitelineTeamMembersQuery,
  useUpdateFormTemplateBuilderMutation,
  useUpdateFormTemplateOriginalFileMutation,
  useUpdateFormTemplateOwnerMutation,
  useUpdateFormTemplateStatusMutation,
  useUpdateFormTemplateValidatorMutation,
} from '../../common/graphql/apollo-operations'
import { finishedFormTemplateStatus } from '../../common/util/FormTemplate'

gql`
  query sitelineTeamMembers {
    sitelineTeamMembers {
      id
      firstName
      lastName
      email
    }
  }
`

type FormTemplateBuildInfoProps = {
  formTemplate: FormTemplateProperties
}

const useStyles = makeStylesFast((theme: Theme) => ({
  actions: {
    padding: theme.spacing(2),
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
}))

gql`
  mutation updateFormTemplateStatus($input: UpdateFormTemplateStatusInput!) {
    updateFormTemplateStatus(input: $input) {
      ...FormTemplateProperties
    }
  }
  ${fragments.formTemplate}
`

gql`
  mutation updateFormTemplateOwner($input: UpdateFormTemplateUserInput!) {
    updateFormTemplateOwner(input: $input) {
      ...FormTemplateProperties
    }
  }
  ${fragments.formTemplate}
`

gql`
  mutation updateFormTemplateBuilder($input: UpdateFormTemplateUserInput!) {
    updateFormTemplateBuilder(input: $input) {
      ...FormTemplateProperties
    }
  }
  ${fragments.formTemplate}
`

gql`
  mutation updateFormTemplateValidator($input: UpdateFormTemplateUserInput!) {
    updateFormTemplateValidator(input: $input) {
      ...FormTemplateProperties
    }
  }
  ${fragments.formTemplate}
`

gql`
  mutation updateFormTemplateOriginalFile($input: UpdateFormTemplateOriginalFileInput!) {
    updateFormTemplateOriginalFile(input: $input) {
      ...FormTemplateProperties
    }
  }
  ${fragments.formTemplate}
`

gql`
  mutation updateFormTemplateDueDate($input: UpdateFormTemplateDueDateInput!) {
    updateFormTemplateDueDate(input: $input) {
      ...FormTemplateProperties
    }
  }
  ${fragments.formTemplate}
`

gql`
  mutation markFormTemplateAsDuplicate($input: MarkFormTemplateAsDuplicateInput!) {
    markFormTemplateAsDuplicate(input: $input) {
      ...FormTemplateProperties
    }
  }
  ${fragments.formTemplate}
`

type MarkAsDuplicateDialogProps = {
  open: boolean
  onClose: () => void
  formTemplate: FormTemplateProperties
}

/**
 * Dialog that allows cloning a form template with a new name + original file
 */
function MarkAsDuplicateDialog({ open, onClose, formTemplate }: MarkAsDuplicateDialogProps) {
  const snackbar = useSitelineSnackbar()
  const [markAsDuplicate] = useMarkFormTemplateAsDuplicateMutation()
  const [duplicateTemplate, setDuplicateTemplate] = useState<AutocompletedTemplate | null>(null)

  const onSubmit = () => {
    if (!duplicateTemplate) {
      return
    }
    if (!window.confirm('Are you sure you want to mark this template as duplicate?')) {
      return
    }
    snackbar.showLoading()
    markAsDuplicate({
      variables: {
        input: {
          id: formTemplate.id,
          duplicateTemplateId: duplicateTemplate.id,
        },
      },
    })
      .then(() => {
        snackbar.showSuccess()
        onClose()
      })
      .catch((err: Error) => snackbar.showError(err.message))
  }

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
      <DialogTitle>Mark as duplicate</DialogTitle>
      <DialogContent>
        <TemplateAutocomplete
          fullWidth
          type={formTemplate.type}
          label="Duplicate of"
          template={duplicateTemplate}
          onTemplateChange={setDuplicateTemplate}
          sx={{ marginTop: 1 }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={() => onSubmit()} variant="contained" color="primary">
          Mark as duplicate
        </Button>
      </DialogActions>
    </Dialog>
  )
}

/**
 * Template build status card that displays the build flow, current status, assignees, and potential actions.
 */
export function FormTemplateBuildInfo({ formTemplate }: FormTemplateBuildInfoProps) {
  const snackbar = useSitelineSnackbar()
  const classes = useStyles()
  const [updateStatus] = useUpdateFormTemplateStatusMutation()
  const [updateOwner] = useUpdateFormTemplateOwnerMutation()
  const [updateBuilder] = useUpdateFormTemplateBuilderMutation()
  const [updateValidator] = useUpdateFormTemplateValidatorMutation()
  const [updateOriginalFile] = useUpdateFormTemplateOriginalFileMutation()
  const [duplicateDialogOpen, setDuplicateDialogOpen] = useState<boolean>(false)
  const { data: adminsData } = useSitelineTeamMembersQuery()
  const admins = _.orderBy(adminsData?.sitelineTeamMembers ?? [], (user) => user.firstName, ['asc'])

  const assignees = [
    formTemplate.owner.id,
    formTemplate.builder.id,
    formTemplate.validator?.id ?? null,
  ]
  const showAssignmentWarning = _.uniq(assignees).length !== assignees.length

  const handleDuplicate = () => {
    if (FormTemplateStatus.DUPLICATE === formTemplate.status) {
      return
    }
    setDuplicateDialogOpen(true)
  }

  const handleCanceled = () => {
    const confirmed = window.confirm(`Are you sure you want cancel building this template?`)
    if (!confirmed) {
      return
    }
    updateStatus({
      variables: {
        input: {
          id: formTemplate.id,
          status: FormTemplateStatus.CANCELED,
        },
      },
    })
      .then(() => snackbar.showSuccess())
      .catch((err) => snackbar.showError(err.message))
  }

  const setOwnerId = (ownerId: string) => {
    updateOwner({
      variables: {
        input: {
          id: formTemplate.id,
          userId: ownerId,
        },
      },
    })
      .then(() => snackbar.showSuccess())
      .catch((err) => snackbar.showError(err.message))
  }
  const setBuilderId = (builderId: string) => {
    updateBuilder({
      variables: {
        input: {
          id: formTemplate.id,
          userId: builderId,
        },
      },
    })
      .then(() => snackbar.showSuccess())
      .catch((err) => snackbar.showError(err.message))
  }
  const setValidatorId = (validatorId: string | null) => {
    if (!validatorId) {
      return
    }
    updateValidator({
      variables: {
        input: {
          id: formTemplate.id,
          userId: validatorId,
        },
      },
    })
      .then(() => snackbar.showSuccess())
      .catch((err) => snackbar.showError(err.message))
  }
  const handleFileChange = (file: File | null) => {
    if (!file) {
      return
    }
    const confirmed = window.confirm('Are you sure you want to update the original file?')
    if (!confirmed) {
      return
    }
    snackbar.showLoading()
    updateOriginalFile({
      variables: {
        input: {
          id: formTemplate.id,
          file,
        },
      },
    })
      .then(() => snackbar.showSuccess())
      .catch((err) => snackbar.showError(err.message))
  }

  const isFormLocked = finishedFormTemplateStatus.includes(formTemplate.status)

  return (
    <Card>
      <CardHeader title="Build info" />
      {showAssignmentWarning && (
        <Alert severity="warning">Owner / validator / builder must be different people</Alert>
      )}
      {isFormLocked && <Alert severity="info">Cannot modify fields on complete form</Alert>}
      <Divider sx={{ marginTop: 2 }} />
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>Owner</TableCell>
            <TableCell colSpan={2}>
              <Autocomplete
                disabled={isFormLocked}
                size="small"
                disablePortal
                disableClearable
                options={admins.map((user) => user.id)}
                value={formTemplate.owner.id}
                onChange={(event, value) => setOwnerId(value)}
                sx={{ paddingTop: -8 }}
                getOptionLabel={(userId) => {
                  const admin = admins.find((user) => user.id === userId)
                  return admin?.email ?? ''
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    hiddenLabel
                    InputProps={{
                      ...params.InputProps,
                      disableUnderline: true,
                    }}
                  />
                )}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Builder</TableCell>
            <TableCell colSpan={2}>
              <Autocomplete
                disabled={isFormLocked}
                size="small"
                disablePortal
                disableClearable
                options={admins.map((user) => user.id)}
                value={formTemplate.builder.id}
                onChange={(event, value) => setBuilderId(value)}
                sx={{ paddingTop: -8 }}
                getOptionLabel={(userId) => {
                  const admin = admins.find((user) => user.id === userId)
                  return admin?.email ?? ''
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    hiddenLabel
                    slotProps={{
                      input: {
                        ...params.InputProps,
                        disableUnderline: true,
                      },
                    }}
                  />
                )}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Validator</TableCell>
            <TableCell colSpan={2}>
              <Autocomplete
                disabled={isFormLocked}
                size="small"
                disablePortal
                options={admins.map((user) => user.id)}
                value={formTemplate.validator?.id ?? null}
                onChange={(event, value) => setValidatorId(value)}
                sx={{ paddingTop: -8 }}
                getOptionLabel={(userId) => {
                  const admin = admins.find((user) => user.id === userId)
                  return admin?.email ?? ''
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    hiddenLabel
                    slotProps={{
                      input: {
                        ...params.InputProps,
                        disableUnderline: true,
                      },
                    }}
                  />
                )}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Original file</TableCell>
            {formTemplate.originalFile && (
              <TableCell>
                <Link href={formTemplate.originalFile.url} target="_blank">
                  {formTemplate.originalFile.name}
                </Link>
              </TableCell>
            )}
            <TableCell>
              <input
                disabled={isFormLocked}
                type="file"
                name="change"
                onChange={(ev) => handleFileChange(ev.target.files?.[0] ?? null)}
              />
            </TableCell>
            {!formTemplate.originalFile && <TableCell />}
          </TableRow>
        </TableBody>
      </Table>
      {!isFormLocked && (
        <CardActions className={classes.actions} disableSpacing>
          <Button onClick={handleCanceled}>Mark as canceled</Button>
          <Button onClick={handleDuplicate}>Mark as duplicate</Button>
        </CardActions>
      )}
      <MarkAsDuplicateDialog
        open={duplicateDialogOpen}
        onClose={() => setDuplicateDialogOpen(false)}
        formTemplate={formTemplate}
      />
    </Card>
  )
}
