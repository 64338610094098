import { gql } from '@apollo/client'
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material'
import { useCallback, useState } from 'react'
import 'react-resizable/css/styles.css'
import { SitelineText, useSitelineSnackbar } from 'siteline-common-web'
import * as fragments from '../../../common/graphql/Fragments'
import {
  FormTemplateProperties,
  useCreateTemplateVariantMutation,
} from '../../../common/graphql/apollo-operations'

gql`
  mutation createTemplateVariant($input: CreateTemplateVariantInput!) {
    createTemplateVariant(input: $input) {
      ...FormTemplateVariantProperties
    }
  }
  ${fragments.formTemplateVariant}
`

type CreateTemplateVariantDialogProps = {
  template: FormTemplateProperties
  open: boolean
  onClose: () => void
  onCreated: (variantId: string) => void
}

export function CreateTemplateVariantDialog({
  template,
  open,
  onClose,
  onCreated,
}: CreateTemplateVariantDialogProps) {
  const [internalName, setInternalName] = useState<string>('')
  const [userVisibleName, setUserVisibleName] = useState<string>('')
  const [createVariant] = useCreateTemplateVariantMutation()
  const snackbar = useSitelineSnackbar()

  const handleCreate = useCallback(() => {
    const cleanInternalName = internalName.trim()
    const cleanUserVisibleName = userVisibleName.trim()
    if (cleanInternalName.length === 0) {
      return
    }

    snackbar.showLoading('Creating variant...')
    createVariant({
      variables: {
        input: {
          formTemplateId: template.id,
          internalName: cleanInternalName,
          userVisibleName: cleanUserVisibleName.length > 0 ? cleanUserVisibleName : null,
        },
      },
      update: (cache, { data }) => {
        if (!data) {
          return
        }
        cache.modify({
          id: cache.identify(template),
          fields: {
            variants(existing, { toReference }) {
              return [...existing, toReference(data.createTemplateVariant)]
            },
          },
        })
      },
    })
      .then((res) => {
        if (!res.data) {
          throw new Error('No data returned')
        }
        snackbar.showSuccess('Variant created')
        onCreated(res.data.createTemplateVariant.id)
      })
      .catch((err) => {
        snackbar.showError(err.message)
      })
  }, [createVariant, internalName, onCreated, snackbar, template, userVisibleName])

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>New variant</DialogTitle>
      <DialogContent>
        <SitelineText variant="body1">
          Variants are a way to make small adjustments to existing templates - like styling,
          signature types, and other annotation properties - for a specific subcontractor, GC, or
          contract. They can be assigned to individual contracts or set as default templates on a
          company.
        </SitelineText>
        <Alert severity="warning" sx={{ marginTop: 1 }}>
          Annotations cannot be added or deleted in variants. Only a limited subset of annotation
          properties can be adjusted.
        </Alert>
        <TextField
          required
          fullWidth
          sx={{ marginTop: 3 }}
          value={internalName}
          onChange={(ev) => setInternalName(ev.target.value)}
          label="Internal name"
          helperText="E.g.: 'Notary', 'Blue fields', 'Required signature'"
        />
        <TextField
          fullWidth
          sx={{ marginTop: 2 }}
          value={userVisibleName}
          onChange={(ev) => setUserVisibleName(ev.target.value)}
          label="User-visible name (optional)"
          helperText="Shows in the main app when signing forms. Falls back to template name if blank."
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleCreate}>Create</Button>
      </DialogActions>
    </Dialog>
  )
}
