import { Card, Tab, Tabs, Theme, Typography, useTheme } from '@mui/material'
import { Box, useMediaQuery } from '@mui/system'
import queryString from 'query-string'
import { useCallback, useEffect, useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { colors, makeStylesFast } from 'siteline-common-web'
import Page from '../../common/components/Page'
import { AbandonedPayAppsDashboard } from './AbandonedPayAppsDashboard'
import { BillingTotalsDashboard } from './BillingTotalsDashboard'
import { FeatureUsageDashboard } from './FeatureUsageDashboard'
import { FormTrackerDashboard } from './FormTrackerDashboard'
import { TimeToValueDashboard } from './TimeToValueDashboard'

const useStyles = makeStylesFast((theme: Theme) => ({
  root: {
    padding: theme.spacing(3),
  },
}))

enum CustomerDashboardTab {
  BILLING_TOTALS = 'billingTotals',
  ABANDONED_PAY_APPS = 'abandonedPayApps',
  FEATURE_USAGE = 'featureUsage',
  FORM_TRACKER = 'formTracker',
  TIME_TO_VALUE = 'timeToValue',
}

/** Admin dashboards for tracking customer metrics */
export function CustomerDashboards() {
  const classes = useStyles()
  const location = useLocation()
  const navigate = useNavigate()

  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down(1300))

  const tab = useMemo(() => {
    return queryString.parse(location.search).tab as CustomerDashboardTab | undefined
  }, [location.search])
  const handleTabChange = useCallback(
    (tab: CustomerDashboardTab, replace?: boolean) => {
      // Reset the query params when switching tabs
      const search = new URLSearchParams()
      search.set('tab', tab)
      navigate({ search: search.toString() }, { replace })
    },
    [navigate]
  )

  // Open the billing totals dashboard if no tab is selected
  useEffect(() => {
    if (!tab) {
      handleTabChange(CustomerDashboardTab.BILLING_TOTALS, true)
    }
  }, [handleTabChange, tab])

  return (
    <Page className={classes.root} title="Customer dashboards">
      <div style={{ display: 'flex', marginBottom: 16 }}>
        <Typography variant="h5">Customer dashboard</Typography>
      </div>
      <Card style={{ padding: 16 }}>
        <Box sx={{ borderBottom: 1, borderColor: colors.grey30, marginBottom: 2 }}>
          <Tabs
            value={tab}
            onChange={(evt, newTab) => {
              handleTabChange(newTab)
            }}
          >
            <Tab value={CustomerDashboardTab.BILLING_TOTALS} label="Billing Totals" />
            <Tab value={CustomerDashboardTab.ABANDONED_PAY_APPS} label="Abandoned pay apps" />
            <Tab value={CustomerDashboardTab.FEATURE_USAGE} label="Feature usage" />
            <Tab value={CustomerDashboardTab.FORM_TRACKER} label="Form tracker" />
            <Tab value={CustomerDashboardTab.TIME_TO_VALUE} label="Time to value" />
          </Tabs>
        </Box>
        {tab === CustomerDashboardTab.BILLING_TOTALS && (
          <BillingTotalsDashboard isSmallScreen={isSmallScreen} />
        )}
        {tab === CustomerDashboardTab.ABANDONED_PAY_APPS && (
          <AbandonedPayAppsDashboard isSmallScreen={isSmallScreen} />
        )}
        {tab === CustomerDashboardTab.FEATURE_USAGE && (
          <FeatureUsageDashboard isSmallScreen={isSmallScreen} />
        )}
        {tab === CustomerDashboardTab.FORM_TRACKER && (
          <FormTrackerDashboard isSmallScreen={isSmallScreen} />
        )}
        {tab === CustomerDashboardTab.TIME_TO_VALUE && <TimeToValueDashboard />}
      </Card>
    </Page>
  )
}
