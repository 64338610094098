import ClearIcon from '@mui/icons-material/Clear'
import { Box, IconButton, Theme } from '@mui/material'
import { PropsWithChildren } from 'react'
import { makeStylesFast } from 'siteline-common-web'

const useStyles = makeStylesFast((theme: Theme) => ({
  override: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row',
  },
  remove: {
    marginLeft: theme.spacing(-0.5),
  },
  value: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
}))

type TemplateVariantSettingOverrideProps = PropsWithChildren<{
  canRemove: boolean
  onRemove: () => void
}>

/**
 * A single override of a template variant setting.
 */
export function TemplateVariantSettingOverride({
  children,
  canRemove,
  onRemove,
}: TemplateVariantSettingOverrideProps) {
  const classes = useStyles()
  return (
    <div className={classes.override}>
      <Box className={classes.remove} sx={{ display: canRemove ? 'initial' : 'none' }}>
        <IconButton onClick={onRemove}>
          <ClearIcon fontSize="small" />
        </IconButton>
      </Box>
      <div className={classes.value}>{children}</div>
    </div>
  )
}
