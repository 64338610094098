import { useCallback, useEffect, useState } from 'react'
import { useDebounce } from 'react-use'
import { normalizeString } from 'siteline-common-all'

type DebouncedSearch = {
  // Contains the live value of the search field.
  // Pass this to the text field.
  search: string

  // Contains the value of the search field after 300ms of no changes
  // Pass this to the query that fetches the filtered data.
  debouncedSearch: string

  // Call this whenever the value of the textfield changes, pass the value of the textfield
  onSearch: (search: string) => void
}

type DebouncedSearchProps = {
  // Initial value of the search field
  initialSearch?: string

  // Whether debounced value should be normalized, to avoid extra HTTP calls.
  // Defaults to true.
  normalize?: boolean

  // Number of milliseconds to wait before updating `debouncedSearch`.
  // A value that is too low will cause unnecessary searches as the user types.
  // A value that is too high will introduce a delay in the search results.
  // Defaults to 300ms, which is standard for regular typers.
  delay?: number
}

/**
 * Hook that manages the value of a search field while debouncing the value for query purposes.
 * This hook is optimized in a way that minimizes HTTP calls, trimming / normalizing the search value by default.
 *
 * Usage:
 *
 * ```
 * const { search, debouncedSearch, onSearch } = useDebouncedSearch()
 * const { data } = useQuery({
 *   variables: { search: debouncedSearch }
 * })
 * return (
 *   <>
 *     <TextField value={search} onChange={(ev) => onSearch(ev.target.value)} />
 *     <Results data={data} />
 *   </>
 * ```
 */
export function useDebouncedSearch(props?: DebouncedSearchProps): DebouncedSearch {
  const [search, setSearch] = useState<string>(props?.initialSearch ?? '')
  const [debouncedSearch, setDebouncedSearch] = useState<string>(props?.initialSearch ?? '')
  const normalize = props?.normalize ?? true

  // Debounce search query by 300ms.
  // When clearing the search, both states are updated immediately so that the contract list resets right away.
  useDebounce(
    () => {
      const transformed = normalize ? normalizeString(search) : search
      setDebouncedSearch(transformed)
    },
    props?.delay ?? 300,
    [search]
  )

  // When the initial search changes, reset the state
  useEffect(() => {
    const newSearch = props?.initialSearch ?? ''
    setSearch(newSearch)
    setDebouncedSearch(newSearch)
  }, [props?.initialSearch])

  const onSearch = useCallback((value: string) => {
    if (!value) {
      setSearch('')
      setDebouncedSearch('')
    } else {
      setSearch(value)
    }
  }, [])

  return {
    search,
    debouncedSearch,
    onSearch,
  }
}
