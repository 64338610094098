import { gql } from '@apollo/client'
import { Edit } from '@mui/icons-material'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
} from '@mui/material'
import _ from 'lodash'
import moment from 'moment-timezone'
import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { makeStylesFast, useSitelineSnackbar } from 'siteline-common-web'
import * as fragments from '../../common/graphql/Fragments'
import {
  FormTemplateProperties,
  GetFormTemplateDocument,
  GetFormTemplateQuery,
  GetFormTemplateQueryVariables,
  useCloneFormTemplateVersionMutation,
} from '../../common/graphql/apollo-operations'

gql`
  mutation cloneFormTemplateVersion($formTemplateVersionId: ID!) {
    cloneFormTemplateVersion(formTemplateVersionId: $formTemplateVersionId) {
      ...FormTemplateVersionProperties
    }
  }
  ${fragments.formTemplateVersion}
`

const useStyles = makeStylesFast((theme: Theme) => ({
  card: {
    marginBottom: theme.spacing(2),
  },
  cardContent: {
    padding: 0,
    '&:last-child': {
      padding: 0,
    },
    '& .MuiFormControl-root': {
      width: '100%',
    },
  },
}))

type FormTemplateInfoProps = {
  formTemplate: FormTemplateProperties
}

export default function FormTemplateVersionList({ formTemplate }: FormTemplateInfoProps) {
  const classes = useStyles()
  const navigate = useNavigate()
  const snackbar = useSitelineSnackbar()

  const [cloneFormTemplateVersion] = useCloneFormTemplateVersionMutation({
    update(cache, { data }) {
      const result = cache.readQuery<GetFormTemplateQuery, GetFormTemplateQueryVariables>({
        query: GetFormTemplateDocument,
        variables: { id: formTemplate.id },
      })

      if (!result || !data) {
        return
      }

      const updatedTemplate = {
        ...result.formTemplate,
        versions: [...result.formTemplate.versions, data.cloneFormTemplateVersion],
      }

      cache.writeQuery({
        query: GetFormTemplateDocument,
        data: { formTemplate: updatedTemplate },
      })
    },
  })

  const sortedFormTemplateVersions = _.orderBy(
    formTemplate.versions,
    (version) => version.versionNumber,
    'asc'
  )

  const onClone = useCallback(
    (versionId: string) => {
      if (!window.confirm('Are you sure you want to clone this template version?')) {
        return
      }

      snackbar.showLoading('Creating ...')
      cloneFormTemplateVersion({ variables: { formTemplateVersionId: versionId } })
        .then(() => {
          navigate(`/templates/${formTemplate.id}/edit`)
          snackbar.showSuccess('Done!')
        })
        .catch((error) => snackbar.showError(error.message))
    },
    [cloneFormTemplateVersion, formTemplate.id, navigate, snackbar]
  )

  const openEditor = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      const path = `/templates/${formTemplate.id}/edit`
      if (event.metaKey) {
        window.open(path, '_blank')
      } else {
        navigate(path)
      }
    },
    [formTemplate.id, navigate]
  )

  return (
    <Card className={classes.card}>
      <CardHeader
        title={'Versions'}
        action={
          <>
            <Button
              startIcon={<AddCircleIcon />}
              color="primary"
              onClick={() => navigate(`/templates/${formTemplate.id}/versions/create`)}
            >
              Create new version
            </Button>
            {formTemplate.versions.length > 0 && (
              <Button startIcon={<Edit />} color="primary" onClick={openEditor}>
                Open editor
              </Button>
            )}
          </>
        }
      />
      <Divider />
      <CardContent className={classes.cardContent}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Version Number</TableCell>
              <TableCell>Updated Date</TableCell>
              <TableCell>File Type</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedFormTemplateVersions.map((version) => (
              <TableRow key={version.id}>
                <TableCell>{version.versionNumber}</TableCell>
                <TableCell>{moment.utc(version.updatedAt).format('MM/DD/YYYY')}</TableCell>
                <TableCell>{version.file.type}</TableCell>
                <TableCell>
                  <Button onClick={() => onClone(version.id)}>Clone</Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  )
}
