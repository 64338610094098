import { gql } from '@apollo/client'
import AddPhotoAlternateOutlinedIcon from '@mui/icons-material/AddPhotoAlternateOutlined'
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Container,
  Grid2,
  Paper,
  Theme,
  Typography,
} from '@mui/material'
import { clsx } from 'clsx'
import { FormEvent, useCallback, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { useNavigate, useParams } from 'react-router-dom'
import { makeStylesFast, useSitelineSnackbar } from 'siteline-common-web'
import { Loader } from '../../../common/components/Loader'
import Page from '../../../common/components/Page'
import * as fragments from '../../../common/graphql/Fragments'
import {
  GetFormTemplateDocument,
  GetFormTemplateQuery,
  GetFormTemplateQueryVariables,
  useCreateFormTemplateVersionMutation,
  useGetFormTemplateQuery,
} from '../../../common/graphql/apollo-operations'
import { FormTemplateDocumentation } from './FormTemplateDocumentation'
import { TemplateVariablesUsage } from './TemplateVariablesUsage'

const useStyles = makeStylesFast((theme: Theme) => ({
  root: {
    padding: theme.spacing(3, 0),
  },
  input: {
    marginBottom: theme.spacing(2),
  },
  actions: {
    padding: theme.spacing(2),
  },
  uploadPaper: {
    height: 148,
    padding: theme.spacing(2),
    border: '1px dashed rgba(0, 0, 0, 0.12)',
    cursor: 'pointer',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  fileDrag: {
    border: '1px solid rgba(0, 0, 0, 0.54)',
  },
  addAttachment: {
    height: 116,
    textAlign: 'center',
    '& span': {
      display: 'block',
    },
  },
  formTemplatePreview: {
    height: 800,
    backgroundSize: 'contain',
    marginBottom: theme.spacing(1),
  },
}))

gql`
  mutation createFormTemplateVersion($input: CreateFormTemplateVersionInput!) {
    createFormTemplateVersion(input: $input) {
      ...FormTemplateVersionProperties
    }
  }
  ${fragments.formTemplateVersion}
`

type FormTemplateVersionCreateParams = {
  templateId: string
}

export default function FormTemplateVersionCreate() {
  const classes = useStyles()
  const { templateId } = useParams() as FormTemplateVersionCreateParams
  const { data: formTemplateData } = useGetFormTemplateQuery({
    variables: { id: templateId },
  })

  const [file, setFile] = useState<File | null>(null)
  const snackbar = useSitelineSnackbar()
  const [createFormTemplateVersionMutation] = useCreateFormTemplateVersionMutation({
    update(cache, { data }) {
      const result = cache.readQuery<GetFormTemplateQuery, GetFormTemplateQueryVariables>({
        query: GetFormTemplateDocument,
        variables: { id: templateId },
      })

      if (!result || !data) {
        return
      }

      const updatedTemplate = {
        ...result.formTemplate,
        versions: [...result.formTemplate.versions, data.createFormTemplateVersion],
      }

      cache.writeQuery({
        query: GetFormTemplateDocument,
        data: { formTemplate: updatedTemplate },
      })
    },
  })
  const navigate = useNavigate()

  const validateAndSubmit = async () => {
    if (!file) {
      throw new Error('Form template file must be provided')
    }
    const fileExtention = file.name.split('.').pop() ?? ''
    if (!['pdf', 'xlsx', 'docx'].includes(fileExtention)) {
      throw new Error('Form template type not allowed. Must be a .docx, .xlsx, or .pdf document.')
    }

    const response = await createFormTemplateVersionMutation({
      variables: {
        input: {
          file,
          formTemplateId: templateId,
        },
      },
    })

    if (!response.data) {
      throw new Error('No data in response')
    }

    return response.data.createFormTemplateVersion
  }

  const onSubmit = (ev: FormEvent): void => {
    ev.preventDefault()
    snackbar.showLoading()
    validateAndSubmit()
      .then(() => {
        snackbar.showSuccess()
        navigate(`/templates/${templateId}/edit`)
      })
      .catch((err) => {
        snackbar.showError(err.message)
      })
  }

  const onDrop = useCallback((acceptedFiles: File[]) => {
    const file = acceptedFiles[0]
    const reader = new FileReader()
    reader.readAsDataURL(file)
    setFile(acceptedFiles[0])
  }, [])
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  if (!formTemplateData) {
    return <Loader />
  }

  const formTemplateName = formTemplateData.formTemplate.userVisibleName

  const title = `Create Template Version`
  return (
    <Page className={classes.root} title={title}>
      <Container maxWidth={false}>
        <Grid2 container spacing={2}>
          <Grid2 size={{ xs: 12 }}>
            <Typography variant="h4">{title}</Typography>
          </Grid2>
          <Grid2 size={{ xs: 6 }}>
            <form onSubmit={onSubmit}>
              <Card>
                <CardHeader title={`${formTemplateName} Form Template Version`} />
                <CardContent>
                  {!file && (
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />
                      <Paper
                        elevation={0}
                        className={clsx(classes.uploadPaper, {
                          [classes.fileDrag]: isDragActive,
                        })}
                      >
                        <Grid2
                          className={classes.addAttachment}
                          container
                          direction="column"
                          justifyContent="center"
                          alignItems="center"
                          spacing={0}
                        >
                          <Grid2>
                            <AddPhotoAlternateOutlinedIcon />
                          </Grid2>
                          <Grid2>
                            {isDragActive && <p>Drop here</p>}
                            {!isDragActive && <p>Upload the form template</p>}
                          </Grid2>
                        </Grid2>
                      </Paper>
                    </div>
                  )}
                  {file && (
                    <Button onClick={() => setFile(null)}>
                      Clear file: <u>{file.name}</u>
                    </Button>
                  )}
                </CardContent>
                <CardActions className={classes.actions}>
                  <Button variant="contained" color="primary" type="submit" disabled={!file}>
                    Create Template Version
                  </Button>
                </CardActions>
              </Card>
            </form>
          </Grid2>
          <Grid2 size={{ xs: 6 }}>
            <TemplateVariablesUsage />
          </Grid2>
          <Grid2 size={{ xs: 12 }}>
            <FormTemplateDocumentation />
          </Grid2>
        </Grid2>
      </Container>
    </Page>
  )
}
