import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import copy from 'copy-to-clipboard'
import _ from 'lodash'
import { useCallback, useMemo, useState } from 'react'
import ReactJson, { InteractionProps, OnCopyProps } from 'react-json-view'
import { integrationTypes } from 'siteline-common-all'
import { makeStylesFast, useSitelineSnackbar } from 'siteline-common-web'
import {
  IntegrationProperties,
  useUpdateIntegrationMappingsMutation,
} from '../../../common/graphql/apollo-operations'

const useStyles = makeStylesFast(() => ({
  content: {
    // Set a minimum width, otherwise the dialog jitters when hovering because of the clipboard button
    minWidth: '700px',
  },
}))

type IntegrationMappingsDialogProps = {
  integration: IntegrationProperties
  open: boolean
  onClose: () => void
}

/**
 * Dialog that allows to view/edit integration mappings (JSON)
 */
export function IntegrationMappingsDialog({
  integration,
  open,
  onClose,
}: IntegrationMappingsDialogProps) {
  const classes = useStyles()
  const snackbar = useSitelineSnackbar()
  const [updateMappings] = useUpdateIntegrationMappingsMutation()

  const initialMappings = useMemo(() => integration.mappings, [integration.mappings])
  const [pendingMappings, setPendingMappings] = useState(initialMappings)

  const hasChanges = useMemo(() => {
    return !_.isEqual(initialMappings, pendingMappings)
  }, [initialMappings, pendingMappings])

  const handleEdit = useCallback((edit: InteractionProps): false | unknown => {
    if (edit.namespace.length === 0 && edit.name === 'type') {
      return false
    }
    setPendingMappings(edit.updated_src as integrationTypes.IntegrationMappings)
    return undefined
  }, [])

  const handleDelete = useCallback((deletion: InteractionProps): false | unknown => {
    if (deletion.namespace.length === 0 && deletion.name === 'type') {
      return false
    }
    setPendingMappings(deletion.updated_src as integrationTypes.IntegrationMappings)
    return undefined
  }, [])

  const handleSave = useCallback(() => {
    updateMappings({
      variables: {
        input: {
          integrationId: integration.id,
          mappings: pendingMappings,
        },
      },
    })
      .then(() => {
        snackbar.showSuccess('Mappings updated')
        onClose()
      })
      .catch((err) => {
        snackbar.showError(err.message)
      })
  }, [integration.id, onClose, pendingMappings, snackbar, updateMappings])

  const handleCopy = useCallback(
    (clip: OnCopyProps) => {
      let stringToCopy: string
      if (_.isString(clip.src)) {
        stringToCopy = clip.src
      } else {
        stringToCopy = JSON.stringify(clip.src)
      }
      copy(stringToCopy)
      snackbar.showSuccess(`Copied to clipboard`)
    },
    [snackbar]
  )

  return (
    <Dialog maxWidth="lg" open={open} onClose={onClose}>
      <DialogTitle>Mappings</DialogTitle>
      <DialogContent className={classes.content}>
        <ReactJson
          src={integration.mappings}
          displayDataTypes={false}
          displayObjectSize={false}
          quotesOnKeys={false}
          onEdit={handleEdit}
          onDelete={handleDelete}
          enableClipboard={handleCopy}
        />
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button color="primary" disabled={!hasChanges} onClick={handleSave}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}
