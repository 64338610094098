import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import { Alert, Box, Grid2, Tab, Tabs } from '@mui/material'
import { useState } from 'react'
import { SitelineText, colors } from 'siteline-common-web'
import { FormTemplateStatus } from '../../common/graphql/apollo-operations'
import { finishedFormTemplateStatus } from '../../common/util/FormTemplate'
import TemplatePreview from '../form-template-versions/form-template-version-details/TemplatePreview'
import { FormTemplateBuildInfo } from './FormTemplateBuildInfo'
import { FormTemplateCommentList } from './FormTemplateCommentList'
import { FormTemplateForDetails } from './FormTemplateDetails'
import FormTemplateDetailsContracts from './FormTemplateDetailsProjects'
import FormTemplateInfo from './FormTemplateInfo'
import { FormTemplateSelectDueDate } from './FormTemplateSelectDueDate'
import { FormTemplateStatusSteps } from './FormTemplateStatusSteps'
import FormTemplateVersionList from './FormTemplateVersionList'

type FormTemplateDetailsContentProps = {
  formTemplate: FormTemplateForDetails
}

type Tab = 'overview' | 'build'

export default function FormTemplateDetailsContent({
  formTemplate: template,
}: FormTemplateDetailsContentProps) {
  const [tab, setTab] = useState<Tab>('overview')

  const isFormLocked = finishedFormTemplateStatus.includes(template.status)

  const isComplete = template.status === FormTemplateStatus.COMPLETE
  return (
    <>
      <SitelineText variant="h4">{template.userVisibleName}</SitelineText>
      <Box sx={{ borderBottom: 1, marginTop: 1, borderColor: 'divider', marginBottom: 3 }}>
        <Tabs value={tab} onChange={(ev, value) => setTab(value)}>
          <Tab label="Overview" value="overview" />
          <Tab
            label="Build"
            value="build"
            iconPosition="end"
            icon={
              isComplete ? undefined : (
                <ErrorOutlineIcon fontSize="small" htmlColor={colors.blue40} />
              )
            }
          />
        </Tabs>
      </Box>
      <Grid2 container spacing={3}>
        {tab === 'overview' && (
          <>
            <Grid2 size={{ xs: 6 }}>
              <FormTemplateInfo formTemplate={template} />
            </Grid2>
            <Grid2 size={{ xs: 6 }}>
              <TemplatePreview templateId={template.id} />
            </Grid2>
            <Grid2 size={{ xs: 6 }}>
              <FormTemplateDetailsContracts formTemplate={template} />
            </Grid2>
            <Grid2 size={{ xs: 6 }}>
              <FormTemplateVersionList formTemplate={template} />
            </Grid2>
          </>
        )}
        {tab === 'build' && (
          <>
            {template.skippedValidation && (
              <Grid2 size={{ xs: 12 }}>
                <Alert severity="warning">
                  This form template has skipped validation and may have been added to projects. It
                  is recommended to go through validation ASAP to prevent any bugs on the live
                  projects.
                </Alert>
              </Grid2>
            )}
            <Grid2 size={{ xs: 6 }}>
              <FormTemplateStatusSteps formTemplate={template} />
            </Grid2>
            <Grid2 size={{ xs: 6 }}>
              <Grid2 container spacing={3}>
                {!isComplete && !isFormLocked && template.dueDate === null && (
                  <Grid2 size={{ xs: 12 }}>
                    <FormTemplateSelectDueDate template={template} />
                  </Grid2>
                )}
                <Grid2 size={{ xs: 12 }}>
                  <FormTemplateCommentList formTemplate={template} />
                </Grid2>
                <Grid2 size={{ xs: 12 }}>
                  <FormTemplateBuildInfo formTemplate={template} />
                </Grid2>
              </Grid2>
            </Grid2>
          </>
        )}
      </Grid2>
    </>
  )
}
