import { gql } from '@apollo/client'
import AddPhotoAlternateOutlinedIcon from '@mui/icons-material/AddPhotoAlternateOutlined'
import {
  Alert,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Chip,
  colors,
  FormControlLabel,
  Grid2,
  Paper,
  Skeleton,
  Theme,
  Typography,
} from '@mui/material'
import clsx from 'clsx'
import _ from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { useLocation, useNavigate } from 'react-router-dom'
import { useMeasure } from 'react-use'
import { decimalToPercent } from 'siteline-common-all'
import {
  generatePdf,
  makeStylesFast,
  StoredFileType,
  useSitelineSnackbar,
} from 'siteline-common-web'
import Page from '../../common/components/Page'
import {
  AutocompletedTemplate,
  TemplateAutocomplete,
} from '../../common/components/TemplateAutocomplete'
import {
  SimilarTemplateProperties,
  StoredFileProperties,
  useGetFormTemplateQuery,
  useSimilarTemplatesQuery,
} from '../../common/graphql/apollo-operations'
import * as fragments from '../../common/graphql/Fragments'
import { Document, Page as PdfPage } from '../../pdf'

const useStyles = makeStylesFast((theme: Theme) => ({
  root: {
    padding: theme.spacing(3),
    '& h4': {
      marginBottom: theme.spacing(1),
    },
    '& .variable-set': {
      marginRight: theme.spacing(2),
      width: 200,
    },
    '& .annotations': {
      color: colors.grey[700],
      fontSize: 12,
      marginBottom: theme.spacing(0.5),
    },
  },
  uploadPaper: {
    height: 148,
    padding: theme.spacing(2),
    border: '1px dashed rgba(0, 0, 0, 0.12)',
    cursor: 'pointer',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  fileDrag: {
    border: '1px solid rgba(0, 0, 0, 0.54)',
  },
  addAttachment: {
    height: 116,
    textAlign: 'center',
    '& span': {
      display: 'block',
    },
  },
  actions: {
    padding: theme.spacing(2),
  },
}))

gql`
  query similarTemplates($upload: Upload!) {
    similarTemplates(upload: $upload) {
      ...SimilarTemplateProperties
    }
  }
  ${fragments.similarTemplate}
`

type FormTemplateCardProps = {
  similarTemplate: SimilarTemplateProperties
}

/**
 * Card showing a similar template from the Form match page, with a PDF preview and a link to the template version.
 */
function FormTemplateCard({ similarTemplate }: FormTemplateCardProps) {
  const [ref, { width }] = useMeasure<HTMLDivElement>()
  const [file, setFile] = useState<string | Blob | null>()
  const url = `/templates/${similarTemplate.template.id}/edit`
  const open = () => {
    window.open(url, '_blank')
  }

  const version = similarTemplate.version
  useEffect(() => {
    if (version.file.type === StoredFileType.PDF) {
      setFile(version.file.url)
    } else {
      generatePdf({
        type: 'templatesPreview',
        formTemplateVersionIds: [version.id],
      }).then(setFile)
    }
  }, [version])

  return (
    <Card
      ref={ref}
      onClick={() => open()}
      sx={{
        cursor: 'pointer',
        transition: '0s',
        ':hover': {
          boxShadow: 5,
        },
      }}
    >
      <div style={{ height: 200, overflow: 'hidden', cursor: 'pointer' }}>
        <Document file={file} loading="" noData="">
          <PdfPage loading="" pageNumber={1} width={width}></PdfPage>
        </Document>
      </div>

      <CardContent>
        <Typography
          gutterBottom
          variant="h6"
          component="div"
          style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
        >
          {similarTemplate.template.userVisibleName}
        </Typography>
        <Chip
          label={`Similarity: ${decimalToPercent(similarTemplate.score, 0)}%`}
          color={similarTemplate.score > 0.8 ? 'success' : 'default'}
          size="small"
          style={{ marginRight: 8 }}
        />
        <Chip label={`${similarTemplate.template.type}`} size="small" />
      </CardContent>
    </Card>
  )
}

function SkeletonCard() {
  return (
    <>
      <Card>
        <Skeleton variant="rectangular" width="100%" height={200} />
        <CardContent>
          <Typography gutterBottom variant="h6" component="div">
            <Skeleton variant="text" />
          </Typography>
          <Skeleton variant="text" />
        </CardContent>
      </Card>
    </>
  )
}

/**
 * Form match page that takes a file and searches for similar templates.
 * User can either:
 *  - Select a file: data stored in `file`
 *  - Select a template: ID stored in `formTemplateId` in URL, data stored in `file`
 */
export function FormTemplateMatch() {
  const classes = useStyles()
  const location = useLocation()
  const navigate = useNavigate()
  const snackbar = useSitelineSnackbar()

  // File to search with. Can be a local file, or a template original file / version.
  const [file, setFile] = useState<File | null>(null)
  const [ref, { width }] = useMeasure<HTMLDivElement>()

  // Parse `formTemplateId` in URL and load associated template
  const search = new URLSearchParams(location.search)
  const formTemplateId = search.get('formTemplateId')
  const { data: templateData } = useGetFormTemplateQuery({
    variables: {
      id: formTemplateId ?? '',
    },
    skip: !formTemplateId,
  })
  const formTemplate = templateData?.formTemplate ?? null

  // Get template versions
  const versions = _.orderBy(
    formTemplate?.versions ?? [],
    (version) => version.versionNumber,
    'desc'
  )
  const latestVersion = versions.length > 0 ? versions[0] : null

  // Find similar templates from local or template file
  // If searching with a template file, exclude selected template from results
  const {
    data: similarTemplatesData,
    loading,
    error,
  } = useSimilarTemplatesQuery({
    variables: {
      upload: file ?? new File([], 'empty.pdf'),
    },
    skip: !file,
    fetchPolicy: 'no-cache',
  })
  const similar = (similarTemplatesData?.similarTemplates ?? []).filter(
    (template) => template.template.id !== formTemplateId
  )

  // Determine whether we can use the original template file
  const hasVersions = (formTemplate?.versions ?? []).length > 0
  const hasOriginalFile = Boolean(formTemplate?.originalFile)
  let useOriginalFile = false
  if (hasOriginalFile) {
    useOriginalFile = hasVersions ? search.get('useOriginalFile') === 'true' : true
  }

  // Determine which stored file to use (original or version)
  let storedFile: StoredFileProperties | null = null
  if (formTemplate && useOriginalFile) {
    storedFile = formTemplate.originalFile
  } else {
    storedFile = latestVersion?.file ?? null
  }

  // Alter URL when selecting template
  const setSearchParam = useCallback(
    (param: string, value: string | null) => {
      const search = new URLSearchParams(location.search)
      if (value) {
        search.set(param, value)
      } else {
        search.delete(param)
      }
      navigate({ search: search.toString() })
    },
    [navigate, location]
  )
  const setFormTemplateId = useCallback(
    (formTemplateId: string | null) => setSearchParam('formTemplateId', formTemplateId),
    [setSearchParam]
  )
  const setUseOriginalFile = (useOriginalFile: boolean) =>
    setSearchParam('useOriginalFile', useOriginalFile ? 'true' : null)

  // Load data when selecting local file
  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      const file = acceptedFiles[0]
      setFile(file)
      setFormTemplateId(null)
    },
    [setFile, setFormTemplateId]
  )

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'application/pdf': ['.pdf'],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
    },
  })

  // When storedFile changes, load its data from GCS
  useEffect(() => {
    if (!storedFile) {
      return
    }

    fetch(storedFile.url)
      .then((res) => res.blob())
      .then((blob) => {
        const file = new File([blob], storedFile.name, { type: blob.type })
        setFile(file)
      })
      .catch((err) => snackbar.showError(err.message))
  }, [storedFile, snackbar])

  // When a template is selected or cleared, alter URL
  const handleFormTemplateChange = (template: AutocompletedTemplate | null) => {
    if (!template) {
      setFile(null)
    }
    setFormTemplateId(template?.id ?? null)
  }

  return (
    <Page className={classes.root} title="Form match">
      <Typography variant="h4">Form match</Typography>
      <Grid2 container spacing={2}>
        <Grid2 size={{ xs: 4 }}>
          <Card>
            <CardHeader subheader="Select a file to search for similar templates" />
            <CardContent>
              {file && (
                <div ref={ref} style={{ marginBottom: 16 }}>
                  {file.type === 'application/pdf' && (
                    <div style={{ maxHeight: 300, overflow: 'hidden' }}>
                      <Document file={file}>
                        <PdfPage pageNumber={1} width={width}></PdfPage>
                      </Document>
                    </div>
                  )}
                  <Typography
                    style={{
                      textAlign: 'center',
                      marginTop: 8,
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {file.name}
                  </Typography>
                </div>
              )}
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <Paper
                  elevation={0}
                  className={clsx(classes.uploadPaper, {
                    [classes.fileDrag]: isDragActive,
                  })}
                >
                  <Grid2
                    className={classes.addAttachment}
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    spacing={0}
                  >
                    <Grid2>
                      <AddPhotoAlternateOutlinedIcon />
                    </Grid2>
                    <Grid2>
                      {isDragActive && <p>Drop here</p>}
                      {!isDragActive && <p>Select a file</p>}
                    </Grid2>
                  </Grid2>
                </Paper>
              </div>
              <TemplateAutocomplete
                fullWidth
                type={null}
                label="Template"
                template={formTemplate}
                onTemplateChange={handleFormTemplateChange}
                sx={{ marginTop: 1 }}
              />
              {formTemplate && hasOriginalFile && (
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={!hasVersions}
                      checked={useOriginalFile}
                      onChange={(ev) => setUseOriginalFile(ev.target.checked)}
                    />
                  }
                  label="Use original file"
                />
              )}
              {formTemplate && !hasVersions && !hasOriginalFile && (
                <Alert severity="info">
                  Cannot match template because it does not have an original file or version
                </Alert>
              )}
            </CardContent>
          </Card>
        </Grid2>
        <Grid2 size={{ xs: 8 }}>
          <Card>
            <CardHeader title="Similar templates" />
            <CardContent>
              {error && <Alert severity="error">{error.message}</Alert>}
              {!loading && similarTemplatesData && similar.length === 0 && (
                <Alert severity="info">No similar templates found.</Alert>
              )}
              <Grid2 container spacing={2}>
                {!loading &&
                  !error &&
                  similar.map((similarTemplate) => (
                    <Grid2 size={{ xs: 4 }} key={similarTemplate.version.id}>
                      <FormTemplateCard similarTemplate={similarTemplate} />
                    </Grid2>
                  ))}
                {loading &&
                  _.range(9).map((index) => (
                    <Grid2 size={{ xs: 4 }} key={index}>
                      <SkeletonCard />
                    </Grid2>
                  ))}
              </Grid2>
            </CardContent>
          </Card>
        </Grid2>
      </Grid2>
    </Page>
  )
}
