import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { supportsCredentialsAutoRotation } from 'siteline-common-all'
import { IntegrationType, useSitelineSnackbar } from 'siteline-common-web'
import {
  CompanyIntegrationProperties,
  useUpdateIntegrationCredentialMutation,
} from '../../common/graphql/apollo-operations'

type UpdateCredentialModalProps = {
  companyIntegration: CompanyIntegrationProperties
  open: boolean
  setOpen: (open: boolean) => void
}

/** Modal to update a credentials username and password */
export function UpdateCredentialModal({
  companyIntegration,
  open,
  setOpen,
}: UpdateCredentialModalProps) {
  const [username, setUsername] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const snackbar = useSitelineSnackbar()

  const [updateCredentials] = useUpdateIntegrationCredentialMutation()

  // Set default values depending on integration type
  useEffect(() => {
    if (
      [
        IntegrationType.FOUNDATION_FILE,
        IntegrationType.QUICKBOOKS_ENTERPRISE_FILE,
        IntegrationType.COMPUTER_EASE_FILE,
      ].includes(companyIntegration.type)
    ) {
      setUsername('file')
      setPassword('file')
    } else {
      setUsername('')
      setPassword('')
    }
  }, [companyIntegration.id, companyIntegration.type])

  const handleSubmit = () => {
    if (username.length === 0 || password.length === 0) {
      return
    }

    updateCredentials({
      variables: {
        input: {
          companyIntegrationId: companyIntegration.id,
          username,
          password,
        },
      },
    })
      .then(() => snackbar.showSuccess('Credentials updated'))
      .catch((error) => snackbar.showError(error.message))

    setOpen(false)
  }

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle>Update Credentials for {companyIntegration.type}</DialogTitle>
      <DialogContent>
        {supportsCredentialsAutoRotation(companyIntegration.type) && (
          <Alert severity="warning">
            This integration supports credentials auto-rotation. If you manually update the
            credentials, you also need to update them in 1password.
          </Alert>
        )}
        <TextField
          fullWidth
          label="Username"
          value={username}
          onChange={(ev) => setUsername(ev.target.value)}
          style={{ marginTop: 8 }}
        />
        <TextField
          fullWidth
          label="Password"
          type="password"
          value={password}
          onChange={(ev) => setPassword(ev.target.value)}
          style={{ marginTop: 8 }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)}>Cancel</Button>
        <Button onClick={handleSubmit} color="primary">
          Update credentials
        </Button>
      </DialogActions>
    </Dialog>
  )
}
