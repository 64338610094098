import { gql } from '@apollo/client'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import {
  Alert,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  TextField,
  Theme,
  Tooltip,
} from '@mui/material'
import _ from 'lodash'
import moment from 'moment-timezone'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { DAY_FORMAT, OPT_OUT_FORMAT } from 'siteline-common-all'
import { SitelineText, colors, makeStylesFast, useSitelineSnackbar } from 'siteline-common-web'
import {
  FormTemplateProperties,
  useContractsWithTemplateQuery,
  useFormTemplateContractsForDueDateQuery,
  useUpdateFormTemplateDueDateMutation,
} from '../../common/graphql/apollo-operations'

gql`
  query formTemplateContractsForDueDate($input: ContractsByIdInput!) {
    contractsById(input: $input) {
      id
      skippedPayAppMonths
      daysBeforePayAppDue
      timeZone
      project {
        id
        name
        metadata {
          payAppDueOnDayOfMonth
        }
      }
      payApps {
        id
        payAppNumber
        billingEnd
        internalDueDate
        status
      }
    }
  }
`

const useStyles = makeStylesFast((theme: Theme) => ({
  root: {
    '& .dateInfo': {
      marginRight: theme.spacing(2),
    },
    '& .dateInputRow': {
      display: 'flex',
      alignItems: 'center',
      '& > :first-child': {
        marginRight: theme.spacing(2),
      },
    },
    '& .suggestionRow': {
      display: 'flex',
      alignItems: 'center',
      '&:not(:first-child)': {
        marginBottom: theme.spacing(2),
      },
    },
    '& .MuiCardHeader-action': {
      marginTop: theme.spacing(0),
      display: 'flex',
      alignItems: 'center',
      alignSelf: 'stretch',
      paddingRight: theme.spacing(2),
    },
  },
  tooltip: {
    backgroundColor: colors.grey90,
    maxWidth: 'unset',
    '& .contractRow': {
      display: 'flex',
      alignItems: 'center',
      '& > :first-child': {
        width: 150,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        marginRight: theme.spacing(2),
      },
      '& > :last-child': {
        whiteSpace: 'nowrap',
      },
    },
  },
  arrow: {
    color: colors.grey90,
  },
}))

interface FormTemplateSelectDueDateProps {
  template: FormTemplateProperties
}

const readableDateFormat = 'M/D/YY'
const SUGGEST_DAYS_AFTER_CREATED = 5

/** Card on the form template admin page for initially setting the form's due date */
export function FormTemplateSelectDueDate({ template }: FormTemplateSelectDueDateProps) {
  const classes = useStyles()
  const snackbar = useSitelineSnackbar()
  const [updateDueDate] = useUpdateFormTemplateDueDateMutation()
  const userTimeZone = moment.tz.guess()

  const { data: contractWithTemplateData } = useContractsWithTemplateQuery({
    variables: {
      input: {
        templateIds: [template.id],
        limit: 0,
      },
    },
  })

  const associatedContractIds = useMemo(
    () => template.associatedContracts.map(({ contract }) => contract.id),
    [template.associatedContracts]
  )
  const allContractIds = useMemo(() => {
    const contractIdsWithFormTemplate =
      contractWithTemplateData?.paginatedContracts.contracts.map(({ id }) => id) ?? []
    return [...contractIdsWithFormTemplate, ...associatedContractIds]
  }, [associatedContractIds, contractWithTemplateData?.paginatedContracts.contracts])
  const { data } = useFormTemplateContractsForDueDateQuery({
    variables: { input: { contractIds: allContractIds } },
  })
  const contracts = useMemo(() => [...(data?.contractsById ?? [])], [data?.contractsById])
  const contractsWithDueDates = useMemo(() => {
    const contractInfo = contracts.map((contract) => {
      const contractTimeZone = contract.timeZone
      const formTemplateCreated = moment.tz(template.createdAt, contractTimeZone)
      // Find the first non-skipped month since the form template was created and use the date of
      // either a pay app in that month or the contract's default due date
      const skippedMonths = contract.skippedPayAppMonths
      const month = formTemplateCreated
      while (skippedMonths.includes(month.format(OPT_OUT_FORMAT))) {
        month.add(1, 'month')
      }
      const monthPayApps = contract.payApps.filter(
        (payApp) => moment.tz(payApp.billingEnd, contractTimeZone).month() === month.month()
      )
      const firstPayApp = _.minBy(monthPayApps, (payApp) =>
        moment.tz(payApp.internalDueDate, contractTimeZone)
      )
      if (firstPayApp) {
        return {
          contractId: contract.id,
          projectName: contract.project.name,
          payAppNumber: firstPayApp.payAppNumber,
          dueDate: moment
            .tz(firstPayApp.internalDueDate, contractTimeZone)
            .format(readableDateFormat),
        }
      }
      return {
        contractId: contract.id,
        projectName: contract.project.name,
        dueDate: formTemplateCreated
          .clone()
          .date(contract.project.metadata.payAppDueOnDayOfMonth)
          .subtract(contract.daysBeforePayAppDue, 'days')
          .format(readableDateFormat),
      }
    })
    return _.orderBy(contractInfo, ({ dueDate }) => dueDate)
  }, [contracts, template.createdAt])
  const firstDueDate = useMemo(() => {
    const dueDate = _.minBy(contractsWithDueDates, (contractInfo) =>
      moment.tz(contractInfo.dueDate, readableDateFormat, userTimeZone)
    )
    return dueDate ? moment.tz(dueDate.dueDate, readableDateFormat, userTimeZone) : null
  }, [contractsWithDueDates, userTimeZone])

  const suggestedDate = useMemo(
    () =>
      moment
        .tz(template.createdAt, userTimeZone)
        .endOf('day')
        .add(SUGGEST_DAYS_AFTER_CREATED, 'days')
        .format(readableDateFormat),
    [template.createdAt, userTimeZone]
  )
  const initialDueDate = useMemo(() => {
    return template.dueDate
      ? moment.tz(template.dueDate, readableDateFormat, userTimeZone).format(readableDateFormat)
      : suggestedDate
  }, [template.dueDate, suggestedDate, userTimeZone])
  const [dueDate, setDueDate] = useState<string>(initialDueDate)

  // When the form template date is set, update the input
  useEffect(() => setDueDate(initialDueDate), [initialDueDate])

  const handleSaveDueDate = useCallback(async () => {
    await updateDueDate({
      variables: {
        input: {
          id: template.id,
          // Convert to format expected by the server
          dueDate: moment.tz(dueDate, readableDateFormat, userTimeZone).format(DAY_FORMAT),
        },
      },
    })
    snackbar.showSuccess(
      'Due date saved! You can always view and edit the due date on the form template info card.'
    )
  }, [dueDate, template.id, snackbar, updateDueDate, userTimeZone])

  return (
    <Card className={classes.root}>
      <CardHeader title="Due date" action={!data && <CircularProgress size={24} />} />
      {data && (
        <>
          <Alert severity="warning" sx={{ margin: '0px 16px' }}>
            Add a due date so this form template can be prioritized appropriately
          </Alert>
          <CardContent>
            <div className="suggestionRow">
              <SitelineText variant="body1" className="dateInfo">
                Form template created:{' '}
                {moment.tz(template.createdAt, userTimeZone).format('MMM D, YYYY')}
              </SitelineText>
            </div>
            {firstDueDate && (
              <div className="suggestionRow">
                <SitelineText
                  variant="body1"
                  className="dateInfo"
                  endIcon={
                    <Tooltip
                      arrow
                      placement="right"
                      classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
                      title={
                        <div>
                          <div className="contractRow">
                            <strong>Project</strong>
                            <strong>Due date</strong>
                          </div>
                          {contractsWithDueDates.map((contractInfo) => (
                            <div key={contractInfo.contractId} className="contractRow">
                              <div>{contractInfo.projectName}</div>
                              <div>
                                {contractInfo.dueDate}
                                {contractInfo.payAppNumber
                                  ? ` (Pay App #${contractInfo.payAppNumber})`
                                  : ' (expected)'}
                              </div>
                            </div>
                          ))}
                        </div>
                      }
                    >
                      <InfoOutlinedIcon style={{ fontSize: 16, color: colors.grey50 }} />
                    </Tooltip>
                  }
                >
                  Next pay app due: {firstDueDate.format('MMM D, YYYY')}
                </SitelineText>
              </div>
            )}
            <div className="dateInputRow">
              <TextField
                type="date"
                size="small"
                value={moment.tz(dueDate, readableDateFormat, userTimeZone).format(DAY_FORMAT)}
                onChange={(ev) =>
                  setDueDate(moment.tz(ev.target.value, userTimeZone).format(readableDateFormat))
                }
              />
              {moment.tz(dueDate, readableDateFormat, userTimeZone).isValid() && (
                <Tooltip
                  title={
                    dueDate === suggestedDate
                      ? `Suggesting ${SUGGEST_DAYS_AFTER_CREATED} days after form template was created`
                      : ''
                  }
                  placement="right"
                  arrow
                >
                  <Button variant="contained" color="primary" onClick={handleSaveDueDate}>
                    {dueDate === suggestedDate ? 'Accept suggestion' : 'Save'}
                  </Button>
                </Tooltip>
              )}
            </div>
          </CardContent>
        </>
      )}
    </Card>
  )
}
