export const colors = {
  grey10: '#f5f9fa',
  grey20: '#e8f0f2',
  grey30: '#bfd4d9',
  grey40: '#7b939d',
  grey50: '#5f7586',
  grey60: '#475766',
  grey70: '#374553',
  grey80: '#2b3640',
  grey90: '#1d222b',

  purple10: '#fcf5ff',
  purple20: '#f0d9fc',
  purple30: '#d7a1f2',
  purple40: '#b864e3',
  purple50: '#943bbf',
  purple70: '#5b2178',

  blue10: '#e3faff',
  blue20: '#bdeffb',
  blue30: '#47ceff',
  blue40: '#0096d6',
  blue50: '#006bad',
  blue70: '#114174',
  blue90: '#012041',

  green10: '#e8fcef',
  green20: '#c1f6d5',
  green30: '#60e291',
  green40: '#13a45c',
  green50: '#177d4a',
  green70: '#045833',

  yellow10: '#fffde2',
  yellow20: '#fff3a6',
  yellow30: '#ffd745',
  yellow40: '#c98300',
  yellow50: '#9e5f00',
  yellow70: '#7a4a00',

  orange10: '#FFEFE5',
  orange20: '#FFCDB8',
  orange30: '#FFAB8F',
  orange40: '#FF6047',
  orange50: '#D61D00',
  orange70: '#881601',

  red10: '#fef1f2',
  red20: '#ffd1d3',
  red30: '#ff99a0',
  red40: '#fa4c60',
  red50: '#db0025',
  red70: '#940020',

  white: '#ffffff',

  brandNavy: '#0E203A',
  // This is the same color as `orange40`
  brandOrange: '#FF6047',
}
