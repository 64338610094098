import { FormControlLabel, Switch } from '@mui/material'

export type PreviewPosition = 'bottom' | 'right'

type PreviewPositionSelectorProps = {
  previewPosition: PreviewPosition
  setPreviewPosition: (previewPosition: PreviewPosition) => void
}

export function PreviewPositionSelector({
  previewPosition,
  setPreviewPosition,
}: PreviewPositionSelectorProps) {
  return (
    <FormControlLabel
      control={
        <Switch
          checked={previewPosition === 'right'}
          onChange={(ev) => setPreviewPosition(ev.target.checked ? 'right' : 'bottom')}
          color="primary"
        />
      }
      label="Right Preview"
    />
  )
}
