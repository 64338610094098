import { Link } from '@mui/material'
import Linkify from 'linkify-react'
import type { IntermediateRepresentation } from 'linkifyjs'
import reactStringReplace from 'react-string-replace'

// React-mentions classes
export const SUGGESTIONS_LIST_CONTAINER_CLASS = 'textbox__suggestions'
export const SUGGESTIONS_LIST_UL_CLASS = 'textbox__suggestions__list'
export const SUGGESTION_LI_CLASS = 'textbox__suggestions__item'
export const FOCUSED_SUGGESTION_CLASS = 'textbox__suggestions__item--focused'
export const MENTION_CLASS = 'mentioned'

// Identifies format used by the react-mentions library
// i.e. @[name](28 character uuid)
const MENTION_MATCH = /(@\[.+?\]\([a-zA-Z0-9]{28}\))/gm
const USER_ID_MATCH = /(\([a-zA-Z0-9]{28}\))/gm
const BRACKETS_MATCH = /[[\]]/g

export const MENTION_CLASS_NAME = 'commentMention'
export const LINK_CLASS_NAME = 'commentLink'

export function deriveMentionsFromComment(comment: string) {
  const mentions = comment.match(MENTION_MATCH)
  return (mentions ?? []).map((mention) => {
    const minusUserId = mention.replace(USER_ID_MATCH, '')
    const minusBrackets = minusUserId.replace(BRACKETS_MATCH, '')
    return minusBrackets.replace(/@/g, '')
  })
}

export function commentToReadableText(comment: string) {
  const replaced = comment.replaceAll(MENTION_MATCH, (match) => {
    const removeUserId = match.replace(USER_ID_MATCH, '')
    return removeUserId.replace(BRACKETS_MATCH, '')
  })
  return replaced
}

/**
 * Converts a comment string with urls and mentions (@[name] (uuid) format) into an array with
 * urls wrapped in an <a> tag and mentions wrapped in a styled span
 */

export function parseCommentURLsAndMentions(comment: string) {
  const withMentions = reactStringReplace(comment, MENTION_MATCH, (match, index) => {
    const removeUserId = match.replace(USER_ID_MATCH, '')
    const formatted = removeUserId.replace(BRACKETS_MATCH, '')
    return (
      <span key={`mention-${index}`} className={MENTION_CLASS_NAME}>
        {formatted}
      </span>
    )
  })

  const withMentionsAndUrls = withMentions.map((mentionOrString, index) => {
    if (typeof mentionOrString === 'string') {
      const renderLink = ({ attributes, content }: IntermediateRepresentation) => {
        const { href, ...props } = attributes
        return (
          <Link
            {...props}
            href={href}
            underline="hover"
            target="_blank"
            rel="noopener noreferrer"
            className={LINK_CLASS_NAME}
          >
            {content}
          </Link>
        )
      }
      return (
        <Linkify key={index} options={{ render: renderLink }}>
          {mentionOrString}
        </Linkify>
      )
    } else {
      return mentionOrString
    }
  })

  return withMentionsAndUrls
}
